import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { GroupService } from '@app/shared/services';
import { AuthService } from '@app/auth/auth.service';
import { Group, Role, User } from '@app/shared/models';
import { cloneDeep } from 'lodash';
import { GroupFormComponent } from '../group-form/group-form.component';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-group-dialog',
  templateUrl: './group-dialog.component.html',
  styleUrls: ['./group-dialog.component.scss'],
})
export class GroupDialogComponent implements OnInit {
  public Role = Role;
  group: Group = {};
  user: User | null = this.authService.getUser();
  @ViewChild('groupForm') groupForm: GroupFormComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public id: string | null,
    public groupDialog: MatDialogRef<GroupDialogComponent>,
    private groupService: GroupService,
    public authService: AuthService
  ) {}

  public disabledSubmit: boolean = false;

  ngOnInit(): void {
    this.disabledSubmit = false;
    if (this.id && typeof this.id === 'string') {
      this.getGroup(this.id);
    }
  }

  displayResourceDetails(): boolean {
    if (this.user?.roles) {
      if (this.user?.roles.includes(Role.sv_admin) && this.id) {
        return true;
      }
      return false;
    }
    return false;
  }

  getGroup(id: string) {
    this.groupService
      .get(id)
      .pipe(take(1))
      .subscribe((data) => {
        this.group = cloneDeep(data);
      });
  }

  // Handles group changes emitted from from component
  onGroupChange(group: any) {
    this.group = group;
  }

  hasRequiredData() {
    if (this.group && this.group.name) {
      return true;
    }
    return false;
  }

  onSubmit() {
    this.disabledSubmit = true;
    this.groupService
      .save(this.group)
      .pipe(take(1))
      .subscribe((data) => {
        this.groupDialog.close(data);
      });
  }

  static openDialog(
    dialog: MatDialog,
    group?: Group
  ): MatDialogRef<GroupDialogComponent> {
    return dialog.open<GroupDialogComponent>(GroupDialogComponent, {
      data: group,
      width: '750px',
    });
  }
}
