import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FilterParams, View } from '@app/shared/models';
import { ActionButton } from '@app/shared/models/actionbutton.model';
import { SortSelect } from '../sort/sort.component';
import { MetricsObservable } from '@app/shared/services';
import { FilterComponent } from '../filter/filter.component';
import { CollectionView } from '../view/view.component';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FilterConfig } from '@shared/components/advanced-search/filter-config';
import { AuthService } from '@app/auth/auth.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FnGroupDialogComponent } from '@app/foreign-visitors/fn-group-dialog/fn-group-dialog.component';

@Component({
  selector: 'app-search-toolbar',
  templateUrl: './search-toolbar.component.html',
  styleUrls: ['./search-toolbar.component.scss'],
})
export class SearchToolbarComponent implements OnInit, AfterViewInit {
  @ViewChild('appFilter') appFilter: FilterComponent;
  @ViewChild('slideToggle', { read: ElementRef }) element:
    | ElementRef
    | undefined;
  @Input() filterPlaceholder: string = 'Given Name, Surname';
  @Input() filterParams: FilterParams;
  @Input() filterFields: FilterConfig[] = [];
  @Input() sortFields: SortSelect[] = [];
  @Input() columns: string[];
  @Input() columnLabels: any;
  @Input() tableName: string = '';
  @Input() actionButtons: ActionButton[];
  @Input() collectionViews: View[];
  @Input() compactCard: boolean = false;
  @Input() view: CollectionView = 'table';
  @Input() loading: boolean = false;
  @Output() viewChange = new EventEmitter<CollectionView>();
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  @Output() groupSelectChange = new EventEmitter<any>();
  _hideViewToggle: boolean = false;
  showAllGroups: boolean = false;
  groupSelectionMode: boolean = false;

  userId: string = '';

  @Input() set hideViewToggle(value: any) {
    this._hideViewToggle = coerceBooleanProperty(value);
  }
  get hideViewToggle(): boolean {
    return this._hideViewToggle;
  }

  constructor(
    private _MetricsObservable: MetricsObservable,
    private authService: AuthService,
    private dialog: MatDialog
  ) {}
  ngOnInit(): void {
    if (this.tableName === 'groupTable') {
      this.userId = this.authService.getUser()?.id || '';
    }
  }

  ngAfterViewInit() {
    //Remove Slide Toggle Icons
    if (this.element) {
      this.element.nativeElement
        .querySelector('.mdc-switch__icon--on')
        .firstChild.setAttribute('d', '');
      this.element.nativeElement
        .querySelector('.mdc-switch__icon--off')
        .firstChild.setAttribute('d', '');
    }
  }

  onViewChange(view: CollectionView) {
    this.viewChange.emit(view);
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParams = filterParams;
    this.refresh();

    this._MetricsObservable.send('update');
  }

  groupToggleChange(change: boolean) {
    if (!change) {
      this.filterParams.createdBy = this.userId;
    } else {
      delete this.filterParams.createdBy;
    }
    this.refresh();
  }

  refresh() {
    this.filterParamsChange.emit(this.filterParams);
  }

  selectFvGroup() {
    this.groupSelectionMode = true;
    this.groupSelectChange.emit(this.groupSelectionMode);
  }

  cancelSelectFvGroup() {
    this.groupSelectionMode = false;
    this.groupSelectChange.emit(this.groupSelectionMode);
  }

  createFvGroup() {
    this.groupSelectChange.emit(null);
  }

  createEmptyGroup() {
    let dialogRef: MatDialogRef<FnGroupDialogComponent> =
      this.dialog.open<FnGroupDialogComponent>(FnGroupDialogComponent, {
        data: null,
      });
    dialogRef.afterClosed().subscribe(() => {
      this.refresh();
    });
  }
}
