import { Input, Component } from '@angular/core';
import { Address, FarLocation } from '@app/shared/models';
import { FarLocationPipe } from '@shared/pipes/far-location.pipe';

@Component({
  selector: 'app-itinerary-table',
  templateUrl: './itinerary-table.component.html',
  styleUrls: ['./itinerary-table.component.scss'],
})
export class ItineraryTableComponent {
  @Input() foreignAccessRequestLocations: FarLocation[];
  private pipe: FarLocationPipe = new FarLocationPipe();

  innerDisplayedColumns: string[] = [
    'startDate',
    'endDate',
    'locationName',
    'locationAddress',
    'orgShortName',
  ];

  addressFieldsHaveData(address: Address): boolean {
    if (
      address.line1 ||
      address.line2 ||
      address.city ||
      address.countryCode ||
      address.postalCode
    )
      return true;
    return false;
  }

  buildCityStateDisplay(city: string, state: string) {
    return this.pipe.buildCityStateDisplay({
      city: city,
      stateProvince: state,
    });
  }

  constructor() {}
}
