import { HttpClient, HttpContext } from '@angular/common/http';
import { BaseModel, FilterParams, PageableCollection } from '@shared/models';
import { Observable } from 'rxjs';
import { EventEmitter } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ENTITY } from '../helpers/success-message.interceptor';

export interface CrudSettings {
  apiUrl: string;
  entity?: string | null;
  hasIdPathUpdate?: boolean;
}

export function Crud(settings: CrudSettings): Function {
  return (constructor: Function) => {
    Object.assign(constructor.prototype, settings);
  };
}

export enum CrudChangeType {
  create = 'create',
  update = 'update',
  delete = 'delete',
}

export interface CrudChangeEvent<T extends BaseModel> {
  type: CrudChangeType | string;
  model?: T;
  modelId?: string;
  component?: string;
}

export class CrudService<T extends BaseModel> implements CrudSettings {
  changeEvent$ = new EventEmitter<CrudChangeEvent<T>>();

  apiUrl: string;
  hasIdPathUpdate: boolean;
  context: HttpContext = new HttpContext();
  entity: string;

  constructor(protected http: HttpClient) {
    if (!this.apiUrl) {
      console.error('CrudService missing @Crud() decorator');
    }
    if (this.entity) {
      this.context.set(ENTITY, this.entity);
    }
  }

  /**
   * fires a request to find multiple resources
   *
   * @param {FilterParams} filterParams filter parameters
   *
   * @return {Observable<PageableCollection<T>>} paged collection of resources
   */
  public find(filterParams?: FilterParams): Observable<PageableCollection<T>> {
    return this.http.get<PageableCollection<T>>(`${this.apiUrl}`, {
      params: filterParams?.httpParams,
    });
  }

  /**
   * fires a request to read a single resource
   *
   * @param {Id} id identifier of the resource
   *
   * @return {Observable<T>} resource
   */
  public get(id: any): Observable<T> {
    return this.http.get<T>(`${this.apiUrl}/${id}`, { context: this.context });
  }

  /**
   * saves a resource - calls create for new, and
   * update for existing
   *
   * @param {T} model body of the request
   *
   * @return {Observable<T>} saved resource
   */
  public save(model: T, context: HttpContext = this.context): Observable<T> {
    return !model.id
      ? this.create(model, context)
      : this.update(model.id, model, context);
  }

  /**
   * fires a request to create a single resource
   *
   * @param {T} model body of the request
   *
   * @return {Observable<T>} created resource
   */
  public create(model: T, context: HttpContext = this.context): Observable<T> {
    return this.http
      .post<T>(`${this.apiUrl}`, model, { context: context })
      .pipe(
        tap((m) => {
          this.changeEvent$.emit({
            type: CrudChangeType.create,
            model: m,
            modelId: m.id,
          });
        })
      );
  }

  /**
   * fires a request to update a single resource
   *
   * @param {Id} id identifier of the resource
   * @param {T} model body of the request
   *
   * @return {Observable<T>} updated resource
   */
  public update(
    id: any,
    model: T,
    context: HttpContext = this.context
  ): Observable<T> {
    /**
     * TODO: Remove once All PUT Methods in api are consistent.
     * Currently some api PUT methods require an id in the path and some do not.  API indicated that the
     * pattern needs to be a standard pattern.  The API will need to make a decision
     * between not having the id in the path or no id in the path as the id is included in the model being submitted.
     * Having a separate id in the path is redundant.
     */
    let apiUrl = this.hasIdPathUpdate ? `${this.apiUrl}/${id}` : this.apiUrl;
    return this.http.put<T>(apiUrl, model, { context: context }).pipe(
      tap((m) =>
        this.changeEvent$.emit({
          type: CrudChangeType.update,
          model: m,
          modelId: id,
        })
      )
    );
  }

  /**
   * fires a request to delete a single resource
   *
   * @param {Id} id identifier of the resource
   *
   * @return {Observable<T>} http response
   */
  public delete(
    id: any,
    archive: boolean = false,
    context: HttpContext = this.context
  ): Observable<T> {
    const url = archive
      ? `${this.apiUrl}/${id}/archive`
      : `${this.apiUrl}/${id}`;
    return this.http.delete<T>(url, { context: context }).pipe(
      tap((m) =>
        this.changeEvent$.emit({
          type: CrudChangeType.delete,
          modelId: id,
        })
      )
    );
  }
}
