<mat-button-toggle-group
  (change)="onToggleChange($event)"
  [hideSingleSelectionIndicator]="true"
>
  <mat-button-toggle
    [checked]="view === 'card'"
    value="card"
    matTooltip="Card View"
  >
    <mat-icon>apps</mat-icon>
  </mat-button-toggle>
  <mat-button-toggle
    [checked]="view === 'table'"
    value="table"
    matTooltip="Table View"
  >
    <mat-icon>list</mat-icon>
  </mat-button-toggle>
</mat-button-toggle-group>
