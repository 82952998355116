import { Component, Input } from '@angular/core';
@Component({
  selector: 'landing-page-card',
  templateUrl: 'landing-page-card.component.html',
  styleUrls: ['landing-page-card.component.scss'],
})
export class LandingPageCardComponent {
  @Input() public title: any;
  @Input() public content: any;
  @Input() public imgSrc: any;
  @Input() public imgAlt: any;
  @Input() public clickable? = false;
  @Input() public routerLink?: string;
}
