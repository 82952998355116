<mat-table #innerTables [dataSource]="contacts">
  <ng-container [matColumnDef]="'givenName'">
    <mat-header-cell class="justify-content-center" *matHeaderCellDef>
      Given Name
    </mat-header-cell>
    <mat-cell class="justify-content-center" *matCellDef="let contact">
      {{ contact.givenName | default }}
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="'surname'">
    <mat-header-cell class="justify-content-center" *matHeaderCellDef>
      Surname
    </mat-header-cell>
    <mat-cell class="justify-content-center" *matCellDef="let contact">
      {{ contact.surname | default }}
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="'groupName'">
    <mat-header-cell class="justify-content-center" *matHeaderCellDef>
      Group Name
    </mat-header-cell>
    <mat-cell class="justify-content-center" *matCellDef="let contact">
      {{ contact.groupName | default }}
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="'type'">
    <mat-header-cell class="justify-content-center" *matHeaderCellDef>
      Contact Type
    </mat-header-cell>
    <mat-cell class="justify-content-center" *matCellDef="let contact">
      <span *ngIf="contact.type === 'OrgAdminApprover'"
        >Organization Admin Approver</span
      >
      <span *ngIf="contact.type === 'Screening'">Screening</span>
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="'phone'">
    <mat-header-cell class="justify-content-center" *matHeaderCellDef>
      Phone Number
    </mat-header-cell>
    <mat-cell class="justify-content-center" *matCellDef="let contact">
      {{ contact.phone | default }}
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="'email'">
    <mat-header-cell class="justify-content-center" *matHeaderCellDef>
      Email
    </mat-header-cell>
    <mat-cell class="justify-content-center" *matCellDef="let contact">
      {{ contact.email | default }}
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="'address'">
    <mat-header-cell class="justify-content-center" *matHeaderCellDef>
      Address
    </mat-header-cell>
    <mat-cell class="justify-content-center" *matCellDef="let contact">
      <div>
        <div>{{ contact.address | address: "street" }}</div>
        <div>{{ contact.address | address: "cityState" }}</div>
        <div>
          {{ contact.address?.countryCode | country: "alpha3" }}
          {{ contact.address?.postalCode }}
        </div>
        <div *ngIf="!isContactAddress(contact)">N/A</div>
      </div>
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="'contactActions'">
    <mat-header-cell class="justify-content-center" *matHeaderCellDef>
      Actions
    </mat-header-cell>
    <mat-cell class="justify-content-center" *matCellDef="let contact">
      <button
        mat-icon-button
        type="button"
        #actionTrigger="matMenuTrigger"
        [matMenuTriggerFor]="contactActionMenu"
      >
        <mat-icon matTooltip="Click For Actions">more_vert</mat-icon>
      </button>
      <mat-menu #contactActionMenu="matMenu">
        <button
          mat-menu-item
          [appContactEdit]="{ id: contact.id }"
          (appContactEdited)="refresh()"
          id="add-contact-button"
        >
          <mat-icon svgIcon="square-edit-outline"></mat-icon> Edit
        </button>
      </mat-menu>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="innerDisplayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: innerDisplayedColumns"></mat-row>
</mat-table>
