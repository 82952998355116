<form id="groupForm" #groupForm="ngForm">
  <mat-form-field [appearance]="appearance" class="mt-2">
    <mat-label>Name</mat-label>
    <input
      matInput
      required
      [disabled]="
        (value.id && !value.isEditable) ||
        value.foreignVisitorGroupType === 'excel_import'
      "
      [(ngModel)]="value.name"
      name="name"
      maxlength="150"
      (ngModelChange)="emitChangeEvent()"
      id="group-name"
    />
    <mat-error>Group Name is required</mat-error>
  </mat-form-field>
  <mat-form-field [appearance]="appearance">
    <mat-label>Description</mat-label>
    <textarea
      id="group-description-input"
      #groupDescription="ngModel"
      [disabled]="
        (value.id && !value.isEditable) ||
        value.foreignVisitorGroupType === 'excel_import'
      "
      matInput
      rows="5"
      [(ngModel)]="value.description"
      (ngModelChange)="emitChangeEvent()"
      name="group-description"
      maxlength="2000"
    ></textarea>
  </mat-form-field>
  <ng-container *ngIf="value.ownerOrganizationId">
    <app-organization-select
      [disabled]="true"
      [orgId]="value.ownerOrganizationId"
      name="owning-organization"
      *appIfRoles="Role.sv_admin"
    >
    </app-organization-select>
  </ng-container>
</form>
