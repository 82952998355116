import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Approval } from '@app/shared/models/approval';
import { FilterParams, SavedUserPreference } from '@shared/models';
import { Subject } from 'rxjs';
import {
  ApprovalService,
  ApprovalSortField,
  ApprovalSortFieldLabels,
} from '@shared/services/approval.service';
import { ColumnChooserEventObservable } from '@shared/components/column-chooser/column-chooser-observable.service';
import { takeUntil } from 'rxjs/operators';
import { ColumnChooserComponent } from '@shared/components';
import { UserPreferenceService } from '@app/shared/services/user-preference.service';

export const ApprovalTableColumns = [
  ApprovalSortField.NAME,
  ApprovalSortField.EMAIL,
  ApprovalSortField.ORGANIZATION,
  ApprovalSortField.APPROVER,
  ApprovalSortField.APPROVAL_DATE,
  ApprovalSortField.EXPIRATION_DATE,
  'actions',
];

export const ApprovalTableName = 'approvalTable';

@Component({
  selector: 'app-approval-table',
  templateUrl: './approval-table.component.html',
  styleUrls: ['./approval-table.component.scss'],
})
export class ApprovalTableComponent implements OnInit, OnDestroy {
  @Input() data: Approval[] = [];
  @Input() filterParams: FilterParams;
  @Output() filterParamsChange = new EventEmitter<FilterParams>();

  private ngUnsubscribe = new Subject<void>();

  ApprovalSortField = ApprovalSortField;
  ApprovalSortFieldLabels = ApprovalSortFieldLabels;

  @Output() sortChange = new EventEmitter<Sort>();

  columns = ApprovalTableColumns;

  approvalColumnsDisplay: { [key: string]: boolean } = this.columns.reduce(
    (val, col) => ({ [col]: true, ...val }),
    {} as { [key: string]: boolean }
  );

  savedUserPreference: SavedUserPreference | undefined;

  constructor(
    private _ColumnChooserEventObservable: ColumnChooserEventObservable,
    private approvalService: ApprovalService,
    private userPreferenceService: UserPreferenceService
  ) {}

  ngOnInit(): void {
    this.userPreferenceService.userPreferenceDisplaySubject$.subscribe((x) => {
      if (x.hasPreference && x.tableName === ApprovalTableName) {
        this.columns = x.columnNames!;
        this.approvalColumnsDisplay = x.selectedColumns!;
      }
    });
    this._ColumnChooserEventObservable.ColumnChooserObservable$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((event) => {
      if (event.tableName === ApprovalTableName) {
        this.approvalColumnsDisplay = ColumnChooserComponent.updateTableDisplay(
          event,
          this.columns,
          this.approvalColumnsDisplay
        );
      }
    });
  }

  onSortChange(sort: Sort) {
    this.filterParams.sortBy = sort.active;
    this.filterParams.direction = sort.direction.toUpperCase();
    this.filterParamsChange.emit(this.filterParams);
  }

  extendApproval(approval: Approval) {
    this.approvalService.save(approval).subscribe();
  }

  refresh() {
    this.filterParamsChange.emit(this.filterParams);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  protected readonly parseInt = parseInt;
}
