import { Component, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { FAR, FilterParams } from '@app/shared/models';
import { CrudService, FarService, Repository } from '@app/shared/services';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [Repository, { provide: CrudService, useExisting: FarService }],
})
export class DashboardComponent implements OnInit {
  fars$ = this.repo.collection$.pipe(
    tap((_) => {
      this.loading = false;
    })
  );
  filterParams = this.repo.defaultFilterParams;
  loading: boolean = false;

  get queryParamsSnapshot(): Params {
    return this.repo.getQueryParamsSnapshot();
  }

  constructor(private repo: Repository<FAR>) {}

  ngOnInit(): void {
    this.loading = true;
    Object.assign(this.filterParams, this.queryParamsSnapshot);
    this.onFilterParamsChange(this.filterParams);
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.navigateWithParams(filterParams);
  }
}
