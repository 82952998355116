<mat-toolbar class="justify-content-between bg-white border-bottom">
  <h1 style="font: 400 24px / 32px Roboto, sans-serif">System Monitoring</h1>
  <button
    mat-icon-button
    color="primary"
    matTooltip="Refresh"
    [disabled]="loading"
    (click)="refresh()"
  >
    <div class="d-flex align-items-center gap-1">
      <mat-icon *ngIf="!loading" svgIcon="refresh"></mat-icon>
      <mat-spinner
        color="primary"
        *ngIf="loading"
        [diameter]="24"
      ></mat-spinner>
    </div>
  </button>
</mat-toolbar>
<div class="metrics-body">
  <div class="container">
    <div class="row mt-2 ml-0 mr-0 mb-2">
      <div class="col pl-1 pr-1" *ngIf="replicationMetrics?.lastEventIdSent">
        <insight-card
          id="last-sent-db-event"
          [svgIcon]="'database-arrow-up'"
          [label]="'Last Transaction Event ID'"
          [value]="replicationMetrics.lastEventIdSent"
          matTooltip="Last DB Transaction Event Sent for replication"
        ></insight-card>
      </div>
      <div class="col pl-1 pr-1" *ngIf="replicationMetrics?.lastEventSentDate">
        <insight-card
          id="last-sent-db-event-date"
          [svgIcon]="'database-clock'"
          [label]="'Last Transaction Event Sent'"
          [value]="replicationMetrics.lastEventSentDate | date: 'medium'"
          matTooltip="Date of Last DB Transaction Event Sent for replication"
        ></insight-card>
      </div>
      <div
        class="col pl-1 pr-1"
        *ngIf="replicationMetrics?.lastScreeningReceivedDate"
      >
        <insight-card
          id="last-received-screening-event"
          [svgIcon]="'fingerprint'"
          [label]="'Last Screening Update'"
          [value]="
            replicationMetrics.lastScreeningReceivedDate | date: 'medium'
          "
          matTooltip="Date of Last Screening Update received"
        ></insight-card>
      </div>
      <div
        class="col pl-1 pr-1"
        *ngIf="replicationMetrics?.lastEventIdProcessed"
      >
        <insight-card
          id="last-processed-db-event"
          [svgIcon]="'database-check'"
          [label]="'Last Processed Transaction Event ID'"
          [value]="replicationMetrics.lastEventIdProcessed"
          matTooltip="Last DB Transaction Event Processed for replication"
        ></insight-card>
      </div>
      <div
        class="col pl-1 pr-1"
        *ngIf="replicationMetrics?.lastEventProcessedDate"
      >
        <insight-card
          id="last-processed-db-event-date"
          [svgIcon]="'database-clock'"
          [label]="'Last Processed Transaction Event Sent'"
          [value]="replicationMetrics.lastEventProcessedDate | date: 'medium'"
          matTooltip="Date of Last DB Transaction Event Processed for replication"
        ></insight-card>
      </div>
      <div
        class="col pl-1 pr-1"
        *ngIf="replicationMetrics?.lastScreeningProcessedDate"
      >
        <insight-card
          id="last-processed-screening-event"
          [svgIcon]="'fingerprint'"
          [label]="'Last Screening Partner Update'"
          [value]="
            replicationMetrics.lastScreeningProcessedDate | date: 'medium'
          "
          matTooltip="Date of Last Screening Update from screening partners"
        ></insight-card>
      </div>
    </div>
    <mat-card
      *ngIf="alerts"
      class="monitor-alerts-card ml-1 mr-1"
      appearance="outlined"
    >
      <mat-card-header>
        <mat-card-title
          >System Issues
          <ng-container *ngIf="alerts.length"
            >({{ alerts.length }})</ng-container
          ></mat-card-title
        >
      </mat-card-header>
      <mat-card-content>
        <mat-list>
          <mat-list-item
            [matTooltip]="alert.details"
            *ngFor="let alert of alerts"
            lines="3"
          >
            <mat-icon
              class="text-danger"
              svgIcon="alert-circle-outline"
              matListItemIcon
            ></mat-icon>
            <div matListItemTitle>{{ alert.message }}</div>
            <span>{{ alert.details }}</span>
            <button
              *ngIf="alert.type === MonitorAlertType.SCREENINGS_DELAYED"
              matListItemMeta
              mat-icon-button
              [routerLink]="'/fvs'"
              [queryParams]="{
                status: 'In Process',
                sortBy: 'lastModifiedDate',
                direction: 'DESC'
              }"
            >
              <mat-icon>open_in_new</mat-icon>
            </button>
          </mat-list-item>
          <mat-list-item *ngIf="!alerts?.length">
            <mat-icon
              class="text-success"
              svgIcon="check-circle-outline"
              matListItemIcon
            ></mat-icon>
            <div matListItemTitle>No Issues Detected</div>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>
</div>
