import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import { AdminComponent, NewAccountComponent } from './admin';
import { HomePageComponent } from './home';
import { LandingPageComponent } from './home/landing-page/landing-page.component';
import { SplashPageComponent } from './home/splash-page/splash-page.component';
import { AuthGuard } from './shared/helpers';
import { Roles } from './shared/models';

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    canActivate: [AuthGuard],
    title: 'Home',
    data: {
      roles: Roles,
    },
  },
  {
    path: 'simple',
    component: LandingPageComponent,
    canActivate: [AuthGuard],
    data: {
      roles: Roles,
    },
  },
  {
    path: 'accept',
    title: 'Notice Acceptance',
    component: SplashPageComponent,
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./error/error.module').then((m) => m.ErrorModule),
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
