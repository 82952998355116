<mat-table #innerTables [dataSource]="foreignAccessRequestLocations">
  <ng-container [matColumnDef]="'startDate'">
    <mat-header-cell class="justify-content-center" *matHeaderCellDef>
      Start Date
    </mat-header-cell>
    <mat-cell class="justify-content-center" *matCellDef="let location">
      {{ location.startDate | date: "mediumDate" }}
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="'endDate'">
    <mat-header-cell class="justify-content-center" *matHeaderCellDef>
      End Date
    </mat-header-cell>
    <mat-cell class="justify-content-center" *matCellDef="let location">
      {{ location.endDate | date: "mediumDate" | default }}
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="'locationName'">
    <mat-header-cell class="justify-content-center" *matHeaderCellDef>
      Location Name
    </mat-header-cell>
    <mat-cell
      class="justify-content-center"
      style="line-break: anywhere"
      *matCellDef="let location"
    >
      {{ location.locationName | titlecase | default }}
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="'locationAddress'">
    <mat-header-cell class="justify-content-center" *matHeaderCellDef>
      Address
    </mat-header-cell>
    <mat-cell class="justify-content-center" *matCellDef="let location">
      <div>
        <div>
          {{ location.addressLine1 }}
        </div>
        <div>
          {{ buildCityStateDisplay(location.city, location.state) }}
        </div>
        <div>
          {{ location.country }}
          {{ location.zip }}
        </div>
      </div>
      <div *ngIf="!addressFieldsHaveData(location)">N/A</div>
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="'orgShortName'">
    <mat-header-cell class="justify-content-center" *matHeaderCellDef>
      Organization
    </mat-header-cell>
    <mat-cell class="justify-content-center" *matCellDef="let location">
      {{ location.orgShortName | default }}
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="innerDisplayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: innerDisplayedColumns"></mat-row>
</mat-table>
