import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CheckBoxProperties, UserPreferenceBlob } from '@app/shared/models';
import { FarSortFieldLabels } from '@app/shared/services';
import { AdditionalTableColumns } from '../user-preferences.component';
import { AuthService } from '@app/auth/auth.service';
import { UserPreferenceService } from '@app/shared/services/user-preference.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import {
  ControlContainer,
  FormArray,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';

@Component({
  selector: 'app-far-tab-checkbox',
  templateUrl: './far-tab-checkbox.component.html',
  styleUrls: ['./far-tab-checkbox.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class FarTabCheckboxComponent implements OnInit, OnChanges {
  @Input() updated: boolean;
  @Input() checkedColumns: Array<string> = [];
  @Input() tabTitle: string;
  @Input() previewName: string;
  tabLabel: string = 'Foreign Access Request (FAR)';
  tabIndex: number;
  _farViewType: string = 'card';
  checkedColumnss: Array<string>;
  farCheckedColumns: Array<CheckBoxProperties> = [];
  farColumnNames: Array<string> = [];
  displayCheckedColumns: Array<CheckBoxProperties> = [];
  _columnNames: Array<string> = [];
  farForm!: FormGroup;

  constructor(
    private userPreferenceService: UserPreferenceService,
    private rootFormGroup: FormGroupDirective
  ) {}

  ngOnInit(): void {
    this.loadCheckboxes();
    this.farForm = this.rootFormGroup.control.get('far') as FormGroup;
  }

  loadCheckboxes(): void {
    this.tabIndex = 1;
    if (this.farColumnNames.length === 0) {
      Object.values(FarSortFieldLabels).forEach((value) => {
        if (value !== 'Last Modified Date') {
          this.farColumnNames.push(value);
          this.farCheckedColumns.push({ selected: true, label: value });
        }
      });
      if (!this.farColumnNames.includes(AdditionalTableColumns.at(0)!)) {
        this.farColumnNames.push(AdditionalTableColumns[0]);
        this.farCheckedColumns.push({
          selected: true,
          label: AdditionalTableColumns[0],
        });
      }

      if (!this.farColumnNames.includes(AdditionalTableColumns.at(1)!)) {
        this.farColumnNames.push(AdditionalTableColumns[1]);
        this.farCheckedColumns.push({
          selected: true,
          label: AdditionalTableColumns[1],
        });
      }
    }
    this.displayCheckedColumns = this.farCheckedColumns;
    this._columnNames = this.farColumnNames;
    this.displayCheckedColumns = this.setDisplayCheckedColumns(
      this.farCheckedColumns,
      this.setCheckedColumns(this.farCheckedColumns)
    );
  }

  chooseView($event: MatButtonToggleChange) {
    this._farViewType = $event.value;
    this.farForm.get('farViewType')?.setValue($event.value);
    $event.source.checked = true;
  }

  selectedColumns(selectedOption: MatCheckboxChange) {
    this.displayCheckedColumns.forEach((c) => {
      if (selectedOption.source.name === c.label) {
        selectedOption.checked ? (c.selected = true) : (c.selected = false);
      }
    });
    this.farCheckboxes.controls.forEach((value, index) => {
      const newValue = value.value;
      if (newValue.label === selectedOption.source.name) {
        if (selectedOption.checked) {
          value.patchValue({ selected: true, label: newValue.label });
        } else {
          value.patchValue({ selected: false, label: newValue.label });
        }
      }
    });

    this.checkedColumnss = this.setCheckedColumns(this.displayCheckedColumns);
  }

  toggleAll(toggle: boolean) {
    this.farCheckedColumns = [];
    this.checkedColumnss = [];
    if (toggle) {
      this.farCheckboxes.controls.forEach((value, index) => {
        const newValue = value.value;
        this.farCheckedColumns.push({ selected: true, label: newValue.label });
        value.patchValue({ selected: true, label: newValue.label });
        this.checkedColumnss.push(newValue.label);
      });
    } else {
      this.farCheckboxes.controls.forEach((value, index) => {
        const newValue = value.value;
        this.farCheckedColumns.push({ selected: false, label: newValue.label });
        value.patchValue({ selected: false, label: newValue.label });
      });

      this.checkedColumnss = [];
    }
    this.displayCheckedColumns = this.farCheckedColumns;
  }

  get farCheckboxes(): FormArray {
    return this.farForm.get('farCheckboxes') as FormArray;
  }

  setDisplayCheckedColumns(
    checkedArray: Array<CheckBoxProperties>,
    checkedArrayColumns: string[]
  ): Array<CheckBoxProperties> {
    let trueCheckedColumns: Array<CheckBoxProperties> = [];
    checkedArray.forEach((c) => {
      checkedArrayColumns.includes(c.label)
        ? trueCheckedColumns.push({
            selected: true,
            label: c.label,
          })
        : trueCheckedColumns.push({
            selected: false,
            label: c.label,
          });
    });

    return trueCheckedColumns;
  }

  setCheckedColumns(checkedArray: Array<CheckBoxProperties>): string[] {
    let trueCheckedColumns: string[] = [];
    checkedArray.forEach((c) => {
      if (c.selected) {
        trueCheckedColumns.push(c.label);
      }
    });
    return trueCheckedColumns;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.userPreferenceService.updated.subscribe((result) => {
      if (result) {
        this.userPreferenceService.updateCheckboxes.subscribe((checkit) => {
          if (this.tabLabel === checkit.viewName) {
            this._farViewType = checkit.viewType!;
            this.checkedColumnss = this.setCheckedColumns(checkit.checkboxes!);
            this.farCheckedColumns = checkit.checkboxes!;
            this.checkedColumnss = this.setCheckedColumns(
              this.farCheckedColumns
            );
            this.displayCheckedColumns = this.setDisplayCheckedColumns(
              this.farCheckedColumns,
              this.checkedColumnss
            );
            this.farForm.get('farViewType')?.patchValue(checkit.viewType!);
            this._farViewType = this.farForm.get('farViewType')?.value;
            this.farCheckboxes.controls.forEach((value, index) => {
              const newValue = value.value;
              checkit.checkboxes?.filter((x) => {
                if (x.label === newValue.label) {
                  value.patchValue({ selected: x.selected, label: x.label });
                }
              });
            });
          }
        });
      }
    });
    if (changes['tabTitle']) {
      if (changes['tabTitle'].currentValue !== null) {
        this.tabTitle = changes['tabTitle'].currentValue;
      }
    }
  }
}
