<div class="system-alerts-collection">
  <mat-toolbar class="system-alerts__toolbar justify-content-between bg-white">
    <span style="font: 400 24px / 32px Roboto, sans-serif">System Alerts</span>
    <button
      mat-button
      color="primary"
      class="system-alerts__create-action"
      (click)="onCreate()"
      data-cy="create-alert"
    >
      <div class="d-flex align-items-center gap-1">
        <mat-icon svgIcon="bullhorn-variant-outline"></mat-icon>
        <span>New System Alert</span>
      </div>
    </button>
  </mat-toolbar>
  <mat-progress-bar
    color="accent"
    [mode]="loading ? 'indeterminate' : 'determinate'"
  ></mat-progress-bar>

  <ng-container *ngIf="systemAlertsCollection">
    <div
      class="system-alerts-collection__container collection-content container"
    >
      <mat-table [dataSource]="systemAlertsCollection.content">
        <!-- #region column definitions -->
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>
            <span>Status</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let systemAlert">
            <span>
              {{ systemAlert | status | titlecase }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="message">
          <mat-header-cell *matHeaderCellDef>Message</mat-header-cell>
          <mat-cell *matCellDef="let systemAlert; let index = index">
            <span>
              {{ systemAlert.message }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="startDateTime">
          <mat-header-cell *matHeaderCellDef="">Starts</mat-header-cell>
          <mat-cell *matCellDef="let systemAlert"
            >{{ systemAlert.startDate | date }} at
            {{ systemAlert.startDate | date: "mediumTime" }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="endDateTime">
          <mat-header-cell *matHeaderCellDef="">Ends</mat-header-cell>
          <mat-cell *matCellDef="let systemAlert"
            >{{ systemAlert.endDate | date }} at
            {{ systemAlert.endDate | date: "mediumTime" }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef="">Actions</mat-header-cell>
          <mat-cell
            *matCellDef="let systemAlert; let i = index; let even = even"
          >
            <mat-menu #actions="matMenu">
              <button
                mat-menu-item
                (click)="onEdit(systemAlert)"
                data-cy="edit-alert"
              >
                <mat-icon
                  svgIcon="square-edit-outline"
                  color="primary"
                ></mat-icon>
                <span>Edit</span>
              </button>
              <button
                mat-menu-item
                (click)="onDelete(systemAlert)"
                data-cy="delete-alert"
              >
                <mat-icon svgIcon="delete-outline" color="warn"></mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
            <button
              mat-icon-button
              [matMenuTriggerFor]="actions"
              data-cy="more-actions"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <!-- #endregion column definitions -->

        <!-- #region no Data Row -->

        <ng-template matNoDataRow>
          <mat-toolbar class="justify-content-center bg-white"
            >No System Alerts Available</mat-toolbar
          >
        </ng-template>
        <!-- #endregion no Data Row -->

        <!-- #region Header and Row Definitions -->
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        <!-- #endregion Header and Row Definitions -->
      </mat-table>
    </div>

    <div
      [class.position--fixed]="position === 'fixed'"
      [class.position--sticky]="position === 'sticky'"
    >
      <mat-paginator
        [length]="systemAlertsCollection?.totalElements"
        [pageSize]="systemAlertsCollection.size"
        [pageIndex]="systemAlertsCollection.number"
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        (page)="onPageChange($event)"
        [showFirstLastButtons]="true"
      ></mat-paginator>
    </div>
  </ng-container>
</div>
