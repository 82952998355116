import { Component, Input } from '@angular/core';
import { Role, Group } from '@app/shared/models';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlComponent } from '@app/shared/controls/base-control.component';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { isEmpty } from 'lodash';
@Component({
  selector: 'app-group-form',
  templateUrl: './group-form.component.html',
  styleUrls: ['./group-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: GroupFormComponent,
      multi: true,
    },
  ],
})
export class GroupFormComponent extends BaseControlComponent<Group> {
  @Input() appearance: MatFormFieldAppearance = 'outline';
  Role = Role;
  invalid: boolean = true;

  constructor() {
    super();
  }

  writeValue(value: Group): void {
    this.value = value || {};
  }

  hasValidValues() {
    if (this.value.name && this.value.ownerOrganizationId) {
      return true;
    }
    return false;
  }
}
