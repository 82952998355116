import {
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { FilterParams, Roles } from '@shared/models';
import {
  clearField,
  FilterConfig,
  FilterConfigType,
  resolveProp,
} from '@shared/components/advanced-search/filter-config';
import { isEmpty, isString, result } from 'lodash';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Directive({
  selector: '[appAdvancedSearch]',
})
export class AdvancedSearchDirective {
  @Input() appAdvancedSearch: FilterConfig[] = [];
  @Input() filterParams: FilterParams;
  @Output() appSearchUpdated = new EventEmitter<FilterParams>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    const dialog = AdvancedSearchComponent.openDialog(
      this.dialog,
      this.filterParams,
      this.appAdvancedSearch
    );
    dialog.afterClosed().subscribe((result) => {
      if (result) return this.appSearchUpdated.emit(result);
    });
  }
}

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss'],
})
export class AdvancedSearchComponent implements OnInit {
  // Just saving filter ref objs, in case needed from special filters (FN, Org, etc)
  filterRefObjs: { [key: string]: any } = {};

  FilterConfigType = FilterConfigType;

  data: FilterParams;
  filterFields: FilterConfig[];

  resolveProp = resolveProp;

  constructor(
    @Inject(MAT_DIALOG_DATA) public inputData: AdvancedSearchDialogConfig,
    public dialogRef: MatDialogRef<AdvancedSearchComponent>
  ) {
    this.data = inputData.filterParams
      ? inputData.filterParams.clone()
      : new FilterParams();
    this.filterFields = inputData.filterFields;
  }

  ngOnInit(): void {
    this.filterFields?.forEach((config) => {
      if (config.type === FilterConfigType.TOGGLE) {
        this.data[config.name] = coerceBooleanProperty(this.data[config.name]);
      }
    });
  }

  clear() {
    this.filterFields?.forEach((config) => {
      clearField(config, this.data);
      delete this.filterRefObjs[config.name];
    });
  }

  submit() {
    this.trimSpace();
    this.dialogRef.close(this.data);
  }

  trimSpace() {
    this.filterFields?.forEach((config) => {
      if (isString(this.data[config.name])) {
        this.data[config.name] = this.data[config.name].trim();
        if (isEmpty(this.data[config.name])) clearField(config, this.data);
      }
    });
  }

  static openDialog(
    dialog: MatDialog,
    filterParams: FilterParams,
    filterFields: FilterConfig[]
  ): MatDialogRef<AdvancedSearchComponent, FilterParams> {
    return dialog.open<
      AdvancedSearchComponent,
      AdvancedSearchDialogConfig,
      FilterParams
    >(AdvancedSearchComponent, {
      data: { filterParams, filterFields },
      minWidth: 650,
      width: '30%',
      disableClose: true, //must click button on dialog to close
    });
  }

  protected readonly Roles = Roles;
}

export interface AdvancedSearchDialogConfig {
  filterParams: FilterParams;
  filterFields: FilterConfig[];
}
