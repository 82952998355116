export enum AuthEventType {
  SIGN_IN = 'SignIn',
  SIGN_UP = 'SignUp',
  FORGOT_PASSWORD = 'ForgotPassword',
}

export enum AuthEventResponse {
  PASS = 'Pass',
  FAIL = 'Fail',
}

export interface AuthEvent {
  id: string;
  type: AuthEventType;
  createdDate: Date;
  response: AuthEventResponse;
  ipAddress: string;
  deviceName: string;
  city: string;
  country: string;
}

export interface AuthSummary {
  loginEventId: string;
  lastLogin?: Date | null;
  failuresSince: number;
}
