import {
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Injector,
  Input,
  Output,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { User } from '@app/shared/models';
import { Approval } from '@app/shared/models/approval';
import { ApprovalService } from '@shared/services/approval.service';

@Directive({
  selector: '[appAddApproval]',
})
export class AddApprovalDirective {
  @Input() user: User;

  @Input() approval: Approval;

  @Output() approvalSubmit = new EventEmitter<Approval>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click')
  onClick() {
    const dialogRef = AddApprovalComponent.openDialog(this.dialog, {
      user: this.user,
      approval: this.approval,
      extendApproval: !!this.user,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.approvalSubmit.emit(result);
      }
    });
  }
}

@Component({
  selector: 'app-add-approval',
  template: `<p
      mat-dialog-title
      class="pt-3 d-flex flex-row align-items-center"
    >
      <span *ngIf="extendApproval && !viewOnly">Extend Approval</span>
      <ng-container *ngIf="!extendApproval && viewOnly">
        <span class="flex-grow-1">Approved ({{ approval.approvalDate }})</span>
        <span *ngIf="approval.endedDate" class="text-danger"
          >Inactive ({{ approval.endedDate }})</span
        >
      </ng-container>
      <span *ngIf="!extendApproval && !viewOnly">Approval Required</span>
    </p>
    <mat-dialog-content class="py-3">
      <form [class.form-inputs-read-only]="viewOnly">
        <app-approval-control
          [disabled]="viewOnly"
          #approvalCtrl
          [(ngModel)]="approval"
          name="approval"
          [orgId]="approval.organizationId!"
        ></app-approval-control>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button [matDialogClose]="null">
        {{ !viewOnly ? 'Cancel' : 'Close' }}
      </button>
      <button
        *ngIf="!viewOnly"
        mat-flat-button
        [disabled]="!approvalCtrl?.valid"
        color="primary"
        (click)="onSubmit()"
      >
        Submit
      </button>
    </mat-dialog-actions> `,
})
export class AddApprovalComponent {
  approval: Approval;
  viewOnly: boolean;
  extendApproval: boolean;
  approvalService: ApprovalService;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddApprovalDialogData,
    private dialogRef: MatDialogRef<AddApprovalComponent>
  ) {
    if (data.user) {
      this.approval = {
        systemUserId: data.user.id!,
        organizationId: data.user.organization?.id!,
      };
    } else if (data.approval) {
      this.approval = data.approval;
    }
    this.viewOnly = !!this.approval?.id;
    this.extendApproval = !!data.extendApproval;
  }

  onSubmit() {
    return this.dialogRef.close(this.approval);
  }

  static openDialog(dialog: MatDialog, data: AddApprovalDialogData) {
    return dialog.open<AddApprovalComponent, AddApprovalDialogData>(
      AddApprovalComponent,
      {
        width: '800px',
        data,
      }
    );
  }
}

export interface AddApprovalDialogData {
  user?: User;
  approval?: Approval;
  extendApproval?: boolean;
}
