import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ChangeDetectorRef,
  AfterContentChecked,
} from '@angular/core';
import { Education } from '@app/shared/models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EducationService } from '@app/shared/services';
import { FvEducationFormComponent } from './fv-education.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-fv-education-dialog',
  templateUrl: './fv-education-dialog.component.html',
  styleUrls: ['./fv-education.component.scss'],
})
export class FvEducationDialogComponent implements OnInit, AfterContentChecked {
  education: Education;
  @ViewChild('educationForm') educationForm: FvEducationFormComponent;
  fvId: string;
  busy: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<FvEducationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fvEducationService: EducationService,
    private changeDetect: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.education = this.data;
    this.fvId = this.data.foreignVisitor?.id;
  }

  ngAfterContentChecked(): void {
    this.changeDetect.detectChanges();
  }

  save() {
    this.busy = true;
    this.education = this.educationForm.education;
    this.fvEducationService
      .save(this.education)
      .pipe(take(1))
      .subscribe(
        (result: Education) => {
          this.onClose({
            update: result,
          });
        },
        (err) => (this.busy = false)
      );
  }

  /**
   *
   * @param data Object that is sent back when the dialog is closed.
   */
  onClose(data: any) {
    this.dialogRef.close(data);
  }
}
