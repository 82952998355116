import { BaseModel } from '@app/shared/models/base.model';
import { ForeignVisitorId } from './foreign-visitor.model';

export interface Visa extends BaseModel {
  id?: string;
  number?: string; // (25)
  type?: string; //  (1)  ?? too small ??
  expirationDate?: string | number;
  issueLocation?: string; // (40)
  alienRegistrationNumber?: string; // (25)
  controlNumber?: string; // (20)
  gender?: string; // (10)
  nationality?: string; // (10)
  dateOfBirth?: string | number;
  entries?: string; // (5)
  foreignVisitor?: ForeignVisitorId;
}
//
export const VisaTypes: string[] = [
  'A-1 Diplomats and Government Officials',
  'A-2 Employees and Officials',
  'Dependent of A-1/A-2',
  'A-3 Employee of Foreign Government Officials',
  'B-1 Visitor for Business',
  'B-2 Visitor for Tourism',
  'B-2 Prospective Student or Prospective Scholar',
  'C-1 Aliens in TransitD-1/D-2 Alien Crewman',
  'E-1 Treaty Trader',
  'E-2 Treaty Investor',
  'F-1 Student',
  'F-2 Dependents of F-1 Visa Holders',
  'Visa Waiver for business (VWB) and Tourism (VWT)',
  'G-1 Permanent Members of Recognized Government',
  'G-2 Temporary Representatives of Recognized Government',
  'G-3 and G-4 Representatives of Unrecognized Government/Appointed Individuals',
  'G-5 Personal Employee of G Visa Holders',
  'H-1A Registered Nurse',
  'H-1B Temporary Worker in a Specialty Occupation',
  'H-2A Agricultural Worker',
  'H-2B Skilled or Unskilled Worker',
  'H-3 Trainee',
  'H-4 Dependent of H Visa Holder',
  'I Representative of Foreign Information Media',
  'J-1 Exchange Visitor (Student)',
  'J-1 Exchange Visitor (Short-term scholar, professor, researcher or specialist)',
  'J-1 Au Pair, J-2 Dependents of J-1 Visa Holder',
  'L-1 Intracompany Transferee',
  'L-2 Dependent of L-1 Visa Holder',
  'M-1 Vocational Student',
  'M-2 Dependent of M-1 Visa Holder',
  'NATO 1-6 NATO Personnel',
  'O-1 Personal of Extraordinary Ability',
  'O-2 Accompanying Personnel',
  'O-3 Dependent of O-1 and O-2 Visa Holder',
  'P-1 Internationally Recognized Athlete/Entertainment Group, Essential Support Personnel',
  'P-2 Artist or Entertainer Under a Reciprocal Exchange Program',
  'P-3 Artist or Entertainer in a Culturally Unique Program',
  'P-4 Dependent of P-1',
  'P-2 or P-3 Visa Holder',
  'Q Participant in an international cultural exchange program',
  'R-1 Religious Worker',
  'R-2 Dependent of R-1 Visa Holder',
  'TN Trade NAFTA (for citizens of Canada &amp; Mexico)T-D Dependent of TN Visa Holder',
];
