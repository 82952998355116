import {
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  ContactEmail,
  DialogCloseMessage,
  ForeignVisitor,
} from '@app/shared/models';
import { cloneDeep } from 'lodash';
import { ForeignVisitorEmailService } from '@app/shared/services';

export interface EmailDialogData {
  email: ContactEmail;
  fvId: string;
}

@Directive({
  selector: '[appEmailDialog]',
})
export class EmailDialogDirective {
  @Input() appEmailDialog?: ContactEmail;
  @Input() fvId: string;
  @Output() fvEmailUpdated = new EventEmitter<ForeignVisitor>();
  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    if (!this.appEmailDialog) this.appEmailDialog = {};
    const dialogData: EmailDialogData = {
      email: cloneDeep(this.appEmailDialog),
      fvId: this.fvId,
    };
    const dialog = EmailDialogComponent.openDialog(this.dialog, dialogData);
    dialog.afterClosed().subscribe((result) => {
      if (result) return this.fvEmailUpdated.emit(result);
    });
  }
}

@Component({
  selector: 'app-email-dialog',
  templateUrl: './email-dialog.component.html',
  styleUrls: ['./email-dialog.component.scss'],
})
export class EmailDialogComponent {
  busy: boolean = false;
  constructor(
    public emailDialog: MatDialogRef<EmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fvEmailService: ForeignVisitorEmailService
  ) {}

  static openDialog(
    dialog: MatDialog,
    item: EmailDialogData
  ): MatDialogRef<EmailDialogComponent> {
    return dialog.open<EmailDialogComponent>(EmailDialogComponent, {
      data: item,
      width: '800px',
    });
  }

  save(email: ContactEmail) {
    this.busy = true;
    email = {
      ...email,
      ...{
        foreignVisitor: {
          id: this.data.fvId,
        },
      },
    };
    this.fvEmailService.save(email).subscribe(
      (result: ForeignVisitor) => {
        this.onClose({
          update: result,
          message: `Updated Email Information for ${result.givenName} ${result.surname} `,
        });
      },
      (err) => {
        this.busy = false;
      }
    );
  }
  onClose(message: DialogCloseMessage<ForeignVisitor>) {
    this.emailDialog.close(message);
  }
}
