import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { DerogatoryInformation } from '@app/shared/models';
import { cloneDeep } from 'lodash';

@Directive({
  selector: '[appScreeningCode]',
})
export class ScreeningCodeDirective {
  @Input() appScreeningCode?: DerogatoryInformation = {};
  @Input() fvId: string;
  @Output() appScreeningCodeUpdated = new EventEmitter<DerogatoryInformation>();
  constructor(private dialog: MatDialog) {}

  @HostListener('click')
  onClick() {
    const dialog = ScreeningCodeDialogComponent.openDialog(
      this.dialog,
      this.appScreeningCode
    );
    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.appScreeningCodeUpdated.emit(result);
      }
    });
  }
}

@Component({
  selector: 'app-screening-code-dialog',
  templateUrl: './screening-code-dialog.component.html',
  styleUrls: ['./screening-code-dialog.component.scss'],
})
export class ScreeningCodeDialogComponent implements AfterContentChecked {
  constructor(
    @Inject(MAT_DIALOG_DATA) public derogCode: DerogatoryInformation,
    private dialogRef: MatDialogRef<ScreeningCodeDialogComponent>,
    private changeDetect: ChangeDetectorRef
  ) {}

  onAddCode(derogCode: DerogatoryInformation) {
    this.dialogRef.close(derogCode);
  }

  ngAfterContentChecked(): void {
    this.changeDetect.detectChanges();
  }

  static openDialog(
    dialog: MatDialog,
    item?: DerogatoryInformation
  ): MatDialogRef<ScreeningCodeDialogComponent, DerogatoryInformation> {
    return dialog.open(ScreeningCodeDialogComponent, { data: cloneDeep(item) });
  }
}
