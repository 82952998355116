import { Crud, CrudService } from '@shared/services/crud.service';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Comment } from '@shared/models/comment.model';
import { Observable } from 'rxjs';

export abstract class CommentService extends CrudService<Comment> {
  /**
   * fires a request to find comments by parent id
   *
   * @param {string} parentId parent object ID
   *
   * @return {Observable<Comment[]>} collection of resources
   */
  public abstract findByParent(parentId: string): Observable<Comment[]>;
}

@Injectable({ providedIn: 'root' })
@Crud({ apiUrl: `${environment.apiUrl}/foreignVisitorComments` })
export class FVCommentService extends CommentService {
  constructor(http: HttpClient) {
    super(http);
  }

  findByParent(parentId: string): Observable<Comment[]> {
    return this.http.get<Comment[]>(`${this.apiUrl}/fv/${parentId}`);
  }
}

@Injectable({ providedIn: 'root' })
@Crud({ apiUrl: `${environment.apiUrl}/farComments` })
export class FarCommentService extends CommentService {
  constructor(http: HttpClient) {
    super(http);
  }

  findByParent(parentId: string): Observable<Comment[]> {
    return this.http.get<Comment[]>(`${this.apiUrl}/far/${parentId}`);
  }
}
