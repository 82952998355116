import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ForeignVisitor } from '@app/shared/models';
import { cloneDeep } from 'lodash';
import { PhysicalIdVehicleDialogComponent } from '../fvidentification/physical-id-dialog/physical-id-vehicle-dialog.component';

@Component({
  selector: 'app-fvview-identifying-vehicle',
  templateUrl: './fvview-identifying-vehicle.component.html',
  styleUrls: ['./fvview-identifying-vehicle.component.scss'],
})
export class FvviewIdentifyingVehicleComponent {
  @Input() deletable?: boolean = false;
  @Input() editable?: boolean = true;
  @Input() fv: ForeignVisitor;

  constructor(public dialog: MatDialog) {}

  openPhysicalDialog() {
    const dialogConfig: MatDialogConfig = {
      data: cloneDeep(this.fv),
      width: '800px',
    };
    this.dialog.open(PhysicalIdVehicleDialogComponent, dialogConfig);
  }

  featuresIsEmpty() {
    if (!this.fv.hairColor && !this.fv.eyeColor && !this.fv.identifyingMarks)
      return true;
    return false;
  }

  vehicleIsEmpty() {
    if (
      !this.fv.driversLicenseId &&
      !this.fv.vehicleLicensePlate &&
      !this.fv.vehicleMake &&
      !this.fv.vehicleModel
    )
      return true;
    return false;
  }

  isEmpty() {
    return this.vehicleIsEmpty() && this.featuresIsEmpty();
  }
}
