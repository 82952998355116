<form #form="ngForm" class="d-flex align-items-baseline">
  <mat-form-field [appearance]="appearance">
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      type="text"
      [(ngModel)]="value"
      name="date"
      [matDatepicker]="picker"
      (ngModelChange)="setDate($event)"
      [required]="required"
      [min]="minDate"
      [minDateTime]="minDate"
      [disabled]="disabled"
      [ngModelOptions]="{ updateOn: 'blur' }"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error>
      <span *ngIf="hasError(form, 'date', 'required')">
        A Date is Required
      </span>
      <span *ngIf="hasError(form, 'date', 'minDate')">
        Must be after {{ minDate | date }} at
        {{ minDate | date: "HH:mm" }}
      </span>
    </mat-error>
  </mat-form-field>
  <mat-icon svgIcon="minus" class="mx-2"></mat-icon>

  <mat-form-field [appearance]="appearance">
    <input
      matInput
      type="time"
      [(ngModel)]="time"
      name="time"
      (ngModelChange)="setTime($event)"
      [required]="required"
      [disabled]="disabled"
      [step]="timeStep"
    />
    <mat-error>Time is Required</mat-error>
  </mat-form-field>
</form>
