import { Component, OnInit } from '@angular/core';
import { User, Role } from '@app/shared/models';
import { Observable } from 'rxjs';
import { AuthService } from '@app/auth/auth.service';
import { AppConfigService } from '@app/shared/services/app-config.services';
import { FVViewRoles } from '@app/shared/models/role-permissions';

@Component({
  templateUrl: 'homepage.component.html',
  styleUrls: ['homepage.component.scss'],
})
export class HomePageComponent implements OnInit {
  systemUser$: Observable<User | null> = this.authService.user$;
  Role = Role;
  FVViewRoles = FVViewRoles;

  constructor(
    private appConfigService: AppConfigService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    //Placeholder example code to retrieve data from environment config files
    let classification = this.appConfigService.get('classification');
    console.log('classification:' + classification);
  }
}
