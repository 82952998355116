import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnDestroy } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Observable, isObservable, of, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

export interface ConfirmDialogData {
  title: string;
  message: string;

  cancelText?: string;
  showCancel?: boolean;
  confirmColor?: ThemePalette;
  confirmText?: string;
  performAction?: () => Observable<any> | any;
  successMsg?: string | null;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirmdialog.component.html',
})
export class ConfirmDialogComponent implements ConfirmDialogData, OnDestroy {
  title: string;
  message: string;
  icon: string;
  color: string;

  cancelText: string = 'Cancel';
  showCancel: boolean = true;
  confirmColor: ThemePalette = 'primary';
  confirmText: string = 'Ok';
  successMsg: string | null = null;
  performAction: () => Observable<any> | any = () => true;
  subscription: Subscription = Subscription.EMPTY;
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {
    Object.assign(this, data);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onConfirm(): void {
    this.loading = true;
    const action = this.performAction();
    const obs = isObservable(action) ? action : of(action || true);
    this.subscription = obs
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((result) => {
        this.dialogRef.close(result || true);
      });
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel implements ConfirmDialogData {
  constructor(
    public title: string,
    public message: string,
    public icon: string,
    public color: string
  ) {}
}
