<h1 mat-dialog-title *ngIf="contact">
  <ng-container>{{ contact?.id ? "Edit" : "Create" }} Contact</ng-container>
</h1>
<mat-dialog-content>
  <app-contact-form
    class="contact-form"
    #contactForm
    [(ngModel)]="contact"
    (emitChangeEvent)="onContactChange($event)"
  ></app-contact-form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
  <button
    mat-button
    color="primary"
    mat-dialog-close
    id="cancel-organization-dialog"
  >
    Close
  </button>
  <span class="col"></span>
  <button
    appDebounceClick
    [disabled]="!contactForm.valid || disabledSubmit"
    mat-raised-button
    color="primary"
    (debounceClick)="onSubmit()"
    id="submit-location"
  >
    <div *ngIf="!disabledSubmit">Submit</div>
    <mat-spinner
      color="primary"
      *ngIf="disabledSubmit"
      [diameter]="35"
    ></mat-spinner>
  </button>
</mat-dialog-actions>
