import {
  Component,
  OnInit,
  OnDestroy,
  AfterContentChecked,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { ScreeningService } from '@app/shared/services';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UspMetricsService } from '../../usp-metrics.service';
import { MatSidenav } from '@angular/material/sidenav';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-organization-graph',
  templateUrl: './organization-graph.component.html',
  styleUrls: ['./organization-graph.component.scss'],
})
export class OrganizationGraphComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  private ngUnsubscribe = new Subject<void>();
  @ViewChild('sideNav') sideNav: MatSidenav;
  orgChart: any[] = [];
  originalOrgCharts: any[];
  loading: boolean = false;
  startDate: string = '';
  endDate: string = '';
  today: Date = new Date();
  datepickerUnit: 'DAY' | 'WEEK' | 'MONTH' = 'DAY';
  datepickerUnitRange: any = {
    DAY: 31,
    WEEK: 180,
    MONTH: 365,
  };
  dateRangeExceeded: boolean = false;
  display: boolean = true;
  organizationDataPoints: any = [];
  sideNavOpen: boolean = false;
  orgStatDetails: any = {};

  screeningCounts: any = {
    totalCount: 0,
    machineRedResultCount: 0,
    greenResultCount: 0,
    redResultCount: 0,
    yellowResultCount: 0,
    uspResultCount: 0,
    inProcessStatusCount: 0,
    newStatusCount: 0,
    completedStatusCount: 0,
    errorStatusCount: 0,
    reviewedGreenResultCount: 0,
    machineGreenResultCount: 0,
  };

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Date';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Count';
  legendTitle: string = 'Screening';
  orgLegendTitle: string = 'Organizations';
  timeline: boolean = true;

  constructor(
    private uspService: UspMetricsService,
    private screenings: ScreeningService,
    private changeDetect: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.endDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US', 'GMT');
    let calcStart = new Date();
    calcStart.setDate(calcStart.getDate() - 7);
    this.startDate = formatDate(calcStart, 'yyyy-MM-dd', 'en-US', 'GMT');

    this.getScreeningResultsMetrics();
  }

  getScreeningResultsMetrics() {
    this.startDate = formatDate(
      new Date(this.startDate),
      'yyyy-MM-dd',
      'en-US',
      'GMT'
    );
    this.endDate = formatDate(
      new Date(this.endDate),
      'yyyy-MM-dd',
      'en-US',
      'GMT'
    );

    this.resetTotalCounts();
    if (this.sideNav) {
      this.sideNav.close();
      this.sideNavOpen = false;
    }

    if (this.dateRangeValidCheck()) {
      this.loading = true;
      forkJoin([
        this.screenings.findFvsMetricsByOrg(
          this.startDate,
          this.endDate,
          this.datepickerUnit
        ),
        this.uspService.findUspMetricsByOrg(
          this.startDate,
          this.endDate,
          this.datepickerUnit
        ),
      ])
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(([metrics, usp]) => {
          this.orgChart = this.translateOrgstoBarGraph(metrics, usp);
          this.originalOrgCharts = JSON.parse(JSON.stringify(this.orgChart));
          this.loading = false;
        });
    }
  }

  dateRangeValidCheck() {
    this.dateRangeExceeded = false;
    let maxRange = this.datepickerUnitRange[this.datepickerUnit];
    let futureDate = new Date(this.startDate);
    futureDate.setDate(futureDate.getDate() + maxRange);

    if (new Date(this.endDate) < futureDate) return true;

    this.dateRangeExceeded = true;
    return false;
  }

  translateOrgstoBarGraph(metrics: any, usp: any) {
    let arrayData = Object.keys(metrics);
    let uspData = Object.keys(usp);
    arrayData = arrayData.concat(uspData);
    arrayData = Array.from(new Set(arrayData));
    let formattedData: any = [];

    let days: any = [];
    for (let data of arrayData) {
      this.organizationDataPoints.push({
        name: data,
        show: true,
      });
      if (metrics[data]) days = days.concat(Object.keys(metrics[data]));
      if (usp[data]) days = days.concat(Object.keys(usp[data]));
    }
    days = Array.from(new Set(days));
    days.sort((a: string, b: string) => (a > b ? 1 : -1));

    for (let day of days) {
      let series = [];
      for (let data of arrayData) {
        let hold: any = {};
        let orgItem = {
          name: data,
          value: 0,
          orgData: hold,
        };
        if (metrics[data] && metrics[data][day] !== undefined) {
          orgItem.value = metrics[data][day].totalCount;
          orgItem.orgData = metrics[data][day];
          this.addToTotalCounts(metrics[data][day]);
        }
        if (usp[data] && usp[data][day] !== undefined) {
          orgItem.value += usp[data][day].totalCount;
          orgItem.orgData['uspResultCount'] = usp[data][day].totalCount;
          if (orgItem.orgData.totalCount)
            orgItem.orgData['totalCount'] += usp[data][day].totalCount;
          else orgItem.orgData.totalCount = usp[data][day].totalCount;
          this.addToTotalCounts(usp[data][day], true);
        }
        series.push(orgItem);
      }
      let item = {
        name: day,
        series: series,
      };
      formattedData.push(item);
    }
    return formattedData;
  }

  ngAfterContentChecked(): void {
    this.changeDetect.detectChanges();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  showTotalCounts() {
    this.orgStatDetails.series = 'Total';
    this.orgStatDetails.name = 'Screening Counts for All Organizations';
    this.orgStatDetails.orgData = this.screeningCounts;
    this.sideNav.open();
    this.sideNavOpen = true;
  }

  resetTotalCounts() {
    let keys = Object.keys(this.screeningCounts);
    for (let key of keys) {
      this.screeningCounts[key] = 0;
    }
    this.screeningCounts.reviewedGreenResultCount = 0;
    this.screeningCounts.machineGreenResultCount = 0;
  }

  addToTotalCounts(data: any, isUsp?: boolean) {
    let keys = Object.keys(this.screeningCounts);
    if (!isUsp) {
      for (let key of keys) {
        if (data[key]) {
          this.screeningCounts[key] += data[key];
        }
      }
    } else {
      this.screeningCounts['uspResultCount'] += data.totalCount;
      this.screeningCounts['totalCount'] += data.totalCount;
    }
  }

  onSelectOrgs(data: any, sideNav: MatSidenav): void {
    if (typeof data === 'string') {
      this.toggleOrg(data);
    } else {
      sideNav.open();
      this.sideNavOpen = true;
      this.orgStatDetails = data;
    }
  }

  toggleOrg(data: any) {
    let tempData = JSON.parse(JSON.stringify(this.orgChart));
    let selected = this.findDataParameterOrgs(data);
    selected.show = !selected.show;
    if (!selected.show) {
      for (let item of tempData) {
        for (let i = 0; i < item.series.length; ++i) {
          if (item.series[i].name === data) {
            item.series[i].value = 0;
          }
        }
      }
    } else {
      for (let i = 0; i < tempData.length; ++i) {
        for (let j = 0; j < tempData[i].series.length; ++j) {
          if (tempData[i].series[j].name === data) {
            tempData[i].series[j].value =
              this.originalOrgCharts[i].series[j].value;
            break;
          }
        }
      }
    }
    this.orgChart = tempData;
  }

  findDataParameterOrgs(event: any) {
    const selectedBar = this.organizationDataPoints.find((model: any) => {
      return model.name === event;
    });

    return selectedBar;
  }
}
