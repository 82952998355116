<mat-form-field [appearance]="appearance" [floatLabel]="floatLabel">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    #matSelect
    id="group-select"
    [required]="required"
    [disabled]="disabled"
    [(ngModel)]="value"
    (selectionChange)="emitChangeEvent()"
    (openedChange)="monitorScroll($event)"
    [name]="label"
    [multiple]="multiple"
    panelClass="group-select"
  >
    <mat-select-trigger>
      <span> {{ getSelectedText(value) }}</span>
    </mat-select-trigger>

    <mat-option class="search-option">
      <ngx-mat-select-search
        [searching]="searching"
        noEntriesFoundLabel="No Groups Found"
        [placeholderLabel]="filterLabel"
        [formControl]="searchCtrl"
        [disableScrollToActiveOnOptionsChanged]="true"
        [clearSearchInput]="false"
      >
      </ngx-mat-select-search>
    </mat-option>
    <mat-option
      class="flex-fill"
      *ngFor="let item of filteredValues | async"
      [value]="item"
    >
      {{ item.name }}
    </mat-option>
  </mat-select>
  <button
    [hidden]="!clearable"
    mat-icon-button
    matSuffix
    *ngIf="value && !disabled"
    matTooltip="Clear"
    (click)="clearValue($event)"
  >
    <mat-icon>clear</mat-icon>
  </button>
  <mat-error *ngIf="required">Foreign National Group is required</mat-error>
</mat-form-field>
