<div class="auth-events-dialog">
  <h1 mat-dialog-title>
    Account Activity<span *ngIf="user?.id"> - {{ user | fullname }}</span>
  </h1>
  <mat-dialog-content>
    <div *ngIf="!events" class="d-flex m-5 justify-content-center">
      <mat-spinner></mat-spinner>
    </div>
    <table *ngIf="events" mat-table [dataSource]="events">
      <!-- Type Column -->
      <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let event" class="align-center">
          <mat-icon
            color="accent"
            *ngIf="event.id === summary?.loginEventId"
            matTooltip="Current session"
            svgIcon="star-circle-outline"
          ></mat-icon>
        </td>
      </ng-container>
      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let event">
          {{ event.type }} ({{ event.deviceName }})
        </td>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let event">
          {{ event.createdDate | date: "short" }}
        </td>
      </ng-container>

      <!-- Response Column -->
      <ng-container matColumnDef="response">
        <th mat-header-cell *matHeaderCellDef>Response</th>
        <td mat-cell *matCellDef="let event">
          <span
            [class.text-success]="event.response === AuthEventResponse.PASS"
            [class.text-danger]="event.response === AuthEventResponse.FAIL"
          >
            {{ event.response }}
          </span>
        </td>
      </ng-container>

      <!-- Location Column -->
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Location</th>
        <td mat-cell *matCellDef="let event">
          {{ event.city }} ({{ event.country }}) ({{ event.ipAddress }})
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9999" class="p-5 text-center">
          <h5>No Account Activity Found</h5>
        </td>
      </tr>
    </table>
  </mat-dialog-content>
  <mat-dialog-actions class="d-flex flex-row">
    <span class="col"></span>
    <button mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions>
</div>
