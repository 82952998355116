import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Routing
import { AdminRoutingModule } from './admin-routing.module';

// Components
import { AccountsComponent } from './accounts/accounts.component';
import { AccountsCollectionsComponent } from './accounts/accounts-collection/accounts-collection.component';
import { AccountsUserFormComponent } from './accounts/accounts-collection/accounts-user-form/accounts-user-form.component';
import { MatSortModule } from '@angular/material/sort';
import { AccountDialogComponent } from './accounts/accounts-collection/account-dialog/account-dialog.component';
import { AccountUserTableComponent } from './accounts/accounts-collection/account-user-table/account-user-table.component';
import { ToggleUserStatusDirective } from '@app/admin/accounts/toggle-user-status.directive';
import { AccountPwdResendDialogComponent } from './accounts/accounts-collection/account-pwd-resend-dialog/account-pwd-resend-dialog.component';
import { ApprovalsModule } from './approvals/approvals.module';

@NgModule({
  declarations: [
    AccountsComponent,
    AccountsCollectionsComponent,
    AccountsUserFormComponent,
    AccountDialogComponent,
    ToggleUserStatusDirective,
    AccountUserTableComponent,
    AccountPwdResendDialogComponent,
  ],
  imports: [
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    MatSortModule,
    AdminRoutingModule,
    ApprovalsModule,
  ],
  exports: [
    AccountsComponent,
    AccountsCollectionsComponent,
    AccountPwdResendDialogComponent,
    ApprovalsModule,
  ],
})
export class AdminModule {}
