<mat-form-field
  [appearance]="appearance"
  [floatLabel]="floatLabel"
  style="width: 100%"
>
  <mat-label>{{ label }}</mat-label>
  <mat-select
    #matSelect
    [required]="required"
    [disabled]="disabled"
    [(ngModel)]="value"
    (selectionChange)="emitChangeEvent()"
    (openedChange)="monitorScroll($event)"
    [name]="label"
    data-cy="orgSelect"
  >
    <mat-select-trigger>
      <div class="select-trigger position-relative">
        <mat-icon
          class="mr-2"
          svgIcon="office-building"
          style="position: relative; top: 5px"
        ></mat-icon>
        <span class="select-trigger__label">
          {{ value | getOrgName: "combo" }}
        </span>
      </div>
    </mat-select-trigger>

    <mat-option>
      <ngx-mat-select-search
        [searching]="searching"
        data-cy="ngx-search-org"
        noEntriesFoundLabel="No Organizations Found"
        [placeholderLabel]="filterLabel"
        [formControl]="searchCtrl"
        [disableScrollToActiveOnOptionsChanged]="true"
        [clearSearchInput]="false"
      >
      </ngx-mat-select-search>
    </mat-option>
    <mat-option class="selected-options" *ngIf="value" [value]="value">
      <mat-icon
        class="flex-shrink-0"
        style="position: relative; top: 5px"
        color="primary"
        mat-list-icon
        svgIcon="office-building"
      ></mat-icon>
      <span class="pl-1">{{ value | getOrgName: "combo" }}</span>
    </mat-option>
    <mat-option *ngFor="let item of filteredValues | async" [value]="item">
      <mat-icon
        class="flex-shrink-0"
        *ngIf="item"
        color="primary"
        mat-list-icon
        svgIcon="office-building"
      ></mat-icon>
      <span class="pl-1" [appHighlight]="searchCtrl?.value">{{
        item | getOrgName: "combo"
      }}</span>
    </mat-option>
  </mat-select>
  <button
    mat-icon-button
    matSuffix
    *ngIf="value && !disabled"
    matTooltip="Clear"
    style="position: relative; top: 2px"
    (click)="clearValue($event)"
  >
    <mat-icon>clear</mat-icon>
  </button>
  <mat-spinner matSuffix *ngIf="isLoading" diameter="25"></mat-spinner>
  <mat-error>{{ placeholder }} is required</mat-error>
</mat-form-field>
