import { NumberInput, coerceNumberProperty } from '@angular/cdk/coercion';
import { Component, HostBinding, Input } from '@angular/core';

export type Colors =
  | 'primary'
  | 'accent'
  | 'dark'
  | 'light'
  | 'white'
  | 'black'
  | 'danger';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
})
export class FieldComponent {
  @HostBinding('class') class = 'field';

  @HostBinding('style.--label-color')
  @Input()
  set labelColor(value: Colors) {
    this._labelColor = value;
  }
  get labelColor(): string {
    return this._labelColor ? `var(--${this._labelColor})` : '';
  }
  private _labelColor: Colors;

  @HostBinding('style.--value-color')
  @Input()
  public set valueColor(value: Colors) {
    this._valueColor = value;
  }
  get valueColor(): string {
    return this._valueColor ? `var(--${this._valueColor})` : '';
  }
  private _valueColor: Colors;

  @HostBinding('style.--font-size.px')
  @Input()
  set fontSize(value: NumberInput) {
    this._fontSize = coerceNumberProperty(value);
  }
  get fontSize(): number {
    return this._fontSize;
  }
  private _fontSize: number;

  @Input() label: string | null = null;
  @Input() value: string = 'value';

  @HostBinding('style.--align')
  @Input()
  set alignItems(value: string) {
    this._alignItems = value;
  }
  get alignItems(): string {
    return this._alignItems;
  }
  private _alignItems: string;
}
