import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@app/shared/models/address.model';

function getStreet(value: Address): string {
  return [value.line1, value.line2].filter((v) => v && v.length).join(', ');
}

function getCityState(value: Address): string {
  return [value.city, value.stateProvince]
    .filter((v) => v && v.length)
    .join(', ');
}

function getCountry(value: Address): string {
  return value.countryCode ?? '';
}

function getPostalCode(value: Address): string {
  return value.postalCode ?? '';
}

@Pipe({
  name: 'address',
})
export class AddressPipe implements PipeTransform {
  transform(
    value: Address,
    format:
      | 'street'
      | 'cityState'
      | 'streetCityState'
      | 'cityStateCountryPostalCode'
      | 'streetCityStateCountry' = 'street'
  ): string {
    if (!value) return '';

    switch (format) {
      case 'street':
        return getStreet(value);
      case 'cityState':
        return getCityState(value);
      case 'streetCityState':
        return `${getStreet(value)} ${getCityState(value)}`;
      case 'cityStateCountryPostalCode':
        return `${getCityState(value)} ${getCountry(value)} ${getPostalCode(
          value
        )}`;
      case 'streetCityStateCountry':
        return `${getStreet(value)} ${getCityState(value)} ${getCountry(
          value
        )}`;
    }
  }
}
