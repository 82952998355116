<h3 mat-dialog-title *ngIf="!far.isCanceled">Cancel FAR</h3>
<h3 mat-dialog-title *ngIf="far.isCanceled">Uncancel FAR</h3>
<mat-dialog-content>
  <mat-form-field appearance="outline" class="mt-3 w-100">
    <mat-label>Reason</mat-label>
    <textarea
      id="far-cancel-reason-input"
      #description="ngModel"
      placeholder="Please describe the reason for change of status"
      matInput
      rows="5"
      [(ngModel)]="reason"
      required
      name="description"
      maxlength="2000"
    ></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="primary" id="cancel-far-cancel" mat-dialog-close>
    Cancel
  </button>
  <button
    id="cancel-far-submit"
    [disabled]="busy || !reason"
    mat-raised-button
    color="primary"
    (click)="onSubmit()"
  >
    <span *ngIf="!busy; else loading">Submit</span>
  </button>
</mat-dialog-actions>

<ng-template #loading>
  <mat-spinner diameter="35" color="primary"></mat-spinner>
</ng-template>
