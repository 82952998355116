import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ForeignVisitor } from '@app/shared/models';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-fvview-contact',
  templateUrl: './fvview-contact.component.html',
  styleUrls: ['./fvview-contact.component.scss'],
})
export class FvviewContactComponent {
  @Input() deletable?: boolean = false;
  @Input() editable?: boolean = true;
  @Input() fv: ForeignVisitor;

  constructor(public dialog: MatDialog) {}

  openVehicleDialog() {
    const dialogConfig: MatDialogConfig = {
      data: cloneDeep(this.fv),
      width: '800px',
    };
    //this.dialog.open(VehicleIDDialogComponent, dialogConfig);
  }

  isEmpty() {
    if (
      !this.fv.driversLicenseId &&
      !this.fv.vehicleLicensePlate &&
      !this.fv.vehicleMake &&
      !this.fv.vehicleModel
    )
      return true;
    return false;
  }
}
