<ng-container [ngSwitch]="view">
  <app-login-form
    *ngSwitchCase="'LOGIN'"
    (viewChange)="updateView($event)"
  ></app-login-form>
  <app-login-forgotaccount
    (viewChange)="updateView($event)"
    *ngSwitchCase="'FORGOT_PASSWORD'"
  ></app-login-forgotaccount>
  <app-multi-factor-authentication
    (viewChange)="updateView($event)"
    *ngSwitchCase="'LOGIN_WITH_MFA'"
  ></app-multi-factor-authentication>
  <app-new-password-required
    *ngSwitchCase="'CHANGE_PASSWORD'"
  ></app-new-password-required>
  <div *ngSwitchCase="'BLOCKED'">
    Citadel cannot be accessed with a mobile device.
  </div>
</ng-container>
