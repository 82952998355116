import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApprovalsComponent } from './approvals.component';
import { AuthGuard } from '@shared/helpers';
import { UserApproverRoles } from '@shared/models/role-permissions';

const routes: Routes = [
  {
    path: 'approvals',
    title: 'Approvals',
    component: ApprovalsComponent,
    canActivate: [AuthGuard],
    data: { roles: UserApproverRoles },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ApprovalsRoutingModule {}
