<div
  id="screening-toolbar"
  class="
    screening-toolbar
    container-fluid
    d-flex
    align-items-center
    bg-white
    px-3
    py-2
  "
>
  <div class="mat-headline-5 my-0 mr-3"><ng-content></ng-content></div>

  <app-filter
    class="mr-3 flex-grow-1"
    [placeholder]="filterPlaceholder"
    [filterFields]="ScreeningAdvancedSearch"
    [filterParams]="filterParams"
    (filterParamsChange)="onFilterParamChange($event)"
  ></app-filter>
  <div class="flex-fill"></div>
  <app-column-chooser
    matTooltip="Column Selection"
    class="mb-1 mr-2"
    [dataList]="fvColumns"
    [labels]="ScreeningFieldLabel"
  ></app-column-chooser>
</div>
<app-active-search-display
  [filterFields]="ScreeningAdvancedSearch"
  [filterParams]="filterParams"
  (filterParamsChange)="onFilterParamChange($event)"
></app-active-search-display>
<mat-progress-bar
  color="accent"
  [mode]="loading ? 'indeterminate' : 'determinate'"
></mat-progress-bar>
