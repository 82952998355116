import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Crud, CrudService } from '@shared/services/crud.service';
import { ContactPhoneNumber } from '../models/contact-phone-number.model';
import { pull } from 'lodash';

@Injectable({ providedIn: 'root' })
@Crud({
  apiUrl: `${environment.apiUrl}/foreignVisitorPhone`,
  hasIdPathUpdate: false,
  entity: 'Foreign National Phone Number',
})
export class ForeignVisitorPhoneService extends CrudService<ContactPhoneNumber> {
  constructor(http: HttpClient) {
    super(http);
  }

  organizePhoneNumbers(phones: ContactPhoneNumber[]): ContactPhoneNumber[] {
    let tempPhones: ContactPhoneNumber[] = phones;
    tempPhones = this.sortPhoneNumbers(tempPhones);
    return tempPhones;
  }

  removePrimary(
    phones: ContactPhoneNumber[],
    primary: ContactPhoneNumber
  ): ContactPhoneNumber[] {
    phones = pull(phones, primary);
    return phones;
  }

  sortPhoneNumbers(phones: ContactPhoneNumber[]) {
    return phones.sort((a: ContactPhoneNumber, b: ContactPhoneNumber) => {
      if (a.primaryPhone && !b.primaryPhone) return -1;
      if (!a.primaryPhone && b.primaryPhone) return 1;
      return `${b.lastModifiedDate}`.localeCompare(`${a.lastModifiedDate}`);
    });
  }

  lockPrimary(
    phones: ContactPhoneNumber[],
    phone: ContactPhoneNumber
  ): boolean {
    let locked: boolean = false;
    phones.forEach((item: ContactPhoneNumber) => {
      if (item.primaryPhone === true && item != phone) {
        locked = true;
      }
    });
    return locked;
  }
}
