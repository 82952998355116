<app-timeline
  *ngIf="screening$ | async as screeningList"
  [data]="screeningList | transition"
  horizontal
  lineWidth="28"
>
  <ng-template timeline-badge let-item="item">
    <div
      #tooltip="matTooltip"
      class="p-1 rounded-circle d-flex"
      [class]="item.classList"
      [matTooltip]="item.tooltip"
      matTooltipClass="mat-tooltip-multiline"
    >
      <mat-icon
        [svgIcon]="item.icon"
        [style.height.px]="iconSize"
        [style.width.px]="iconSize"
        [style.lineHeight.px]="iconSize"
      ></mat-icon>
    </div>
  </ng-template>
</app-timeline>
