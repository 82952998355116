<mat-drawer-container>
  <mat-drawer
    class="screening-drawer"
    mode="side"
    position="start"
    [(opened)]="showHistory"
  >
    <app-screening-history
      (emitHide)="showHistory = false"
    ></app-screening-history>
  </mat-drawer>
  <mat-drawer-content>
    <app-screening-collection
      *ngIf="fvs$ | async as pageableFVs"
      [pageableCollection]="pageableFVs"
      [filterParams]="filterParams"
      (filterParamsChange)="onFilterParamsChange($event)"
      [loading]="loading"
    ></app-screening-collection>
  </mat-drawer-content>
</mat-drawer-container>
