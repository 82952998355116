import { TimelineEvent } from '@app/shared/components';
import { Screening, ScreeningStatus } from '@app/shared/models';

export type ScreeningIcons =
  | 'circle-outline'
  | 'checkbox-blank-circle-outline'
  | 'autorenew'
  | 'alert-circle-outline'
  | 'checkbox-marked-circle-outline'
  | 'robot-vacuum-alert'
  | 'account-search';

export interface ScreeningTimelineEvent extends TimelineEvent {
  icon?: ScreeningIcons;
  backgroundColor?: string;
}

export const screeningTrainstions: ScreeningTimelineEvent[] = [
  {
    startDate: new Date('2023-09-22T16:16:20.284Z'),
    icon: 'circle-outline',
    classList: 'bg-secondary text-white',
    color: 'bg-secondary',
    tooltip: `Status: new
    Date: 1/2/2001 @ 13:00
    `,
  },
  {
    startDate: new Date('2023-09-22T16:16:20.284Z'),
    icon: 'autorenew',
    classList: 'bg-info text-white',
    color: 'bg-info',
    tooltip: `Status: In Progress
    Date: 1/2/2001 @ 14:00
    `,
  },
  {
    startDate: new Date('2023-09-22T16:16:20.284Z'),
    icon: 'account-search',
    classList: 'bg-machine-red text-white',
    color: 'bg-machine-red',
    tooltip: `Status: In Progress
    Date: 1/2/2001 @ 14:30
    Result: MACHINE_RED
    `,
  },
  {
    startDate: new Date('2023-09-22T16:16:20.284Z'),
    icon: 'alert-circle-outline',
    color: 'bg-danger',
    classList: 'bg-danger text-white',
    tooltip: `Status: Completed
    Date: 1/2/2003
    Result: DEROG FOUND
    `,
  },
  {
    startDate: new Date('2023-09-22T16:16:20.284Z'),
    icon: 'checkbox-marked-circle-outline',
    color: 'bg-success',
    classList: 'bg-success text-white',
    tooltip: `Status: Completed
    Date: 1/2/2003
    Result: NO DEROG FOUND
    `,
  },
];

export const SCREENING_TRANSITIONS: Screening[] = [
  {
    status: ScreeningStatus.New,
    result: undefined,
    lastModifiedDate: new Date('2023-09-22T16:16:20.284Z').toISOString(),
  },
];
