<h3 mat-dialog-title>
  {{ dialogData?.editMode ? "Edit" : "New" }} Social Media
</h3>
<app-social-media-form
  [(ngModel)]="socialMedia"
  name="socialMedia"
></app-social-media-form>
<mat-dialog-actions class="row d-flex justify-content-end">
  <button
    color="primary"
    mat-button
    mat-dialog-close
    id="cancel-social-media-button"
  >
    <span>Cancel</span>
  </button>
  <button
    [disabled]="
      busy || (!socialMedia.url && !socialMedia.type && !socialMedia.username)
    "
    mat-raised-button
    color="primary"
    id="submit-social-media-button"
    (click)="onSubmit()"
  >
    <span *ngIf="!busy">Submit</span>
    <mat-spinner
      class="mr-1 ml-1"
      *ngIf="busy"
      color="primary"
      diameter="35"
    ></mat-spinner>
  </button>
</mat-dialog-actions>
