<dd *ngIf="emails[0] as email">
  <button
    style="font-size: 12px"
    mat-button
    *ngIf="emails && emails.length > 0"
    matTooltip="View Emails"
    class="text-truncate"
    [matMenuTriggerFor]="emailMenu"
  >
    {{ email.emailAddress | shorten: 20:"..." | default }}
  </button>
</dd>
<mat-menu #emailMenu class="pt-0">
  <ng-container *ngIf="editable; else readOnly">
    <div mat-subheader class="d-flex justify-content-between mb-1 mt-0">
      <b style="font-size: 14px">EMAIL</b>
    </div>
    <mat-divider></mat-divider>
    <mat-nav-list class="my-0">
      <mat-list-item
        *ngFor="let email of emails; last as lastEmail"
        [appEmailDialog]="email"
        [fvId]="fvId"
        data-cy="edit-email-item"
      >
        <div
          class="d-flex justify-content-between text-truncate"
          matTooltip="{{ email?.type | titlecase | default }} - {{
            email?.emailAddress
          }}"
        >
          <span class="text-truncate">
            {{ email?.type | titlecase | default }}: {{ email?.emailAddress }}
          </span>
          <mat-icon class="ml-3 wh-20" svgIcon="square-edit-outline"></mat-icon>
        </div>
        <mat-divider *ngIf="!lastEmail"></mat-divider>
      </mat-list-item>
    </mat-nav-list>
  </ng-container>
  <ng-template #readOnly>
    <div mat-subheader class="d-flex justify-content-between mb-1 mt-0">
      <b>EMAIL</b>
    </div>
    <mat-divider></mat-divider>
    <mat-list>
      <mat-list-item
        *ngFor="let email of emails; last as lastEmail"
        data-cy="read-email-item"
      >
        <div
          class="d-flex justify-content-between text-truncate"
          matTooltip="{{ email?.type | titlecase | default }} - {{
            email?.emailAddress
          }}"
        >
          <span class="text-truncate">
            {{ email?.type | titlecase | default }}: {{ email?.emailAddress }}
          </span>
        </div>
        <mat-divider *ngIf="!lastEmail"></mat-divider>
      </mat-list-item>
    </mat-list>
  </ng-template>
</mat-menu>
