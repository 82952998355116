import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterParams, FAR, UserPreferenceBlob } from '@app/shared/models';
import { PageableCollection } from '@app/shared/models/pageable-collection.model';
import { FarSortFieldLabels, FarSorts } from '@app/shared/services';
import { ActionButton } from '@app/shared/models/actionbutton.model';
import { FarEditRoles } from '@shared/models/role-permissions';
import { CollectionView } from '@app/shared/components';
import { FarAdvancedSearch } from '@app/foreign-access-requests/dashboard/far-advanced-search';
import {
  FARTableColumns,
  FARTableName,
} from '@app/foreign-access-requests/dashboard/collections/far-table/far-table.component';
import { AuthService } from '@app/auth/auth.service';
import { UserPreferenceService } from '@app/shared/services/user-preference.service';
import { ViewService } from '@app/shared/services/view.service';
import { take, debounceTime } from 'rxjs';

const actionButtons: ActionButton[] = [
  {
    label: 'New FAR',
    routerLink: '/far',
    menuIcon: 'note_add',
    roles: FarEditRoles,
  },
];

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss'],
})
export class CollectionsComponent implements OnInit {
  @Input() position: 'fixed' | 'sticky' = 'sticky';
  @Input() pageableCollection: PageableCollection<FAR>;
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  @Input() loading: boolean = false;
  @Input() workspaceView: boolean = false;
  userPreference: UserPreferenceBlob | undefined;

  view: CollectionView = '!';
  headerActionButtons: ActionButton[] = actionButtons;
  FarSorts = FarSorts;
  label: string = 'Foreign Access Request (FAR)';

  constructor(
    private viewService: ViewService,
    private auth: AuthService,
    private userPreferenceService: UserPreferenceService
  ) {}

  ngOnInit(): void {
    this.userPreferenceService.userPreferenceSubject$.subscribe(
      (hasUserPreference) => {
        if (hasUserPreference !== null && hasUserPreference !== undefined) {
          this.getUserPreference();
        } else {
          this.viewService.viewChangeSubject.subscribe((y) => {
            if (y.name === this.label) {
              this.view = y.viewType;
            }
          });
        }
      }
    );
  }

  getUserPreference() {
    this.userPreferenceService
      .getCurrentUserId(this.auth?.getUser()?.id!)
      .pipe(take(1), debounceTime(1000))
      .subscribe(
        (result) => {
          if (result !== null && result !== undefined) {
            this.userPreference = JSON.parse(result.userPreferenceBlob);
            let tabDetails = this.userPreference?.tabs.at(1);
            this.view = this.userPreference?.tabs.at(1)?.tabViewType!;
            let farArray: Array<string> = [];
            tabDetails?.tabColumns.filter((x) => {
              if (x.selected === true) {
                farArray.push(x.label);
              }
            });
            if (
              this.userPreference !== null &&
              this.userPreference !== undefined
            ) {
              this.userPreferenceService.loadDisplayColumns(
                FarSortFieldLabels,
                farArray,
                FARTableColumns,
                FARTableName,
                this.auth?.getUser()?.roles!,
                this.auth.getUser()?.networkDomain!
              );
            }
          } else {
            this.view = 'card';
          }
        },
        (err) => {}
      );
  }

  onViewChange(view: CollectionView) {
    this.view = view;
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParams = filterParams;
    this.filterParamsChange.emit(filterParams);
  }

  protected readonly FarAdvancedSearch = FarAdvancedSearch;
  protected readonly FARTableColumns = FARTableColumns;
  protected readonly FarSortFieldLabels = FarSortFieldLabels;
  protected readonly FARTableName = FARTableName;
}
