import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { screeningTrainstions } from './timeline.mock';
interface TimelineEvent {
  startDate: string | number;
  [key: string]: any;
}

const itinerary: TimelineEvent[] = [
  {
    startDate: new Date('1/25/1981').getTime(),
    endDate: '1644517635413',
    title: 'you',
    subject: 'Some Visit',
    content:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam at impedit dolores nihil labore exercitationem velit minima ut eaque repellat?',
  },
  {
    startDate: new Date('1/25/2021').getTime(),
    endDate: '1644517635413',
    from: 'you',
    subject: 'Visiting Olando',
    content: 'Content 3',
  },
  {
    startDate: new Date('1/25/2000').getTime(),
    endDate: '1644517635413',
    from: 'you',
    subject: 'Visiting Bel Air',
    content: 'Content 2',
    icon: 'fingerprint',
  },
];

@Component({
  selector: 'app-sandbox',
  templateUrl: './sandbox.component.html',
  styleUrls: ['./sandbox.component.scss'],
})
export class SandboxComponent {
  dbug: boolean = false;
  left: boolean = true;
  alternate: boolean = false;
  itinerary = itinerary;
  screeningTransitions = screeningTrainstions;
  descriptionSize = 15;

  addEvent(form: NgForm) {
    const value = form.value as TimelineEvent;
    value.startDate = new Date(value.startDate).valueOf();
    const copy = this.itinerary.slice();
    copy.push(value);
    this.itinerary = copy;
    form.reset();
  }
  setClass(col: string) {
    console.log(col);
  }
  dateValue(date: string) {
    console.log(date);
  }
  getInputType(inputType: string) {
    return this.isDate(inputType) ? 'date' : 'text';
  }
  isDate(inputType: string): boolean {
    return !!inputType.toLowerCase().match('date');
  }
}
