import { Component, Input, ViewChild, Injector } from '@angular/core';
import {
  Role,
  RoleColors,
  RoleLabels,
  RoleDescriptions,
} from '@app/shared/models';
import {
  AbstractControl,
  ValidationErrors,
  Validator,
  NgForm,
} from '@angular/forms';
import { BaseControlComponent } from '../base-control.component';
import { RoleAssignableBy } from '@shared/models/role-permissions';

@Component({
  selector: 'app-roles-select-control',
  templateUrl: './roles-select-control.component.html',
  styleUrls: ['./roles-select-control.component.scss'],
})
export class RolesSelectControlComponent
  extends BaseControlComponent<Role[]>
  implements Validator
{
  @ViewChild('roleForm') roleForm: NgForm;
  @Input() currentUserRoles: Role[];
  @Input() roles: Role[];
  Role = Role;
  @Input() value: Role[] = [];
  @Input() readOnly: boolean = false;
  @Input() unlocked: boolean = false;
  onValidationChange: any = () => {};
  roleColors = RoleColors;
  roleLabels = RoleLabels;
  roleDescriptions = RoleDescriptions;
  constructor(private injector: Injector) {
    super(injector);
  }

  /**
   * Compares the selectable roles against the roles that the User has.
   *
   * @param role Role that is being rendered in the user form
   * @returns boolean that confirms the user is able to see the role.
   */
  checkAdmin(role: Role): boolean {
    let adminCheck: boolean = false;
    if (this.currentUserRoles) {
      return !this.currentUserRoles.find((r) =>
        RoleAssignableBy[role].includes(r)
      );
    }
    return adminCheck;
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    return _control.value ? null : { roles: true };
  }
}
