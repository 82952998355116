import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FvService, GroupService } from '@app/shared/services';
import { Observable, Subscribable, Subscription } from 'rxjs';
import { ForeignVisitor, Group, Passport } from '@app/shared/models';
import { take } from 'rxjs/operators';
import { PassportService } from '@app/shared/services/passport.service';
import { SUPPRESS_MESSAGE } from '@app/shared/helpers/success-message.interceptor';
import { HttpContext } from '@angular/common/http';
import { hasValue, hasValues } from '@app/shared/helpers/has-values';

@Component({
  selector: 'app-screening-results-dialog',
  templateUrl: './screening-results-dialog.component.html',
  styleUrls: ['./screening-results-dialog.component.scss'],
})
export class ScreeningResultsDialogComponent implements OnInit {
  resultColumn: string[] = [
    'surname',
    'dob',
    'passportNumber',
    'passportCountry',
    'actions',
  ];
  duplicateCols: string[] = ['rowNumber', 'message'];
  upload$: Observable<Group>;
  today: Date = new Date();
  @Output() submitUpload = new EventEmitter<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ScreeningResultsDialogComponent>,
    private groupService: GroupService,
    private passportService: PassportService,
    private fvService: FvService
  ) {}

  ngOnInit(): void {
    let groupId: any = this.data['FV Group ineligible'].messages;
    if (groupId) {
      this.upload$ = this.groupService.get(groupId['1'][0]);
    }
  }

  /**
   * Saves the Foreign Visitor from the result table. This checks the status of
   * a FV's passport and updates/deletes accordingly.
   *
   * @param fv Foreign Visitor that is getting saved
   */
  quickSaveFV(fv: ForeignVisitor, hideMessage: boolean = false): void {
    const newPassport = this.isPassportSaved(fv);
    if (newPassport) {
      this.passportService
        .save(fv.passports![0], new HttpContext().set(SUPPRESS_MESSAGE, true))
        .subscribe();
    } else {
      if (hasValue(fv.passports![0].id && !newPassport)) {
        this.passportService
          .delete(
            fv.passports![0].id,
            false,
            new HttpContext().set(SUPPRESS_MESSAGE, true)
          )
          .subscribe();
        fv.passports = [];
      }
      this.checkPassports(fv);
    }
    this.fvService
      .save(fv, new HttpContext().set(SUPPRESS_MESSAGE, hideMessage))
      .subscribe();
  }

  /**
   * Function that makes sure that all of the FVs have an id on their passports
   * for successful interactions with the API.
   *
   * @param fv Foreign Visitor that is in the result dialog
   * @returns true to render the name
   */
  checkPassports(fv: ForeignVisitor): void {
    const fvId: string = fv.id;
    if (hasValue(fv.passports) && hasValue(fv.passports![0])) {
      fv.passports = [
        {
          ...fv.passports![0],
          foreignVisitor: { id: fvId },
        },
      ];
    } else {
      fv.passports = [
        {
          foreignVisitor: {
            id: fvId,
          },
          number: undefined,
          issuingCountryCode: undefined,
        },
      ];
    }
  }

  saveAll(fvs: ForeignVisitor[] | undefined) {
    if (fvs) {
      fvs?.forEach((fv, x, fvs) => {
        if (x === fvs.length - 1) {
          this.quickSaveFV(fv, false);
          return;
        }
        this.quickSaveFV(fv, true);
      });
    }
    this.dialogRef.close();
  }

  /**
   * Creates an empty passport on each foreign Visitor and/or adds the fvId to
   * the passport to be updated.
   *
   * @param fvs Foreign Visitors in the dialog
   * @returns Foreign Visitors with the passports on their models
   */
  createPassports(fvs: ForeignVisitor[]): ForeignVisitor[] {
    fvs.forEach((fv) => {
      this.checkPassports(fv);
    });
    return fvs;
  }

  /**
   * Checks to see if the passport on the FV is saveable.
   *
   * @param fv Foreign Visitor from the save function
   */
  isPassportSaved(fv: ForeignVisitor) {
    let passport: Passport = fv.passports![0];
    const noNumber: boolean =
      !hasValue(passport.number) || passport.number === '';
    const noCountry: boolean =
      !hasValue(passport.issuingCountryCode) ||
      passport.issuingCountryCode === '';
    const idOnly: boolean =
      noNumber &&
      noCountry &&
      (hasValue(passport.foreignVisitor) || hasValue(passport.id));
    const noPassport: boolean = !hasValue(fv.passports);
    if (idOnly || noPassport) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Function so we can use hasValue in template
   *
   * @param item item we're checking to have value
   * @returns boolean
   */
  hasData(item: any): boolean {
    return hasValue(item);
  }
}
