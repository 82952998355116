import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ForeignVisitor, Group } from '@app/shared/models';
import { Crud, CrudService } from '@shared/services/crud.service';
import { environment } from '@environments/environment';
import { SortSelect } from '../components';
import { without } from 'lodash';
import { Observable, tap } from 'rxjs';

export enum GroupSortField {
  NAME = 'name',
  DESCRIPTION = 'description',
  CREATED_DATE = 'createdDate',
  TYPE = 'foreignVisitorGroupType',
  FN_COUNT = 'foreignVisitorCount',
  NETWORK = 'networkDomain',
}

export const GroupTableColumns: string[] = [
  GroupSortField.NAME,
  GroupSortField.DESCRIPTION,
  GroupSortField.CREATED_DATE,
  GroupSortField.TYPE,
  GroupSortField.FN_COUNT,
  GroupSortField.NETWORK,
  'actions',
];

export const GroupSortLabels: { [key in GroupSortField]: string } = {
  [GroupSortField.NAME]: 'Name',
  [GroupSortField.DESCRIPTION]: 'Description',
  [GroupSortField.CREATED_DATE]: 'Created Date',
  [GroupSortField.TYPE]: 'Group Type',
  [GroupSortField.FN_COUNT]: 'Foreign National Count',
  [GroupSortField.NETWORK]: 'Network',
};

export const GroupSorts: SortSelect[] = without(
  Object.values(GroupSortField),
  // Non-sortable fields
  GroupSortField.NETWORK
).map((value) => {
  return { label: GroupSortLabels[value], value };
});

@Injectable({ providedIn: 'root' })
@Crud({
  apiUrl: `${environment.apiUrl}/foreignVisitorGroup`,
  entity: 'Foreign National Group',
  hasIdPathUpdate: true,
})
export class GroupService extends CrudService<Group> {
  constructor(http: HttpClient) {
    super(http);
  }

  /**
   * @param groupId
   * @param model
   * @returns
   */
  public addFvToGroup(
    groupId: string,
    fvs: ForeignVisitor[]
  ): Observable<Group> {
    return this.http
      .put<Group>(
        `${this.apiUrl}/${groupId}/addfvs`,
        { ids: fvs },
        { context: this.context }
      )
      .pipe(
        tap((m) =>
          this.changeEvent$.emit({
            type: 'update',
            model: m,
            modelId: groupId,
          })
        )
      );
  }

  public removeFvFromGroup(
    groupId: string,
    fvs: ForeignVisitor[]
  ): Observable<Group> {
    return this.http
      .put<Group>(
        `${this.apiUrl}/${groupId}/deletefvs`,
        { ids: fvs },
        { context: this.context }
      )
      .pipe(
        tap((m) =>
          this.changeEvent$.emit({
            type: 'update',
            model: m,
            modelId: groupId,
          })
        )
      );
  }
}
