export enum CountryAssignment {
  OFFICIALLY_ASSIGNED = 'OFFICIALLY_ASSIGNED',
  USER_ASSIGNED = 'USER_ASSIGNED',
  EXCEPTIONALLY_RESERVED = 'EXCEPTIONALLY_RESERVED',
  TRANSITIONALLY_RESERVED = 'TRANSITIONALLY_RESERVED',
  INDETERMINATELY_RESERVED = 'INDETERMINATELY_RESERVED',
  NOT_USED = 'NOT_USED',
}

export interface Country {
  id: string;
  name: string;
  numericCode: string;
  alpha2Code: string;
  alpha3Code: string;
  nationality?: string;
  usTerritory: boolean;
  assignmentState: CountryAssignment;
}
