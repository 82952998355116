import { NetworkDomains } from '@app/shared/models/network-domain';
import {
  FilterConfig,
  FilterConfigType,
} from '@shared/components/advanced-search/filter-config';
import { Role } from '@shared/models';

export const GroupAdvancedSearch: FilterConfig[] = [
  {
    type: FilterConfigType.INPUT,
    label: 'Group Name',
    name: 'groupName',
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Description',
    name: 'description',
  },
  {
    type: FilterConfigType.DATE_RANGE,
    label: 'Create Date',
    name: 'createDate',
    max: new Date(),
  },
  {
    type: FilterConfigType.SELECT,
    label: 'Group Type',
    name: 'type',
    multiple: false,
    options: ['excel_import', 'manual_link'],
  },
  {
    type: FilterConfigType.SELECT,
    label: 'Network',
    name: 'networkDomain',
    ifConfig: { key: 'highEnvFeatures', value: true },
    options: NetworkDomains,
  },
  {
    type: FilterConfigType.ORG,
    label: 'Organization',
    name: 'organizationId',
    col: 12,
    ifRole: [
      Role.sv_admin,
      Role.sv_help_desk,
      Role.sv_vetter,
      Role.sv_org_admin,
    ],
  },
];
