<mat-chip-listbox
  multiple
  *ngIf="roles?.length"
  [class.mat-chip-set-stacked]="stacked"
  aria-label="User Roles"
  class="role-chip-list"
>
  <mat-chip
    *ngFor="let role of roles"
    class="role-chip"
    [matTooltip]="RoleDescriptions[role]"
    highlighted="true"
    [color]="RoleColors[role]"
    [attr.aria-label]="'Role: ' + RoleLabels[role]"
  >
    <div class="d-flex align-items-center gap-1">
      <mat-icon class="mr-2" [svgIcon]="RoleIcons[role]"></mat-icon>
      <span>{{ RoleLabels[role] }}</span>
    </div>
  </mat-chip>
</mat-chip-listbox>
