<h1>Lets make some cards</h1>

<div class="container">
  <div class="d-flex flex-wrap gap">
    <app-fn-card
      *ngFor="let item of (fn$ | async)?.content"
      [fn]="item"
    ></app-fn-card>
  </div>
</div>
