import { Component, Input } from '@angular/core';
import { Role, BaseModel, ScreeningDateFormat } from '@app/shared/models';

@Component({
  selector: 'app-resource-user-details',
  templateUrl: './resource-user-details.component.html',
  styleUrls: ['resource-user-details.component.scss'],
})
export class ResourceUserDetailsComponent {
  @Input() hideOwnerOrg = false;
  @Input() data: BaseModel;
  @Input() fvVersion: boolean = false;
  Role = Role;
  ScreeningDateFormat = ScreeningDateFormat;

  constructor() {}
}
