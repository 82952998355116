<div [formGroup]="locationForm" id="locationFormId" class="py-2 spacing">
  <mat-checkbox
    formArrayName="locationCheckboxes"
    *ngFor="let item of displayCheckedColumns; let i = index"
    (change)="selectedColumns($event)"
    [value]="item.label"
    name="{{ item.label }}"
    #locCheckBox
    [checked]="item.selected"
  >
    {{ item.label }}
  </mat-checkbox>
</div>
<div class="mt-2" #groupButton id="selectButton">
  <button
    mat-raised-button
    color="primary"
    class="mr-2"
    (click)="toggleAll(true)"
  >
    Select All
  </button>
  <button mat-raised-button color="primary" (click)="toggleAll(false)">
    Deselect All
  </button>
</div>
