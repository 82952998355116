<div class="d-flex justify-content-end">
  <div>
    <mat-icon
      class="read-only"
      *ngIf="!fv.isEditable"
      color="primary"
      matTooltip="Read Only Record"
      svgIcon="pencil-off-outline"
    >
    </mat-icon>
  </div>
  <div
    *ngIf="fv.latestScreening?.inheritedFromFlag"
    class="d-flex justify-content-center p-1 rounded inheritedBadgeColor"
    matTooltip="Inherited Screening"
  >
    <mat-icon>toll</mat-icon>
  </div>
  <mat-icon
    matTooltip="FN ineligible for screening"
    color="warn"
    *ngIf="fv.screeningEligible === false"
    >warning</mat-icon
  >
  <ng-container *appIfRoles="Role.sv_admin">
    <mat-icon
      matTooltip="Average Response Time Exceeded"
      style="color: #fdd835"
      *ngIf="fv.isScreeningTimeOutstanding"
      >warning</mat-icon
    >
  </ng-container>
</div>
