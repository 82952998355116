import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm } from '@angular/forms';
import { Address } from '@app/shared/models';
import { without } from 'lodash';

export interface Name {
  firstName?: string;
  lastName?: string;
}

export enum EmailType {
  Home = 'Home',
  Work = 'Work',
  Personal = 'Personal',
  Other = 'Other',
}

export interface Email {
  type?: string;
  address?: string;
}
@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss'],
})
export class FormsComponent {
  address: Address = {};
  name: Name = {};
  array: Name[] = [];
  simpleForm = new UntypedFormGroup({
    firstName: new UntypedFormControl(),
    lastName: new UntypedFormControl(),
  });

  email: Email = {};
  emailArray: Email[] = [];
  types = EmailType;

  @Output() emitRemove = new EventEmitter();

  @ViewChild('nameForm') nameForm: NgForm;
  @ViewChild('emailForm') emailForm: NgForm;

  submit(item: any, oldArray: any[], form: NgForm) {
    let newEntries = oldArray;
    newEntries.push({ ...item });
    form.reset();
  }

  removeName(item: any) {
    this.array = without(this.array, item);
  }

  removeEmail(item: any) {
    this.emailArray = without(this.emailArray, item);
  }
}
