import { ErrorHandler, Injectable } from '@angular/core';
import { CustomNGXLoggerService, NGXLogger } from 'ngx-logger';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  logger: NGXLogger;

  constructor(private customLogger: CustomNGXLoggerService) {
    super();
    this.logger = customLogger.getNewInstance({
      partialConfig: { context: 'GlobalErrorHandler' },
    });
  }

  handleError(error: string) {
    this.logger.error(error);
  }
}
