import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FAR } from '@app/shared/models';
import { FarService } from '@app/shared/services';
@Component({
  selector: 'app-far-details-dialog',
  templateUrl: './far-details-dialog.component.html',
  styleUrls: ['./far-details-dialog.component.scss'],
})
export class FarDetailsDialogComponent implements OnInit {
  busy: boolean = false;
  far: FAR = {};
  hideDescription: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private farService: FarService,
    private dialogRef: MatDialogRef<FarDetailsDialogComponent>
  ) {}

  ngOnInit(): void {
    if (this.data.hideDescription || this.data.far) {
      this.far = this.data.far;
      this.hideDescription = this.data.hideDescription;
    } else this.far = this.data;
  }

  onSubmit() {
    this.busy = true;
    this.farService.save(this.far).subscribe((_far) => {
      this.dialogRef.close();
    });
  }
}
