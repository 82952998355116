<h1 mat-dialog-title class="d-flex align-items-center gap-1">
  <mat-icon [color]="color" *ngIf="icon" [svgIcon]="icon"></mat-icon>
  <span>{{ title }}</span>
</h1>

<div mat-dialog-content class="p-4">
  <p>{{ message }}</p>
</div>

<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ cancelText }}</button>
  <button
    data-cy="confirm-dialog-button"
    mat-raised-button
    [color]="confirmColor"
    (click)="onConfirm()"
    [disabled]="loading"
    cdkFocusInitial
  >
    <div *ngIf="!loading">{{ confirmText }}</div>
    <mat-spinner color="primary" *ngIf="loading" [diameter]="35"></mat-spinner>
  </button>
</div>
