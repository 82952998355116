<h2 mat-dialog-title>{{ data.displayTitle }}</h2>
<mat-dialog-content>{{ data.displayMsg }}</mat-dialog-content>
<mat-dialog-actions>
  <ng-container
    *ngIf="
      data.confirmBtn || data.cancelBtn;
      then confirmDenyButtons;
      else basicButtons
    "
  >
  </ng-container>
</mat-dialog-actions>
<ng-template #basicButtons>
  <button
    id="dialog-close"
    #closeButton
    mat-flat-button
    color="primary"
    mat-dialog-close
  >
    <mat-icon svgIcon="close"></mat-icon>Close
  </button>
</ng-template>
<ng-template #confirmDenyButtons>
  <button
    #confirmButton
    *ngIf="data.confirmBtn"
    mat-flat-button
    color="primary"
    (click)="dialogRef.close({ event: 'confirm' })"
  >
    <mat-icon [svgIcon]="data.confirmBtn.icon"> </mat-icon>
    {{ data.confirmBtn.label | titlecase }}
  </button>
  <button
    #cancelButton
    *ngIf="data.cancelBtn"
    mat-flat-button
    color="warn"
    mat-dialog-close
  >
    <mat-icon [svgIcon]="data.cancelBtn.icon"></mat-icon>
    {{ data.cancelBtn.label | titlecase }}
  </button>
</ng-template>
