import {
  FilterConfig,
  FilterConfigType,
} from '@shared/components/advanced-search/filter-config';

export const ApprovalAdvancedSearch: FilterConfig[] = [
  {
    type: FilterConfigType.INPUT,
    label: 'Given Name',
    name: 'givenName',
    maxLength: 256,
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Surname',
    name: 'surname',
    maxLength: 256,
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Email Address',
    name: 'email',
    maxLength: 256,
  },
  {
    type: FilterConfigType.TOGGLE,
    label: 'Include Inactive Approvals',
    name: 'showInactive',
    tooltip: 'Include Inactive/Expired Approvals in search',
  },
  {
    type: FilterConfigType.DATE_RANGE,
    label: 'Approval Date',
    name: 'approvalDate',
    max: new Date(),
  },
  {
    type: FilterConfigType.DATE_RANGE,
    label: 'Expiration Date',
    name: 'expirationDate',
    min: new Date(),
  },
  {
    type: FilterConfigType.ORG,
    label: 'Organization',
    name: 'organizationId',
    col: 12,
  },
];
