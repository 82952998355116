import { Component, Input } from '@angular/core';
import { ActionButton } from '@app/shared/models/actionbutton.model';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {
  @Input() title = '';
  @Input() actionButtons: ActionButton[];
}
