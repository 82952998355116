import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { APP_CONFIG } from '@environments/ienvironment';

async function bootstrap() {
  let config = { ...environment };
  if (environment.configJson) {
    const configResp = await fetch(environment.configJson);
    const configJson = await configResp.json();
    config = { ...environment, ...configJson };
  }

  if (environment.production) {
    enableProdMode();
  }

  return platformBrowserDynamic([
    {
      provide: APP_CONFIG,
      useValue: config,
    },
  ]).bootstrapModule(AppModule);
}

bootstrap().catch((err) => console.error(err));
