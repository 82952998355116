import { isBoolean, isDate, isEmpty, isNumber } from 'lodash';

/**
 * helper function to determine if each property of an object has a value
 * will return true if a property has a value other than
 * an empty string, null, undefined, empty object {}, or empty array[]
 * @param obj an object to validate
 * @returns boolean
 */
export function hasValues(obj: Object): boolean {
  if (obj === null) return false;
  return !!Object.values(obj).find(hasValue);
}

/**
 * Predicate used to determine if a value exists.
 * will return false if value is empty string, null, undefined, empty object {}
 * or an empty array []
 * @param value any value to be checked for a value type
 * @returns boolean
 */
export function hasValue(value: any) {
  return (
    isDate(value) || isBoolean(value) || isNumber(value) || !isEmpty(value)
  );
}
