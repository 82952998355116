<button
  mat-icon-button
  matTooltip="Passport Help Info"
  color="primary"
  id="passport-help-open"
  #passportHelpTrigger="matMenuTrigger"
  [matMenuTriggerFor]="passportHelpMenu"
>
  <mat-icon>help</mat-icon>
</button>
<mat-menu #passportHelpMenu class="passportHelpMenu">
  <img class="pl-1" src="assets/images/passport example.png" />
</mat-menu>
