import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CognitoAuthService } from '@app/auth/cognito-auth/cognito-auth.service';
import { AuthService } from '@app/auth/auth.service';
import { LoginModule } from '@app/auth/cognito-auth/login/login.module';
import { CognitoAuthLoginComponent } from './cognito-auth-login/cognito-auth-login.component';

@NgModule({
  declarations: [CognitoAuthLoginComponent],
  providers: [
    CognitoAuthService,
    { provide: AuthService, useExisting: CognitoAuthService },
  ],
  imports: [CommonModule, LoginModule],
  exports: [LoginModule],
})
export class CognitoAuthModule {}
