import {
  Directive,
  EventEmitter,
  Output,
  HostListener,
  Input,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ForeignVisitor } from '@app/shared/models';
import { ScreeningDialogComponent } from './screening-dialog.component';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Directive({
  selector: '[screeningUpdate]',
})
export class ScreeningUpdateDirective {
  @Input() screeningUpdate: ForeignVisitor = {};
  @Input() route: any[];
  @Input() disable: boolean = false;
  @Output() triggerUpdate: EventEmitter<boolean> = new EventEmitter();
  constructor(private dialog: MatDialog, private router: Router) {}

  @HostListener('click', ['$event'])
  onClick() {
    if (this.disable) return;
    this.openDialog(this.screeningUpdate);
  }

  openDialog(fv: ForeignVisitor) {
    const dialogRef = this.dialog.open(ScreeningDialogComponent, {
      autoFocus: false,
      data: fv,
      width: '900px',
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.triggerUpdate.emit();
        if (dialogRef.componentInstance.confirmUsp && this.route)
          this.router.navigate(this.route);
      });
  }
}
