<ng-container
  *ngIf="
    fv.education && fv.education.length > 0;
    else emptyCollectionButtonStyle
  "
>
  <mat-expansion-panel
    class="flex-fill mt-3 panel-200-mh"
    #educationPanel="matExpansionPanel"
    id="fv-view-education-panel"
    *ngIf="fv.education.length"
  >
    <mat-expansion-panel-header>
      <mat-panel-title class="d-flex justify-content-between py-2">
        <span>Education</span>
        <mat-icon
          svgIcon="school-outline"
          [matBadge]="fv.education.length"
          matBadgeSize="small"
          matBadgeColor="accent"
        ></mat-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-divider class="panel-header-divider"></mat-divider>
    <div class="education-body">
      <ng-container *ngFor="let item of fv.education; index as i">
        <div class="content row">
          <div class="col">
            <div class="row">
              <div class="col-6">
                <dl>
                  <dt>Dates</dt>
                  <dd>
                    {{
                      item.educationDates[0].startDate | militaryDate | default
                    }}
                    <span *ngIf="item.educationDates[0].endDate">
                      -
                      {{ item.educationDates[0].endDate | militaryDate }}</span
                    >
                  </dd>
                  <dt>School Name</dt>
                  <dd class="d-flex align-items-center">
                    {{ item.schoolName | default }}
                  </dd>
                </dl>
              </div>
              <div class="col-6">
                <dl>
                  <dt>Degree</dt>
                  <dd>
                    {{ item.degree | default }}
                  </dd>
                  <dt>Address</dt>
                  <dd
                    *ngIf="
                      item.campusLocation && isAddress(item.campusLocation)
                    "
                  >
                    {{
                      item.campusLocation
                        | address: "street"
                        | shorten: 50:"..."
                    }}
                    <br />
                    {{
                      item.campusLocation
                        | address: "cityStateCountryPostalCode"
                        | shorten: 50:"..."
                    }}
                  </dd>
                  <dd *ngIf="!isAddress(item.campusLocation)">N/A</dd>
                </dl>
              </div>
            </div>
          </div>
          <mat-divider
            [vertical]="true"
            class="panel-vertical-divider"
          ></mat-divider>
          <div
            class="col-2 d-flex justify-content-center align-items-center"
            style="flex-direction: column"
          >
            <button
              *ngIf="fv.isEditable"
              mat-icon-button
              (click)="openEducationDialog(item)"
              matTooltip="Edit Passport"
            >
              <mat-icon svgIcon="square-edit-outline"></mat-icon>
            </button>

            <div class="flex-fill"></div>
            <div *ngIf="i === 0 && fv.isEditable" class="mb-2">
              <button
                mat-mini-fab
                matTooltip="Add Passport"
                class="bg-warning"
                style="color: white"
                (click)="openEducationDialog()"
              >
                <mat-icon svgIcon="plus-circle-outline"></mat-icon>
              </button>
            </div>
          </div>
        </div>
        <mat-divider
          *ngIf="i + 1 < fv.education.length"
          class="panel-header-divider"
        ></mat-divider>
      </ng-container>
    </div>
  </mat-expansion-panel>
</ng-container>
<ng-template #emptyCollectionButtonStyle>
  <button
    class="mt-3 w-100"
    style="height: 48px"
    mat-raised-button
    matTooltip="Add New Education"
    color="primary"
    (click)="openEducationDialog()"
    [disabled]="!fv.isEditable"
  >
    <div class="d-flex align-items-center">
      <span>Add Education</span>
      <mat-icon class="ml-2" svgIcon="school-outline"></mat-icon>
    </div>
  </button>
</ng-template>
