import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  DialogData,
  FilterParams,
  ForeignVisitor,
  Group,
  PageableCollection,
  Role,
} from '@app/shared/models';
import { BiographicDialogComponent } from '../biographic-dialog/biographic-dialog.component';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  FvService,
  AlertService,
  ScreeningService,
  ForeignVisitorGroupService,
} from '@app/shared/services';
import { ClassificationModel } from '@app/shared/models/classification.model';
import { ConfirmDialogComponent } from '@app/shared/components';
import { Subject, takeUntil } from 'rxjs';
import {
  FVEditRoles,
  FVRescreenRoles,
} from '@app/shared/models/role-permissions';
import { Router } from '@angular/router';

@Component({
  selector: 'fvview-info',
  templateUrl: 'fvinfo.component.html',
  styleUrls: ['fvinfo.component.scss'],
})
export class FvInfoComponent implements OnInit, OnDestroy {
  @Input() fv: ForeignVisitor;
  FVRescreenRoles = FVRescreenRoles;
  Role = Role;
  FVEditRoles = FVEditRoles;
  private ngUnsubscribe = new Subject<void>();
  groups: PageableCollection<Group>;

  constructor(
    public dialog: MatDialog,
    public alert: AlertService,
    private fvService: FvService,
    private screeningService: ScreeningService,
    private groupService: ForeignVisitorGroupService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.groupService
      .find(
        new FilterParams({
          sortBy: 'name',
          direction: 'DESC',
          pageNum: 0,
          pageSize: 100,
          includeFvId: this.fv.id,
        })
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.groups = res;
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onEdit(): void {
    this.openDialog({ mode: 'update', model: this.fv });
  }

  openDialog(dialogData: DialogData<ForeignVisitor>): void {
    const dialogConfig: MatDialogConfig = {
      data: dialogData,
      disableClose: true,
      width: '600px',
      autoFocus: '#submit-button',
    };
    this.dialog.open(BiographicDialogComponent, dialogConfig);
  }

  onClassify(model: ClassificationModel) {
    this.fvService
      .save(model as ForeignVisitor)
      .subscribe((fv: ForeignVisitor) => {
        this.alert.successAlert(
          `Updated Classification for ${fv.givenName} ${fv.surname}`
        );
      });
  }

  openConfirmationDialog(
    fv: ForeignVisitor
  ): MatDialogRef<ConfirmDialogComponent> {
    let data = {
      icon: 'alert-octagon',
      color: 'warn',
      title: `ARE YOU SURE?`,
      message: `This will create a new screening request for ${fv.givenName} ${fv.surname}.`,
      performAction: () => this.rescreen(fv),
      confirmText: 'Confirm',
    };

    return this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data,
    });
  }

  navigateToGroup(item: Group) {
    let filter: any = {};
    let destination = '../fvs';
    let queryField = 'foreignVisitorGroupId';
    let queryParam = item.id!;

    if (queryField) {
      filter[queryField] = [queryParam];
      this.router.navigate([destination], {
        queryParams: filter,
      });
    }
  }

  rescreen(fv: ForeignVisitor) {
    this.screeningService
      .rescreen({ id: fv.id })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe();
  }
}
