<ng-container *ngIf="derogItems">
  <mat-toolbar>
    <span> Derogatory Codes ({{ derogItems.length }}) </span>
    <div class="flex-fill"></div>
    <button
      *ngIf="overrideCode"
      [appScreeningCode]
      (appScreeningCodeUpdated)="onAdd($event)"
      mat-button
      color="primary"
      matTooltip="Add Derogatory Info"
    >
      <div class="d-flex align-items-center gap-1">
        <mat-icon svgIcon="plus-circle-outline"></mat-icon>
        <span>Add</span>
      </div>
    </button>
    <a
      [href]="screeningCodeUrl"
      target="_blank"
      matSuffix
      mat-icon-button
      color="primary"
      matTooltip="View code definitions"
    >
      <mat-icon>help</mat-icon>
    </a>
  </mat-toolbar>

  <mat-table [dataSource]="derogItems" name="derogTable" #derogTable>
    <ng-container matColumnDef="classification">
      <mat-header-cell *matHeaderCellDef>Classification</mat-header-cell>
      <mat-cell *matCellDef="let item">{{ item.classification }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="program">
      <mat-header-cell *matHeaderCellDef> Program </mat-header-cell>
      <mat-cell *matCellDef="let item">
        {{ item.offenseCode | derogDescription: "program" }}
      </mat-cell>
    </ng-container>
    <!-- TODO: Verify that all codes in DB contain values -->
    <ng-container matColumnDef="code">
      <mat-header-cell *matHeaderCellDef>Code</mat-header-cell>
      <mat-cell *matCellDef="let item">
        <div
          class="p-2 bg-light rounded"
          matTooltip="Offense: {{ item | offenseDescription }}"
        >
          {{ item.offenseCode }}
        </div>
        <div
          class="p-2 bg-light rounded"
          matTooltip="Relational: {{ item | relationalDescription }}"
        >
          {{ item.relationalCode }}
        </div>
        <div
          class="p-2 bg-light rounded"
          matTooltip="Contacts: {{ item | communicaitonDescription }}"
        >
          {{ item.numberOfCommunicationContacts }}
        </div>
        <div
          class="p-2 bg-light rounded"
          matTooltip="Timeframe: {{ item | timeframeDescription }}"
        >
          {{ item.timeFrameOfCommunicationContacts }}
        </div>
        <div
          class="p-2 bg-light rounded"
          matTooltip="Verification: {{ item | verificationDescription }}"
        >
          {{ item.verificationOfCommunicationContent }}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="rationale">
      <mat-header-cell *matHeaderCellDef>Rationale</mat-header-cell>
      <mat-cell
        *matCellDef="let item"
        [matTooltip]="item.rationale ? 'Click to View Rationale' : 'N/A'"
      >
        <button
          *ngIf="item.rationale; else noRationale"
          mat-icon-button
          [matMenuTriggerFor]="rationaleMenu"
        >
          <mat-icon [svgIcon]="'note-text'"></mat-icon>
        </button>
        <mat-menu #rationaleMenu="matMenu">
          <div class="container">
            <div class="row">
              <div class="col">
                <dd>{{ item.rationale | default }}</dd>
              </div>
            </div>
          </div>
        </mat-menu>
        <ng-template #noRationale> N/A </ng-template>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell
        *matCellDef="let item; let index = index"
        [matTooltip]="'Click to Edit'"
      >
        <mat-button mat-icon-button [matMenuTriggerFor]="codeActions">
          <mat-icon [svgIcon]="'dots-vertical'"></mat-icon>
        </mat-button>
        <mat-menu #codeActions="matMenu">
          <button
            mat-menu-item
            [appScreeningCode]="item"
            (appScreeningCodeUpdated)="onEdit($event, index)"
          >
            <mat-icon [svgIcon]="'pencil'"></mat-icon>Edit
          </button>
          <button mat-menu-item (click)="removeCode(index)">
            Remove
            <mat-icon [svgIcon]="'delete'"></mat-icon>
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>
    <ng-template matNoDataRow>
      <mat-toolbar class="justify-content-center bg-white"
        >No Derogatory Information Available</mat-toolbar
      >
    </ng-template>
    <mat-header-row
      *matHeaderRowDef="overrideCode ? overrideCodeCol : screeningCodeColumns"
    ></mat-header-row>
    <mat-row
      *matRowDef="
        let row;
        columns: overrideCode ? overrideCodeCol : screeningCodeColumns
      "
    ></mat-row>
  </mat-table>
</ng-container>
