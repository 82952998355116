import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, IsNotAuthenticated } from '@shared/helpers';
import { LoginDashboardComponent } from '../login-dashboard/login-dashboard.component';
import { CognitoAuthLoginComponent } from '@app/auth/cognito-auth/cognito-auth-login/cognito-auth-login.component';

const routes: Routes = [
  {
    path: 'login',
    title: 'Login',
    component: LoginDashboardComponent,
    canDeactivate: [AuthGuard],
    canActivate: [IsNotAuthenticated],
    children: [{ path: '', component: CognitoAuthLoginComponent }],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CognitoAuthRoutingModule {}
