<ng-container *ngIf="!isEmpty(); else emptyCollectionButtonStyle">
  <mat-expansion-panel
    class="flex-fill mt-3 panel-200-mh"
    #identifyingFeaturesPanel="matExpansionPanel"
    id="fv-view-vehicle-panel"
  >
    <mat-expansion-panel-header>
      <mat-panel-title class="d-flex justify-content-between py-2">
        <span>Vehicle and Identifying Information</span>
        <div class="d-flex">
          <mat-icon svgIcon="car-outline" style="margin-left: 2px"></mat-icon>
          <mat-divider vertical style="background: black"></mat-divider>
          <mat-icon svgIcon="head"></mat-icon>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-divider class="panel-header-divider"></mat-divider>
    <div class="identifying-vehicle-body">
      <div class="content row">
        <div class="col">
          <div class="row">
            <div class="col-6" style="border-bottom: 1px solid lightgrey">
              <dl class="mb-0">
                <dt>Driver's License</dt>
                <dd>{{ fv.driversLicenseId | default }}</dd>
                <dt>License Plate</dt>
                <dd>
                  {{ fv.vehicleLicensePlate | default }}
                </dd>
              </dl>
            </div>
            <div class="col-6" style="border-bottom: 1px solid lightgrey">
              <dl class="mb-0">
                <dt>Vehicle Make</dt>
                <dd>{{ fv.vehicleMake | default }}</dd>
                <dt>Vehicle Model</dt>
                <dd>
                  {{ fv.vehicleModel | default }}
                </dd>
              </dl>
            </div>
            <div class="col-6 mt-1">
              <dl>
                <dt>Hair Color</dt>
                <dd>{{ fv.hairColor | default }}</dd>
              </dl>
            </div>
            <div class="col-6 mt-1">
              <dl>
                <dt>Eye Color</dt>
                <dd>{{ fv.eyeColor | default }}</dd>
              </dl>
            </div>
            <div class="col-12">
              <dl>
                <dt>Identifying Marks</dt>
                <dd>{{ fv.identifyingMarks | default }}</dd>
              </dl>
            </div>
          </div>
        </div>
        <mat-divider
          [vertical]="true"
          class="panel-vertical-divider"
        ></mat-divider>
        <div class="col-2 d-flex justify-content-center">
          <button
            *ngIf="editable"
            mat-icon-button
            (click)="openPhysicalDialog()"
            matTooltip="Edit Vehicle Information"
          >
            <mat-icon svgIcon="square-edit-outline"></mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</ng-container>
<ng-template #emptyCollectionButtonStyle>
  <button
    class="mt-3 w-100"
    style="height: 48px"
    mat-raised-button
    matTooltip="Add Identifying Information"
    color="primary"
    (click)="openPhysicalDialog()"
    [disabled]="!fv.isEditable"
  >
    <div class="d-flex align-items-center">
      <span>Add Vehicle and Identifying Information</span>
      <mat-icon svgIcon="car-outline"></mat-icon>
      <mat-icon
        svgIcon="head"
        style="border-left: 1px solid white; margin-left: 1px"
      ></mat-icon>
    </div>
  </button>
</ng-template>
