import { Component, Input, OnInit } from '@angular/core';
import { AppConfigService } from '@shared/services/app-config.services';
import {
  FAR,
  FilterParams,
  ForeignVisitor,
  PageableCollection,
  FarLocation,
} from '@app/shared/models';
import { FarService, FvService } from '@app/shared/services';
import { AddFarDialogComponent } from './add-far-dialog/add-far-dialog.component';
import { take } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'fvview-activity',
  templateUrl: 'fvactivity.component.html',
  styleUrls: ['fvactivity.component.scss'],
})
export class FvActivityComponent implements OnInit {
  loading = false;
  activeFilter: string = 'all';
  @Input() fv: ForeignVisitor;
  @Input() fars: PageableCollection<FAR>;
  @Input() linkedById: string | null = '';
  upcomingFarMode: boolean = false;
  isDecending: boolean = true;
  farScreeningEligibility: boolean = false;
  filterParams = new FilterParams({
    sortBy: 'primaryFar.startDate',
    direction: 'DESC',
    pageNum: 0,
    pageSize: 10,
  });

  constructor(
    private farService: FarService,
    private config: AppConfigService,
    private dialog: MatDialog,
    private fvService: FvService
  ) {}

  ngOnInit(): void {
    this.farScreeningEligibility =
      this.config.get('farScreeningEligibility') || false;
    this.filterParams.fvId = this.fv.id;
    this.doSearch('all');
  }

  doSearch(type?: string) {
    this.loading = true;
    if (type === 'all') {
      this.filterParams.pageNum = 0;
      delete this.filterParams.isActiveFar;
      this.upcomingFarMode = false;
    } else if (type === 'upcoming') {
      this.upcomingFarMode = true;
      this.filterParams.pageNum = 0;
      this.filterParams.isActiveFar = true;
    }
    this.farService
      .find(this.filterParams)
      .pipe(take(1))
      .subscribe((data) => {
        this.fars = data;
        this.loading = false;
      });

    const fvGet = this.linkedById?.length
      ? this.fvService.getLinked(this.linkedById, this.fv.id)
      : this.fvService.get(this.fv.id);

    fvGet.pipe(take(1)).subscribe((data) => {
      this.fv.activeFarCount = data.activeFarCount;
      this.fv.farCount = data.farCount;
    });
  }

  onAdd() {
    let dialogRef = this.dialog.open(AddFarDialogComponent, {
      data: this.fv,
      width: '600px',
    });

    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe(() => {
        let ok = dialogRef.componentInstance.ok;
        if (ok) {
          this.doSearch();
        }
      });
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParams = filterParams;
    this.doSearch();
  }

  changeSort(isDecending: boolean) {
    this.isDecending = isDecending;
    if (this.isDecending) this.filterParams.direction = 'DESC';
    else this.filterParams.direction = 'ASC';
    this.doSearch();
  }
}
