import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Crud, CrudService } from '@shared/services/crud.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
@Crud({
  apiUrl: `${environment.apiUrl}/usp`,
  entity: 'Screening',
  hasIdPathUpdate: false,
})
export class UspMetricsService extends CrudService<any> {
  constructor(http: HttpClient) {
    super(http);
  }

  public findUspMetrics(
    startDate: string,
    endDate: string,
    unit: string
  ): Observable<any> {
    let params = {
      startDate: startDate,
      endDate: endDate,
      interval: unit + '_OF_YEAR',
      byOrg: false,
    };
    return this.http.get<any>(`${this.apiUrl}/metrics/interval`, {
      params: params,
    });
  }

  public findUspMetricsByOrg(
    startDate: string,
    endDate: string,
    unit: string
  ): Observable<any> {
    let params = {
      startDate: startDate,
      endDate: endDate,
      interval: unit + '_OF_YEAR',
      byOrg: true,
    };
    return this.http.get<any>(`${this.apiUrl}/metricsByOrgInterval`, {
      params: params,
    });
  }
}
