import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/shared/material/material.module';
import { FormsModule } from '@angular/forms';
import {
  TimelineBadgeDirective,
  TimelineComponent,
  TimelineContentTemplateDirective,
} from './';

const SHARED_COMPONENTS = [
  TimelineBadgeDirective,
  TimelineComponent,
  TimelineContentTemplateDirective,
];
@NgModule({
  declarations: SHARED_COMPONENTS,
  imports: [CommonModule, MaterialModule, FormsModule],
  exports: SHARED_COMPONENTS,
})
export class TimelineModule {}
