<h1 mat-dialog-title>{{ data.phone.id ? "Edit" : "Add New" }} Phone Number</h1>
<mat-dialog-content>
  <div class="container mt-2">
    <app-phone-number-control
      #phoneForm
      name="phoneForm"
      [(ngModel)]="data.phone"
      [lockPrimary]="data.lockPrimary"
    ></app-phone-number-control>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-end">
  <button mat-button mat-dialog-close color="primary">Close</button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="
      (phoneForm.phoneNumberForm && !phoneForm.phoneNumberForm.valid) || busy
    "
    (click)="save(data.phone)"
  >
    Submit
  </button>
</mat-dialog-actions>
