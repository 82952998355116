Password must be at least
<span
  [class.text-danger]="touched && errors?.hasMinLength"
  [class.text-success]="touched && !errors?.hasMinLength"
>
  <mat-icon class="text-success" *ngIf="touched && !errors?.hasMinLength"
    >check_circle</mat-icon
  >
  {{ policy.minLength }} characters long,
</span>
must contain at least one
<span
  *ngIf="policy.requireCapitalCase"
  [class.text-danger]="touched && errors?.hasCapitalCase"
  [class.text-success]="touched && !errors?.hasCapitalCase"
>
  <mat-icon class="text-success" *ngIf="touched && !errors?.hasCapitalCase"
    >check_circle</mat-icon
  >
  uppercase,
</span>
one
<span
  *ngIf="policy.requireSmallCase"
  [class.text-danger]="touched && errors?.hasSmallCase"
  [class.text-success]="touched && !errors?.hasSmallCase"
>
  <mat-icon class="text-success" *ngIf="touched && !errors?.hasSmallCase"
    >check_circle</mat-icon
  >
  lowercase,
</span>
one
<span
  *ngIf="policy.requireNumber"
  [class.text-danger]="touched && errors?.hasNumber"
  [class.text-success]="touched && !errors?.hasNumber"
>
  <mat-icon class="text-success" *ngIf="touched && !errors?.hasNumber"
    >check_circle</mat-icon
  >
  number,
</span>
and one
<span
  *ngIf="policy.requireSpecialCharacter"
  [class.text-danger]="touched && errors?.hasSpecialCharacter"
  [class.text-success]="touched && !errors?.hasSpecialCharacter"
>
  <mat-icon class="text-success" *ngIf="touched && !errors?.hasSpecialCharacter"
    >check_circle</mat-icon
  >
  special character.
</span>
