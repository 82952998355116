import { NumberInput, coerceNumberProperty } from '@angular/cdk/coercion';
import { Component, Input } from '@angular/core';
import { Screening } from '@app/shared/models';
import { ScreeningService } from '@app/shared/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'screening-transition',
  templateUrl: './screening-transition.component.html',
})
export class ScreeningTransitionComponent {
  @Input()
  public get screeningId(): string {
    return this._screeningId;
  }
  public set screeningId(value: string) {
    this._screeningId = value;
    this.screening$ = this.screeningService.findHistoryByScreeningId(
      this._screeningId
    );
  }
  private _screeningId: string;

  _loading: boolean = false;

  public get transitions() {
    return this._transitions;
  }
  public set transitions(value) {
    this._transitions = value;
  }
  private _transitions: Screening[];

  @Input()
  public get iconSize() {
    return this._iconSize;
  }
  public set iconSize(value: NumberInput) {
    this._iconSize = coerceNumberProperty(value);
  }
  private _iconSize: number;

  screening$: Observable<Screening[]>;

  constructor(private screeningService: ScreeningService) {}
}
