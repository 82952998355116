<mat-card
  class="fn-card"
  [class]="fn.selected ? 'selected' : ''"
  matRipple
  [matTooltip]="
    groupSelectionMode && isSelectDisabled(fn)
      ? 'Not eligible for Group Selection'
      : ''
  "
>
  <app-fn-profile
    #fnProfile
    [fn]="fn"
    [showPills]="showPills"
    [additionalDocumentsScreeningEligibility]="
      additionalDocumentsScreeningEligibility
    "
    [groupSelectionMode]="groupSelectionMode && !isSelectDisabled(fn)"
  ></app-fn-profile>
  <app-fn-latest-screening
    *ngIf="fn?.latestScreening"
    [screening]="fn?.latestScreening!"
  ></app-fn-latest-screening>
  <ng-container *ngIf="!fn?.latestScreening">
    <div class="p-1 d-flex align-items-center" style="background-color: black">
      <div class="flex-fill d-flex align-items-center gap-1">
        <mat-icon
          svgIcon="progress-alert"
          class="md-36"
          style="color: white"
        ></mat-icon>
        <strong style="color: white">Screening Not Found</strong>
      </div>
    </div>
  </ng-container>

  <app-fn-metadata
    [fn]="fn"
    [hideMetadataFooter]="hideMetadataFooter"
    [farScreeningEligibility]="farScreeningEligibility"
  ></app-fn-metadata>
</mat-card>
