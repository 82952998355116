import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject, from } from 'rxjs';
import { get as getScript } from 'scriptjs';
import { switchMap } from 'rxjs/operators';
import { Classification, CMT } from '@shared/cmt/cmt.model';
import { ClassificationModel } from '@shared/models/classification.model';
import { AppConfigService } from '@shared/services/app-config.services';
import { AuthService } from '@app/auth/auth.service';
import { CMTSettings } from '@environments/ienvironment';

export function classificationAppInitializerFactory(
  classificationService: ClassificationService
): () => Promise<void> {
  return () => classificationService.init();
}

@Injectable({
  providedIn: 'root',
})
export class ClassificationService {
  private settings: CMTSettings;
  private $storeXMLSubject?: Subject<ClassificationModel>;

  constructor(
    private ngZone: NgZone,
    private auth: AuthService,
    private config: AppConfigService
  ) {
    this.settings = config.get('cmt') as CMTSettings;
  }

  async init(): Promise<void> {
    if (this.classification || !this.settings) {
      return;
    }

    return new Promise((resolve) => {
      getScript(this.settings.cmtIntegrationUrl, () => {
        this.cmt.initAcg(null, null);
        this.cmt.initAcgPortionMarking(null, null);
        this.cmt.disableSaveAsFavorite = false;
        this.cmt.storeClassificationXML =
          this.storeClassificationXML.bind(this);
        resolve();
      });
    });
  }

  private storeClassificationXML(xml: string) {
    this.ngZone.run(() => {
      const model: ClassificationModel = {};
      model.classificationXML = xml;
      model.classification = this.classification?.getHeader()?.getBanner();

      if (this.$storeXMLSubject) {
        this.$storeXMLSubject.next(model);
        this.$storeXMLSubject.complete();
        delete this.$storeXMLSubject;
      }
    });
  }

  async setModel(model?: ClassificationModel): Promise<void> {
    const classifiedBy = await this.auth.getClaim(
      this.settings.cmtClassifiedByAuthClaim
    );

    if (model?.classificationXML) {
      this.classification.setClassificationXML(model.classificationXML);
    } else {
      this.classification.clear();
    }
    this.classification.setClassifiedBy(classifiedBy);
  }

  launchCmt(model?: ClassificationModel): Observable<ClassificationModel> {
    return from(this.setModel(model)).pipe(
      switchMap(() => {
        if (this.$storeXMLSubject) {
          this.$storeXMLSubject.complete();
        }

        this.$storeXMLSubject = new Subject<ClassificationModel>();

        this.cmt.launchAcgWindow(this.settings.cmtTemplatePath);
        return this.$storeXMLSubject.asObservable();
      })
    );
  }

  get enabled(): boolean {
    return !!this.settings;
  }

  get cmt(): CMT {
    return window as any as CMT;
  }

  get classification(): Classification {
    return this.cmt?.classification;
  }
}
