<div data-cy="fv-view-details" *ngIf="fv" style="font-size: 12px">
  <dl class="mb-0">
    <div class="contact-section-grid">
      <dt class="title-area" style="border-right: 1px solid lightgrey">
        Address
      </dt>
      <dd
        class="item-area"
        style="border-right: 1px solid lightgrey"
        *ngIf="fv.address && hasAddress(fv.address); else noAddress"
      >
        <div>{{ fv.address | address: "street" }}</div>
        <div>
          {{ fv.address | address: "cityState" }}
          {{ fv.address?.countryCode | country }}
          {{ fv.address?.postalCode }}
        </div>
      </dd>
      <button
        class="button-area"
        mat-icon-button
        *ngIf="fv.isEditable"
        [appAddressDialog]="fv"
        data-cy="edit-address-button"
        matTooltip="Edit Address"
      >
        <mat-icon
          svgIcon="square-edit-outline"
          class="align-self-center"
        ></mat-icon>
      </button>
    </div>
    <mat-divider class="panel-header-divider"></mat-divider>
    <div class="contact-section-grid">
      <dt class="title-area">
        <mat-icon [svgIcon]="'phone-log'"></mat-icon> Phone Numbers ({{
          fv.phoneNumbers?.length || 0
        }})
      </dt>
      <dd class="item-area" *ngIf="fv.phoneNumbers">
        <app-fv-phone-display
          [editable]="fv.isEditable"
          [phones]="fv.phoneNumbers | sortPhones"
        ></app-fv-phone-display>
      </dd>
      <button
        mat-mini-fab
        *ngIf="fv.isEditable"
        data-cy="add-phone-button"
        matTooltip="Add Phone Number"
        class="bg-warning button-area"
        [class]="fv.phoneNumbers?.length ? 'mt-2' : ''"
        style="color: white"
        [appPhoneDialog]
        [fvId]="fv.id"
      >
        <mat-icon svgIcon="plus-circle-outline"></mat-icon>
      </button>
    </div>
    <mat-divider class="panel-header-divider"></mat-divider>
    <div class="contact-section-grid">
      <dt class="title-area">
        <mat-icon [svgIcon]="'email-multiple'"></mat-icon> Email ({{
          fv.emails!.length || 0
        }})
      </dt>
      <dd class="item-area">
        <ng-container *ngIf="fv.emails!.length > 0; else noData">
          <app-fv-email-display
            *ngIf="fv.emails as emails"
            [editable]="fv.isEditable"
            [emails]="emails | sortEmail"
            [fvId]="fv.id"
          ></app-fv-email-display>
        </ng-container>
      </dd>
      <button
        mat-mini-fab
        *ngIf="fv.isEditable"
        data-cy="add-email-button"
        matTooltip="Add Email"
        class="bg-warning button-area mt-2"
        style="color: white"
        [appEmailDialog]
        [fvId]="fv.id"
      >
        <mat-icon svgIcon="plus-circle-outline"></mat-icon>
      </button>
    </div>
  </dl>
</div>
<ng-template #noData>
  <dd class="item-area" matTooltip="N/A">N/A</dd>
</ng-template>

<ng-template #noAddress>
  <dd
    style="border-right: 1px solid lightgrey"
    class="item-area"
    matTooltip="N/A"
  >
    N/A
  </dd>
</ng-template>
