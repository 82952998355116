import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { MatSortModule } from '@angular/material/sort';
import { ScreeningRoutingModule } from './screening-routing.module';
import { ScreeningComponent } from './screening.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { ScreeningCollectionComponent } from './collection/collection.component';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { ForeignVisitorsModule } from '@app/foreign-visitors/foreign-visitors.module';
import { ScreeningTableComponent } from './collection/screening-table/screening-table.component';
import { ScreeningToolbarComponent } from './collection/screening-toolbar/screening-toolbar.component';
import { MatMenuModule } from '@angular/material/menu';
import { ScreeningDrawerObservable } from './screening-drawer-observable.service';
import { ScreeningHistoryComponent } from './screening-history/screening-history.component';

@NgModule({
  declarations: [
    ScreeningComponent,
    ScreeningCollectionComponent,
    ScreeningTableComponent,
    ScreeningToolbarComponent,
    ScreeningHistoryComponent,
  ],
  imports: [
    SharedModule,
    ScreeningRoutingModule,
    FormsModule,
    MatSortModule,
    MatIconModule,
    MatDialogModule,
    MatListModule,
    MatTableModule,
    MatDividerModule,
    MatButtonModule,
    CommonModule,
    ForeignVisitorsModule,
    MatMenuModule,
  ],
  providers: [ScreeningDrawerObservable],
  exports: [ScreeningComponent, ScreeningCollectionComponent],
})
export class ScreeningModule {}
