import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ScreeningDialogComponent } from '@app/shared/components/screening';
import {
  ForeignVisitor,
  Screening,
  ScreeningDateFormat,
  ScreeningResult,
  ScreeningUtils,
  ScreeningStatus,
} from '@app/shared/models';
import { cloneDeep } from 'lodash';
@Component({
  selector: 'fvview-screening-events',
  templateUrl: 'fvscreening-events.component.html',
  styleUrls: ['fvscreening-events.component.scss'],
})
export class FvScreeningEventsComponent {
  @Input() fv: ForeignVisitor;
  pageEvent: PageEvent;
  ScreeningDateFormat = ScreeningDateFormat;
  ScreeningUtils = ScreeningUtils;
  ScreeningStatus = ScreeningStatus;

  constructor(private dialog: MatDialog) {}

  getLegacyMessage() {
    return `Result contains a Legacy Value`;
  }

  viewScreening(screening: Screening) {
    let fv = cloneDeep(this.fv);
    fv.latestScreening = screening;
    const dialogRef = this.dialog.open(ScreeningDialogComponent, {
      autoFocus: false,
      data: fv,
      width: '900px',
    });
  }
}
