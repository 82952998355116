<ng-container *ngIf="systemUser$ | async as systemUser">
  <ng-container *ngIf="userAccepted">
    <app-active-alerts></app-active-alerts>
    <app-application-toolbar
      [systemUser]="systemUser"
    ></app-application-toolbar>
  </ng-container>
</ng-container>
<div class="overflow-auto position-relative">
  <router-outlet></router-outlet>
</div>
