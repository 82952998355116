import { NetworkDomains } from '@app/shared/models/network-domain';
import {
  FilterConfig,
  FilterConfigType,
} from '@shared/components/advanced-search/filter-config';
import {
  OffenseCode,
  OffenseCodeDescriptions,
  OffenseCodes,
  Role,
  ScreeningResult,
  ScreeningResultLabels,
  ScreeningStatus,
  ScreeningStatuses,
  ScreeningStatusLabels,
} from '@shared/models';
import { without } from 'lodash';

export const ScreeningEligibleField = 'screeningEligible';

export const FvAdvancedSearch: FilterConfig[] = [
  {
    type: FilterConfigType.INPUT,
    label: 'Given Name',
    name: 'givenName',
    maxLength: 256,
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Surname',
    name: 'surname',
    maxLength: 256,
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Email Address',
    name: 'emailAddress',
    maxLength: 256,
  },
  {
    type: FilterConfigType.DATE_RANGE,
    label: 'Screening Completed Date',
    name: 'screeningCompletedDate',
    max: new Date(),
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Passport Number',
    name: 'passportNumber',
    maxLength: 20,
  },
  {
    type: FilterConfigType.COUNTRY,
    label: 'Passport Issuing Country',
    placeholder: 'Select Issuing Country',
    name: 'passportIssuingCountryCode',
    multiple: true,
    excludeUS: true,
    includeOrgs: true,
  },
  {
    type: FilterConfigType.DATE_RANGE,
    label: 'Date of Birth',
    name: 'dateOfBirth',
    max: new Date(),
  },
  {
    type: FilterConfigType.COUNTRY,
    label: 'Birth Country',
    placeholder: 'Select Birth Country',
    name: 'birthCountry',
    multiple: true,
    includeOrgs: false,
  },
  {
    type: FilterConfigType.COUNTRY,
    label: 'Citizenship Country',
    placeholder: 'Select Citizenship Country',
    name: 'citizenshipCountry',
    multiple: true,
    excludeUS: true,
    includeOrgs: false,
  },
  {
    type: FilterConfigType.COUNTRY,
    label: 'Residence Country',
    placeholder: 'Select Residence Country',
    name: 'residenceCountry',
    multiple: true,
    includeOrgs: false,
  },
  {
    type: FilterConfigType.SELECT,
    label: 'Screening Status',
    name: 'status',
    multiple: true,
    options: without(
      ScreeningStatuses,
      ScreeningStatus.Delayed,
      ScreeningStatus.Ineligible
    ),
    optLabel: (opt: ScreeningStatus) => ScreeningStatusLabels[opt],
  },
  {
    type: FilterConfigType.SELECT,
    label: 'Screening Result',
    name: 'result',
    multiple: true,
    options: [
      ScreeningResult.Green,
      ScreeningResult.Yellow,
      ScreeningResult.Red,
      ScreeningResult.MachineRed,
      ScreeningResult.ReviewedGreen,
      ScreeningResult.MachineGreen,
    ],
    optLabel: (opt: ScreeningResult) => ScreeningResultLabels[opt],
  },
  {
    type: FilterConfigType.SELECT,
    label: 'Screening Offense Code',
    name: 'offenseCode',
    multiple: true,
    ifRole: [Role.sv_admin, Role.sv_vetter],
    ifConfig: { key: 'highEnvFeatures', value: true },
    options: OffenseCodes,
    optTooltip: (opt: OffenseCode) => OffenseCodeDescriptions[opt],
  },
  {
    type: FilterConfigType.DATE_RANGE,
    label: 'Deletion Date',
    name: 'deletionDate',
    ifConfig: {
      key: 'dataDeletionFiltersEnabled',
      defaultVal: true,
    },
    min: new Date(),
  },
  {
    type: FilterConfigType.TOGGLE,
    label: 'Only Mine',
    name: 'currentUserCreatedBy',
    tooltip: 'Only Foreign Nationals created by current user',
  },
  {
    type: FilterConfigType.TOGGLE,
    label: 'Has Attachments',
    name: 'hasAttachments',
    tooltip: 'Show Foreign Nationals with Attachments',
  },
  {
    type: FilterConfigType.SELECT,
    label: 'Network',
    name: 'networkDomain',
    ifConfig: { key: 'highEnvFeatures', value: true },
    options: NetworkDomains,
  },
  {
    type: FilterConfigType.TOGGLE,
    label: 'Expedited',
    name: 'vip',
  },
  {
    type: FilterConfigType.TOGGLE,
    label: 'Linked Nationals',
    name: 'hasLinked',
    tooltip: 'Return only Foreign Nationals with links to other Nationals',
  },
  {
    type: FilterConfigType.TOGGLE,
    label: 'Has Notes',
    name: 'hasNotes',
    tooltip: 'Foreign National Screenings with Notes',
    ifRole: [Role.sv_vetter],
    ifConfig: { key: 'highEnvFeatures', value: true },
  },
  {
    type: FilterConfigType.BUTTON_TOGGLE,
    label: 'Screening Eligible',
    name: ScreeningEligibleField,
    options: [
      { label: 'All', val: null },
      { label: 'Eligible', val: 'true' },
      { label: 'Ineligible', val: 'false' },
    ],
    optLabel: 'label',
    optVal: 'val',
  },
  {
    type: FilterConfigType.ORG,
    label: 'Organization',
    name: 'organizationId',
    col: 12,
    ifRole: [
      Role.sv_admin,
      Role.sv_help_desk,
      Role.sv_vetter,
      Role.sv_org_admin,
      Role.sv_org_user,
    ],
  },
  {
    type: FilterConfigType.USER,
    label: 'Created By',
    name: 'createdByUserId',
    col: 12,
    ifRole: [Role.sv_admin, Role.sv_help_desk, Role.sv_org_admin],
  },
  {
    type: FilterConfigType.GROUP_ID,
    label: 'Foreign National Group',
    name: 'foreignVisitorGroupId',
    col: 12,
    ifRole: [Role.sv_admin, Role.sv_help_desk, Role.sv_org_user],
  },
];
