import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SandboxRoutingModule } from './sandbox-routing.module';
import { MaterialModule } from '../material/material.module';
import { TimelineModule } from '../components/timeline/timeline.module';
import { SandboxComponent } from './sandbox/sandbox.component';
import { NgPipesModule } from 'ngx-pipes';
import { FormsModule } from '@angular/forms';
import { FormsComponent } from './forms/forms.component';
import { SharedModule } from '../shared.module';
import { ForeignNationalsComponent } from './foreign-nationals/foreign-nationals.component';

@NgModule({
  declarations: [SandboxComponent, FormsComponent, ForeignNationalsComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TimelineModule,
    SandboxRoutingModule,
    NgPipesModule,
    FormsModule,
    SharedModule,
  ],
})
export class SandboxModule {}
