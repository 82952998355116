import { NgModule } from '@angular/core';

import { ApprovalsRoutingModule } from './approvals-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { ApprovalsComponent } from './approvals.component';
import { ApprovalCollectionComponent } from './approval-collection/approval-collection.component';
import { ApprovalTableComponent } from './approval-table/approval-table.component';
import {
  AddApprovalComponent,
  AddApprovalDirective,
} from '@app/admin/approvals/dialogs/add-approval.component';

@NgModule({
  declarations: [
    ApprovalsComponent,
    ApprovalCollectionComponent,
    ApprovalTableComponent,
    AddApprovalDirective,
    AddApprovalComponent,
  ],
  imports: [SharedModule, ApprovalsRoutingModule],
})
export class ApprovalsModule {}
