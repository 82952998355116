<h2 mat-dialog-title>Screening History Details - {{ fvName | titlecase }}</h2>
<mat-dialog-content data-cy="screening-dialog-table-content">
  <table
    data-cy="screening-dialog-table"
    mat-table
    [dataSource]="screeningHistory"
    multiTemplateDataRows
    style="width: 100%"
  >
    <ng-container [matColumnDef]="ScreeningField.REQUESTED_DATE">
      <th mat-header-cell *matHeaderCellDef>
        {{ ScreeningFieldLabel[ScreeningField.REQUESTED_DATE] }}
      </th>
      <td mat-cell *matCellDef="let history">
        {{ history.requestedDate | date: ScreeningDateFormat }}
      </td>
    </ng-container>
    <ng-container [matColumnDef]="ScreeningField.MODIFIED_DATE">
      <th mat-header-cell *matHeaderCellDef>
        {{ ScreeningFieldLabel[ScreeningField.MODIFIED_DATE] }}
      </th>
      <td mat-cell *matCellDef="let history">
        {{ history.lastModifiedDate | date: ScreeningDateFormat }}
      </td>
    </ng-container>
    <ng-container [matColumnDef]="ScreeningField.COMPLETED_DATE">
      <th mat-header-cell *matHeaderCellDef>
        {{ ScreeningFieldLabel[ScreeningField.COMPLETED_DATE] }}
      </th>
      <td mat-cell *matCellDef="let history">
        {{ history.completedDate | date: ScreeningDateFormat }}
      </td>
    </ng-container>
    <ng-container [matColumnDef]="ScreeningField.STATUS">
      <th mat-header-cell *matHeaderCellDef>
        {{ ScreeningFieldLabel[ScreeningField.STATUS] }}
      </th>
      <td mat-cell *matCellDef="let history">
        <app-screening-indicator
          data-cy="screening-status-indicator"
          id="'screening-indicator-' + {{ history.id }}"
          *ngIf="history as screening"
          [screening]="screening"
          [showStatusIcon]="false"
          [display]="'status'"
          [hasMenu]="true"
        >
        </app-screening-indicator>
      </td>
    </ng-container>
    <ng-container [matColumnDef]="ScreeningField.RESULT">
      <th mat-header-cell *matHeaderCellDef>
        {{ ScreeningFieldLabel[ScreeningField.RESULT] }}
      </th>
      <td mat-cell *matCellDef="let history">
        <app-screening-indicator
          data-cy="screening-result-indicator"
          id="screening-result-{{ history.id }}"
          *ngIf="history.result"
          [screening]="history"
          [display]="'result'"
        >
        </app-screening-indicator>
      </td>
    </ng-container>
    <ng-container [matColumnDef]="ScreeningField.DEROG_INFO">
      <th mat-header-cell *matHeaderCellDef>
        {{ ScreeningFieldLabel[ScreeningField.DEROG_INFO] }}
      </th>
      <td mat-cell *matCellDef="let history">
        <ng-container
          *ngIf="history.derogatoryInformation; then hasCodes; else noCodes"
        >
        </ng-container>
        <ng-template #hasCodes>
          <button
            mat-icon-button
            #codeTrigger="matMenuTrigger"
            [color]="'primary'"
            [matMenuTriggerFor]="codeMenu"
            [matTooltip]="'View Codes'"
            matTooltipPosition="below"
          >
            <mat-icon [svgIcon]="'alert-box'"></mat-icon>
          </button>
          <mat-menu #codeMenu="matMenu">
            <dl class="m-3 small">
              <dt>Derogatory Info</dt>
              <dd
                *ngFor="let code of history.derogatoryInformation; index as i"
                [matTooltip]="code.offenseCode | derogDescription: 'offense'"
              >
                {{ i + 1 }}) {{ code.code | default }}
              </dd>
            </dl>
          </mat-menu>
        </ng-template>
        <ng-template #noCodes> N/A </ng-template>
      </td>
    </ng-container>
    <ng-container [matColumnDef]="ScreeningField.NOTES">
      <th mat-header-cell *matHeaderCellDef>
        {{ ScreeningFieldLabel[ScreeningField.NOTES] }}
      </th>
      <td mat-cell *matCellDef="let history">
        <ng-container
          *ngIf="history.notes; then hasNotes; else noNotes"
        ></ng-container>
        <ng-template #hasNotes>
          <button
            data-cy="notes-button"
            mat-icon-button
            type="button"
            #statusTrigger="matMenuTrigger"
            [matMenuTriggerFor]="notesMenu"
            [matTooltip]="'View Notes'"
            matTooltipPosition="below"
          >
            <mat-icon color="primary">description</mat-icon>
          </button>
          <mat-menu #notesMenu="matMenu">
            <dl class="m-3 small">
              <dt>Notes</dt>
              <dd>
                {{ history.notes }}
              </dd>
            </dl>
          </mat-menu>
        </ng-template>
        <ng-template #noNotes
          ><span [matTooltip]="'No Notes Available'"> N/A </span>
        </ng-template>
      </td>
    </ng-container>
    <ng-container [matColumnDef]="ScreeningField.NETWORK_DOMAIN">
      <th mat-header-cell *matHeaderCellDef>
        {{ ScreeningFieldLabel[ScreeningField.NETWORK_DOMAIN] }}
      </th>
      <td mat-cell *matCellDef="let history">
        {{ history.latestScreening?.networkDomain }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="historyColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: historyColumns"></tr>
  </table>
</mat-dialog-content>
<mat-dialog-actions data-cy="screening-dialog-actions" align="end">
  <button
    data-cy="screening-dialog-close-button"
    id="screening-dialog-close"
    mat-button
    color="primary"
    mat-dialog-close
  >
    Close
  </button>
</mat-dialog-actions>
