<mat-icon
  style="cursor: pointer"
  [class]="fvVersion ? 'fv-data-icon' : 'mt-2'"
  [matMenuTriggerFor]="userDetailsMenu"
  svgIcon="information"
  matTooltip="Last Modifying User Details"
  [matTooltipPosition]="fvVersion ? 'before' : 'below'"
  [color]="fvVersion ? 'primary' : ''"
></mat-icon>

<mat-menu #userDetailsMenu class="reset">
  <mat-list>
    <ng-container *ngIf="data | auditUser as createdBy">
      <mat-list-item>
        <span matListItemTitle
          ><span>Created by </span>
          <strong class="text-primary">{{ createdBy | fullname }}</strong>
        </span>
        <span matListItemLine
          ><span>On </span>
          <strong class="text-primary">{{
            data?.createdDate | date: ScreeningDateFormat
          }}</strong>
        </span>
        <span matListItemLine
          ><span>Phone </span>
          <strong class="text-primary">{{ createdBy.phone }}</strong>
        </span>
        <span matListItemLine class="mb-2"
          ><span>Email </span>
          <strong class="text-primary">{{ createdBy.email }}</strong></span
        >
      </mat-list-item>
    </ng-container>

    <ng-container *ngIf="data | auditUser: 'modifiedBy' as modifiedBy">
      <mat-divider></mat-divider>
      <mat-list-item>
        <span matListItemTitle
          ><span>Modified by </span>
          <strong class="text-primary">{{ modifiedBy | fullname }}</strong>
        </span>
        <span matListItemLine
          ><ng-container>On </ng-container>
          <strong class="text-primary">{{
            data?.lastModifiedDate | date: ScreeningDateFormat
          }}</strong>
        </span>
        <span matListItemLine
          >Email
          <strong class="text-primary">{{ modifiedBy.email }}</strong></span
        >
      </mat-list-item>
    </ng-container>

    <ng-container *ngIf="data | auditOrg as org">
      <mat-divider></mat-divider>
      <mat-list-item matTooltip="{{ org.name }}">
        <span matListItemTitle
          >Owning Org
          <strong class="text-primary">{{
            org.shortName ?? org.name
          }}</strong></span
        >
      </mat-list-item>
    </ng-container>
  </mat-list>
</mat-menu>
