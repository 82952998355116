<!-- dialogbox.component.html -->

<h4 mat-dialog-title>
  <strong>{{ action }}</strong>
</h4>
<div mat-dialog-content>
  <div *ngIf="action !== 'Delete'; else elseTemplate">
    Are you sure you want to {{ action }} this item?
  </div>
  <ng-template #elseTemplate>
    Are you sure you want to delete <strong>{{ local_data.name }}</strong
    >?
  </ng-template>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="doAction()" mat-flat-button color="primary">
    {{ action }}
  </button>
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">
    Cancel
  </button>
</div>
