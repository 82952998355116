<mat-card appearance="outlined" class="container mt-3">
  <mat-card-header class="justify-content-center mb-2">
    <mat-card-title>WELCOME!</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>
      You are accessing the Office of the Director of National Intelligence's
      (ODNI) CITADEL, a United States (U.S) Government Information system (IS).
      This IS includes this computer, this computer network, all computers
      connected to this network, and all devices and/or storage media attached
      to this network or to computers on this network.
    </p>

    <p>
      THIS INFORMATION SYSTEM IS PROVIDED FOR THE U.S. GOVERNMENT AUTHORIZED
      USERS AND USES ONLY. Any unofficial, improper, and/or unauthorized use of
      this IS or any process/component related to this IS will result in
      disciplinary action, loss of access, civil, and criminal penalties. By
      logging into this information system, you consent to:
    </p>

    <ol>
      <li>
        No expectation of privacy regarding to any activity conducted in this
        IS, including but not limited to communications transmitted/received or
        data stored on this IS;
      </li>
      <li>
        At any time the U.S. Government or its representative may monitor,
        intercept, search, seize, and/or remove data being transmitted/received
        through or stored in this system without notice;
      </li>
      <li>
        Any communication transmitted/received through or data stored on this IS
        may be disclosed or used for any U.S. Government purpose including
        intelligence collection, prosecution, administrative action, or any
        other authorized purposes.
      </li>
    </ol>
    <p>
      This IS contains information obtained from third parties through
      Memorandum of Understandings (MOU), Memorandum of Agreements (MOA), and/or
      Non-Disclosure Agreements (NDA) on the condition that it will not be used
      or disseminated to another entity except as specified in the MOU, MOA, or
      NDA. Consult ODNI's CITADEL for further guidance.
    </p>

    <p>
      The information in this IS is the property of the ODNI and may be
      distributed to federal, state, tribal, or local government entities with a
      need-to-know. Further distribution without the ODNI's authorization is
      prohibited. Precautions should be taken to ensure the information/data
      submitted into this IS is stored and/or destroyed in a manner that
      precludes unauthorized access.
    </p>

    <p>
      The following may <b><u>NOT</u></b> be entered into this IS:
    </p>
    <ol>
      <li>
        U.S Person (U.S. citizen, permanent resident alien, an unincorporated
        association substantially composed of U.S. citizen or permanent resident
        aliens, or a corporation incorporated in the U.S.) information/data
      </li>
      <li>
        Information/data solely based on ethnicity, race, or religion of an
        individual
      </li>
      <li>
        Information/data solely based on the exercise of rights guaranteed by
        the First Amendment or lawful exercise of any rights secured by the
        Constitution or laws of the U.S.
      </li>
    </ol>
    <p>
      By clicking the Accept button below, you agree and will abide by the
      restrictions listed above as well as the corporate rules of ODNI as well
      as your respective Department and Agency policies. If you do not consent,
      please click the Decline button below.
    </p>
  </mat-card-content>
</mat-card>
<mat-toolbar *ngIf="!userAccepted" class="d-flex justify-content-center">
  <button
    mat-button
    data-cy="decline-govt-agreement"
    color="primary"
    (click)="decline()"
  >
    Decline
  </button>
  <button
    mat-raised-button
    id="accept-govt-agreement"
    data-cy="accept-govt-agreement"
    color="primary"
    (click)="accept()"
  >
    Accept
  </button>
</mat-toolbar>
