<mat-form-field [appearance]="appearance" [floatLabel]="floatLabel">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    #matSelect
    id="fv-select"
    [required]="required"
    [disabled]="disabled"
    [(ngModel)]="value"
    (selectionChange)="emitChangeEvent()"
    (openedChange)="monitorScroll($event)"
    [name]="label"
    [multiple]="multiple"
    panelClass="fv-select"
  >
    <mat-select-trigger>
      <span> {{ getSelectedText(value) }}</span>
    </mat-select-trigger>

    <mat-option class="search-option">
      <ngx-mat-select-search
        [searching]="searching"
        noEntriesFoundLabel="No FARs Found"
        [placeholderLabel]="filterLabel"
        [formControl]="searchCtrl"
        [disableScrollToActiveOnOptionsChanged]="true"
        [clearSearchInput]="false"
      >
      </ngx-mat-select-search>
    </mat-option>
    <mat-option
      class="flex-fill"
      *ngFor="let item of filteredValues | async"
      [value]="item"
    >
      <ng-container
        *ngTemplateOutlet="
          farSelectCard;
          context: { value: this.item, searchCtrl: this.searchCtrl }
        "
      ></ng-container>
    </mat-option>
  </mat-select>
  <button
    [hidden]="!clearable"
    mat-icon-button
    matSuffix
    *ngIf="value && !disabled"
    matTooltip="Clear"
    (click)="clearValue($event)"
  >
    <mat-icon>clear</mat-icon>
  </button>
  <mat-error *ngIf="required">FAR is required</mat-error>
</mat-form-field>

<ng-template #farSelectCard let-value="value" let-searchCtrl="searchCtrl">
  <div class="far-option">
    <app-far-header [far]="value"></app-far-header>
    <app-far-event
      [showLocation]="false"
      [contentTrimLength]="50"
      [primaryLocation]="value?.primaryFar"
    ></app-far-event>
  </div>
</ng-template>
