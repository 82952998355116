<div class="grid-collection">
  <div class="grid-header">
    <app-search-toolbar
      [filterFields]="AccountsAdvancedSearch"
      [filterParams]="filterParams"
      (filterParamsChange)="onFilterParamChange($event)"
      [actionButtons]="actionButtons"
      [columns]="UserTableColumns"
      [columnLabels]="UserSortFieldLabels"
      [tableName]="UserTableName"
      filterPlaceholder="Filter Criteria (e.g): Name, Email"
      hideViewToggle="true"
      [loading]="loading"
      >User Administration</app-search-toolbar
    >
  </div>
  <ng-container *ngIf="pageableCollection">
    <div [ngClass]="workspaceView ? 'workspace-main' : 'grid-main'">
      <div class="container">
        <ng-container *ngIf="pageableCollection.content.length; else noData">
          <app-account-user-table
            [users]="pageableCollection.content"
            [filterParams]="filterParams"
            class="app-user-table-container"
            (filterParamsChange)="onFilterParamChange($event)"
          ></app-account-user-table>
        </ng-container>
        <ng-template #noData>
          <app-no-result-found></app-no-result-found>
        </ng-template>
      </div>
    </div>
    <div class="grid-footer">
      <app-paginator
        class="sticky"
        [filterParams]="filterParams"
        (filterParamsChange)="onFilterParamChange($event)"
        [pageIndex]="pageableCollection.number"
        [pageSize]="pageableCollection.size"
        [totalElements]="pageableCollection.totalElements"
      ></app-paginator>
    </div>
  </ng-container>
</div>
