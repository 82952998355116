import {
  Component,
  OnInit,
  Inject,
  AfterContentChecked,
  ChangeDetectorRef,
} from '@angular/core';
import { Passport } from '@app/shared/models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { PassportService } from '@app/shared/services/passport.service';

@Component({
  selector: 'app-passport-dialog',
  templateUrl: './passport-dialog.component.html',
})
export class PassportDialogComponent implements OnInit, AfterContentChecked {
  passport: Passport;
  showPassportHelp: boolean = false;
  busy: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { data: Passport; editable: boolean },
    private changeDetect: ChangeDetectorRef,
    private passportService: PassportService,
    private dialogRef: MatDialogRef<PassportDialogComponent>
  ) {}

  ngOnInit() {
    this.passport = this.data.data;
  }

  submitPassport() {
    this.busy = true;
    this.passportService
      .save(this.passport)
      .pipe(take(1))
      .subscribe(
        (result: Passport) => {
          this.onClose(result);
        },
        (err) => (this.busy = false)
      );
  }

  //Supressing "ExpressionChangedAfterIt HasBeenCheckedError" occurring due to passportForm in PassportForm Component values changing
  ngAfterContentChecked(): void {
    this.changeDetect.detectChanges();
  }

  onClose(passport: Passport) {
    this.dialogRef.close(passport);
  }
}
