<form #derogCodeForm="ngForm" *ngIf="value">
  <div class="code-control-grid mt-3">
    <mat-form-field appearance="outline">
      <mat-label>Offense Code</mat-label>
      <mat-select required [(ngModel)]="value.offenseCode" name="offenseCode">
        <mat-option
          class="select-code-container"
          *ngFor="let code of offenseCodes"
          [value]="code"
          >{{ code }} - {{ code | derogDescription: "offense"
          }}<mat-divider></mat-divider
        ></mat-option>
      </mat-select>
      <mat-error>Required For Override</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Relational Code</mat-label>
      <mat-select
        required
        [(ngModel)]="value.relationalCode"
        name="relationalCode"
      >
        <mat-option *ngFor="let code of relationalCodes" [value]="code"
          >{{ code }} - {{ code | derogDescription: "relations" }}</mat-option
        >
      </mat-select>
      <mat-error>Required For Override</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Number of Communication Contacts</mat-label>
      <mat-select
        required
        [(ngModel)]="value.numberOfCommunicationContacts"
        name="numberOfCommunicationContacts"
      >
        <mat-option *ngFor="let code of communicationContacts" [value]="code"
          >{{ code }} - {{ code | derogDescription: "comms" }}</mat-option
        >
      </mat-select>
      <mat-error>Required For Override</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Time Frame Of Communication</mat-label>
      <mat-select
        required
        [(ngModel)]="value.timeFrameOfCommunicationContacts"
        name="timeFrameOfCommunicationContacts"
      >
        <mat-option *ngFor="let code of timeFrames" [value]="code"
          >{{ code }} - {{ code | derogDescription: "timeframe" }}</mat-option
        >
      </mat-select>
      <mat-error>Required For Override</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Verification of Communication</mat-label>
      <mat-select
        required
        [(ngModel)]="value.verificationOfCommunicationContent"
        name="value.verificationOfCommunicationContent"
      >
        <mat-option *ngFor="let code of verificationCodes" [value]="code"
          >{{ code }} - {{ code | derogDescription: "verified" }}</mat-option
        >
      </mat-select>
      <mat-error>Required For Override</mat-error>
    </mat-form-field>
  </div>
</form>
