<button
  mat-icon-button
  #columnTrigger="matMenuTrigger"
  [matMenuTriggerFor]="columnMenu"
  (click)="$event.stopPropagation()"
>
  <mat-icon>view_column</mat-icon>
</button>
<mat-menu #columnMenu class="columnCard">
  <div class="columnHidingContainer">
    <h5 class="columnHidingHeader">Column Selection</h5>
    <mat-form-field
      appearance="outline"
      floatLabel="always"
      class="mt-1"
      (click)="$event.stopPropagation()"
    >
      <mat-label>Filter</mat-label>
      <input
        matInput
        placeholder="Filter on Column Name"
        [(ngModel)]="filter"
        (ngModelChange)="filterList()"
      />
      <button mat-icon-button matSuffix *ngIf="filter" (click)="clear()">
        <mat-icon>clear</mat-icon>
      </button>
    </mat-form-field>
    <mat-card class="columnHidingOptions">
      <mat-checkbox
        *ngFor="let item of displayDataList"
        [(ngModel)]="item.checked"
        (click)="toggleColumn(item); $event.stopPropagation()"
        >{{ item.label }}</mat-checkbox
      >
    </mat-card>
    <mat-card class="columnsOrderOptionsContainer">
      <mat-radio-group
        class="vertical"
        aria-label="Select an option"
        [(ngModel)]="columnDisplayOrder"
      >
        <mat-radio-button
          value="Alphabetical"
          (click)="changeDisplayOrder('Alphabetical'); $event.stopPropagation()"
          >Alphabetical</mat-radio-button
        >
        <mat-radio-button
          value="DisplayOrder"
          (click)="changeDisplayOrder('DisplayOrder'); $event.stopPropagation()"
          >Display Order</mat-radio-button
        >
      </mat-radio-group>
    </mat-card>
  </div>
  <div class="columnHidingFooter d-flex justify-content-between">
    <button
      class="ml-5"
      mat-button
      (click)="toggleAll(true); $event.stopPropagation()"
    >
      Show All
    </button>
    <button
      class="mr-5"
      mat-button
      (click)="toggleAll(false); $event.stopPropagation()"
    >
      Hide All
    </button>
  </div>
</mat-menu>
