<div
  class="avatar"
  [ngClass]="{
    'avatar--horizontal': isHorizontal,
    'avatar--xsmall': isXSmall,
    'avatar--small': isSmall,
    'avatar--medium': isMedium,
    'avatar--large': isLarge
  }"
>
  <div class="avatar__portrait" [class]="showShadow ? 'shadow' : ''">
    <app-profile-pic
      *ngIf="fv"
      [fv]="fv"
      [editable]="picEditable"
    ></app-profile-pic>
  </div>
  <mat-icon
    *ngIf="fv"
    class="flag"
    [class]="enableBorderOutline ? 'border-outline' : ''"
    [matTooltip]="country | default"
    matTooltipPositionAtOrigin
    [flagIcon]="chooseFlag(fv) || 'XX'"
  ></mat-icon>
</div>
<div class="pills d-flex mt-1 gap-1 small" *ngIf="showPills">
  <div class="bg-white px-2 rounded-pill">
    {{ fv.gender | uppercase | default }}
  </div>
  <div
    class="bg-white px-2 rounded-pill"
    matTooltip="Age: {{ fv.dateOfBirth! | age | default }}"
  >
    {{ fv.dateOfBirth! | age | default }}
  </div>
  <mat-icon
    *ngIf="fv.vip"
    svgIcon="star-circle"
    class="text-yellow"
    matTooltip="Expedited"
  ></mat-icon>
</div>
