<div mat-dialog-title>
  {{ operation }} {{ fvsToAssociate.length }} Foreign Nationals
  {{ appropriatePreposition }} this
  {{ type }}
</div>
<mat-dialog-content>
  <form #form="ngForm" class="mt-3">
    <app-select-fv
      [(ngModel)]="fvsToAssociate"
      name="fvsToAccociate"
      [hideInputDisplay]="true"
      [multiple]="true"
      [clearable]="false"
      [excludeById]="id"
      [restrictCurrentOrganization]="false"
      [organizationId]="organizationId"
      [foreignVisitorGroupId]="foreignVisitorGroupId"
      ngDefaultControl
    ></app-select-fv>
  </form>
  <mat-list mat-dialog-content class="pt-0 px-0" style="max-height: 450px">
    <ng-container *ngFor="let fv of fvsToAssociate">
      <app-fv-list-item
        [item]="fv"
        [showRemove]="true"
        [showOwningOrg]="false"
        (removed)="onRemove($event)"
        size="small"
      ></app-fv-list-item>
      <mat-divider></mat-divider>
    </ng-container>
  </mat-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onClose()" data-cy="cancel">Cancel</button>
  <button
    mat-flat-button
    (click)="onSubmit()"
    color="primary"
    [disabled]="!fvsToAssociate.length || busy"
    data-cy="submit"
  >
    <span *ngIf="!busy; else loading">Submit</span>
  </button>
</mat-dialog-actions>
<ng-template #loading>
  <mat-spinner diameter="35"></mat-spinner>
</ng-template>
