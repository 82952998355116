import {
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppConfigService } from '@shared/services/app-config.services';

@Directive({
  selector: '[appContactSupport]',
})
export class ContactSupportDialogDirective {
  @Output() appSupportDialogClosed = new EventEmitter<void>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    const dialog = ContactSupportDialogComponent.openDialog(this.dialog);
    dialog.afterClosed().subscribe(() => {
      return this.appSupportDialogClosed.emit();
    });
  }
}

@Component({
  selector: 'app-contact-support-dialog',
  templateUrl: './contact-support-dialog.component.html',
  styleUrls: ['./contact-support-dialog.component.scss'],
})
export class ContactSupportDialogComponent {
  supportEmail?: string;
  supportPhone?: string;
  _startTime?: number;
  _endTime?: number;

  get startTime() {
    return new Date().setHours(this._startTime!, 0, 0, 0);
  }
  get endTime() {
    return new Date().setHours(this._endTime!, 0, 0, 0);
  }

  constructor(config: AppConfigService) {
    this.supportEmail = config.get('supportEmail');
    this.supportPhone = config.get('supportPhone');
    this._startTime = config.get('supportStartTime', 7);
    this._endTime = config.get('supportEndTime', 15);
  }

  static openDialog(
    dialog: MatDialog
  ): MatDialogRef<ContactSupportDialogComponent> {
    return dialog.open<ContactSupportDialogComponent>(
      ContactSupportDialogComponent,
      {
        maxWidth: 500,
        autoFocus: false,
      }
    );
  }
}
