import { Directive, HostListener, Input } from '@angular/core';
import { AlertService, AppConfigService } from '@shared/services';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { ForeignVisitor } from '@app/shared/models';
import { UspDeleteDialogComponent } from './usp-delete-dialog/usp-delete-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@app/shared/components';

@Directive({
  selector: '[appDeleteFv]',
})
export class DeleteFvDirective {
  /** Emits when the component is destroyed. */
  @Input() appDeleteFv: ForeignVisitor;
  @Input() fvDeleteArchive = false;
  @Input() fvDeletedRoute: any[];
  deletionMessageCheckEnabled?: boolean = true;

  constructor(
    private alert: AlertService,
    private router: Router,
    private dialog: MatDialog,
    private config: AppConfigService
  ) {
    let lowToggle = this.config.get('lowEnvFeatures', true);
    this.deletionMessageCheckEnabled = lowToggle;
  }

  @HostListener('click', ['$event'])
  onClick(): void {
    if (!this.appDeleteFv) return;
    if (this.deletionMessageCheckEnabled)
      this.openConfirmationDialog(this.appDeleteFv);
    else this.openFVDeleteDialog(this.appDeleteFv);
  }

  openFVDeleteDialog(fv: ForeignVisitor) {
    let deleteDialog = this.dialog.open(UspDeleteDialogComponent, {
      width: '500px',
      data: fv,
      autoFocus: false,
    });

    deleteDialog
      .afterClosed()
      .pipe(take(1))
      .subscribe((data: boolean) => {
        if (data) this.router.navigate(this.fvDeletedRoute);
      });
  }

  openConfirmationDialog(
    fv: ForeignVisitor
  ): MatDialogRef<ConfirmDialogComponent> {
    let data = {
      icon: 'alert-octagon',
      color: 'warn',
      title: `STOP`,
      message: `Before confirming the deletion of the record, make sure this record still exists on the high side.  If the record does not exist on the high side, cancel this action.  Deletion of this record without it existing on the high side will cause replication to fail.`,
      performAction: () => this.openFVDeleteDialog(fv),
      confirmText: 'Confirm',
    };

    return this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data,
    });
  }
}
