import { Component, Input } from '@angular/core';
import { ForeignVisitor, Role } from '@app/shared/models';

@Component({
  selector: 'app-table-icon',
  templateUrl: './table-icon.component.html',
  styleUrls: ['./table-icon.component.scss'],
})
export class TableIconComponent {
  @Input() fv: ForeignVisitor;
  Role = Role;
}
