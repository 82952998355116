import { NgIf } from '@angular/common';
import { Directive, OnInit, inject } from '@angular/core';
import { environment } from '@environments/environment';

@Directive({
  selector: '[restrictDelete]',
  hostDirectives: [NgIf],
})
export class RestrictDeleteDirective implements OnInit {
  private readonly ngIfDirective = inject(NgIf);

  ngOnInit(): void {
    this.ngIfDirective.ngIf = environment.restrictDelete === false;
  }
}
