import { Component, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FAR } from '@shared/models';

@Component({
  selector: 'app-new-far-card',
  templateUrl: './far-card.component.html',
  styleUrls: ['./far-card.component.scss'],
})
export class FarCardComponent {
  @Input() far: FAR;
  @Input() showRollups = true;

  @HostListener('click')
  onClick() {
    this.router.navigate([`/far/${this.far.id}`]);
  }

  constructor(private router: Router) {}
}
