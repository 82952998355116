<mat-expansion-panel
  class="flex-fill mt-3 panel-200-mh"
  #vehiclePanel="matExpansionPanel"
  id="fv-view-vehicle-panel"
>
  <mat-expansion-panel-header>
    <mat-panel-title class="d-flex justify-content-between py-2">
      <span>Contact Information</span>
      <mat-icon svgIcon="comment-account-outline"></mat-icon>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-divider class="panel-header-divider"></mat-divider>
  <div class="contact-body">
    <app-fv-details [fv]="fv"></app-fv-details>
  </div>
</mat-expansion-panel>
