import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Organization } from '@app/shared/models/organization.model';
import { Crud, CrudService } from '@shared/services/crud.service';
import { environment } from '@environments/environment';
import { SortSelect } from '@shared/components';

export enum OrgSortField {
  NAME = 'name',
  SHORT_NAME = 'shortName',
  PARENT_ORG = 'parentOrganization.name',
  SUB_ORGS = 'subOrgCount',
  CREATED_DATE = 'createdDate',
  LAST_MODIFIED_DATE = 'lastModifiedDate',
  FN_RECORD_COUNT = 'fnRecordCount',
  CONTACTS_COUNT = 'contactsCount',
  RESTRICTED_ACCESS = 'restrictedAccess',
}

export const OrgSortFieldLabels: { [key in OrgSortField]: string } = {
  [OrgSortField.NAME]: 'Name',
  [OrgSortField.SHORT_NAME]: 'Short Name',
  [OrgSortField.PARENT_ORG]: 'Parent Organization',
  [OrgSortField.SUB_ORGS]: 'Sub Orgs',
  [OrgSortField.CREATED_DATE]: 'Created Date',
  [OrgSortField.LAST_MODIFIED_DATE]: 'Last Modified Date',
  [OrgSortField.FN_RECORD_COUNT]: 'FN Records',
  [OrgSortField.CONTACTS_COUNT]: 'Contacts',
  [OrgSortField.RESTRICTED_ACCESS]: 'Restricted Access',
};

export const OrgSorts: SortSelect[] = Object.values(OrgSortField).map(
  (value) => {
    return { label: OrgSortFieldLabels[value], value };
  }
);

@Injectable({ providedIn: 'root' })
@Crud({
  apiUrl: `${environment.apiUrl}/organizations`,
  hasIdPathUpdate: true,
  entity: 'Organization',
})
export class OrganizationService extends CrudService<Organization> {
  constructor(http: HttpClient) {
    super(http);
  }
}
