import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';

import { SystemAlertsRoutingModule } from './system-alerts-routing.module';
import { SystemAlertsComponent } from './system-alerts/system-alerts.component';
import { SystemAlertsCollectionComponent } from './system-alerts-collection/system-alerts-collection.component';
import { StatusPipe } from './status.pipe';
import { SystemAlertDialogComponent } from './system-alert-dialog/system-alert-dialog.component';

@NgModule({
  declarations: [
    SystemAlertsComponent,
    SystemAlertsCollectionComponent,
    StatusPipe,
    SystemAlertDialogComponent,
  ],
  imports: [SharedModule, SystemAlertsRoutingModule],
  providers: [StatusPipe],
})
export class SystemAlertsModule {}
