<div class="d-flex flex-column">
  <mat-form-field appearance="outline" class="mt-2">
    <mat-label>Event Title</mat-label>
    <input
      id="far-title-input"
      #eventType="ngModel"
      required
      matInput
      [(ngModel)]="value.eventTitle"
      (ngModelChange)="emitChangeEvent()"
      name="eventType"
      maxlength="40"
    />
    <mat-error>Please provide an Event Title</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Purpose Type</mat-label>
    <mat-select
      id="purposeType"
      #purposeType="ngModel"
      required
      [(ngModel)]="value.purposeType"
      (ngModelChange)="emitChangeEvent(); checkLocationRequirement()"
      name="purposeType"
      aria-label="Please select an option"
      color="primary"
    >
      <mat-select-trigger
        *ngIf="value.purposeType === FarPurposeType.FACILITY_ACCESS"
      >
        <span class="d-flex align-items-center"
          >USG {{ FarPurposeType.FACILITY_ACCESS | titlecase }}
          <mat-icon
            class="ml-1"
            [matTooltip]="FarPurposeTypeTooltip[FarPurposeType.FACILITY_ACCESS]"
            >help</mat-icon
          ></span
        >
      </mat-select-trigger>
      <mat-option selected disabled>Select a Purpose Type</mat-option>
      <span [matTooltip]="generateTooltip()">
        <mat-option
          [value]="FarPurposeType.IN_PERSON_MEETING"
          [disabled]="disablePurposeType()"
        >
          {{ FarPurposeType.IN_PERSON_MEETING | titlecase }}
          ({{ FarPurposeTypeTooltip[FarPurposeType.IN_PERSON_MEETING] }})
        </mat-option>
      </span>
      <mat-option [value]="FarPurposeType.VIRTUAL_MEETING">
        {{ FarPurposeType.VIRTUAL_MEETING | titlecase }}
      </mat-option>
      <mat-option [value]="FarPurposeType.SYSTEM_ACCESS">
        {{ FarPurposeType.SYSTEM_ACCESS | titlecase }} ({{
          FarPurposeTypeTooltip[FarPurposeType.SYSTEM_ACCESS]
        }})
      </mat-option>
      <span [matTooltip]="generateTooltip()">
        <mat-option
          [value]="FarPurposeType.FACILITY_ACCESS"
          [disabled]="disablePurposeType()"
        >
          <span class="d-flex align-items-center"
            >USG {{ FarPurposeType.FACILITY_ACCESS | titlecase }}
            <mat-icon
              class="ml-1"
              [matTooltip]="
                FarPurposeTypeTooltip[FarPurposeType.FACILITY_ACCESS]
              "
              >help</mat-icon
            ></span
          >
        </mat-option>
      </span>
      <!--<mat-option [value]="FarPurposeType.OTHER">
        {{ FarPurposeType.OTHER | titlecase }}
      </mat-option>-->
    </mat-select>
    <mat-error>Please select a Purpose Type</mat-error>
  </mat-form-field>
  <mat-form-field
    appearance="outline"
    *ngIf="!hideDescription"
    floatLabel="always"
  >
    <mat-label>Event Description</mat-label>
    <textarea
      id="far-purpose-input"
      #purposeOfVisit="ngModel"
      placeholder="General details of the purpose of visit"
      matInput
      rows="5"
      [(ngModel)]="value.description"
      (ngModelChange)="emitChangeEvent()"
      name="purposeOfVisit"
      maxlength="2000"
    ></textarea>
  </mat-form-field>
</div>
