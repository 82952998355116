<mat-form-field
  [appearance]="appearance"
  [floatLabel]="floatLabel"
  style="width: 100%"
>
  <mat-label>{{ label }}</mat-label>
  <mat-select
    #countrySelect
    [multiple]="multiple"
    [required]="required"
    [disabled]="disabled"
    [(ngModel)]="value"
    (selectionChange)="emitChangeEvent()"
    [name]="label"
  >
    <mat-select-trigger>
      <div
        class="select-trigger position-relative"
        [class.has-icon]="value?.length === 1"
        *ngIf="multiple"
      >
        <mat-icon
          *ngIf="value?.length === 1"
          [flagIcon]="value![0] | country: 'alpha2' | default"
          class="position-absolute"
        ></mat-icon>
        <span class="select-trigger__label">
          <ng-container *ngFor="let val of value; last as last">
            {{ val | country: "name" }}{{ last ? "" : "," }}
          </ng-container>
        </span>
      </div>
      <div class="select-trigger has-icon position-relative" *ngIf="!multiple">
        <mat-icon
          [flagIcon]="value ? (value | country: 'alpha2' | default) : null"
          class="position-absolute"
        ></mat-icon>
        <span class="select-trigger__label">
          {{ value ? (value | country: "name") : value }}
        </span>
      </div>
    </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search
        [attr.data-country-search]="id"
        [searching]="searching"
        noEntriesFoundLabel="No Countries Found"
        [placeholderLabel]="filterLabel"
        [formControl]="searchCtrl"
      >
      </ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngFor="let option of filteredCountries | async; trackBy: trackBy"
      [value]="option.alpha3Code"
    >
      <mat-icon class="mr-2" [flagIcon]="option.alpha3Code"></mat-icon
      ><span class="pl-1" [appHighlight]="searchCtrl?.value">
        {{ option.name }}
      </span>
    </mat-option>
    <mat-option
      class="selected-options"
      *ngIf="value && !multiple"
      [value]="value"
    >
      <mat-icon class="mr-2" [flagIcon]="value.toString()"></mat-icon
      >{{ value | country: "name" }}</mat-option
    >
  </mat-select>
  <button
    mat-icon-button
    matSuffix
    type="button"
    *ngIf="value && !disabled"
    matTooltip="Clear"
    (click)="clearValue($event)"
  >
    <mat-icon>clear</mat-icon>
  </button>
  <mat-error>{{ placeholder }} is required</mat-error>
</mat-form-field>
