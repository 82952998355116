import { Component, Inject, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { DialogCloseMessage, ForeignVisitor } from '@app/shared/models';
import { FvService } from '@app/shared/services';

@Component({
  selector: 'app-physical-id-vehicle-dialog',
  templateUrl: './physical-id-vehicle-dialog.component.html',
  styleUrls: ['./physical-id-vehicle-dialog.component.scss'],
})
export class PhysicalIdVehicleDialogComponent {
  @ViewChild('physicalIdForm', { static: true }) physicalIdForm: NgForm;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ForeignVisitor,
    public dialogRef: MatDialogRef<PhysicalIdVehicleDialogComponent>,
    private fvService: FvService
  ) {}

  saveChanges(data: ForeignVisitor) {
    this.fvService.save(data).subscribe((fv: ForeignVisitor) => {
      this.onClose({
        update: fv,
        message: `Updated Identifying and Vehicle Information for ${fv.givenName} ${fv.surname}`,
      });
    });
  }

  onClose(message: DialogCloseMessage<ForeignVisitor>) {
    this.dialogRef.close(message);
  }
}
