import { Component, Input } from '@angular/core';
import {
  Screening,
  ScreeningDateFormat,
  ScreeningStatus,
  ScreeningResult,
} from '@app/shared/models';
@Component({
  selector: 'screening-insight-card',
  templateUrl: 'screening-insight-card.component.html',
  styleUrls: ['screening-insight-card.component.scss'],
})
export class ScreeningInsightCardComponent {
  @Input() public value: Screening;
  ScreeningDateFormat = ScreeningDateFormat;
  ScreeningResult = ScreeningResult;

  getDateType(status?: string) {
    if (status === ScreeningStatus.New) return 'Created: ';
    else if (
      status === ScreeningStatus.InProcess ||
      status === ScreeningStatus.Error
    )
      return 'Requested: ';
    else if (
      status === ScreeningStatus.Completed ||
      status === ScreeningStatus.Historical
    )
      return 'Completed: ';
    return '';
  }

  isBlackTextColor(screening: Screening) {
    if (screening.result === ScreeningResult.Yellow) return true;
    return false;
  }
}
