import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '@app/shared/models';

@Component({
  selector: 'acct-pwd-resend-dialog',
  templateUrl: './account-pwd-resend-dialog.component.html',
  styleUrls: ['./account-pwd-resend-dialog.component.scss'],
})
export class AccountPwdResendDialogComponent implements OnInit {
  user: User;
  newPwd: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AccountPwdResendDialogComponent>
  ) {}

  ngOnInit(): void {
    this.user = this.data.user;
    this.newPwd = this.data.password;
  }
}
