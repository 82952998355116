import { Component, Input } from '@angular/core';
import { FarLocationPipe } from '@shared/pipes/far-location.pipe';
@Component({
  selector: 'app-far-event',
  templateUrl: './far-event.component.html',
  styleUrls: ['./far-event.component.scss'],
})
export class FarEventComponent {
  @Input() showLocation = true;
  @Input() contentTrimLength = 25;
  @Input() primaryLocation?: any = {};
  private pipe: FarLocationPipe = new FarLocationPipe();

  buildCityStateDisplay(city: string, state: string) {
    return this.pipe.buildCityStateDisplay({
      city: city,
      stateProvince: state,
    });
  }
}
