<form #contactForm="ngForm" autocomplete="off">
  <h5>Foreign National's Contact Information</h5>
  <div class="row">
    <div class="col-4">
      <fieldset>
        <legend>Email Address</legend>
        <app-email-control
          [(ngModel)]="fvEmail"
          [addressRequired]="false"
          name="emailCtl"
          (ngModelChange)="emitChangeEvent()"
        >
        </app-email-control>
      </fieldset>
    </div>
    <div class="col">
      <fieldset>
        <legend>Phone Number</legend>

        <app-phone-number-control
          [numberRequired]="false"
          [showPrimaryToggle]="false"
          [(ngModel)]="fvPhone"
          name="phoneCtl"
          (ngModelChange)="emitChangeEvent()"
        ></app-phone-number-control>
      </fieldset>
    </div>
  </div>
</form>
