import { Organization } from './organization.model';
import { Injectable } from '@angular/core';
import { BaseModel } from '@app/shared/models/base.model';
import { Role } from '@app/shared/models/role';
import { ThemePalette } from '@angular/material/core';
import { Approval } from './approval';

export interface User extends BaseModel {
  id?: string; //number | string;
  email?: string; // (60)
  username?: string; // (64)
  phone?: string; // (15)
  mobilePhone?: string; // (15)
  givenName?: string; // (25)
  surname?: string; // (25)
  rankScore?: number;
  accountStatus?: string; // (40)
  lastLogin?: Date;
  avatarImageLink?: string; //(500) link to a file in S3 bucket (50)
  accountLocked?: boolean;
  multifactorAuthenticationType?: string; // (10)
  organization?: Organization;
  roles?: Role[];
  token?: string;
  tempPassword?: string;
  orgAdminApproval?: Approval;
}

@Injectable({ providedIn: 'root' })
export class UserAccountStatus {
  public accountStatus = [
    { value: 'pre-registered', text: 'Pre-Registered' },
    { value: 'waiting approval', text: 'Waiting Approval' },
    { value: 'active', text: 'Active' },
    { value: 'disabled', text: 'Disabled' },
  ];

  get status() {
    return this.accountStatus;
  }
}

export enum AccountStatus {
  PreRegistered = 'pre-registered',
  Active = 'active',
  Inactive = 'inactive',
}

export const AccountStatuses = Object.values(AccountStatus);

export const AccountStatusLabels: {
  [key in AccountStatus]: string;
} = {
  [AccountStatus.PreRegistered]: 'Pre-Registered',
  [AccountStatus.Active]: 'Active',
  [AccountStatus.Inactive]: 'Inactive',
};

export const AccountActionLabel: {
  [key in AccountStatus]?: {
    action: string;
    present: string;
    past: string;
    color: ThemePalette;
  };
} = {
  [AccountStatus.Active]: {
    action: 'activate',
    present: 'activating',
    past: 'activated ',
    color: 'primary',
  },
  [AccountStatus.Inactive]: {
    action: 'deactivate',
    present: 'deactivating',
    past: 'deactivated ',
    color: 'warn',
  },
};
