<div class="login__entry">
  <form [formGroup]="signInForm" (ngSubmit)="signIn()">
    <h2>Sign In</h2>

    <mat-form-field appearance="outline">
      <mat-label>Username</mat-label>
      <input
        id="login-username-input"
        matInput
        placeholder="Enter Username"
        formControlName="userName"
        required
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input
        id="login-password-input"
        matInput
        [type]="hide.password ? 'password' : 'text'"
        placeholder="Enter Password"
        formControlName="password"
        required
      />
      <mat-icon
        matSuffix
        style="cursor: pointer"
        (click)="hide.password = !hide.password"
        >{{ hide.password ? "visibility_off" : "visibility" }}</mat-icon
      >
    </mat-form-field>

    <div class="d-flex justify-content-between">
      <button
        id="login-forgot-password-button"
        type="button"
        mat-button
        (click)="goToForgotPassword()"
      >
        Forgot Password?
      </button>
      <button
        id="login-submit-button"
        mat-raised-button
        type="submit"
        color="primary"
        [disabled]="busy | async"
      >
        <div *ngIf="(busy | async) === false">Login</div>
        <mat-spinner
          color="primary"
          *ngIf="busy | async"
          [diameter]="35"
        ></mat-spinner>
      </button>
    </div>
  </form>
</div>
