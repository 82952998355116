import { Pipe, PipeTransform } from '@angular/core';
import { Screening } from '@app/shared/models/screening.model';
/**
 * This pipe will return the latest screening
 * based on requested date by default or
 * based on completed date if the completed paramater is passed as true
 *
 * ## Example
 *
 * ```
 * {{ fv.screenings | currentScreening}}
 * ```
 *
 * When you wan the most current completed screening
 *
 * ```
 * {{fv.screenings | currentScreening: true}}
 * ```
 *
 */
@Pipe({
  name: 'currentScreening',
})
export class CurrentScreeningPipe implements PipeTransform {
  transform(
    screenings: Screening[],
    completed: boolean = false
  ): Screening | null {
    if (!screenings || screenings.length == 0) return null;
    // return the most recent requested screening by default.
    // return the most recent completed screening if completed == true
    return screenings
      .slice()
      .filter((screening) => (completed ? this.isCompleted(screening) : true))
      .sort((a, b) => {
        return this.sortScreenings(a, b, completed);
      })[0];
  }

  isCompleted(screening: Screening): boolean | null | undefined {
    return screening.completedDate !== null || undefined;
  }

  dateDiff(a: string | number | Date, b: string | number | Date): number {
    if (a === null || b === null) {
      return 0;
    }
    if (typeof a === 'string' || typeof a === 'number') {
      a = new Date(a);
    }
    if (typeof b === 'string' || typeof b === 'number') {
      b = new Date(b);
    }

    return a.valueOf() - b.valueOf();
  }

  sortScreenings(a: Screening, b: Screening, completed: boolean) {
    if (
      a.completedDate &&
      b.completedDate &&
      a.requestedDate &&
      b.requestedDate
    ) {
      return completed
        ? this.dateDiff(a.completedDate, b.completedDate) * -1
        : this.dateDiff(a.requestedDate, b.requestedDate) * -1;
    } else {
      return 0;
    }
  }
}
