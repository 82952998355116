import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { FilterParams, OrgLocation } from '@app/shared/models';
import {
  CrudService,
  OrgLocationService,
  Repository,
} from '@app/shared/services';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-location-workspace',
  templateUrl: './location-workspace.component.html',
  styleUrls: ['./location-workspace.component.scss'],
  providers: [
    Repository,
    { provide: CrudService, useExisting: OrgLocationService },
  ],
})
export class LocationWorkspaceComponent implements OnInit, AfterContentChecked {
  loading: boolean = false;
  filterParams: FilterParams = new FilterParams({
    pageNum: 0,
    pageSize: 20,
    sortBy: 'name',
  });
  locations$ = this.repo.collection$.pipe(
    tap((_) => {
      this.loading = false;
    })
  );

  constructor(
    private repo: Repository<OrgLocation>,
    private changeDetect: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.onFilterParamsChange(this.filterParams);
  }

  ngAfterContentChecked(): void {
    this.changeDetect.detectChanges();
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.onFilterParamChange(this.filterParams);
  }
}
