import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import {
  ScreeningFieldLabels,
  fvColumns,
} from '@app/screening/collection/screening-table/screening-table.component';
import { take } from 'rxjs';
import {
  FarSortFieldLabels,
  FvSortFieldLabels,
  GroupSortField,
  GroupTableColumns,
  OrgLocationSortLabels,
  OrgSortFieldLabels,
  UserService,
  UserSortFieldLabels,
} from '@app/shared/services';
import { UserAcceptedObservable } from '@app/shared/services/user-accepted-observable.service';
import { UserPreferenceService } from '@app/shared/services/user-preference.service';
import {
  SavedUserPreference,
  UserPreference,
  UserPreferenceBlob,
} from '@app/shared/models';
import { ViewNamesConstants } from '@app/user-preferences/user-preferences.component';

import {
  OrgTableColumns,
  OrgTableName,
} from '@app/organizations/collection/organization-collection-table/organization-collection-table.component';
import {
  UserTableColumns,
  UserTableName,
} from '@app/admin/accounts/accounts-collection/account-user-table/account-user-table.component';
import {
  FARTableColumns,
  FARTableName,
} from '@app/foreign-access-requests/dashboard/collections/far-table/far-table.component';
import {
  FVTableColumns,
  FVTableName,
} from '@app/foreign-visitors/dashboard/fv-table/fv-table.component';
import {
  LocationTableColumns,
  LocationTableName,
} from '@app/locations/collection/location-collection-table/location-collection-table.component';
import { ColumnToggleEvent } from '@app/shared/components';
import { ViewDetails, ViewService } from '@app/shared/services/view.service';
import { GroupTableName } from '@app/groups/collection/group-collection-table/group-collection-table.component';
@Component({
  selector: 'app-splash-page',
  templateUrl: 'splash-page.component.html',
  styleUrls: ['splash-page.component.scss'],
})
export class SplashPageComponent implements OnInit {
  userAccepted: boolean = false;
  userPreference: UserPreferenceBlob | undefined;
  viewNameArray = ViewNamesConstants;
  savedUserPreference: SavedUserPreference | undefined;
  columnEvent: ColumnToggleEvent | undefined;
  viewDetails: ViewDetails | undefined;
  userPreferenceLink: string = '/';
  constructor(
    private router: Router,
    private auth: AuthService,
    private userService: UserService,
    private userAccept: UserAcceptedObservable,
    private userPreferenceService: UserPreferenceService,
    private viewService: ViewService
  ) {}

  ngOnInit(): void {
    this.auth.authenticated$.subscribe((isLoggedIn) => {
      if (!isLoggedIn) this.router.navigate(['/login']);
    });
    this.userAccepted = JSON.parse(
      localStorage.getItem('userAccepted') as string
    );
    this.retrieveUserPreference();
  }

  accept() {
    this.userAccepted = true;
    this.userAccept.accepted();
    localStorage.setItem('userAccepted', JSON.stringify(this.userAccepted));
    localStorage.setItem('userAcceptedDate', JSON.stringify(Date.now()));
    if (this.userPreference !== undefined && this.userPreference !== null) {
      this.loadUserPreferences(this.userPreference);
    } else {
      this.auth.redirectFromLogin();
      this.auth.showLoginInfo();
    }
  }

  decline() {
    this.auth.logout();
  }

  retrieveUserPreference() {
    this.userPreferenceService
      .getCurrentUserId(this.auth?.getUser()?.id!)
      .pipe(take(1))
      .subscribe(
        (result) => {
          if (result !== null && result !== undefined) {
            this.userPreference = JSON.parse(result.userPreferenceBlob);
            if (this.userAccepted)
              this.auth.navigateToUserPreference(this.userPreferenceLink);
          } else {
            if (this.userAccepted) this.router.navigate(['/']);
            else this.userAccept.notAccepted();
          }
        },
        (err) => {
          if (this.userAccepted) this.router.navigate(['/']);
          else this.userAccept.notAccepted();
        }
      );
  }

  loadTable(selectedViewColumns: string[], viewName: string) {
    let displayColumns: any;
    let tableColumns: any;
    let tableName: string = 'undefined';
    switch (viewName) {
      case 'Home':
        this.userPreferenceLink = '/';
        break;
      case 'Foreign Nationals (FN)':
        displayColumns = FvSortFieldLabels;
        tableColumns = FVTableColumns;
        tableName = FVTableName;
        this.userPreferenceLink = '/fvs';
        break;
      case 'Foreign Access Request (FAR)':
        displayColumns = FarSortFieldLabels;
        tableColumns = FARTableColumns;
        tableName = FARTableName;
        this.userPreferenceLink = '/fars';
        break;
      case 'Screening Review':
        displayColumns = ScreeningFieldLabels;
        tableColumns = fvColumns;
        tableName = '';
        this.userPreferenceLink = '/screening';
        break;
      case 'Group Management':
        displayColumns = GroupSortField;
        tableColumns = GroupTableColumns;
        tableName = GroupTableName;
        this.userPreferenceLink = '/groups';
        break;
      case 'Locations':
        displayColumns = OrgLocationSortLabels;
        tableColumns = LocationTableColumns;
        tableName = LocationTableName;
        this.userPreferenceLink = '/locations';
        break;
      case 'Metrics':
        this.userPreferenceLink = '/metrics';
        break;
      case 'System Monitoring':
        this.userPreferenceLink = '/monitoring';
        break;
      case 'Organizations':
        displayColumns = OrgSortFieldLabels;
        tableColumns = OrgTableColumns;
        tableName = OrgTableName;
        this.userPreferenceLink = '/organizations';
        break;
      case 'System Alerts':
        this.userPreferenceLink = '/systemalerts';
        break;
      case 'User Administration':
        displayColumns = UserSortFieldLabels;
        tableColumns = UserTableColumns;
        tableName = UserTableName;
        this.userPreferenceLink = '/admin';
        break;
      default:
        this.userPreferenceLink = '/';
        break;
    }
    if (tableName !== 'undefined') {
      this.userPreferenceService.loadDisplayColumns(
        displayColumns,
        selectedViewColumns,
        tableColumns,
        tableName,
        this.auth?.getUser()?.roles!,
        this.auth.getUser()?.networkDomain!
      );
    }
  }

  loadUserPreferences(userPreference: UserPreferenceBlob): void {
    if (
      userPreference.selectedViewName == null ||
      (typeof userPreference.selectedViewName === 'string' &&
        userPreference.selectedViewName.trim().length === 0)
    ) {
      this.auth.navigateToUserPreference('/');
    } else if (
      userPreference.selectedViewName ===
        (null ||
          'Home' ||
          undefined ||
          '' ||
          'Group Management' ||
          'Metrics' ||
          'System Monitoring' ||
          'System Alerts') ||
      userPreference.selectedViewName.length === 0
    ) {
      this.loadTable([], userPreference.selectedViewName);
      this.auth.navigateToUserPreference(this.userPreferenceLink);
    } else if (this.viewNameArray.includes(userPreference.selectedViewName)) {
      if (userPreference.selectedViewType) {
        if (
          userPreference.selectedViewName === this.viewNameArray[1] ||
          userPreference.selectedViewName === this.viewNameArray[2]
        ) {
          this.userPreferenceService.hasUserPreference(
            this.auth.getUser()?.id!,
            userPreference.selectedViewName
          );
          this.viewDetails = {
            name: userPreference.selectedViewName,
            viewType: userPreference.selectedViewType,
          };
          this.viewService.sendViewChange(this.viewDetails);
        }
      }
      if (typeof userPreference.selectedViewColumns !== undefined) {
        if (userPreference.selectedViewColumns === null) {
          userPreference.selectedViewColumns = [];
        }
        this.loadTable(
          userPreference.selectedViewColumns!,
          userPreference.selectedViewName
        );
      }
      this.auth.navigateToUserPreference(this.userPreferenceLink);
    }
  }
}
