import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ClassificationModel } from '@shared/models/classification.model';
import { ClassificationService } from '@shared/cmt/classification.service';

@Directive({
  selector: '[appClassifyShow]',
})
export class ClassifyShowDirective {
  constructor(
    private classificationService: ClassificationService,
    private viewContainer: ViewContainerRef,
    private changeDetector: ChangeDetectorRef,
    private templateRef: TemplateRef<any>
  ) {
    this.viewContainer.clear();
    if (this.classificationService.enabled) {
      this.viewContainer.createEmbeddedView(templateRef);
      this.changeDetector.markForCheck();
    }
  }
}

@Directive({
  selector: '[appClassify]',
})
export class ClassifyDirective {
  @Input() classificationModel: ClassificationModel;
  @Output() classificationModelChange = new EventEmitter<ClassificationModel>();
  @Input() confirmClassified: boolean = false;
  @Output() classificationConfirmed = new EventEmitter<void>();

  constructor(
    private ngZone: NgZone,
    private classificationService: ClassificationService
  ) {}

  @HostListener('click')
  classify() {
    if (this.confirmClassified && this.classificationModel.classification) {
      this.classificationConfirmed.emit();
      return;
    }

    this.classificationService
      .launchCmt(this.classificationModel)
      .subscribe((model) => {
        this.classificationModel = this.classificationModel || {};
        Object.assign(this.classificationModel, model);
        this.classificationModelChange.emit(this.classificationModel);
        this.classificationConfirmed.emit();
      });
  }
}
