<h3 mat-dialog-title>Foreign National Contact Information</h3>
<mat-dialog-content data-cy="contact-fv-form">
  <app-fv-contact-form
    #contactForm
    ngForm
    name="fv"
    [(ngModel)]="fv"
  ></app-fv-contact-form>
</mat-dialog-content>
<mat-dialog-actions align="end" data-cy="contact-fv-actions">
  <button
    data-cy="contact-fv-cancel-button"
    mat-button
    color="primary"
    id="contact-edit-cancel"
    mat-dialog-close
  >
    Cancel
  </button>
  <button
    data-cy="contact-fv-submit-button"
    [disabled]="!contactForm.valid || busy"
    id="updateContactEmail"
    mat-raised-button
    color="primary"
    (click)="save(fv)"
  >
    <span *ngIf="!busy">Submit</span>
    <mat-spinner
      class="mr-1 ml-1"
      *ngIf="busy"
      color="primary"
      diameter="35"
    ></mat-spinner>
  </button>
</mat-dialog-actions>
