import { Component } from '@angular/core';
import { Role } from '@app/shared/models';
import { Subscription } from 'rxjs';
import {
  AdminViewRoles,
  FarViewRoles,
  FVViewRoles,
  LocViewRoles,
} from '@shared/models/role-permissions';
import { AppConfigService } from '@app/shared/services';

@Component({
  selector: 'app-workspace-dashboard',
  templateUrl: './workspace-dashboard.component.html',
  styleUrls: ['./workspace-dashboard.component.scss'],
})
export class WorkspaceDashboardComponent {
  disableUserAdmin?: boolean;
  insight$: Subscription;
  Role = Role;
  FVViewRoles = FVViewRoles;
  FarViewRoles = FarViewRoles;
  LocViewRoles = LocViewRoles;
  AdminViewRoles = AdminViewRoles;

  constructor(private config: AppConfigService) {
    this.disableUserAdmin = config.get('disableUserAdmin');
  }
}
