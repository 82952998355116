import { Component, Inject, OnInit } from '@angular/core';
import { BaseControlComponent } from '@app/shared/controls/base-control.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FvSocialMedia } from '@app/shared/models';
import { SocialMediaService } from '@app/shared/services';
import { take } from 'rxjs/operators';
import { MatFormFieldAppearance } from '@angular/material/form-field';

export interface SocialMediaDialogData {
  id: string;
  editMode: boolean;
  item?: FvSocialMedia;
}
@Component({
  selector: 'app-social-media-dialog',
  templateUrl: './social-media-dialog.component.html',
  styleUrls: ['./social-media-dialog.component.scss'],
})
export class SocialMediaDialogComponent
  extends BaseControlComponent<SocialMediaDialogComponent>
  implements OnInit
{
  socialMedia: FvSocialMedia;
  appearance: MatFormFieldAppearance = 'outline';
  busy: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SocialMediaDialogComponent>,
    private socialMediaService: SocialMediaService,
    @Inject(MAT_DIALOG_DATA) public dialogData: SocialMediaDialogData | null
  ) {
    super();
  }

  ngOnInit() {
    if (!this.dialogData?.editMode) {
      this.socialMedia = { contentType: 'jpeg', type: '' };
    } else {
      if (this.dialogData.item) {
        this.socialMedia = this.dialogData.item;
      }
    }
    if (!this.socialMedia.foreignVisitor) {
      this.socialMedia.foreignVisitor = {
        id: this.dialogData?.id,
      };
    }
  }

  onSubmit() {
    this.busy = true;
    this.socialMediaService
      .save(this.socialMedia)
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.dialogRef.close({ complete: data });
        },
        (err) => (this.busy = false)
      );
  }
}
