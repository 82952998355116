<div class="container">
  <div class="row align-items-baseline justify-content-between">
    <app-filter
      class="flex-grow-1"
      [label]="''"
      [placeholder]="'Search Countries'"
      [filterParams]="filterParams"
      (filterParamsChange)="onFilterParamChange($event)"
    >
    </app-filter>
  </div>
</div>
<mat-dialog-content class="d-flex">
  <ng-container *ngIf="loading === false; else loadingScreen">
    <mat-table
      style="height: 350px"
      [dataSource]="countries"
      matSort
      class="flex-fill"
    >
      <ng-container matColumnDef="flag">
        <mat-header-cell class="justify-content-center" *matHeaderCellDef>
          Country Flag
        </mat-header-cell>
        <mat-cell class="justify-content-center" *matCellDef="let country">
          <mat-icon
            class="md-36"
            [flagIcon]="country.alpha3Code"
            [matTooltip]="country.name"
          ></mat-icon>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="name">
        <mat-header-cell class="justify-content-center" *matHeaderCellDef
          >Country Name</mat-header-cell
        >
        <mat-cell class="justify-content-center" *matCellDef="let country">
          <div matTooltip="{{ country.name }}">{{ country.name }}</div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="iso-3">
        <mat-header-cell class="justify-content-center" *matHeaderCellDef
          >ISO Code</mat-header-cell
        >
        <mat-cell class="justify-content-center" *matCellDef="let country">
          <div [matTooltip]="country.alpha3Code">
            {{ country.alpha3Code }}
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="countriesHeaders; sticky: true"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: countriesHeaders"></mat-row>
      <ng-template matNoDataRow>
        <h5>
          <div class="d-flex mt-3 align-items-center justify-content-center">
            <mat-icon [svgIcon]="'magnify-close'"></mat-icon>No countries found
            matching '{{ filterParams.search }}'
          </div>
        </h5>
      </ng-template>
    </mat-table>
  </ng-container>
  <ng-template #loadingScreen>
    <div class="container flex-grow-1 d-flex flex-fill" style="height: 350px">
      <div class="row justify-content-center align-self-center flex-grow-1">
        <mat-spinner [diameter]="144"> </mat-spinner>
      </div>
    </div>
  </ng-template>
</mat-dialog-content>
<div class="container sticky" *ngIf="loading === false">
  <div class="row justify-content-end" *ngIf="countries && countries.length">
    {{ countries?.length }} result{{ countries.length > 1 ? "s" : "" }} found
  </div>
</div>
