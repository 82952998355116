<h3 mat-dialog-title>Edit Sponsor</h3>
<mat-dialog-content>
  <app-sponsor
    #sponsorControl
    [(ngModel)]="sponsor"
    name="sponsor"
  ></app-sponsor>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-button
    color="primary"
    id="updateFarSponsorCancel"
    mat-dialog-close
  >
    Cancel
  </button>
  <button
    id="updateFarSponsorSubmit"
    [disabled]="sponsorControl?.sponsorForm?.form?.invalid || busy"
    mat-raised-button
    color="primary"
    (click)="onSubmit()"
  >
    <span *ngIf="!busy; else loading"> Submit </span>
  </button>
</mat-dialog-actions>
<ng-template #loading>
  <mat-spinner color="primary" diameter="35"></mat-spinner>
</ng-template>
