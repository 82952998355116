import { ViewChild, Component } from '@angular/core';
import { ForeignVisitor, ContactEmail } from '@app/shared/models';
import { AddressComponent } from '@app/shared/controls/address/address.component';
import { NgForm, NgModelGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfirmDialogData } from '../confirm/confirmdialog.component';
import { BaseControlComponent } from '@app/shared/controls/base-control.component';
import { AlertService, ForeignVisitorEmailService } from '@app/shared/services';
import { take } from 'rxjs/operators';
import { without } from 'lodash';
import { ContactPhoneNumber } from '@app/shared/models/contact-phone-number.model';
import { ForeignVisitorPhoneService } from '@app/shared/services/foreign-visitor-phone.service';

@Component({
  selector: 'app-fv-contact-form',
  templateUrl: './fv-contact.component.html',
  styleUrls: ['./fv-contact.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FvContactFormComponent,
      multi: true,
    },
  ],
})
export class FvContactFormComponent extends BaseControlComponent<ForeignVisitor> {
  onValidationChange: any = () => {};
  @ViewChild('address') address: AddressComponent;
  @ViewChild('fvContactForm') fvContactForm: NgForm;
  @ViewChild('emailGroup') emailGroup: NgModelGroup;
  @ViewChild('phoneNumberGroup') phoneNumberGroup: NgModelGroup;

  constructor(
    private fvEmailService: ForeignVisitorEmailService,
    private fvPhoneService: ForeignVisitorPhoneService,
    private alertService: AlertService
  ) {
    super();
  }

  writeValue(_value: ForeignVisitor): void {
    super.writeValue(_value);
    this.value = _value || {};
  }

  get valid(): boolean {
    return this.fvContactForm?.valid ?? true;
  }

  addItem(items: ContactEmail[] | ContactPhoneNumber[]) {
    if (!items) {
      items = [];
    }
    items.push({});
    this.emitChangeEvent();
  }

  /**
   * Opens dialog that appears when deleting a Phone Number
   *
   * @param item Item that is to be removed.
   */
  deletePhoneDialog(item: ContactPhoneNumber) {
    if (item?.id) {
      let deleteData: ConfirmDialogData = {
        title: `Delete Phone Number Record`,
        message: `Are you sure? This action cannot be undone.`,
        performAction: () => {
          this.deletePhone(item);
        },
        successMsg: `Record successfully deleted.`,
      };
      this.alertService.confirmDelete(deleteData).subscribe();
    } else {
      this.value.phoneNumbers = without(this.value.phoneNumbers, item);
    }
  }

  deletePhone(item: ContactPhoneNumber) {
    if (item.id) {
      this.fvPhoneService
        .delete(item.id)
        .pipe(take(1))
        .subscribe(() => {
          this.value.phoneNumbers = without(this.value.phoneNumbers, item);
          this.emitChangeEvent();
        });
    }
  }

  deleteEmailDialog(item: ContactEmail) {
    if (item?.id) {
      let deleteData: ConfirmDialogData = {
        title: `Delete Email Record`,
        message: `Are you sure? This action cannot be undone.`,
        performAction: () => {
          this.deleteEmail(item);
        },
        successMsg: `Record successfully deleted.`,
      };
      this.alertService.confirmDelete(deleteData).pipe(take(1)).subscribe();
    } else {
      this.value.emails = without(this.value.emails, item);
    }
  }

  deleteEmail(item: ContactEmail) {
    if (item.id) {
      this.fvEmailService
        .delete(item.id)
        .pipe(take(1))
        .subscribe(() => {
          this.value.emails = without(this.value.emails, item);
          this.emitChangeEvent();
        });
    }
  }

  updatePrimary(phone: ContactPhoneNumber, index: number): void {
    const isPrimary = phone.primaryPhone;
    if (!isPrimary) return;
    this.value.phoneNumbers!.forEach((number) => {
      number.primaryPhone = false;
    });
    if (this.value.phoneNumbers && this.value.phoneNumbers[index]) {
      this.value.phoneNumbers[index].primaryPhone = isPrimary;
    }
  }
}
