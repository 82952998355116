import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ControlContainer,
  FormArray,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';

import { MatCheckboxChange } from '@angular/material/checkbox';
import { CheckBoxProperties, Role } from '@app/shared/models';
import { GroupSortLabels, UserSortFieldLabels } from '@app/shared/services';
import { AdditionalTableColumns } from '../user-preferences.component';
import { AuthService } from '@app/auth/auth.service';
import { UserPreferenceService } from '@app/shared/services/user-preference.service';

@Component({
  selector: 'app-group-tab-checkbox',
  templateUrl: './group-tab-checkbox.component.html',
  styleUrls: ['./group-tab-checkbox.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class GroupTabCheckboxComponent implements OnInit, OnChanges {
  @Input() updated: boolean;
  @Input() checkedColumns: Array<string>;
  @Input() userRoles: Role[];
  @Input() tabTitle: string;
  @Input() previewName: string;
  tabLabel: string = 'Group Management';
  tabIndex: number;
  checkedColumnss: Array<string>;
  groupCheckedColumns: Array<CheckBoxProperties> = [];
  userColumnNames: Array<string> = [];
  displayCheckedColumns: Array<CheckBoxProperties> = [];
  _columnNames: string[];
  groupForm!: FormGroup;

  constructor(
    private authService: AuthService,
    private userPreferenceService: UserPreferenceService,
    private rootFormGroup: FormGroupDirective
  ) {}

  ngOnInit(): void {
    this.loadCheckboxes();
    this.groupForm = this.rootFormGroup.control.get('group') as FormGroup;
  }

  loadCheckboxes(): void {
    this.tabIndex = 4;

    if (this.userColumnNames.length === 0) {
      Object.values(GroupSortLabels).forEach((value) => {
        this.userColumnNames.push(value);
        this.groupCheckedColumns.push({ selected: true, label: value });
      });
    }
    if (!this.userColumnNames.includes(AdditionalTableColumns.at(1)!)) {
      this.userColumnNames.push(AdditionalTableColumns.at(1)!);
      this.groupCheckedColumns.push({
        selected: true,
        label: AdditionalTableColumns.at(1)!,
      });
    }

    this.displayCheckedColumns = this.groupCheckedColumns;
    this._columnNames = this.userColumnNames;

    this.displayCheckedColumns = this.setDisplayCheckedColumns(
      this.groupCheckedColumns,
      this.setCheckedColumns(this.groupCheckedColumns)
    );
  }
  selectedColumns(selectedOption: MatCheckboxChange) {
    this.displayCheckedColumns.forEach((c) => {
      if (selectedOption.source.name === c.label) {
        selectedOption.checked ? (c.selected = true) : (c.selected = false);
      }
    });

    this.groupCheckboxes.controls.forEach((value) => {
      const newValue = value.value;
      if (newValue.label === selectedOption.source.name) {
        if (selectedOption.checked) {
          value.patchValue({ selected: true, label: newValue.label });
        } else {
          value.patchValue({ selected: false, label: newValue.label });
        }
      }
    });
    this.checkedColumnss = this.setCheckedColumns(this.displayCheckedColumns);
  }

  toggleAll(toggle: boolean) {
    this.groupCheckedColumns = [];
    this.checkedColumnss = [];
    if (toggle) {
      this.groupCheckboxes.controls.forEach((value, index) => {
        const newValue = value.value;
        this.groupCheckedColumns.push({
          selected: true,
          label: newValue.label,
        });
        value.patchValue({ selected: true, label: newValue.label });
        this.checkedColumnss.push(newValue.label);
      });
    } else {
      this.groupCheckboxes.controls.forEach((value, index) => {
        const newValue = value.value;
        this.groupCheckedColumns.push({
          selected: false,
          label: newValue.label,
        });
        value.patchValue({ selected: false, label: newValue.label });
      });

      this.checkedColumnss = [];
    }
    this.displayCheckedColumns = this.groupCheckedColumns;
  }

  get groupCheckboxes(): FormArray {
    return this.groupForm.get('groupCheckboxes') as FormArray;
  }

  setDisplayCheckedColumns(
    checkedArray: Array<CheckBoxProperties>,
    checkedArrayColumns: string[]
  ): Array<CheckBoxProperties> {
    let trueCheckedColumns: Array<CheckBoxProperties> = [];
    checkedArray.forEach((c) => {
      if (checkedArrayColumns.includes(c.label)) {
        trueCheckedColumns.push({
          selected: true,
          label: c.label,
        });
      } else {
        trueCheckedColumns.push({
          selected: false,
          label: c.label,
        });
      }
    });

    return trueCheckedColumns;
  }

  setCheckedColumns(checkedArray: Array<CheckBoxProperties>): string[] {
    let trueCheckedColumns: string[] = [];
    checkedArray.forEach((c) => {
      if (c.selected) {
        trueCheckedColumns.push(c.label);
      }
    });
    return trueCheckedColumns;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.userPreferenceService.updated.subscribe((result) => {
      if (result) {
        this.userPreferenceService.updateCheckboxes.subscribe((checkit) => {
          if (this.tabLabel === checkit.viewName) {
            this.checkedColumnss = this.setCheckedColumns(checkit.checkboxes!);
            this.groupCheckedColumns = checkit.checkboxes!;
            this.checkedColumnss = this.setCheckedColumns(
              this.groupCheckedColumns
            );
            this.displayCheckedColumns = this.setDisplayCheckedColumns(
              this.groupCheckedColumns,
              this.checkedColumnss
            );
            this.groupCheckboxes.controls.forEach((value, index) => {
              const newValue = value.value;
              checkit.checkboxes?.filter((x) => {
                if (x.label === newValue.label) {
                  value.patchValue({ selected: x.selected, label: x.label });
                }
              });
            });
          }
        });
      }
    });
    if (changes['tabTitle']) {
      if (changes['tabTitle'].currentValue !== null) {
        this.tabTitle = changes['tabTitle'].currentValue;
      }
    }
  }
}
