import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import {
  AbstractControl,
  NgForm,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { ForeignVisitor } from '@app/shared/models';
import { BaseControlComponent } from '@app/shared/controls/base-control.component';
import { CountrySelectComponent } from '@app/shared/controls';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'app-biographic-form',
  templateUrl: './biographic-form.component.html',
  styleUrls: ['./biographic-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: BiographicFormComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BiographicFormComponent),
      multi: true,
    },
  ],
})
export class BiographicFormComponent extends BaseControlComponent<ForeignVisitor> {
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @ViewChild('birthCountryCtl') birthCountryCtl: CountrySelectComponent;
  @ViewChild('bioForm') bioForm: NgForm;
  today = new Date();
  gender: any = ['male', 'female', 'other'];
  minDate = new Date(1900, 1, 1);
  constructor() {
    super();
  }

  writeValue(value: ForeignVisitor): void {
    this.value = value || {};
  }

  validate(_control: AbstractControl<any, any>): ValidationErrors | null {
    return this.bioForm?.valid ? null : { bioError: true };
  }
}
