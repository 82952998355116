<div class="d-flex align-items-center">
  <div class="d-flex align-items-center" mat-dialog-title>
    Passport<app-passport-help></app-passport-help>
  </div>
</div>
<mat-dialog-content>
  <app-passport
    #passportForm
    [editable]="data.editable"
    [(ngModel)]="passport"
    [isPassport]="true"
    [showClearButton]="false"
  ></app-passport>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <ng-container *ngIf="!data.editable">
    <button
      mat-button
      color="primary"
      id="passport-edit-cancel"
      mat-dialog-close
    >
      Close
    </button>
  </ng-container>
  <ng-container *ngIf="data.editable">
    <button
      mat-button
      color="primary"
      id="passport-edit-cancel"
      mat-dialog-close
    >
      Cancel
    </button>
    <button
      id="passport-submit"
      [disabled]="
        !passportForm?.passportForm?.valid ||
        !passportForm?.hasPassportData() ||
        busy
      "
      mat-raised-button
      color="primary"
      (click)="submitPassport()"
    >
      <span *ngIf="!busy">Submit</span>
      <mat-spinner
        class="mr-1 ml-1"
        *ngIf="busy"
        color="primary"
        diameter="35"
      ></mat-spinner>
    </button>
  </ng-container>
</mat-dialog-actions>
