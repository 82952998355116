import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class AsyncBulkUploadObservable {
  private _AsyncBulkUploadObservable$ = new Subject<any>();
  AsyncBulkUploadObservable$: Observable<any> =
    this._AsyncBulkUploadObservable$.asObservable();

  startPolling(data: any) {
    this._AsyncBulkUploadObservable$.next(data);
  }
}
