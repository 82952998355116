import { Component } from '@angular/core';

import { User } from '@app/shared/models';
import { Observable } from 'rxjs';
import { AuthService } from '@app/auth/auth.service';
import { AuthSummary } from '@shared/models/auth-event.model';
import { AppConfigValue } from '@shared/services/app-config.services';

@Component({
  selector: 'useracct',
  templateUrl: 'useracct.component.html',
  styleUrls: ['useracct.component.scss'],
})
export class UserAcctComponent {
  systemUser$: Observable<User | null> = this.auth.user$;

  summary: AuthSummary | null = null;

  constructor(public auth: AuthService) {
    this.auth.getAuthSummary().then((summary) => (this.summary = summary));
  }
}
