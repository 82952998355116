<div class="grid-collection">
  <mat-toolbar class="border-bottom justify-content-between">
    <h5 class="m-2">Exported Screening History</h5>
    <button
      id="close-exported-screening-history-button"
      mat-icon-button
      matTooltip="Close Exported Screening History Drawer"
      (click)="hideHistory()"
    >
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </mat-toolbar>
  <ng-container *ngIf="ExportedScreeningHistory$ | async as esh">
    <ng-container *ngIf="esh.content; else noData">
      <ng-container *ngIf="esh.content.length > 0; else noData">
        <div class="grid-main">
          <mat-nav-list style="line-height: normal" class="flex-fill p-0">
            <mat-list-item
              style="border-bottom: 1px solid lightgrey"
              appDebounceClick
              *ngFor="let item of esh.content"
              (debounceClick)="downloadScreeningHistory(item)"
              (click)="item.downloading = true"
            >
              <mat-icon
                matListItemIcon
                mimeType="excel"
                matTooltip="Excel File"
              ></mat-icon>
              <h1 matListItemTitle>
                {{ item?.exportFileName | uppercase }}
              </h1>
              <h5 matListItemLine class="mb-0">
                {{ item?.dateOfExport | date: ScreeningDateFormat | uppercase }}
                / {{ item?.numberForeignVisitorsExported }} Exported
              </h5>
              <h5 matListItemLine>
                Exported By:
                {{
                  (item.vetterGivenName | uppercase) +
                    " " +
                    (item.vetterSurname | uppercase)
                }}
              </h5>
              <div matListItemMeta>
                <mat-icon
                  *ngIf="!item.downloading"
                  class="mat-24"
                  style="margin-top: 2rem"
                  matTooltip="Click to Download"
                  >file_download</mat-icon
                >
                <mat-spinner
                  diameter="24"
                  *ngIf="item.downloading"
                ></mat-spinner>
              </div>
            </mat-list-item>
          </mat-nav-list>
        </div>
        <app-paginator
          *ngIf="esh"
          [filterParams]="filterParams"
          (filterParamsChange)="onFilterParamChange($event)"
          [pageIndex]="esh.number"
          [pageSize]="esh.size"
          [totalElements]="esh?.totalElements"
        ></app-paginator>
      </ng-container>
    </ng-container>
    <ng-template #noData> No History Available </ng-template>
  </ng-container>
</div>
