<!-- TODO: Fix test affected by toggle. -->
<div
  class="row panel-banner p-2"
  id="workspaceDashboardToggle"
  data-cy="workspace-dashboard-toggle"
>
  <mat-icon class="mr-2">view_comfy</mat-icon>Collections
  <span class="flex-fill"></span>
</div>
<mat-tab-group
  data-cy="collectionsTabGroup"
  id="collectionsTabGroup"
  #collectionsTabGroup
  #collections_display
>
  <mat-tab
    data-cy="foreign-visitors-tab"
    label="Foreign Nationals"
    *appIfRoles="FVViewRoles"
  >
    <app-fv-workspace data-cy="foreign-visitors-collection"></app-fv-workspace>
  </mat-tab>
  <mat-tab
    data-cy="foreign-access-requests-tab"
    label="Foreign Access Requests"
    *appIfRoles="FarViewRoles"
  >
    <app-far-workspace
      data-cy="foreign-access-requests-collection"
    ></app-far-workspace>
  </mat-tab>
  <mat-tab label="Locations" *appIfRoles="LocViewRoles" data-cy="locations-tab">
    <app-location-workspace
      data-cy="locations-collection"
    ></app-location-workspace>
  </mat-tab>
  <ng-container *ngIf="!disableUserAdmin">
    <mat-tab
      data-cy="admin-tab"
      label="User Administration"
      *appIfRoles="AdminViewRoles"
    >
      <app-accounts-workspace
        data-cy="admin-user-collection"
      ></app-accounts-workspace>
    </mat-tab>
  </ng-container>
</mat-tab-group>
