<ng-container *ngIf="value">
  <form ngForm #approvalForm="ngForm">
    <contact-select
      [disabled]="disabled"
      [required]="true"
      [(ngModel)]="value.contactId"
      (ngModelChange)="emitChangeEvent()"
      name="approver"
      [orgId]="orgId"
    ></contact-select>
    <mat-form-field class="w-100">
      <mat-label>Approval Comment</mat-label>
      <textarea
        name="content"
        [disabled]="disabled"
        required
        matInput
        [(ngModel)]="value.content"
        rows="10"
        (ngModelChange)="emitChangeEvent()"
      ></textarea>
    </mat-form-field>
  </form>
</ng-container>
