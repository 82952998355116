import {
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { AddressComponent } from '@app/shared/controls';
import {
  Address,
  DialogCloseMessage,
  ForeignVisitor,
} from '@app/shared/models';
import { AddressService } from '@app/shared/services/address.service';
import { cloneDeep } from 'lodash';

@Directive({
  selector: '[appAddressDialog]',
})
export class AddressDialogDirective {
  @Input() appAddressDialog: ForeignVisitor;
  @Output() fvAddressUpdated = new EventEmitter<ForeignVisitor>();
  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    const dialog = AddressDialogComponent.openDialog(
      this.dialog,
      this.appAddressDialog
    );
    dialog.afterClosed().subscribe((result) => {
      if (result) return this.fvAddressUpdated.emit(result);
    });
  }
}

@Component({
  selector: 'app-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.scss'],
})
export class AddressDialogComponent {
  @ViewChild('addressForm') addressForm: NgForm;
  busy: boolean = false;
  fv: ForeignVisitor;
  constructor(
    public addressDialog: MatDialogRef<AddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public addressService: AddressService
  ) {}

  static openDialog(
    dialog: MatDialog,
    item: ForeignVisitor
  ): MatDialogRef<AddressDialogComponent> {
    return dialog.open<AddressDialogComponent>(AddressDialogComponent, {
      data: cloneDeep(item),
      width: '800px',
    });
  }

  save(address: Address) {
    this.busy = true;
    address.fvId = this?.data?.id;
    this.addressService.save(address).subscribe(
      (result: Address) => {
        this.onClose({
          update: result,
          message: `Updated Address Information!`,
        });
      },
      (error) => {
        this.busy = false;
      }
    );
  }

  onClose(message: DialogCloseMessage<ForeignVisitor>) {
    this.addressDialog.close(message);
  }
}
