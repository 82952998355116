import { Pipe, PipeTransform } from '@angular/core';
import { Role, RoleLabels, User } from '@app/shared/models';

@Pipe({
  name: 'userRoleList',
})
export class UserRoleListPipe implements PipeTransform {
  transform(user: User): string {
    if (user && user.roles && user.roles.length) {
      return `[${(user.roles as Role[])
        .map((r) => {
          const key = Object.keys(RoleLabels).find(
            (k) => r.toUpperCase() === k.toUpperCase()
          );
          return key ? RoleLabels[key as keyof typeof RoleLabels] : r;
        })
        .join(', \n')}]`;
    }

    return 'No Roles';
  }
}
