<ng-container *ngIf="data && data.length > 1; then multi; else single">
</ng-container>
<ng-template #multi>
  <ng-container *ngIf="data">
    <button mat-button [matMenuTriggerFor]="multiMenu">
      {{ data.length }} {{ buttonText }}
    </button>
    <mat-menu #multiMenu="matMenu">
      <mat-list>
        <mat-icon matListItemIcon>{{ menuIcon }}</mat-icon>
        <span matListItemTitle>
          {{ menuTitle }}
        </span>
        <ng-content select="[multipleItem]"></ng-content>
      </mat-list>
    </mat-menu>
  </ng-container>
</ng-template>
<ng-template #single>
  <ng-container *ngIf="data && data.length > 0; else noData">
    <mat-list>
      <ng-content select="[singleItem]"></ng-content>
    </mat-list>
  </ng-container>
  <ng-template #noData> </ng-template>
</ng-template>
