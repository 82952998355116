<h1 mat-dialog-title>Create Group</h1>
<mat-dialog-content>
  <div class="container mt-2">
    <form #groupForm="ngForm">
      <mat-form-field
        class="w-100 p-0"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Group Name</mat-label>
        <input
          #groupName="ngModel"
          matInput
          required
          [(ngModel)]="value.name"
          name="valueGroupName"
          maxlength="320"
        />
        <mat-error>Group Name is Required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always" class="w-100">
        <mat-label>Group Description</mat-label>
        <textarea
          id="group-description-input"
          #groupDescription="ngModel"
          matInput
          rows="5"
          [(ngModel)]="value.description"
          name="groupDescription"
          maxlength="2000"
        ></textarea>
      </mat-form-field>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-end">
  <button mat-button mat-dialog-close color="primary">Cancel</button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="(groupForm && !groupForm?.valid) || busy"
    (click)="save()"
  >
    Submit
  </button>
</mat-dialog-actions>
