import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'daysUntil',
})
export class DaysUntilPipe implements PipeTransform {
  transform(inputDate: string | number | Date): string {
    if (inputDate === null) {
      return '';
    }
    let today: Date = this.getToday();
    const startDate: Date =
      typeof inputDate == 'string' || typeof inputDate == 'number'
        ? new Date(inputDate)
        : inputDate;
    const Time = startDate.getTime() - today.getTime();
    const daysFloat = Time / (1000 * 3600 * 24);
    const days = Math.max(Math.trunc(daysFloat), 0);

    return days.toString();
  }

  getToday() {
    return new Date();
  }
}
