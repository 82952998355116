<form #baselineForm="ngForm" *ngIf="value" autocomplete="off">
  <div class="row">
    <div class="col-12">
      <h5>Create New Foreign National or Check for Existing</h5>
      <div class="row">
        <div class="col-6 pr-1">
          <!-- Given Name field-->
          <mat-form-field [appearance]="appearance">
            <mat-label matTooltip="First Name">Given Name</mat-label>
            <input
              id="givenName"
              matInput
              #givenNameControl="ngModel"
              [(ngModel)]="value.givenName"
              (ngModelChange)="addToFilterParams($event, 'givenName')"
              placeholder="Enter Given name (First Name)"
              name="givenName"
              maxlength="256"
              aria-label="first name"
            />
          </mat-form-field>
        </div>
        <div class="col-6 pl-1">
          <!-- surname field-->
          <mat-form-field [appearance]="appearance">
            <mat-label matTooltip="Last Name">Surname</mat-label>
            <input
              id="surname"
              matInput
              #surnameControl="ngModel"
              [(ngModel)]="value.surname"
              (ngModelChange)="addToFilterParams($event, 'surname')"
              name="surname"
              maxlength="256"
              placeholder="Enter Surname (Last Name)"
              aria-label="last name"
            />
          </mat-form-field>
        </div>
        <div class="col-3 mt-negative-10">
          <mat-form-field [appearance]="appearance">
            <mat-label>Date Of Birth</mat-label>
            <input
              id="dateOfBirth-baseline"
              matInput
              #dateOfBirthControl="ngModel"
              [matDatepicker]="dobPicker"
              [(ngModel)]="value.dateOfBirth"
              (ngModelChange)="addToFilterParams($event, 'dateOfBirth')"
              name="dateOfBirth-baseline"
              [max]="today"
              placeholder="MM/DD/YYYY"
              aria-label="date of birth"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="dobPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #dobPicker></mat-datepicker>
            <mat-error> Please add a valid date</mat-error>
          </mat-form-field>
        </div>
        <div class="col-3 mt-negative-10 pl-0 pr-1">
          <!--Passport Number field-->
          <mat-form-field [appearance]="appearance">
            <mat-label>Passport Number</mat-label>
            <input
              id="passportNumber"
              matInput
              #baselinePassportNumberControl="ngModel"
              [(ngModel)]="passport.number"
              name="passportNumber"
              maxlength="20"
              aria-label="passport number"
              placeholder="Enter Passport Number"
              (ngModelChange)="setPassport($event, 'passportNumber')"
            />
          </mat-form-field>
        </div>
        <div class="col-6 mt-negative-10 pl-1">
          <!-- Issuing Country field-->
          <app-country-select
            id="issuingCountryCode"
            #issueCountryControl
            [(ngModel)]="passport.issuingCountryCode"
            name="issuingCountryCode"
            placeholder="Passport Issuing Country"
            label="Passport Issuing Country"
            floatLabel="auto"
            errMsg="Please select a Country"
            [excludeUS]="true"
            [includeOrgs]="true"
            (ngModelChange)="setPassport($event, 'passportIssuingCountryCode')"
            ngDefaultControl
          >
          </app-country-select>
        </div>
      </div>
    </div>
  </div>
  <mat-divider class="mt-negative-10 baseline-divider mb-2"></mat-divider>
  <div class="row" style="min-height: 300px">
    <div class="col d-flex flex-column">
      <ng-container *ngIf="fvs$ | async as fvs; else emptyBlock">
        <h5>
          Matching Foreign Nationals:<span> {{ fvs.totalElements }}</span>
        </h5>
        <app-collection
          [pageableCollection]="fvs"
          [compactCard]="true"
          [dashboardType]="'baseline'"
        ></app-collection>
      </ng-container>
      <ng-template #emptyBlock>
        <h5>Matching Foreign Nationals: <span class="">0</span></h5>
        <mat-icon
          svgIcon="account-search"
          class="md-64 flex-fill align-self-center"
        ></mat-icon>
      </ng-template>
    </div>
  </div>
</form>
