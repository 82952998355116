<ng-container *ngIf="displayedAlerts$ | async as systemAlerts">
  <mat-toolbar class="system-alerts bg-danger" *ngIf="systemAlerts.length">
    <ng-container
      *ngFor="let systemAlert of systemAlerts; let i = index; let last = last"
    >
      <mat-toolbar-row
        id="{{ i }}-alert-item"
        class="system-alerts__item justify-content-between text-white"
        [class.seperator]="!last"
      >
        <span></span>
        <span class="d-flex align-items-center">
          {{ systemAlert.message }}</span
        >
        <button mat-icon-button (click)="onClose(systemAlert.id)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-toolbar-row>
    </ng-container>
  </mat-toolbar>
</ng-container>
