import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class UserAcceptedObservable {
  private _UserAcceptedObservable$ = new Subject<any>();
  UserAcceptedObservable$: Observable<any> =
    this._UserAcceptedObservable$.asObservable();

  accepted() {
    this._UserAcceptedObservable$.next(true);
  }

  notAccepted() {
    this._UserAcceptedObservable$.next(false);
  }
}
