<h2
  mat-dialog-title
  id="deactivate-dialog-header"
  data-cy="deactivate-dialog-header"
>
  Confirmation Needed
</h2>
<mat-dialog-content>
  <p>You are navigating away from changes that are not saved.</p>
  <p>
    Click <span class="font-weight-bold">'Continue'</span> if you are sure this
    is what you want to do.
  </p>
  <p>
    Otherwise, click <span class="font-weight-bold">'Cancel'</span> to return to
    your unsaved work.
  </p>
</mat-dialog-content>
<mat-dialog-actions [align]="'end'">
  <button
    mat-button
    mat-dialog-close
    data-cy="deactivate-dialog-button-continue"
    (click)="proceed()"
    color="primary"
  >
    Continue
  </button>
  <button
    data-cy="deactivate-dialog-button-cancel"
    mat-raised-button
    mat-dialog-close
    cdkFocusInitial
    (click)="cancel()"
    color="primary"
  >
    Cancel
  </button>
</mat-dialog-actions>
