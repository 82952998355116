import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { APIInfo } from '@shared/models/app-info.model';
import { VERSION } from '@environments/version';
import { IVersion } from '@environments/iversion';

@Injectable({
  providedIn: 'root',
})
export class AboutService {
  constructor(private http: HttpClient) {}

  getAPIInfo(): Observable<APIInfo> {
    return this.http.get<APIInfo>(`${environment.apiUrl}/actuator/info`);
  }

  getUIInfo(): IVersion {
    return VERSION;
  }
}
