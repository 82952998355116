import {
  OAuthEvent,
  OAuthService,
  OAuthSuccessEvent,
  TokenResponse,
} from 'angular-oauth2-oidc';

// Patch the provided OauthService created until this gets resolved: https://github.com/manfredsteyer/angular-oauth2-oidc/issues/850
// Awaiting pull request to get merge: https://github.com/manfredsteyer/angular-oauth2-oidc/pull/1423
export function patchOAuthService(oauth: OAuthService) {
  let lastUpdatedAccessToken: string | null = null;

  if (oauth.hasValidAccessToken()) {
    lastUpdatedAccessToken = oauth.getAccessToken();
  }

  const origRefreshToken = oauth.refreshToken.bind(oauth);
  oauth.refreshToken = (): Promise<TokenResponse> => {
    // Handle multiple browser tabs if navigator.locks is available
    if (!navigator.locks) {
      return origRefreshToken();
    }
    return navigator.locks.request(
      `refresh_token_${location.origin}`,
      async (): Promise<TokenResponse> => {
        if (lastUpdatedAccessToken !== oauth.getAccessToken()) {
          // Was already updated in another tab/window
          (oauth as any).eventsSubject.next(
            new OAuthSuccessEvent('token_received')
          );
          (oauth as any).eventsSubject.next(
            new OAuthSuccessEvent('token_refreshed')
          );
          lastUpdatedAccessToken = oauth.getAccessToken();
          // @ts-ignore
          return;
        } else {
          // Simply run the original update
          return origRefreshToken();
        }
      }
    );
  };

  const origSilentRefresh = oauth.silentRefresh.bind(oauth);
  oauth.silentRefresh = (
    params: object = {},
    noPrompt = true
  ): Promise<OAuthEvent> => {
    // Handle multiple browser tabs if navigator.locks is available
    if (!navigator.locks) {
      return origSilentRefresh(params, noPrompt);
    }
    return navigator.locks.request(
      `silent_refresh_${location.origin}`,
      async (): Promise<OAuthEvent> => {
        if (lastUpdatedAccessToken !== oauth.getAccessToken()) {
          // Was already updated in another tab/window
          (oauth as any).eventsSubject.next(
            new OAuthSuccessEvent('token_received')
          );
          (oauth as any).eventsSubject.next(
            new OAuthSuccessEvent('token_refreshed')
          );
          const event = new OAuthSuccessEvent('silently_refreshed');
          (oauth as any).eventsSubject.next(event);
          lastUpdatedAccessToken = oauth.getAccessToken();
          return event;
        } else {
          // Simply run the original update
          return origSilentRefresh(params, noPrompt);
        }
      }
    );
  };

  const origStoreAccessTokenResponse = (
    oauth as any
  ).storeAccessTokenResponse.bind(oauth);
  (oauth as any).storeAccessTokenResponse = (
    accessToken: string,
    refreshToken: string,
    expiresIn: number,
    grantedScopes: string,
    customParameters?: Map<string, string>
  ): void => {
    lastUpdatedAccessToken = accessToken;
    origStoreAccessTokenResponse(
      accessToken,
      refreshToken,
      expiresIn,
      grantedScopes,
      customParameters
    );
  };

  const origLogOut = oauth.logOut.bind(oauth);
  oauth.logOut = (
    customParameters: boolean | object = {},
    state = ''
  ): void => {
    lastUpdatedAccessToken = null;
    origLogOut(customParameters, state);
  };
}
