import { NgModule } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { CitadelOauthService } from '@app/auth/oauth/citadel-oauth.service';
import {
  AuthConfig,
  OAuthStorage,
  provideOAuthClient,
} from 'angular-oauth2-oidc';
import { OauthLoginComponent } from '@app/auth/oauth/oauth-login/oauth-login.component';
import { OauthRoutingModule } from '@app/auth/oauth/oauth-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { AppConfigService } from '@shared/services/app-config.services';

export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  declarations: [OauthLoginComponent],
  providers: [
    provideOAuthClient(),
    CitadelOauthService,
    { provide: AuthService, useExisting: CitadelOauthService },
    {
      provide: AuthConfig,
      useFactory: (config: AppConfigService) => config.get('oauthConfig'),
      deps: [AppConfigService],
    },
    //{ provide: OAuthModuleConfig, useValue: authModuleConfig },
    { provide: OAuthStorage, useFactory: storageFactory },
  ],
  imports: [SharedModule, OauthRoutingModule],
})
export class CitadelOauthModule {}
