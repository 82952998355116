import {
  Component,
  Input,
  Injector,
  ViewChild,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { FilterParams, PageableCollection, User } from '@shared/models';
import { Observable } from 'rxjs';
import { Organization } from '@shared/models/organization.model';

import { MatSelect } from '@angular/material/select';
import {
  FloatLabelType,
  MatFormFieldAppearance,
} from '@angular/material/form-field';

import { BaseMatSelectComponent } from '@shared/controls';
import { take } from 'rxjs/operators';
import { UserService } from '@shared/services';

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSelectComponent extends BaseMatSelectComponent<User> {
  @Input() placeholder = 'User';
  @ViewChild('matSelect', { static: true })
  matSelect: MatSelect;
  @Input() floatLabel: FloatLabelType = 'auto';
  @Input() label: string = 'User';
  @Input() filterLabel: string = 'Search';
  @Input()
  appearance: MatFormFieldAppearance = 'outline';
  @Input() isLoading: boolean = false;
  @Output() isLoadingChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Output() userIdChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  set userId(_userId: string) {
    this.loadUserId(_userId);
  }

  get userId(): string {
    return this.value?.id!;
  }

  baseFilterParams: FilterParams = new FilterParams({
    sort: {
      active: 'givenName',
      direction: 'asc',
    },
  });

  constructor(public injector: Injector, private userService: UserService) {
    super(injector);
  }

  loadUserId(userId: string) {
    if ((!userId && !this.value) || userId === this.value?.id) return;

    if (!userId) {
      // @ts-ignore
      this.value = null;
      super.emitChangeEvent();
      return;
    }

    this.value = { id: userId };
    this.userService
      .get(userId)
      .pipe(take(1))
      .subscribe((data) => {
        this.value = data;
        super.emitChangeEvent();
      });
  }

  emitChangeEvent() {
    super.emitChangeEvent();
    this.userIdChange.emit(this.userId);
  }

  searchItems(
    params: FilterParams
  ): Observable<PageableCollection<Organization>> {
    return this.userService.find(params);
  }
}
