<div class="d-flex flex-column h-100 edit-contact-info-container mt-2">
  <div mat-dialog-title class="d-flex flex-row align-items-center">
    <mat-icon class="mr-3" svgIcon="square-edit-outline"></mat-icon> Edit
    Contact Info
  </div>
  <mat-dialog-content>
    <form
      #editContactForm="ngForm"
      id="editContactForm"
      class="d-flex flex-column flex-grow-1"
    >
      <mat-form-field appearance="outline" class="mt-2">
        <mat-label>Work Phone</mat-label>
        <input
          required
          name="phone"
          maxlength="15"
          matInput
          [(ngModel)]="profileInfo.phone"
        />
        <mat-error>Please Enter a Valid Phone Number</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Mobile Phone</mat-label>
        <input
          name="mobilePhone"
          minlength="10"
          maxlength="15"
          matInput
          [(ngModel)]="profileInfo.mobilePhone"
        />
        <mat-hint *ngIf="limitedUserAdmin"
          >Updates from your organization's directory may override these
          values.</mat-hint
        >
        <mat-error>Please Enter a Valid Phone Number</mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="flex-row">
    <button mat-button mat-dialog-close>Cancel</button>
    <span class="flex-grow-1"></span>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      form="editContactForm"
      [disabled]="!editContactForm || editContactForm.invalid || busy"
      (click)="submit()"
    >
      <span *ngIf="!busy">Submit</span>
      <mat-spinner
        class="mr-1 ml-1"
        *ngIf="busy"
        color="primary"
        diameter="35"
      ></mat-spinner>
    </button>
  </mat-dialog-actions>
</div>
