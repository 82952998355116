<form #roleForm="ngForm">
  <mat-chip-listbox
    multiple
    [disabled]="readOnly"
    [(ngModel)]="value"
    [value]="value"
    name="roleSelect"
    (ngModelChange)="emitChangeEvent()"
  >
    <ng-container *ngFor="let role of roles">
      <mat-chip-option
        *ngIf="(!checkAdmin(role) || (value || []).includes(role)) && !unlocked"
        [value]="role"
        [matTooltip]="roleDescriptions[role]"
        [disabled]="checkAdmin(role) || readOnly"
        [selectable]="!checkAdmin(role)"
        [color]="roleColors[role]"
      >
        {{ roleLabels[role] || role + " (invalid)" }}
      </mat-chip-option>
      <mat-chip-option
        *ngIf="unlocked"
        [value]="role"
        [matTooltip]="roleDescriptions[role]"
        [selectable]="true"
        [color]="roleColors[role]"
      >
        {{ roleLabels[role] || role + " (invalid)" }}
      </mat-chip-option>
    </ng-container>
  </mat-chip-listbox>
</form>
