import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Screening,
  ScreeningDateFormat,
  ScreeningResult,
} from '@app/shared/models';
import { AppConfigService } from '@app/shared/services';

@Component({
  selector: 'app-screening-history-dialog',
  templateUrl: './screening-history-dialog.component.html',
  styleUrls: ['./screening-history-dialog.component.scss'],
})
export class ScreeningHistoryDialogComponent implements OnInit {
  screeningHistory: Screening[];
  fvName: string = '';

  ScreeningField = ScreeningField;
  ScreeningFieldLabel = ScreeningFieldLabels;
  ScreeningResult = ScreeningResult;
  ScreeningDateFormat = ScreeningDateFormat;

  historyColumns: string[] = [
    ScreeningField.REQUESTED_DATE,
    ScreeningField.MODIFIED_DATE,
    ScreeningField.COMPLETED_DATE,
    ScreeningField.STATUS,
    ScreeningField.RESULT,
    ScreeningField.NOTES,
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private config: AppConfigService
  ) {}

  ngOnInit(): void {
    let highSideEnabled = this.config.get('highEnvFeatures');
    if (highSideEnabled)
      this.historyColumns.splice(
        this.historyColumns.length - 1,
        0,
        ScreeningField.DEROG_INFO
      );
    this.screeningHistory = this.data.history;
    this.fvName = this.data.fvName;
  }
}

export enum ScreeningField {
  SURNAME = 'surname',
  VIP = 'vip',
  STATUS = 'latestScreening.status',
  NOTES = 'latestScreening.notes',
  NETWORK_DOMAIN = 'latestScreening.networkDomain',
  REQUESTED_DATE = 'latestScreening.requestedDate',
  COMPLETED_DATE = 'latestScreening.completedDate',
  MODIFIED_DATE = 'latestScreening.lastModifiedDate',
  DEROG_INFO = 'latestScreening.derogatoryInformation',
  RESULT = 'latestScreening.result',
}

export const ScreeningFieldLabels: { [key: string]: string } = {
  [ScreeningField.NOTES]: 'Notes Available',
  [ScreeningField.NETWORK_DOMAIN]: 'Fabric',
  [ScreeningField.STATUS]: 'Screening Status',
  [ScreeningField.REQUESTED_DATE]: 'Requested Date',
  [ScreeningField.COMPLETED_DATE]: 'Completed Date',
  [ScreeningField.MODIFIED_DATE]: 'Modified Date',
  [ScreeningField.DEROG_INFO]: 'Derogatory Info',
  [ScreeningField.RESULT]: 'Screening Result',
};
