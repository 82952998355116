import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { Platform } from '@angular/cdk/platform';
import { CitadelOauthService } from '@app/auth/oauth/citadel-oauth.service';

@Component({
  templateUrl: 'oauth-login.component.html',
  styleUrls: ['oauth-login.component.scss'],
})
export class OauthLoginComponent {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private platform: Platform,
    private auth: CitadelOauthService
  ) {}

  public async login() {
    return this.auth.login();
  }
}
