import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'default',
})
export class DefaultPipe implements PipeTransform {
  transform(value: any, defaultValue: any = 'N/A'): any {
    if (
      value === undefined ||
      value === null ||
      (value.length !== undefined && !value.length)
    ) {
      return defaultValue;
    }

    return value;
  }
}
