import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  from,
  fromEvent,
  Observable,
  Subscription,
} from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import {
  AuthFlow,
  CognitoAuthService,
} from '@app/auth/cognito-auth/cognito-auth.service';
import { AlertService } from '@app/shared/services';

@Component({
  selector: 'app-multi-factor-authentication',
  templateUrl: './multi-factor-authentication.component.html',
  styleUrls: ['./multi-factor-authentication.component.scss'],
})
export class MultiFactorAuthenticationComponent
  implements OnInit, OnDestroy, AfterContentInit
{
  //#region with Amplify

  digit1: UntypedFormControl = new UntypedFormControl('');
  digit2: UntypedFormControl = new UntypedFormControl('');
  digit3: UntypedFormControl = new UntypedFormControl('');
  digit4: UntypedFormControl = new UntypedFormControl('');
  digit5: UntypedFormControl = new UntypedFormControl('');
  digit6: UntypedFormControl = new UntypedFormControl('');
  @ViewChild('digit1el', { static: true }) digit1element: ElementRef;
  @ViewChild('digit2el', { static: true }) digit2element: ElementRef;
  @ViewChild('digit3el', { static: true }) digit3element: ElementRef;
  @ViewChild('digit4el', { static: true }) digit4element: ElementRef;
  @ViewChild('digit5el', { static: true }) digit5element: ElementRef;
  @ViewChild('digit6el', { static: true }) digit6element: ElementRef;
  @Output() viewChange = new EventEmitter<AuthFlow>();
  private busy_ = new BehaviorSubject(false);
  public busy = this.busy_.asObservable();

  private backspaceKeySubscriber$ = new Subscription();
  private allSubscriptions = new Subscription();

  private email_ = new BehaviorSubject('');
  public email = this.email_.asObservable();

  private digitControls: UntypedFormControl[] = [
    this.digit1,
    this.digit2,
    this.digit3,
    this.digit4,
    this.digit5,
    this.digit6,
  ];

  constructor(
    private auth: CognitoAuthService,
    private router: Router,
    private alert: AlertService
  ) {}

  ngOnInit() {
    // Get e-mail address the code was sent to
    // It is a public challenge parameter so let's try it that way
    const param = this.auth.getPublicChallengeParameters();
    if (param.email) {
      this.email_.next(param.email);
      this.moveFocus();
      this.pasteCode();
      this.createBackspaceListener();
    }
  }

  moveFocus(): void {
    // Move focus to next field upon entry of a digit
    [2, 3, 4, 5, 6].forEach((digit) => {
      let digitCtl = `digit${digit - 1}`;
      const prev = this[
        digitCtl as keyof MultiFactorAuthenticationComponent
      ] as UntypedFormControl;
      const next = this[
        `digit${digit}element` as keyof MultiFactorAuthenticationComponent
      ] as ElementRef;
      this.allSubscriptions.add(
        prev.valueChanges
          .pipe(
            tap(() => {
              if (prev.value) {
                next.nativeElement.focus();
                next.nativeElement.setSelectionRange(0, 1);
              }
            })
          )
          .subscribe()
      );
    });
  }

  createBackspaceListener(): void {
    let nativeElements = [
      this.digit2element.nativeElement,
      this.digit3element.nativeElement,
      this.digit4element.nativeElement,
      this.digit5element.nativeElement,
      this.digit6element.nativeElement,
    ];

    this.backspaceKeySubscriber$ = from(nativeElements)
      .pipe(mergeMap((element) => fromEvent(element, 'keydown')))
      .subscribe((keyEvent: any) => {
        if (keyEvent.key === 'Backspace') {
          this.handleBackspace(keyEvent);
        }
      });
  }

  handleBackspace(keyEvent: any): void {
    if (!keyEvent.target.value) {
      keyEvent.preventDefault();

      let currentFieldNumber = (keyEvent.target.name as string).substring(5, 6);
      let prevFieldNumber: number = Number(currentFieldNumber) - 1;

      const prevField = this[
        `digit${prevFieldNumber.toString()}element` as keyof MultiFactorAuthenticationComponent
      ] as ElementRef;

      prevField.nativeElement.focus();
    }
  }

  pasteCode(): void {
    // If the user copy pastes the code into the first digit field
    // we'll be so kind to cut it in 6 pieces and distribute it to the right fields
    this.allSubscriptions.add(
      this.digit1.valueChanges
        .pipe(
          tap((value) => {
            if (value && value.length > 1) {
              const digits = value.split('').slice(0, 6);
              this.digit1.setValue(digits[0]);
              this.digit2.setValue(digits[1]);
              this.digit3.setValue(digits[2]);
              this.digit4.setValue(digits[3]);
              this.digit5.setValue(digits[4]);
              this.digit6.setValue(digits[5]);
            }
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.allSubscriptions.unsubscribe();
    this.backspaceKeySubscriber$.unsubscribe();
  }

  ngAfterContentInit() {
    this.digit1element.nativeElement.focus();
  }

  cancelLogin() {
    this.viewChange.emit('LOGIN' as AuthFlow);
  }

  joinCode(): string {
    return [
      this.digit1.value,
      this.digit2.value,
      this.digit3.value,
      this.digit4.value,
      this.digit5.value,
      this.digit6.value,
    ].join('');
  }

  completeCodeEntered(): boolean {
    let inputValues = '';
    this.digitControls.forEach((control) => {
      inputValues += control.getRawValue();
    });
    if (inputValues.length === 6) {
      return true;
    }
    return false;
  }

  public async submit() {
    try {
      this.busy_.next(true);
      const loginSucceeded = await this.auth.answerCustomChallenge(
        this.joinCode()
      );
      if (loginSucceeded) {
        this.router.navigate(['/']);
      } else {
        this.alert.errorAlert('Invalid Code');
      }
    } catch (err) {
      this.alert.errorAlert(err.message || err);
    } finally {
      this.busy_.next(false);
    }
  }

  resendMFA(): void {
    this.auth.resendMFA();
  }

  //#endregion with Amplify
}
