import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsComponent } from './forms/forms.component';
import { SandboxComponent } from './sandbox/sandbox.component';
import { ForeignNationalsComponent } from './foreign-nationals/foreign-nationals.component';

const routes: Routes = [
  {
    path: 'sandbox',
    title: 'Sandbox',
    component: SandboxComponent,
  },
  { path: 'sandbox/forms', title: 'Sandbox Forms', component: FormsComponent },
  {
    path: 'sandbox/fns',
    title: 'Sandbox Fn Cards',
    component: ForeignNationalsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SandboxRoutingModule {}
