import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { IEnvironment } from '@environments/ienvironment';
import { AppConfigService } from '@shared/services';
import { isEqual, isUndefined } from 'lodash';

export type AppIfConfig<K extends keyof IEnvironment> = {
  key: K;
  value?: IEnvironment[K];
  defaultVal?: IEnvironment[K];
};

@Directive({
  selector: '[appIfConfig]',
})
export class AppConfigDirective<K extends keyof IEnvironment>
  implements OnInit, OnChanges
{
  @Input() appIfConfig: AppIfConfig<K>;

  @Input() appIfConfigThen: TemplateRef<any>;
  @Input() appIfConfigElse: TemplateRef<any>;

  constructor(
    private config: AppConfigService,
    private viewContainer: ViewContainerRef,
    private changeDetector: ChangeDetectorRef,
    private templateRef: TemplateRef<any>
  ) {}

  ngOnInit(): void {
    this.viewContainer.clear();
    this.validateView();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appIfConfig) {
      this.validateView();
    }
  }

  validateView() {
    if (this.canView()) this.handleShow();
    else this.handleHide();
  }

  canView(): boolean {
    if (!this.appIfConfig) return true;

    const value = this.config.get(
      this.appIfConfig.key,
      this.appIfConfig.defaultVal
    );

    if (isUndefined(this.appIfConfig.value)) return !!value;

    return isEqual(value, this.appIfConfig.value);
  }

  handleShow() {
    this.showTemplateBlockInView(this.appIfConfigThen || this.templateRef);
  }

  handleHide() {
    this.showTemplateBlockInView(this.appIfConfigElse);
  }

  private showTemplateBlockInView(template?: TemplateRef<any>): void {
    this.viewContainer.clear();
    if (!template) {
      return;
    }

    this.viewContainer.createEmbeddedView(template);
    this.changeDetector.markForCheck();
  }
}
