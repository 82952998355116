import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ClassificationModel } from '@shared/models/classification.model';

@Component({
  selector: 'app-stepper-footer',
  templateUrl: './stepper-footer.component.html',
  styleUrls: ['./stepper-footer.component.scss'],
})
export class StepperFooterComponent {
  @Input() stepper: MatStepper;
  @Input() form: NgForm;
  @Input() parentRoute: string;
  @Input() classificationModel: ClassificationModel;
  @Output() classificationModelChange = new EventEmitter<ClassificationModel>();

  @Output() emitSubmit = new EventEmitter();
  public busy: boolean = false;
}
