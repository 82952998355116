<h1 mat-dialog-title *ngIf="organization">
  <ng-container>{{ id ? "Edit" : "Create" }} Organization</ng-container>
</h1>
<mat-dialog-content>
  <app-classification-banner
    class="d-block mb-2"
    [classificationModel]="organization"
  ></app-classification-banner>
  <!--Dev Note: Audit Metadata doesn't exist on Organization Objects-->
  <!--<app-resource-user-details
    *ngIf="organization?.id"
    [data]="organization"
    [hideOwnerOrg]="true"
    [hideBorder]="true"
    #resourceUserDetails
  ></app-resource-user-details>-->
  <app-organization-form
    class="organization-form"
    #organizationForm
    [(ngModel)]="organization"
    (emitChangeEvent)="onOrganizationChange($event)"
  ></app-organization-form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
  <button
    mat-button
    color="primary"
    mat-dialog-close
    id="cancel-organization-dialog"
  >
    Close
  </button>
  <span class="col"></span>
  <!-- CLASSIFY BUTTON -->
  <button
    mat-flat-button
    type="button"
    id="organization-dialog-classify-btn"
    appClassify
    *appClassifyShow
    [(classificationModel)]="organization"
    color="accent"
  >
    Classify
  </button>
  <button
    appDebounceClick
    [disabled]="!organizationForm.valid || disabledSubmit"
    mat-raised-button
    color="primary"
    (debounceClick)="onSubmit()"
    id="submit-location"
  >
    <div *ngIf="!disabledSubmit">Submit</div>
    <mat-spinner
      color="primary"
      *ngIf="disabledSubmit"
      [diameter]="35"
    ></mat-spinner>
  </button>
</mat-dialog-actions>
