import {
  Component,
  OnInit,
  Inject,
  AfterContentChecked,
  ChangeDetectorRef,
} from '@angular/core';
import { Visa } from '@app/shared/models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VisaService } from '@app/shared/services/visa.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-visa-dialog',
  templateUrl: './visa-dialog.component.html',
})
export class VisaDialogComponent implements OnInit, AfterContentChecked {
  visa: Visa = {};
  busy: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<VisaDialogComponent>,
    public visaService: VisaService,
    private changeDetect: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: Visa
  ) {}

  ngOnInit() {
    this.visa = this.data;
  }

  //Supressing "ExpressionChangedAfterItHasBeenCheckedError" occurring due to visaForm in VisaForm Component values changing
  ngAfterContentChecked(): void {
    this.changeDetect.detectChanges();
  }

  submitVisa() {
    this.busy = true;
    this.visaService
      .save(this.visa)
      .pipe(take(1))
      .subscribe(
        (result: Visa) => {
          this.onClose(result);
        },
        (err) => (this.busy = false)
      );
  }

  onClose(visa: Visa) {
    this.dialogRef.close(visa);
  }
}
