import { Component, OnInit } from '@angular/core';
import { AuthFlow } from '@app/auth/cognito-auth/cognito-auth.service';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-cognito-auth-login',
  templateUrl: './cognito-auth-login.component.html',
  styleUrls: ['./cognito-auth-login.component.scss'],
})
export class CognitoAuthLoginComponent implements OnInit {
  public view: AuthFlow;
  constructor(private platform: Platform) {
    //redirect to blocked if mobile device
    if (this.platform.ANDROID || this.platform.IOS) {
      console.log('Using Mobile Device');
      this.view = AuthFlow.BLOCKED;
    }
  }

  ngOnInit() {
    if (this.view !== AuthFlow.BLOCKED) {
      this.view = AuthFlow.LOGIN;
    }
  }

  updateView(event: AuthFlow) {
    this.view = event;
  }
}
