import { Component, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { ScreeningRoles } from '@shared/models/role-permissions';
import { hasValues } from '@app/shared/helpers/has-values';
import {
  Screening,
  ScreeningResult,
  ScreeningStatus,
  ScreeningStatusLabels,
  ScreeningUtils,
} from '@app/shared/models';
import { pick } from 'lodash';
import { BaseControlComponent } from '../base-control.component';
import { AppConfigService } from '@app/shared/services/app-config.services';

/**
 *
 * # Fields
 * 1. notes - can be changed by a vetter or admin
 * 2. result - default state is disabled and only a vetter or admin may change if after specifically selecting edit action.
 * 3. code - Is only required if the result field is "RED"
 * 4. status  - Is updated by the system to "In Process" when screening records are exported. Prior records sent to a vetting partner are updated from "In Process" to "Completed"  when the results from the vetting partner are imported. If the result field is manually changed by a vetter, this control will automatically update status to "Completed"
 * 5. requestedDate - System generated date value when the screening record is exported to vetting partner.
 * 6. completedDate - System generated date value when the screening result is imported from the vetting partner (or overidden manually)
 * 7. networkDomain - The network domain where the record is generated.
 */
@Component({
  selector: 'screening-control',
  templateUrl: './screening-control.component.html',
  styleUrls: ['./screening-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ScreeningControlComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: ScreeningControlComponent,
      multi: true,
    },
  ],
})
export class ScreeningControlComponent
  extends BaseControlComponent<Screening>
  implements Validator
{
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() overrideResults: boolean = false;

  ScreeningStatusLabels = ScreeningStatusLabels;
  status = ScreeningStatus;
  result = ScreeningResult;
  screeningRoles = ScreeningRoles;
  screeningCodeUrl = this.config.get('screeningCodeLink');
  orgValue: any = null;
  confirmUsp: boolean = false;
  ScreeningUtils = ScreeningUtils;

  constructor(private config: AppConfigService) {
    super();
  }

  onUpdateResult(result: ScreeningResult) {
    if (!this.isRed) {
      this.value.derogatoryInformation = [];
    }
    this.value.status = this.status.Completed;
    this.emitChangeEvent();
  }

  get isRed() {
    return (
      this.value?.result === ScreeningResult.Red ||
      this.value?.result === ScreeningResult.ReviewedRed
    );
  }

  validate(control: AbstractControl<Screening>): ValidationErrors | null {
    const value = control.value;
    if (value) {
      return this.getErrors(value) as ValidationErrors;
    }
    return null;
  }

  getErrors(value: Screening): ValidationErrors | null {
    const errors: any[] = [];
    if (!hasValues(pick(value, ['notes', 'result']))) {
      errors.push({ isEmpty: true });
    }
    if (ScreeningUtils.isLegacyResult(value.result)) {
      errors.push({ legacyValue: true });
    }
    return errors.length ? { screening: errors } : null;
  }
}
