import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { SharedModule } from '@app/shared/shared.module';
import { ForeignAccessRequestRoutingModule } from './foreign-access-request-routing.module';
import { CollectionsComponent } from './dashboard/collections/collections.component';
import { FarViewComponent } from './farview/farview.component';
import { FarSponsorComponent } from './farview/farscope/farsponsor/farsponsor.component';
import { FarCreationComponent } from '@app/foreign-access-requests/far-creation/far-creation.component';
import { FarTableComponent } from './dashboard/collections/far-table/far-table.component';
import { FarViewAssociatedFvsComponent } from './farview/far-view-associated-fvs/far-view-associated-fvs.component';
import { ForeignVisitorsModule } from '@app/foreign-visitors/foreign-visitors.module';
import { FarReviewComponent } from './far-creation/far-review/far-review.component';
import { SponsorComponent } from './far-creation/sponsor/sponsor.component';
import { FarItineraryComponent } from './farview/farscope/far-itinerary/far-itinerary.component';
import { FarDetailsDialogComponent } from './farview/edit-far-details/far-details-dialog.component';
import {
  SponsorDialogComponent,
  SponsorEditDirective,
} from './far-creation/sponsor/sponsor-dialog.component';
import { FarDetailsControlComponent } from './far-creation/far-details-control/far-details-control.component';
import { DeleteFarDirective } from './delete-far.directive';
import { AddFvsDialogComponent } from './farview/far-view-associated-fvs/add-fvs-dialog/add-fvs-dialog.component';
import { MaterialModule } from '@app/shared/material/material.module';
import { ItineraryControlObservable } from '@app/shared/services/itinerary-control-observable.service';
import { FarDescriptionDialogComponent } from './farview/edit-far-description/far-description-dialog.component';
import { ItineraryTableComponent } from './dashboard/collections/far-table/itinerary-table/itinerary-table.component';
import { CancelFarDialogComponent } from './farview/cancel-far/cancel-far-dialog.component';
import { AddGroupDialogComponent } from './farview/far-view-associated-fvs/add-group-dialog/add-group-dialog.component';
@NgModule({
  declarations: [
    DashboardComponent,
    CollectionsComponent,
    FarTableComponent,
    FarViewComponent,
    FarSponsorComponent,
    FarCreationComponent,
    FarViewComponent,
    FarViewAssociatedFvsComponent,
    FarReviewComponent,
    SponsorComponent,
    FarItineraryComponent,
    FarDetailsDialogComponent,
    SponsorDialogComponent,
    FarDetailsControlComponent,
    DeleteFarDirective,
    SponsorEditDirective,
    AddFvsDialogComponent,
    FarDescriptionDialogComponent,
    ItineraryTableComponent,
    CancelFarDialogComponent,
    AddGroupDialogComponent,
  ],
  imports: [
    SharedModule,
    ForeignAccessRequestRoutingModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    MatSortModule,
    ForeignVisitorsModule,
  ],
  providers: [ItineraryControlObservable],
  exports: [
    CollectionsComponent,
    FarDetailsDialogComponent,
    SponsorDialogComponent,
    FarDetailsControlComponent,
    SponsorEditDirective,
    AddFvsDialogComponent,
  ],
})
export class ForeignAccessRequestModule {}
