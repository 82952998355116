<div
  class="p-1 d-flex align-items-center"
  [class]="screening | screeningClassList"
>
  <div class="flex-fill d-flex align-items-center gap-1">
    <mat-icon
      svgIcon="{{ screening | screeningIcon }}"
      class="md-36"
    ></mat-icon>
    <strong>{{ screening | screeningLabel: "result" }}</strong>
  </div>
  <app-field
    label="Modified at"
    value="{{ modifiedDate | date: 'MMM d, y, HH:mm:ss' }}"
  ></app-field>
</div>
