import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterParams, PageableCollection } from '@app/shared/models';
import { Approval } from '@app/shared/models/approval';
import { ActionButton } from '@shared/models/actionbutton.model';
import { ApprovalAdvancedSearch } from '@app/admin/approvals/approval-advanced-search';
import {
  ApprovalTableColumns,
  ApprovalTableName,
} from '@app/admin/approvals/approval-table/approval-table.component';
import { ApprovalSortFieldLabels } from '@shared/services/approval.service';

@Component({
  selector: 'app-approval-collection',
  templateUrl: './approval-collection.component.html',
  styleUrls: ['./approval-collection.component.scss'],
})
export class ApprovalCollectionComponent {
  @Input() loading: boolean = false;
  @Input() position: 'fixed' | 'sticky' = 'sticky';
  @Input() pageableCollection: PageableCollection<Approval> | null = null;
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  @Input() workspaceView: boolean = false;
  actionButtons: ActionButton[] = [];

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParamsChange.emit(filterParams);
  }

  protected readonly ApprovalAdvancedSearch = ApprovalAdvancedSearch;
  protected readonly ApprovalTableColumns = ApprovalTableColumns;
  protected readonly ApprovalSortFieldLabels = ApprovalSortFieldLabels;
  protected readonly ApprovalTableName = ApprovalTableName;
}
