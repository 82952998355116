<div mat-dialog-title class="text-center py-3">
  <mat-icon [citadelLogo]="'horizontal'"></mat-icon>
</div>

<mat-dialog-content>
  Due to Inactivity on this Site You will be Logged Out Shortly
</mat-dialog-content>

<mat-dialog-actions class="d-flex flex-row">
  <button mat-stroked-button color="warn" class="col" (click)="logout()">
    Logout ({{ timeout$ | async }})
  </button>
  <button
    mat-flat-button
    class="col"
    color="primary"
    mat-dialog-close
    cdkFocusInitial
  >
    Continue
  </button>
</mat-dialog-actions>
