import { Component, Input, ViewChild } from '@angular/core';
import { Role, Contact, ContactType } from '@app/shared/models';
import { NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import { BaseControlComponent } from '@app/shared/controls/base-control.component';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { PhoneValidator } from '@app/shared/validators/phone-number.validator';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ContactFormComponent,
      multi: true,
    },
  ],
})
export class ContactFormComponent extends BaseControlComponent<Contact> {
  @ViewChild('contactForm') contactForm: NgForm;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  Role = Role;
  invalid: boolean = true;
  ContactType = ContactType;
  PhoneValidation = PhoneValidator.getPattern();

  constructor() {
    super();
  }

  clearNameFields() {
    this.value.givenName = '';
    this.value.surname = '';
    this.value.groupName = '';
  }

  writeValue(value: Contact): void {
    this.value = value || {};
  }

  get valid(): boolean {
    return this.contactForm?.valid ?? true;
  }
}
