<div mat-dialog-title>Select Foreign National Group to add to this FAR</div>
<mat-dialog-content>
  <form #form="ngForm" class="mt-3">
    <app-select-group
      [(ngModel)]="groupToAssociate"
      name="groupToAccociate"
      [hideInputDisplay]="true"
      [multiple]="false"
      [clearable]="false"
      [excludeById]="id"
      [restrictCurrentOrganization]="false"
      [organizationId]="organizationId"
      ngDefaultControl
    ></app-select-group>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onClose()" data-cy="cancel">Cancel</button>
  <button
    mat-flat-button
    (click)="onSubmit()"
    color="primary"
    [disabled]="!groupToAssociate || busy"
    data-cy="submit"
  >
    <span *ngIf="!busy; else loading">Submit</span>
  </button>
</mat-dialog-actions>
<ng-template #loading>
  <mat-spinner diameter="35"></mat-spinner>
</ng-template>
