import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ItineraryControlObservable {
  private _ItineraryControlObservable$ = new Subject<any>();
  ItineraryControlObservable$: Observable<any> =
    this._ItineraryControlObservable$.asObservable();

  locationRequired() {
    this._ItineraryControlObservable$.next(true);
  }

  locationOptional() {
    this._ItineraryControlObservable$.next(false);
  }
}
