import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import {
  DialogCloseMessage,
  EmployerType,
  EmploymentRecord,
  FvWork,
  OrganizationalOwnership,
} from '@app/shared/models';
import { take } from 'rxjs/operators';
import { AlertService } from '@app/shared/services';
import { EmploymentService } from '@app/shared/services/employment.service';
import { isEmpty } from 'lodash';
import { DialogData } from '../fvview-employment.component';
import { PhoneValidator } from '@app/shared/validators/phone-number.validator';

@Component({
  selector: 'app-employment-form',
  templateUrl: './employment-form.component.html',
  styleUrls: ['./employment-form.component.scss'],
})
export class EmploymentFormComponent implements OnInit {
  mode: 'create' | 'update' | 'delete' = 'create';
  appearance: MatFormFieldAppearance = 'outline';
  employmentRecord!: EmploymentRecord;
  busy: boolean = false;
  // Using Enums for the mat-select components
  orgOwnership = OrganizationalOwnership;
  employerType = EmployerType;
  PhoneValidation = PhoneValidator.getPattern();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData<EmploymentRecord>,
    private employmentService: EmploymentService,
    private dialogRef: MatDialogRef<EmploymentFormComponent>
  ) {}

  ngOnInit(): void {
    this.mode = this.dialogData.mode;
    this.employmentRecord = this.dialogData.model;
  }

  isValid() {
    return !isEmpty(this.employmentRecord?.employerName);
  }

  onSubmit() {
    this.busy = true;
    this.employmentService
      .save(this.employmentRecord)
      .pipe(take(1))
      .subscribe(
        (employment: FvWork) => {
          this.close({
            [this.mode]: employment,
            message: `Employment Record for ${this.employmentRecord.employerName} has been ${this.mode}d!`,
          });
        },
        (err) => (this.busy = false)
      );
  }

  // Close should only be called on a successful call to the api
  close(message: DialogCloseMessage<FvWork>) {
    this.dialogRef.close(message);
  }
}
