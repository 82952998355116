import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { ForeignVisitorsModule } from '@app/foreign-visitors/foreign-visitors.module';
import { ForeignAccessRequestModule } from '@app/foreign-access-requests/foreign-access-request.module';
import { LocationsModule } from '@app/locations/locations.module';
import { AdminModule } from '@app/admin/admin.module';
import { RouterModule } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { InsightsComponent } from '@app/home/insights/insights.component';
import { WorkspaceDashboardComponent } from '@app/home/workspace-dashboard/workspace-dashboard.component';
import { SplashPageComponent } from './splash-page/splash-page.component';
import { FvWorkspaceComponent } from './workspace-dashboard/fv-workspace/fv-workspace.component';
import { FarWorkspaceComponent } from './workspace-dashboard/far-workspace/far-workspace.component';
import { LocationWorkspaceComponent } from './workspace-dashboard/location-workspace/location-workspace.component';
import { AccountsWorkspaceComponent } from './workspace-dashboard/accounts-workspace/accounts-workspace.component';
@NgModule({
  imports: [
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    ForeignVisitorsModule,
    ForeignAccessRequestModule,
    LocationsModule,
    AdminModule,
    RouterModule,
  ],
  declarations: [
    InsightsComponent,
    WorkspaceDashboardComponent,
    SplashPageComponent,
    FvWorkspaceComponent,
    FarWorkspaceComponent,
    LocationWorkspaceComponent,
    AccountsWorkspaceComponent,
    LandingPageComponent,
  ],
  exports: [
    InsightsComponent,
    WorkspaceDashboardComponent,
    SplashPageComponent,
    LandingPageComponent,
  ],
})
export class HomePageModule {}
