import { Component, Input, ViewEncapsulation } from '@angular/core';
import { User } from '@shared/models';

const hRange = [0, 360];
const sRange = [50, 75];
const lRange = [25, 60];

function hashString(value: string) {
  return value
    .split('')
    .map((letter) => letter.charCodeAt(0))
    .reduce((previous, current) => previous + current);
}

function normalizeHash(hash: number, min: number, max: number) {
  return Math.floor((hash % (max - min)) + min);
}

function stringToHslColor(value: string) {
  const hash = hashString(value);

  const h = normalizeHash(hash, hRange[0], hRange[1]);
  const s = normalizeHash(hash, sRange[0], sRange[1]);
  const l = normalizeHash(hash, lRange[0], lRange[1]);

  return `hsl(${h}, ${s}%, ${l}%)`;
}

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserAvatarComponent {
  _user: User;
  @Input()
  set user(_user: User) {
    this._user = _user;
    this.calcAvatarDisplay();
  }

  get user(): User {
    return this._user;
  }

  @Input() size = 32;

  avatarText: string | null;
  avatarColor: string | null;

  calcAvatarDisplay() {
    if (!this.user) {
      this.avatarText = null;
      this.avatarColor = null;
      return;
    }

    if (this.user.givenName && this.user.surname) {
      this.avatarText =
        `${this.user.givenName[0]}${this.user.surname[0]}`.toUpperCase();

      this.avatarColor = stringToHslColor(this.user.id as string);
    }
  }
}
