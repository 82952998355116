import { Component, Input } from '@angular/core';
import { BaseControlComponent } from '@app/shared/controls/base-control.component';
import { FAR } from '@app/shared/models';

@Component({
  selector: 'app-far-review',
  templateUrl: './far-review.component.html',
  styleUrls: ['./far-review.component.scss'],
})
export class FarReviewComponent extends BaseControlComponent<FAR> {
  @Input() reviewFAR: FAR = {};

  @Input() formInvalid?: boolean = true;

  constructor() {
    super();
  }
}
