import { Component, ViewChild } from '@angular/core';
import { NgForm, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { BaseControlComponent } from '@app/shared/controls/base-control.component';
import { FvSocialMedia } from '@app/shared/models';

@Component({
  selector: 'app-social-media-form',
  templateUrl: './social-media-form.component.html',
  styleUrls: ['./social-media-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SocialMediaFormComponent,
      multi: true,
    },
  ],
})
export class SocialMediaFormComponent extends BaseControlComponent<FvSocialMedia> {
  @ViewChild('socialMediaForm')
  socialMediaForm: NgForm;
  appearance: MatFormFieldAppearance = 'outline';
  types = ['FaceBook', 'Instagram', 'LinkedIn', 'TikTok', 'Twitter', 'Youtube'];
  constructor() {
    super();
  }
}
