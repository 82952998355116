import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  MonitoringService,
  MonitorAlert,
  ReplicationMetrics,
  MonitorAlertType,
} from '@app/monitoring/monitoring.service';
import { combineLatestWith, Subject, takeUntil } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  alerts: MonitorAlert[];
  replicationMetrics: ReplicationMetrics;
  loading = true;

  constructor(private monitoringService: MonitoringService) {
    this.refresh();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  refresh() {
    this.loading = true;

    this.monitoringService
      .getAlerts()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        combineLatestWith(this.monitoringService.getReplicationMetrics()),
        tap(() => (this.loading = false))
      )
      .subscribe(([alerts, replicationMetrics]) => {
        this.alerts = alerts;
        this.replicationMetrics = replicationMetrics;
      });
  }

  protected readonly MonitorAlertType = MonitorAlertType;
}
