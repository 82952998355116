import { Pipe, PipeTransform } from '@angular/core';
import { FarLocation, OrgLocation, Address } from '../models';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'farLocation',
})
export class FarLocationPipe implements PipeTransform {
  datePipe = new DatePipe('en-US');

  transform(
    location: FarLocation,
    fieldType: string
  ): string | null | undefined {
    return location ? this.returnContent(location, fieldType) : null;
  }

  returnContent(
    itineraryItem: FarLocation,
    fieldType: string
  ): string | undefined {
    let itineraryLocation: OrgLocation;
    if (itineraryItem.organizationLocation) {
      itineraryLocation = itineraryItem.organizationLocation;
      if (fieldType == 'cityState') {
        if (itineraryLocation.address) {
          return this.buildCityStateDisplay(itineraryLocation.address);
        }
      }
      if (fieldType == 'buildingName') {
        return itineraryLocation?.name;
      }
      if (fieldType == 'toolTip') {
        return this.buildTooltip(itineraryLocation);
      }
    }
    if (fieldType == 'visitDate') return this.buildVisitDate(itineraryItem);
  }

  buildCityStateDisplay(location: Address): string {
    let result: string = '';
    if (location.city) result += location.city;
    if (location.stateProvince) {
      if (location.city) result += ', ';
      result += location.stateProvince;
    }
    return `${result}`;
  }

  buildTooltip(location: OrgLocation): string {
    let result: string = '';
    if (location.name) result += location.name;
    if (location.address) {
      if (location.address.city || location.address.stateProvince)
        result += ': ' + this.buildCityStateDisplay(location.address);
    }
    return result;
  }

  buildVisitDate(visit: FarLocation): string {
    let result: string = '';
    if (visit.startDate) {
      result += this.datePipe.transform(visit.startDate);
      if (visit.endDate) {
        result += ' - ' + this.datePipe.transform(visit.endDate);
      }
    } else {
      return 'N/A';
    }
    return result;
  }
}
