<form #visaForm="ngForm" id="visa" *ngIf="value" class="mt-2">
  <div class="row">
    <div class="col-6">
      <!-- Visa Number -->
      <mat-form-field [appearance]="appearance">
        <mat-label>Visa Number</mat-label>
        <input
          id="visaNumberControl"
          matInput
          [required]="hasVisaData()"
          #visaNumberControl="ngModel"
          [(ngModel)]="value.number"
          (ngModelChange)="emitChangeEvent()"
          name="visaNumberControl"
          aria-label="visa number"
          placeholder="Enter Visa Number"
          maxlength="25"
        />
      </mat-form-field>
    </div>
    <div class="col-6">
      <!-- Visa Issue Location -->
      <mat-form-field [appearance]="appearance">
        <mat-label>Visa Issue Location</mat-label>
        <input
          id="issueLocation"
          matInput
          #issueLocationControl="ngModel"
          [(ngModel)]="value.issueLocation"
          (ngModelChange)="emitChangeEvent()"
          name="issueLocation"
          maxlength="40"
          aria-label="visa issue location"
          placeholder="Enter Visa Issue Location"
        />
      </mat-form-field>
    </div>
    <div class="col-6">
      <!-- Visa Type -->
      <mat-form-field [appearance]="appearance">
        <mat-label>Visa Type</mat-label>
        <mat-select
          id="type"
          #visaType
          (ngModelChange)="emitChangeEvent()"
          [(ngModel)]="value.type"
          name="type"
          aria-label="visa value"
        >
          <mat-option selected [value]="null">Choose Visa Type</mat-option>
          <mat-option *ngFor="let type of visaTypes" value="{{ type }}">{{
            type
          }}</mat-option>
        </mat-select>
        <mat-error> Please select a visa type</mat-error>
      </mat-form-field>
    </div>
    <div class="col-6">
      <!-- Visa Expiration Date -->
      <mat-form-field [appearance]="appearance">
        <mat-label>Visa Expiration Date</mat-label>
        <input
          matInput
          #expirationDateControl="ngModel"
          [matDatepicker]="visaExpirationDatePicker"
          [(ngModel)]="value.expirationDate"
          (ngModelChange)="emitChangeEvent()"
          name="expirationDate"
          aria-label="visa expiration date"
          id="expirationDate"
          placeholder="MM/DD/YYYY"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="visaExpirationDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #visaExpirationDatePicker></mat-datepicker>
        <mat-error>Please select a valid date</mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
