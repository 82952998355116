import { ViewChild, Component, Input } from '@angular/core';
import { Education } from '@app/shared/models';
import { NgForm } from '@angular/forms';
import { hasValue, hasValues } from '@app/shared/helpers/has-values';

@Component({
  selector: 'app-fv-education-form',
  templateUrl: './fv-education.component.html',
  styleUrls: ['./fv-education.component.scss'],
})
export class FvEducationFormComponent {
  @ViewChild('fvEducationForm') fvEducationForm: NgForm;
  @Input() education: Education;

  constructor() {}

  get valid(): boolean | null {
    return this.fvEducationForm?.valid && this.hasValues();
  }

  hasValues(): boolean {
    return (
      hasValue(this.education.educationDates[0].startDate) ||
      hasValue(this.education.educationDates[0].endDate) ||
      hasValue(this.education.schoolName) ||
      hasValue(this.education.degree) ||
      hasValue(this.education.campusLocation)
    );
  }
}
