import {
  Component,
  Input,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Address, FAR, FarLocation, FarPurposeType } from '@app/shared/models';
import { MatDialog } from '@angular/material/dialog';
import { ItineraryDialogComponent } from '@shared/components/itinerary-control/itinerary-dialog.component';
import { FarLocationService, AlertService } from '@app/shared/services';

@Component({
  selector: 'app-far-itinerary',
  templateUrl: './far-itinerary.component.html',
  styleUrls: ['./far-itinerary.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FarItineraryComponent {
  @Input() far: FAR;
  FarPurposeType = FarPurposeType;

  constructor(
    private dialog: MatDialog,
    private farLocationService: FarLocationService,
    private alerts: AlertService
  ) {}

  onCreate() {
    this.openDialog({ foreignAccessRequest: { id: this.far.id } });
  }

  onUpdate(farLocation: FarLocation) {
    this.openDialog({ ...farLocation });
  }

  onDelete(id: string) {
    this.alerts.confirmDelete({
      title: 'Delete Itinerary Item',
      message: 'Are you sure you would you like to delete this item?',
      performAction: () => this.farLocationService.delete(id),
    });
  }

  openDialog(location: FarLocation) {
    let locationRequired = this.checkLocationRequirement();
    return this.dialog.open(ItineraryDialogComponent, {
      data: {
        locationRequired: locationRequired,
        location: location,
        farOwningOrgId: this.far.ownerOrganizationId,
      },
      width: '600px',
    });
  }

  generateTooltip(): string {
    if (this.far.purposeType) return 'Add Itinerary Item';
    else return 'Please Select a Purpose Type';
  }

  generateEditTooltip(): string {
    if (this.far.purposeType) return 'Edit Itinerary Item';
    else return 'Please Select a Purpose Type';
  }

  checkLocationRequirement() {
    if (
      this.far.purposeType === FarPurposeType.FACILITY_ACCESS ||
      this.far.purposeType === FarPurposeType.IN_PERSON_MEETING
    ) {
      return true;
    } else if (
      this.far.purposeType === FarPurposeType.OTHER ||
      this.far.purposeType === FarPurposeType.SYSTEM_ACCESS ||
      this.far.purposeType === FarPurposeType.VIRTUAL_MEETING
    ) {
      return false;
    }
  }

  isAddress(location?: Address) {
    if (!location) return false;
    if (
      !location.line1 &&
      !location.line2 &&
      !location.city &&
      !location.stateProvince &&
      !location.countryCode &&
      !location.postalCode
    )
      return false;
    return true;
  }
}
