<mat-toolbar class="justify-content-between bg-white metrics-toolbar">
  <h1 style="font: 400 24px / 32px Roboto, sans-serif">Metrics</h1>
  <button
    mat-icon-button
    color="primary"
    matTooltip="Select Components"
    #componentTrigger="matMenuTrigger"
    [matMenuTriggerFor]="componentMenu"
    (click)="$event.stopPropagation()"
  >
    <div class="d-flex align-items-center gap-1">
      <mat-icon svgIcon="cog"></mat-icon>
    </div>
  </button>
</mat-toolbar>
<div class="metrics-body">
  <div class="container">
    <app-metrics-insights
      [hidden]="!displayDataList[0].checked"
    ></app-metrics-insights>
    <app-graph-container #graphContainer></app-graph-container>
  </div>
</div>

<mat-menu #componentMenu class="columnCard">
  <div class="columnHidingContainer">
    <h5 class="columnHidingHeader">Component Selection</h5>
    <div>
      <mat-checkbox
        class="w-100"
        *ngFor="let item of displayDataList"
        [(ngModel)]="item.checked"
        (click)="toggleComponent(item); $event.stopPropagation()"
        >{{ item.label }}</mat-checkbox
      >
    </div>
  </div>
  <div class="columnHidingFooter d-flex justify-content-between mt-2">
    <button
      class="ml-5"
      mat-button
      (click)="toggleAll(true); $event.stopPropagation()"
    >
      Show All
    </button>
    <button
      class="mr-5"
      mat-button
      (click)="toggleAll(false); $event.stopPropagation()"
    >
      Hide All
    </button>
  </div>
</mat-menu>
