import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Crud, CrudService } from '@shared/services/crud.service';
import { ContactEmail } from '../models';

@Injectable({ providedIn: 'root' })
@Crud({
  apiUrl: `${environment.apiUrl}/foreignVisitorEmail`,
  hasIdPathUpdate: false,
  entity: 'Foreign National Email',
})
export class ForeignVisitorEmailService extends CrudService<ContactEmail> {
  constructor(http: HttpClient) {
    super(http);
  }

  organizeEmails(emails: ContactEmail[]) {
    return emails.sort((a: ContactEmail, b: ContactEmail) => {
      return -`${b.lastModifiedDate}`.localeCompare(`${a.lastModifiedDate}`);
    });
  }
}
