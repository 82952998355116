import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

export interface ReplicationMetrics {
  lastEventIdSent: number;
  lastEventSentDate: Date;
  lastScreeningReceivedDate: Date;
  lastEventIdProcessed: number;
  lastEventProcessedDate: Date;
  lastScreeningProcessedDate: Date;
}

export enum MonitorAlertType {
  FAILED_TRANSACTION = 'FAILED_TRANSACTION',
  SCREENINGS_DELAYED = 'SCREENINGS_DELAYED',
  MISSING_TRANSACTION = 'MISSING_TRANSACTION',
}

export interface MonitorAlert {
  level: 'INFO' | 'WARN' | 'ERROR';
  type: MonitorAlertType;
  message: string;
  details: string;
}

@Injectable({ providedIn: 'root' })
export class MonitoringService {
  apiUrl = `${environment.apiUrl}/monitoring`;

  constructor(private http: HttpClient) {}
  public getReplicationMetrics(): Observable<ReplicationMetrics> {
    return this.http.get<ReplicationMetrics>(
      `${this.apiUrl}/replicationMetrics`
    );
  }

  public getAlerts(): Observable<MonitorAlert[]> {
    return this.http.get<MonitorAlert[]>(`${this.apiUrl}/alerts`);
  }
}
