import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ForeignVisitor } from '@app/shared/models/foreign-visitor.model';
import { ScreeningDrawerObservable } from './screening-drawer-observable.service';
import { debounceTime, take, takeUntil, tap } from 'rxjs/operators';
import { FilterParams, UserPreferenceBlob } from '@app/shared/models';
import { CrudService, FvService, Repository } from '@app/shared/services';
import { AuthService } from '@app/auth/auth.service';
import { UserPreferenceService } from '@app/shared/services/user-preference.service';
import {
  ScreeningFieldLabels,
  fvColumns,
} from './collection/screening-table/screening-table.component';

@Component({
  selector: 'app-screening-review',
  templateUrl: 'screening.component.html',
  styleUrls: ['screening.component.scss'],
  providers: [Repository, { provide: CrudService, useExisting: FvService }],
})
export class ScreeningComponent implements OnInit, OnDestroy {
  showHistory: boolean = false;
  loading: boolean = false;
  userPreference: UserPreferenceBlob | undefined;
  filterParams = new FilterParams({
    sortBy: 'latestScreening.requestedDate',
    pageSize: 20,
    pageNum: 0,
    screeningEligible: true,
  });

  fvs$ = this.repo.collection$.pipe(
    tap((_) => {
      this.loading = false;
    })
  );

  get queryParamsSnapshot() {
    return this.repo.getQueryParamsSnapshot();
  }

  private unSubscribe = new Subject<void>();

  constructor(
    private repo: Repository<ForeignVisitor>,
    private _ScreeningDrawerObservable: ScreeningDrawerObservable,
    private auth: AuthService,
    private userPreferenceService: UserPreferenceService
  ) {}

  ngOnInit(): void {
    Object.assign(this.filterParams, this.queryParamsSnapshot);
    this.onFilterParamsChange(this.filterParams);

    this._ScreeningDrawerObservable.ScreeningDrawerObservable$.pipe(
      takeUntil(this.unSubscribe)
    ).subscribe((data: any) => {
      if (data !== 'refresh') this.showHistory = data;
    });
    this.userPreferenceService
      .getCurrentUserId(this.auth?.getUser()?.id!)
      .pipe(take(1), debounceTime(1000))
      .subscribe(
        (result) => {
          if (result !== null && result !== undefined) {
            this.userPreference = JSON.parse(result.userPreferenceBlob);
            let tabDetails = this.userPreference?.tabs.at(2);
            let screenArray: Array<string> = [];
            tabDetails?.tabColumns.filter((x) => {
              if (x.selected === true) {
                screenArray.push(x.label);
              }
            });
            if (
              this.userPreference !== null &&
              this.userPreference !== undefined
            ) {
              this.userPreferenceService.loadDisplayColumns(
                ScreeningFieldLabels,
                screenArray,
                fvColumns,
                '',
                this.auth?.getUser()?.roles!,
                this.auth.getUser()?.networkDomain!
              );
            }
          }
        },
        (err) => {}
      );
  }

  ngOnDestroy() {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.navigateWithParams(filterParams);
  }
}
