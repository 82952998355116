<ng-container *ngIf="fv">
  <div class="d-flex justify-content-between">
    <h3 mat-dialog-title>Edit Foreign National</h3>
    <mat-slide-toggle
      class="p-2"
      [(ngModel)]="fv.vip"
      (click)="vipTouched = true"
      ><div class="mt-2">Expedited</div></mat-slide-toggle
    >
  </div>
  <mat-dialog-content data-cy="bio-form">
    <app-biographic-form
      #bioForm
      ngForm
      [(ngModel)]="fv"
      [appearance]="'outline'"
      name="biographic"
    ></app-biographic-form>
  </mat-dialog-content>
  <mat-dialog-actions data-cy="bio-dialog-buttons" class="justify-content-end">
    <button
      data-cy="cancel-bio-button"
      mat-button
      mat-dialog-close
      id="cancel-bio-button"
      color="primary"
    >
      Cancel
    </button>
    <button
      data-cy="submit-button"
      mat-raised-button
      color="primary"
      id="submit-button"
      (click)="onSubmit()"
      [disabled]="(bioForm.bioForm && !bioForm.bioForm.valid) || busy"
    >
      <span *ngIf="!busy">Submit</span>
      <mat-spinner
        class="mr-1 ml-1"
        *ngIf="busy"
        color="primary"
        diameter="35"
      ></mat-spinner>
    </button>
  </mat-dialog-actions>
</ng-container>
