import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ForeignVisitor } from '@app/shared/models';
import {
  AbstractControl,
  NgForm,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { BaseControlComponent } from '@app/shared/controls/base-control.component';
import { MatFormFieldAppearance } from '@angular/material/form-field';
// sets up the interface for the Alias
export interface Alias {
  name: string;
}
@Component({
  selector: 'app-biographic',
  templateUrl: './biographic.component.html',
  styleUrls: ['biographic.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: BiographicComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BiographicComponent),
      multi: true,
    },
  ],
})
export class BiographicComponent
  extends BaseControlComponent<ForeignVisitor>
  implements Validator
{
  @Input() activeFV?: ForeignVisitor;
  @ViewChild('bioForm')
  bioForm: NgForm;

  appearance: MatFormFieldAppearance = 'outline';
  gender: any = ['male', 'female', 'other'];
  today = new Date();
  value: ForeignVisitor = {};

  onValidationChange: any = () => {};

  constructor() {
    super();
  }

  addFVBio() {
    this.emitChangeEvent();
  }

  writeValue(_value: ForeignVisitor) {
    super.writeValue(_value);
    this.value = this.value || {};
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    return this.bioForm?.valid ? null : { fv: true };
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }
}
