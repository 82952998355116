import { ThemePalette } from '@angular/material/core';

export enum Role {
  sv_admin = 'SV-ADMIN',
  sv_analyst = 'SV-ANALYST',
  sv_help_desk = 'SV-HELP-DESK',
  sv_org_admin = 'SV-ORG-ADMIN',
  sv_org_user = 'SV-ORG-USER',
  sv_vetter = 'SV-VETTER',
}

export const Roles = Object.values(Role);

export const RoleLabels: { [key in Role]: string } = {
  [Role.sv_admin]: 'Admin',
  [Role.sv_analyst]: 'Analyst',
  [Role.sv_help_desk]: 'Help Desk',
  [Role.sv_org_admin]: 'Org Admin',
  [Role.sv_org_user]: 'Org User',
  [Role.sv_vetter]: 'Vetter',
};

export const RoleIcons: { [key in Role]: string } = {
  [Role.sv_admin]: 'account-supervisor-circle',
  [Role.sv_analyst]: 'account-circle',
  [Role.sv_help_desk]: 'account-supervisor-circle',
  [Role.sv_org_admin]: 'account-supervisor-circle',
  [Role.sv_org_user]: 'account-circle',
  [Role.sv_vetter]: 'account-circle',
};

export const RoleColors: { [key in Role]?: ThemePalette } = {
  [Role.sv_admin]: 'warn',
  [Role.sv_analyst]: 'accent',
  [Role.sv_help_desk]: 'warn',
  [Role.sv_org_admin]: 'warn',
  [Role.sv_org_user]: 'primary',
  [Role.sv_vetter]: 'accent',
};

export const RoleDescriptions: { [key in Role]: string } = {
  [Role.sv_admin]:
    'CITADEL Program Management Office (PMO) user authorized to manage accounts and view all screening data.',
  [Role.sv_analyst]:
    'CITADEL Program Management Office (PMO) user authorized to view all screening data for analysis purposes.',
  [Role.sv_help_desk]:
    'CITADEL Program Management Office (PMO) user authorized to perform help desk functions.',
  [Role.sv_org_admin]:
    'Department or agency administrative user authorized to create, activate, and deactivate users (Org Users and Org Admins) and manage Locations within their organization.',
  [Role.sv_org_user]:
    'Department or agency assigned user authorized to create and submit Foreign National (FN) profiles and Foreign Access Requests (FAR) for screening.',
  [Role.sv_vetter]:
    'CITADEL Program Management Office (PMO) user authorized to view all Foreign National (FN) profiles and Foreign Access Requests (FARs) for purpose of facilitating screening with FBI screening partner.',
};
