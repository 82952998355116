import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, IEnvironment } from '@environments/ienvironment';
import { isUndefined } from 'lodash';

export function AppConfigValue<K extends keyof IEnvironment>(key: K): any {
  return function (target: any, propertyKey: string): any {
    let defaultVal: IEnvironment[K] | undefined = target[propertyKey];

    Object.defineProperty(target, propertyKey, {
      get: function () {
        return AppConfigService.getInstance().get(
          key,
          defaultVal
        ) as IEnvironment[K];
      },

      set: function (val: IEnvironment[K] | undefined) {
        defaultVal = val;
      },
    });
  };
}

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private static instance: AppConfigService;
  constructor(@Inject(APP_CONFIG) private readonly config: IEnvironment) {
    AppConfigService.instance = this;
  }

  public static getInstance(): AppConfigService {
    if (!AppConfigService.instance) {
      throw new Error('AppConfigService not initialized');
    }
    return AppConfigService.instance;
  }

  public get<K extends keyof IEnvironment>(
    key: K,
    defaultVal?: IEnvironment[K]
  ): IEnvironment[K] | undefined {
    return isUndefined(this.config[key]) ? defaultVal : this.config[key];
  }

  public set<K extends keyof IEnvironment>(key: K, value: IEnvironment[K]) {
    this.config[key] = value;
  }
}
