<mat-nav-list class="pt-0">
  <mat-list-item
    style="border-bottom: 1px solid lightgrey"
    class="activity__item"
    *ngFor="let far of fars"
    [routerLink]="routerNavEnabled ? ['/far', far.id] : []"
    [id]="'go-to-far-' + far.id"
    [matTooltip]="routerNavEnabled ? 'To Foreign Access Request' : ''"
  >
    <mat-icon
      *ngIf="far && far.purposeType"
      class="text-primary"
      matListItemAvatar
      [svgIcon]="FarPurposeTypeIcons[far.purposeType]"
    ></mat-icon>

    <div matListItemTitle>
      <strong>
        {{ far?.eventTitle | shorten: 30:"..." }}
      </strong>
    </div>
    <div matListItemLine>
      {{ far.purposeType }}
    </div>

    <div matListItemMeta class="row d-flex h-100 align-items-center">
      <mat-icon
        class="mr-2"
        [matTooltip]="'Event Date'"
        color="primary"
        svgIcon="calendar"
      ></mat-icon>
      <dl class="mb-0">
        <dt>
          <span
            *ngIf="far.primaryFar as item"
            matTooltip="{{ item | farLocation: 'visitDate' | default }}"
          >
            <ng-container *ngIf="item | farLocation: 'visitDate' as visitDate">
              {{ visitDate | default }}
            </ng-container>
          </span>
          <span *ngIf="!far.primaryFar">N/A</span>
        </dt>
      </dl>
      <button mat-icon-button class="ml-3" *ngIf="routerNavEnabled">
        <mat-icon>open_in_new</mat-icon>
      </button>
    </div>
  </mat-list-item>
</mat-nav-list>
