import { Component, Input, OnInit } from '@angular/core';
import { ForeignVisitor } from '@app/shared/models';
import { AppConfigService } from '@app/shared/services';

@Component({
  selector: 'app-fv-view-info-banner',
  templateUrl: './fv-view-info-banner.component.html',
  styleUrls: ['./fv-view-info-banner.component.scss'],
})
export class FvViewInfoBannerComponent implements OnInit {
  @Input() fv?: ForeignVisitor;
  farScreeningEligibility: boolean = false;
  additionalDocumentsScreeningEligibility: boolean = false;

  constructor(private config: AppConfigService) {}

  ngOnInit(): void {
    this.farScreeningEligibility =
      this.config.get('farScreeningEligibility') || false;

    this.additionalDocumentsScreeningEligibility =
      this.config.get('additionalDocumentsScreeningEligibility') || false;
  }

  isPassportData() {
    if (!this.fv?.passports) return false;
    else if (this.fv.passports) {
      if (
        !this.fv.passports[0].issuingCountryCode ||
        !this.fv.passports[0].number
      ) {
        return false;
      }
    }
    return true;
  }

  isVisaData() {
    if (this.fv?.visas && this.fv.visas[0] && this.fv.visas[0].number)
      return true;
    return false;
  }

  isDiplomaticData() {
    if (
      this.fv?.diplomaticIds &&
      this.fv.diplomaticIds[0] &&
      this.fv.diplomaticIds[0].documentId
    )
      return true;
    return false;
  }

  isDocumentData() {
    return (
      this.isPassportData() || this.isVisaData() || this.isDiplomaticData()
    );
  }

  documentsCheck() {
    if (this.additionalDocumentsScreeningEligibility)
      return this.isDocumentData();
    else return this.isPassportData();
  }

  checkForActiveFar() {
    if (!this.farScreeningEligibility) return true;
    if (this.fv && this.fv?.foreignAccessRequests) {
      for (let visit of this.fv?.foreignAccessRequests) {
        if (visit.isActiveFar) return true;
      }
    }
    return false;
  }
}
