<form id="organizationForm" #organizationForm="ngForm">
  <mat-form-field [appearance]="appearance">
    <mat-label>Name</mat-label>
    <input
      matInput
      required
      [(ngModel)]="value.name"
      name="name"
      maxlength="150"
      (ngModelChange)="emitChangeEvent()"
      id="organization-name"
    />
    <mat-error>Organization Name is required</mat-error>
  </mat-form-field>
  <mat-form-field [appearance]="appearance">
    <mat-label>Short Name</mat-label>
    <input
      matInput
      required
      [(ngModel)]="value.shortName"
      name="shortName"
      maxlength="20"
      (ngModelChange)="emitChangeEvent()"
      id="organization-short-name"
    />
    <mat-error>Organization Short Name is required</mat-error>
  </mat-form-field>
  <app-organization-select
    class="admin__org__select"
    name="orgControl"
    [disabled]="!value.parentOrganization && !!value.subOrgCount"
    [excludeOrg]="value.id"
    [excludeChilds]="true"
    [label]="'Parent Organization'"
    [(ngModel)]="value.parentOrganization"
    (ngModelChange)="emitChangeEvent()"
  >
  </app-organization-select>
  <div>
    <mat-checkbox
      style="margin-top: -10px"
      id="restrictAccess"
      name="restrictAccess"
      color="primary"
      [(ngModel)]="value.restrictedAccess"
      >Restrict Access </mat-checkbox
    ><mat-icon
      class="ml-1 help-icon"
      matTooltip="Records owned by this Organization will be view restricted to Organization Users only"
      >help</mat-icon
    >
  </div>
</form>
