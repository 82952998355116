import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpEventType,
  HttpContextToken,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AlertService } from '../services';

export const ENTITY = new HttpContextToken<string | null>(() => null);
export const SUPPRESS_MESSAGE = new HttpContextToken<boolean>(() => false);

export const HTTPMethodMap = new Map([
  ['POST', 'created'],
  ['PUT', 'updated'],
  ['DELETE', 'deleted'],
]);

/**
 * Intercepts http responses when modifying an application entity.
 * Emits success alert as a `MatSnackBar` when POST, PUT, or DELETE methods are used on an entity.
 *
 * ## HttpContext
 *
 * ### ENTITY
 * Describes the entity being modified.
 * Type `string | null` with a default value of `null`.
 * If `request.context.get(ENTITY)` is falsy, then a message will not be emitted.
 *
 * ### SUPPRESS_MESSAGE
 * Describes wheather or not to emit a message.
 * Type `boolean` with a default value of `false`.
 * If request.context.get('SUPPRESS_MESSAGE') is truthy, then a message will not be emitted.
 *
 * ### Usage
 *
 * From within a service
 *
 * ```ts
 * // Set up an entity
 * this.http.post<any>(`${this.apiUrl}/${id}`, someModel, { context: new HttpContext().set(ENTITY, 'FAR')});
 *
 * // Supress a message
 * this.http.post<any>(`${this.apiUrl}/${id}`, someModel, { context: new HttpContext().set(SUPPRESS_MESSAGE, true)});
 * ```
 */
@Injectable()
export class SuccessMessageInterceptor implements HttpInterceptor {
  constructor(private alert: AlertService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const entity = request.context.get(ENTITY);
    const suppress = request.context.get(SUPPRESS_MESSAGE);
    const action = HTTPMethodMap.get(request.method);
    return next.handle(request).pipe(
      tap((event) => {
        if (!action || suppress || !entity) return;
        if (event.type === HttpEventType.Response) {
          this.alert.crudAlert(entity, action);
        }
      })
    );
  }
}
