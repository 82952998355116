import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Crud, CrudService } from '@shared/services/crud.service';
import { Visa } from '../models';

@Injectable({ providedIn: 'root' })
@Crud({ apiUrl: `${environment.apiUrl}/visas`, entity: 'Visa' })
export class VisaService extends CrudService<Visa> {
  constructor(http: HttpClient) {
    super(http);
  }
}
