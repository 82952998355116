import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterParams } from '@app/shared/models/filter-params.model';
import { ForeignVisitor } from '@app/shared/models/foreign-visitor.model';
import { PageableCollection } from '@app/shared/models/pageable-collection.model';
import { FvSorts } from '@app/shared/services';
import { SortSelect } from '@app/shared/components';

@Component({
  selector: 'app-screening-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
})
export class ScreeningCollectionComponent {
  @Input() loading: boolean = false;
  @Input() pageableCollection: PageableCollection<ForeignVisitor>;
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();

  FvSorts: SortSelect[] = FvSorts;

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParamsChange.emit(filterParams);
  }
}
