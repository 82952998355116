import { CustomNGXLoggerService } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@shared/services/app-config.services';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';

export function auditAppInitializerFactory(
  auditService: AuditService
): () => Promise<void> {
  return () => auditService.init();
}

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  constructor(
    private customLogger: CustomNGXLoggerService,
    private config: AppConfigService,
    private router: Router
  ) {}

  async init() {
    const logger = this.customLogger.getNewInstance({
      partialConfig: { context: 'AuditService' },
    });

    if (!this.config.get('auditUserActivity', false)) return;

    let prevEvent: NavigationEnd;
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((navEvent: NavigationEnd) => {
        logger.info(`User navigated to ${navEvent.url}`, {
          from: prevEvent,
          to: navEvent,
        });
        prevEvent = navEvent;
      });
  }
}
