import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { EmploymentRecord } from '../models';
import { Crud, CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
@Crud({
  apiUrl: `${environment.apiUrl}/employment`,
  hasIdPathUpdate: true,
  entity: 'Employment Record',
})
export class EmploymentService extends CrudService<EmploymentRecord> {
  constructor(http: HttpClient) {
    super(http);
  }
}
