<mat-toolbar>Foreign Access Request (FAR) Creation</mat-toolbar>
<form #farForm="ngForm" class="far-create-form container">
  <mat-card appearance="outlined">
    <mat-card-content>
      <app-classification-banner
        [classificationModel]="far"
      ></app-classification-banner>
      <mat-horizontal-stepper
        #stepper
        aria-label="Foreign Access Request (FAR) Tabs"
      >
        <ng-template matStepperIcon="edit">
          <mat-icon>done</mat-icon>
        </ng-template>
        <mat-step
          tabindex="1"
          ngModelGroup="farEventInfo"
          #farEventInfo="ngModelGroup"
          [stepControl]="farForm.controls.farEventInfo"
          aria-label="Far Event Info"
          [hasError]="!hasRequirements()"
          errorMessage="Please Review Event and Visit Information"
        >
          <ng-template matStepLabel>Event and Visit</ng-template>
          <div class="d-flex flex-row">
            <div class="col-6 d-flex flex-column">
              <h5>Basic Information</h5>
              <app-far-details-control
                #detailsControl
                [(ngModel)]="far"
                name="farDetails"
                [isFarCreation]="true"
                ngDefaultControl
              >
              </app-far-details-control>
            </div>
            <mat-divider [vertical]="true"></mat-divider>
            <div class="col-6 d-flex flex-column">
              <h5 class="mb-3 d-flex">Visit Details</h5>
              <app-itinerary-control
                #itineraryControl
                [owningFarId]="userOrg.id || ''"
                [(ngModel)]="location"
                name="foreignAccessRequestLocations"
                ngDefaultControl
              >
              </app-itinerary-control>
            </div>
          </div>
        </mat-step>
        <mat-step
          class="far-create-stepper"
          ngModelGroup="farFV"
          #farFV="ngModelGroup"
          [stepControl]="farForm.controls.farFV"
          tabindex="0"
          aria-label="Foreign Nationals and Sponsor"
          [hasError]="!hasSponsorRequirements()"
          errorMessage="Please Review Foreign Nationals and Sponsor"
        >
          <ng-template matStepLabel>Foreign Nationals and Sponsor</ng-template>
          <div class="d-flex flex-row">
            <div class="col-6 d-flex flex-column">
              <h5 class="mb-0">Available Foreign Nationals</h5>
              <form #form="ngForm" class="mt-3">
                <app-select-fv
                  [(ngModel)]="far.foreignVisitors"
                  name="fvsToAccociate"
                  [hideInputDisplay]="true"
                  [multiple]="true"
                  [clearable]="false"
                  [excludeById]="far.id"
                  ngDefaultControl
                ></app-select-fv>
              </form>
              <mat-list class="pt-0 px-0 items-selected">
                <ng-container *ngFor="let fv of far.foreignVisitors">
                  <app-fv-list-item
                    [item]="fv"
                    [showRemove]="true"
                    (removed)="onRemove($event)"
                    size="small"
                  ></app-fv-list-item>
                  <mat-divider></mat-divider>
                </ng-container>
              </mat-list>
            </div>
            <mat-divider [vertical]="true"></mat-divider>
            <div class="col-6 d-flex flex-column">
              <h5>FAR Sponsor Information</h5>
              <app-sponsor
                #sponsorControl
                [(ngModel)]="far.sponsor"
                name="sponsor"
                ngDefaultControl
              ></app-sponsor>
            </div>
          </div>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Review</ng-template>
          <app-far-review
            [reviewFAR]="far"
            [formInvalid]="isInvalid(farForm)"
          ></app-far-review>
        </mat-step>
      </mat-horizontal-stepper>
    </mat-card-content>
    <app-stepper-footer
      #stepperFooter
      [parentRoute]="'/fars'"
      [stepper]="stepper"
      [form]="farForm"
      [(classificationModel)]="far"
      (emitSubmit)="onSubmit()"
    >
    </app-stepper-footer>
  </mat-card>
</form>
<div class="d-flex m-5 justify-content-center" *ngIf="!far">
  <mat-spinner></mat-spinner>
</div>
