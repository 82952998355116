import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilterParams, ForeignVisitor } from '@app/shared/models';
import { FvService } from '@app/shared/services';

@Component({
  selector: 'app-usp-delete-dialog',
  templateUrl: './usp-delete-dialog.component.html',
  styleUrls: ['./usp-delete-dialog.component.scss'],
})
export class UspDeleteDialogComponent {
  isUsp: boolean = false;

  constructor(
    private fvService: FvService,
    private dialog: MatDialogRef<UspDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ForeignVisitor
  ) {}

  confirmDelete() {
    this.fvService
      .deleteUSP(this.data.id, new FilterParams({ isUsp: this.isUsp }))
      .subscribe(() => {
        this.dialog.close(true);
      });
  }
}
