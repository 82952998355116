<form *ngIf="value">
  <div class="container">
    <mat-dialog-content>
      <mat-form-field [appearance]="appearance">
        <mat-label>Account Type</mat-label>
        <mat-select
          name="type"
          id="type"
          [(ngModel)]="value.type"
          placeholder="Enter Social Media Platform"
          aria-label="social media platform"
        >
          <mat-option *ngFor="let item of types" [value]="item">
            {{ item | titlecase }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field [appearance]="appearance">
        <mat-label>Foreign National's Username</mat-label>
        <input
          matInput
          id="username"
          maxlength="40"
          name="username"
          placeholder="Enter FN's username"
          aria-label="social media username"
          [(ngModel)]="value.username"
        />
      </mat-form-field>
      <mat-form-field [appearance]="appearance">
        <mat-label>URL to Social Media</mat-label>
        <input
          matInput
          id="url"
          name="url"
          maxlength="2083"
          placeholder="Enter URL to Social Media"
          aria-label="social media url"
          [(ngModel)]="value.url"
        />
      </mat-form-field>
      <mat-form-field [appearance]="appearance">
        <mat-label>Social Media Notes</mat-label>
        <textarea
          matInput
          rows="3"
          id="notes"
          name="notes"
          maxlength="500"
          [(ngModel)]="value.notes"
        >
        </textarea>
      </mat-form-field>
    </mat-dialog-content>
  </div>
</form>
