import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { MatSortModule } from '@angular/material/sort';
import { GroupsRoutingModule } from './group-routing.module';
import { GroupCollectionTableComponent } from '../groups/collection/group-collection-table/group-collection-table.component';
import { GroupDialogComponent } from '../groups/collection/group-dialog/group-dialog.component';
import { GroupFormComponent } from '../groups/collection/group-form/group-form.component';
import { GroupsCollectionComponent } from '../groups/collection/collection.component';
import { GroupsComponent } from './groups/groups.component';
@NgModule({
  declarations: [
    GroupCollectionTableComponent,
    GroupDialogComponent,
    GroupFormComponent,
    GroupsComponent,
    GroupsCollectionComponent,
  ],
  imports: [
    SharedModule,
    GroupsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
  ],
  exports: [GroupsComponent, GroupsCollectionComponent],
})
export class GroupModule {}
