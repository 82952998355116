<mat-card-footer>
  <mat-toolbar>
    <div class="container">
      <div class="row">
        <div class="col">
          <!-- EXIT BUTTON -->
          <button mat-stroked-button [routerLink]="parentRoute">Exit</button>
        </div>
        <div class="col-auto">
          <!-- CLASSIFY BUTTON -->
          <button
            mat-flat-button
            type="button"
            class="mr-3"
            id="far-stepper-classify-btn"
            appClassify
            *appClassifyShow
            [(classificationModel)]="classificationModel"
            (classificationModelChange)="classificationModelChange.emit($event)"
            color="accent"
          >
            Classify
          </button>
          <!-- BACK BUTTON -->
          <button
            (click)="stepper.previous()"
            *ngIf="stepper.selectedIndex !== 0"
            mat-stroked-button
            color="primary"
            id="far-stepper-back-btn"
            class="mr-3"
          >
            <mat-icon>keyboard_arrow_left</mat-icon>
            Back
          </button>
          <!-- NEXT BUTTON -->
          <button
            (click)="stepper.next()"
            *ngIf="stepper.selectedIndex !== stepper.steps.length - 1"
            mat-stroked-button
            type="button"
            id="far-stepper-next-btn"
            color="primary"
          >
            Next
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
          <button
            id="far-stepper-save-btn"
            *ngIf="stepper.selectedIndex === stepper.steps.length - 1"
            mat-raised-button
            (click)="emitSubmit.emit(); busy = true"
            [disabled]="form?.invalid || busy"
            color="primary"
          >
            <span *ngIf="!busy">Submit</span>
            <mat-spinner
              class="mr-1 ml-1"
              *ngIf="busy"
              color="primary"
              diameter="35"
            ></mat-spinner>
          </button>
        </div>
      </div>
    </div>
  </mat-toolbar>
</mat-card-footer>
