<mat-card
  [routerLink]="[routerLink]"
  [class.insight-clickable]="clickable"
  style="height: 440px"
>
  <mat-card-header class="pt-0">
    <mat-card-title class="py-1">{{ title }}</mat-card-title></mat-card-header
  >
  <img class="px-2" mat-card-image [src]="imgSrc" [alt]="imgAlt" />
  <mat-card-content class="pt-2">{{ content }}</mat-card-content>
</mat-card>
