<div class="grid-collection">
  <app-screening-toolbar
    [sortFields]="FvSorts"
    [filterParams]="filterParams"
    (filterParamsChange)="onFilterParamChange($event)"
    [loading]="loading"
  >
    Screening Review - Foreign Nationals
  </app-screening-toolbar>
  <ng-container *ngIf="pageableCollection">
    <ng-container
      *ngIf="
        pageableCollection.content && pageableCollection.content.length;
        else noData
      "
    >
      <div class="grid-main">
        <div class="container">
          <app-screening-table
            [fvs]="pageableCollection.content"
            [filterParams]="filterParams"
            (filterParamsChange)="onFilterParamChange($event)"
          ></app-screening-table>
        </div>
      </div>
    </ng-container>
    <app-paginator
      [filterParams]="filterParams"
      (filterParamsChange)="onFilterParamChange($event)"
      [pageIndex]="pageableCollection.number"
      [pageSize]="pageableCollection.size"
      [totalElements]="pageableCollection.totalElements"
      [enableQuickFilters]="true"
      [screeningReview]="true"
    ></app-paginator>
  </ng-container>
  <ng-template #noData>
    <app-no-result-found class="no-results"></app-no-result-found>
  </ng-template>
</div>
