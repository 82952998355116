import { Component, OnInit, OnDestroy } from '@angular/core';
import { FarService, FvService, MetricsObservable } from '@app/shared/services';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-insights',
  templateUrl: 'insights.component.html',
  styleUrls: ['insights.component.scss'],
})
export class InsightsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();

  metrics: any = {}; //FVS Metrics Data from homepage.component
  ineligibleScreenings: number = 0;
  loading = false;

  constructor(
    private fvService: FvService,
    private farService: FarService,
    private _MetricsObservable: MetricsObservable
  ) {}

  ngOnInit() {
    this.getMetrics();

    this._MetricsObservable.MetricsObservable$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((data: any) => {
      this.getMetrics();
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getMetrics() {
    this.loading = true;
    forkJoin([this.fvService.getFvsMetrics(), this.farService.getFarMetrics()])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([fvMetrics, farMetrics]) => {
        this.metrics = fvMetrics;
        this.metrics.farCount = farMetrics['totalCount'];
        this.loading = false;
      });
  }

  refresh() {
    this.getMetrics();
  }
}
