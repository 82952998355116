import { Pipe, PipeTransform } from '@angular/core';
import { isNaN } from 'lodash';

export function isDate(value: any): Boolean {
  return value instanceof Date && !isNaN(value.valueOf());
}

@Pipe({
  name: 'isDateBefore',
})
export class IsDateBeforePipe implements PipeTransform {
  transform(
    value: Date | string,
    compareTo: Date | string = new Date()
  ): boolean {
    const valDate = new Date(value);
    const compareDate = new Date(compareTo);
    if (!isDate(valDate) || !isDate(compareDate)) return false;

    return valDate.valueOf() < compareDate.valueOf();
  }
}
