import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { AlertService, UserService } from '@shared/services';
import { AccountActionLabel, AccountStatus, User } from '@shared/models';
import { capitalize } from 'lodash';
import { tap } from 'rxjs/operators';

@Directive({
  selector: '[appToggleUserStatus]',
})
export class ToggleUserStatusDirective {
  @Input() appToggleUserStatus: User;

  @Output() userStatusUpdated = new EventEmitter<User>();

  constructor(private userService: UserService, private alert: AlertService) {}

  @HostListener('click', ['$event'])
  onClick() {
    const user: User = this.appToggleUserStatus;
    if (!user) return;

    const status: AccountStatus =
      user.accountStatus === AccountStatus.Inactive
        ? AccountStatus.Active
        : AccountStatus.Inactive;
    const label = AccountActionLabel[status];

    if (label && user?.id) {
      this.alert
        .confirm({
          title: `Confirm ${capitalize(label.action)} User`,
          message: `Are you sure you would like to ${label.action} ${user.givenName} ${user.surname}?`,
          performAction: () => this.userService.manageUser(user.id!, status),
          confirmText: capitalize(label.action),
          confirmColor: label.color,
          successMsg: `${user.givenName} ${user.surname} has been ${label.past}!`,
        })
        .subscribe((result) => this.userStatusUpdated.emit(result));
    }
  }
}
