import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FAR } from '@app/shared/models';
import { AlertService, FarService } from '@app/shared/services';
import { FarCommentService } from '@app/shared/services/comment.service';
import { take } from 'rxjs';
import { Comment } from '@app/shared/models/comment.model';
import { AuthService } from '@app/auth/auth.service';

@Component({
  selector: 'app-cancel-far-dialog',
  templateUrl: './cancel-far-dialog.component.html',
  styleUrls: ['./cancel-far-dialog.component.scss'],
})
export class CancelFarDialogComponent {
  busy: boolean = false;
  reason: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public far: FAR,
    private farService: FarService,
    private dialogRef: MatDialogRef<CancelFarDialogComponent>,
    private alertService: AlertService,
    private commentService: FarCommentService,
    private authService: AuthService
  ) {}

  onSubmit() {
    this.busy = true;
    let state = this.far.isCanceled ? 'Uncanceled' : 'Canceled';
    this.farService
      .changeCancelState(this.far.id, !this.far.isCanceled)
      .pipe(take(1))
      .subscribe(() => {
        let comment: Comment = {
          comment: state + ' reason: ' + this.reason,
          foreignAccessRequest: this.far,
          author: this.authService.getUser() || {},
        };

        this.commentService.save(comment).subscribe(() => {
          this.alertService.successAlert('FAR ' + state);
          this.dialogRef.close();
        });
      });
  }
}
