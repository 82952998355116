import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { BaseControlComponent } from '../base-control.component';
import {
  CommunicationContacts,
  DerogatoryInformation,
  OffenseCodes,
  RelationalCodes,
  TimeFrames,
  Verifications,
} from '@app/shared/models';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgForm,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Component({
  selector: 'app-screening-code-control',
  templateUrl: './screening-code-control.component.html',
  styleUrls: ['./screening-code-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ScreeningCodeControlComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ScreeningCodeControlComponent),
      multi: true,
    },
  ],
})
export class ScreeningCodeControlComponent
  extends BaseControlComponent<DerogatoryInformation>
  implements Validator, AfterContentChecked
{
  @ViewChild('derogCodeForm') derogCodeForm: NgForm;
  onValidationChange: any = () => {};
  offenseCodes = OffenseCodes;
  relationalCodes = RelationalCodes;
  communicationContacts = CommunicationContacts;
  timeFrames = TimeFrames;
  verificationCodes = Verifications;
  appearance: MatFormFieldAppearance = 'outline';

  constructor(private changeDetect: ChangeDetectorRef) {
    super();
  }

  ngAfterContentChecked(): void {
    this.changeDetect.detectChanges();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.derogCodeForm?.valid ? null : { derog: true };
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }
}
