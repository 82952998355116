<div class="row">
  <div class="col">
    <div class="changePassword">
      <h5 *ngIf="!(authFlow === AuthFlow.TEMPORARY_PASSWORD)">
        Change Password
      </h5>

      <form
        #formDirective="ngForm"
        [formGroup]="passwordForm"
        (ngSubmit)="onSubmit()"
      >
        <div>
          <!-- #region Required Attributes -->
          <div
            *ngIf="requiredAttributes.length"
            formGroupName="requiredAttributes"
          >
            <p>Please Add the following attributes</p>
            <p *ngFor="let attribute of requiredAttributes">
              <mat-form-field appearance="outline">
                <mat-label>{{ attribute }}</mat-label>
                <input matInput formControlName="{{ attribute }}" />
              </mat-form-field>
            </p>
          </div>

          <!-- #endregion Required Attributes -->

          <!-- #region Old Password -->
          <p *ngIf="authFlow === AuthFlow.CHANGE_PASSWORD">
            <mat-form-field appearance="outline">
              <mat-label>Current Password</mat-label>
              <input
                matInput
                [type]="hide.oldPassword ? 'password' : 'text'"
                placeholder="Enter Old Password"
                formControlName="oldPassword"
              />
              <mat-icon
                matSuffix
                (click)="hide.oldPassword = !hide.oldPassword"
                >{{
                  hide.oldPassword ? "visibility_off" : "visibility"
                }}</mat-icon
              >
            </mat-form-field>
          </p>
          <!-- #endregion Old Password -->

          <!-- #region New Password -->
          <p>
            <mat-form-field appearance="outline">
              <mat-label>New Password</mat-label>
              <input
                matInput
                [type]="hide.newPassword ? 'password' : 'text'"
                placeholder="Enter New Password"
                formControlName="newPassword"
              />
              <mat-icon
                matSuffix
                (click)="hide.newPassword = !hide.newPassword"
                >{{
                  hide.newPassword ? "visibility_off" : "visibility"
                }}</mat-icon
              >
              <!-- Dynamic Requirment Check -->
              <mat-hint>
                Password be at least
                <span
                  [ngClass]="
                    f['newPassword'].hasError('required') ||
                    f['newPassword'].hasError('minlength')
                      ? 'text-danger'
                      : 'text-success'
                  "
                >
                  12 characters long
                  <mat-icon
                    class="text-success"
                    *ngIf="
                      !f.newPassword.hasError('required') &&
                      !f.newPassword.hasError('minlength')
                    "
                    >check_circle</mat-icon
                  >
                </span>
                must contain at least one
                <span
                  [ngClass]="
                    f['newPassword'].hasError('required') ||
                    f['newPassword'].hasError('hasCapitalCase')
                      ? 'text-danger'
                      : 'text-success'
                  "
                >
                  uppercase,
                  <mat-icon
                    class="text-success"
                    *ngIf="
                      !f.newPassword.hasError('required') &&
                      !f.newPassword.hasError('hasCapitalCase')
                    "
                    >check_circle</mat-icon
                  >
                </span>
                one
                <span
                  [ngClass]="
                    f['newPassword'].hasError('required') ||
                    f['newPassword'].hasError('hasSmallCase')
                      ? 'text-danger'
                      : 'text-success'
                  "
                >
                  lowercase,
                  <mat-icon
                    class="text-success"
                    *ngIf="
                      !f.newPassword.hasError('required') &&
                      !f.newPassword.hasError('hasSmallCase')
                    "
                    >check_circle</mat-icon
                  >
                </span>
                one
                <span
                  [ngClass]="
                    f['newPassword'].hasError('required') ||
                    f['newPassword'].hasError('hasNumber')
                      ? 'text-danger'
                      : 'text-success'
                  "
                >
                  number,
                  <mat-icon
                    class="text-success"
                    *ngIf="
                      !f.newPassword.hasError('required') &&
                      !f.newPassword.hasError('hasNumber')
                    "
                    >check_circle</mat-icon
                  >
                </span>
                and one
                <span
                  [ngClass]="
                    f['newPassword'].hasError('required') ||
                    f['newPassword'].hasError('hasSpecialCharacter')
                      ? 'text-danger'
                      : 'text-success'
                  "
                >
                  special character
                  <mat-icon
                    class="text-success"
                    *ngIf="
                      !f.newPassword.hasError('required') &&
                      !f.newPassword.hasError('hasSpecialCharacter')
                    "
                    >check_circle</mat-icon
                  >
                </span>
              </mat-hint>
            </mat-form-field>
          </p>
          <!-- #endregion New Password -->

          <!-- #region Confirm Password -->
          <p *ngIf="!(authFlow === AuthFlow.TEMPORARY_PASSWORD)">
            <mat-form-field appearance="outline">
              <mat-label>Confirm Password</mat-label>
              <input
                matInput
                [type]="hide.confirmPassword ? 'password' : 'text'"
                placeholder="Placeholder"
                formControlName="confirmNewPassword"
              />
              <mat-icon
                matSuffix
                (click)="hide.confirmPassword = !hide.confirmPassword"
              >
                {{ hide.confirmPassword ? "visibility_off" : "visibility" }}
              </mat-icon>
              <mat-error>Passwords Must Match</mat-error>
            </mat-form-field>
          </p>
          <!-- #endregion Confirm Password -->
        </div>

        <div
          *ngIf="!(authFlow === AuthFlow.TEMPORARY_PASSWORD)"
          class="d-flex justify-content-end"
        >
          <button
            mat-raised-button
            color="primary"
            [disabled]="passwordForm.invalid || (busy$ | async)"
          >
            <mat-spinner
              color="primary"
              *ngIf="busy$ | async; else changePw"
              [diameter]="36"
            ></mat-spinner>
            <ng-template #changePw>
              <ng-container>Change Password</ng-container>
            </ng-template>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
