import {
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UserService } from '@shared/services';
import {
  AuthEvent,
  AuthEventResponse,
  AuthSummary,
} from '@shared/models/auth-event.model';
import { AuthService } from '@app/auth/auth.service';
import { User } from '@shared/models';

@Directive({
  selector: '[appAuthEventsDialog]',
})
export class AuthEventsDialogDirective {
  @Input() authEventUser: User;
  @Output() appAuthEventsClosed = new EventEmitter<void>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    const dialog = AuthEventsDialogComponent.openDialog(this.dialog, {
      user: this.authEventUser,
    });
    dialog.afterClosed().subscribe(() => {
      return this.appAuthEventsClosed.emit();
    });
  }
}

@Component({
  selector: 'app-auth-events-dialog',
  templateUrl: './auth-events-dialog.component.html',
  styleUrls: ['./auth-events-dialog.component.scss'],
})
export class AuthEventsDialogComponent {
  AuthEventResponse = AuthEventResponse;

  user: User;

  summary: AuthSummary | null;

  events: AuthEvent[];

  displayedColumns = ['icon', 'type', 'createdDate', 'response', 'location'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AuthEventsDialogData,
    private auth: AuthService,
    private userService: UserService
  ) {
    this.user = data?.user as User;
    if (this.user?.id) {
      this.userService
        .getUsersAuthEvents(this.user.id)
        .subscribe((events) => (this.events = events));
    } else {
      this.auth.getAuthSummary().then((summary) => (this.summary = summary));

      this.userService
        .getAuthEvents()
        .subscribe((events) => (this.events = events));
    }
  }

  static openDialog(
    dialog: MatDialog,
    data: AuthEventsDialogData = {}
  ): MatDialogRef<AuthEventsDialogComponent> {
    return dialog.open<AuthEventsDialogComponent>(AuthEventsDialogComponent, {
      minWidth: '955px',
      data,
    });
  }
}

export interface AuthEventsDialogData {
  user?: User;
}
