import { Component, Injector, Input, OnInit } from '@angular/core';
import {
  Contact,
  ContactType,
  FilterParams,
  PageableCollection,
} from '@app/shared/models';
import { BaseMatSelectComponent } from '../base-mat-select.component';
import { Observable } from 'rxjs';
import { ContactService } from '@app/shared/services';
import { map, tap } from 'rxjs/operators';
import { BaseControlComponent } from '@shared/controls';

@Component({
  selector: 'contact-select',
  templateUrl: './contact-select.component.html',
  styleUrls: ['./contact-select.component.scss'],
})
export class ContactSelectComponent
  extends BaseControlComponent<string>
  implements OnInit
{
  @Input() orgId: string | undefined = '';

  baseFilterParams: FilterParams;

  filteredValues: Observable<Contact[]>;

  constructor(
    private contactService: ContactService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.baseFilterParams = new FilterParams({
      sortBy: 'fullName',
      direction: 'ASC',
      type: ContactType.ORG_ADMIN_APPROVER,
      organizationId: this.orgId,
    });
    this.refresh();
  }

  refresh() {
    this.filteredValues = this.searchItems(this.baseFilterParams).pipe(
      map((resp) => resp.content)
    );
  }

  searchItems(params: FilterParams): Observable<PageableCollection<Contact>> {
    return this.contactService.find(params);
  }

  newContactCreated(contact: Contact) {
    this.value = contact?.id!;
    this.refresh();
    this.emitChangeEvent();
  }

  protected readonly ContactType = ContactType;
}
