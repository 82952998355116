import { Component, OnInit } from '@angular/core';
import { Alert } from '@app/shared/models';
import { SystemAlert } from '@app/shared/models/system-alert';
import { SystemAlertsCrudService } from '@app/system-alerts/system-alerts-crud.service';
import { SessionStorage } from 'ngx-webstorage';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-active-alerts',
  templateUrl: './active-alerts.component.html',
  styleUrls: ['./active-alerts.component.scss'],
})
export class ActiveAlertsComponent implements OnInit {
  @SessionStorage()
  dismissedAlerts: string[];

  dismiss = new BehaviorSubject<string[]>([]);

  activeAlerts$ = this.systemAlertsService.changeEvent$.pipe(
    startWith(0),
    switchMap((_) => this.systemAlertsService.activeAlerts()),
    map((pagebleSystemAlerts) => {
      return pagebleSystemAlerts.content;
    }),
    tap((systemAlerts) => {
      this.removeInactiveDismissedAlerts(systemAlerts);
    })
  );

  /** shows system alerts which are not stored stored in local storage */
  displayedAlerts$ = combineLatest([this.activeAlerts$, this.dismiss]).pipe(
    map(([activeAlerts, dismissedAlerts]: [SystemAlert[], string[]]) => {
      return activeAlerts
        .filter((x) => !dismissedAlerts.includes(x.id))
        .slice(0, 3);
    })
  );

  constructor(private systemAlertsService: SystemAlertsCrudService) {}

  ngOnInit(): void {
    this.dismissedAlerts = this.dismissedAlerts || [];
    this.dismiss.next(this.dismissedAlerts);
  }

  onClose(systemAlert: string) {
    if (this.dismissedAlerts?.includes(systemAlert)) return;

    this.dismissedAlerts.push(systemAlert);
    this.dismissedAlerts = [...this.dismissedAlerts];
    this.dismiss.next(this.dismissedAlerts);
  }

  removeInactiveDismissedAlerts(systemAlerts: SystemAlert[]) {
    this.dismissedAlerts = this.dismissedAlerts.filter((dismissedAlert) =>
      systemAlerts.map((x) => x.id).includes(dismissedAlert)
    );
  }
}
