import { Component, Input } from '@angular/core';
import {
  Screening,
  ScreeningResult,
  ScreeningStatusLabels,
} from '@app/shared/models';
import { ForeignVisitorScreeningRollup } from '@app/shared/models/foreignVisitorScreeningRollup';

@Component({
  selector: 'app-screening-indicator',
  templateUrl: './screening-indicator.component.html',
  styleUrls: ['./screening-indicator.component.scss'],
})
export class ScreeningIndicatorComponent {
  ScreeningStatusLabels = ScreeningStatusLabels;

  @Input() rollup?: ForeignVisitorScreeningRollup | null = null;
  @Input() screening?: Screening | null = null;
  @Input() display: 'rollup' | 'status' | 'result';
  @Input() showStatusIcon: boolean = true;
  @Input() hasMenu: boolean = true;
  @Input() showTooltip: boolean = true;
  @Input() showResultText: boolean = true;
  @Input() showStatusText: boolean = true;
  @Input() iconSize: 'normal' | 'large' = 'normal';

  getResult(result: ScreeningResult | undefined) {
    if (result === 'MACHINE_GREEN' || result === 'REVIEWED_GREEN') {
      return 'GREEN';
    }

    if (result === 'MACHINE_RED') {
      return 'MACHINE RED';
    }

    if (result === 'REVIEWED_RED_WITH_DEROG') {
      return 'RED';
    }

    if (result === 'REVIEWED_RESTRICTED') {
      return 'USP';
    }
    return result;
  }
}
