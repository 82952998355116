import { Component, Input } from '@angular/core';
import { ScreeningRollupDetail } from '@app/shared/models';

@Component({
  selector: 'app-screening-fab-button',
  templateUrl: './screening-fab-button.component.html',
  styleUrls: ['./screening-fab-button.component.scss'],
})
export class ScreeningFabButtonComponent {
  @Input() item?: ScreeningRollupDetail;
  @Input() customTooltip: string = '';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
}
