<div class="container">
  <div class="row mb-3">
    <div class="col-4 mt-3">
      <landing-page-card
        [title]="'Foreign Nationals (FN)'"
        [content]="
          'Create and Track Data and Screening Status on Any Foreign Nationals'
        "
        [imgSrc]="'./../../../assets/images/FV.png'"
        [routerLink]="'fvs'"
        [clickable]="true"
      ></landing-page-card>
    </div>
    <div class="col-4 mt-3">
      <landing-page-card
        [title]="'Foreign Access Requests (FAR)'"
        [content]="
          'Create and Track Visit Requests for Government Facilites in which any Foreign Nationals may visit'
        "
        [imgSrc]="'./../../../assets/images/FAR.png'"
        [routerLink]="'fars'"
        [clickable]="true"
      ></landing-page-card>
    </div>
    <div class="col-4 mt-3" *appIfRoles="Role.sv_vetter">
      <landing-page-card
        [title]="'Screening Review'"
        [content]="vetterContent"
        [imgSrc]="'./../../../assets/images/Screening.png'"
        [routerLink]="'screening'"
        [clickable]="true"
      ></landing-page-card>
    </div>
    <div
      class="col-4 mt-3"
      *appIfRoles="[Role.sv_org_admin, Role.sv_admin, Role.sv_org_user]"
    >
      <landing-page-card
        [title]="'Locations'"
        [content]="'Create and Track Location Data of Government Facilites'"
        [imgSrc]="'./../../../assets/images/Locations.png'"
        [routerLink]="'locations'"
        [clickable]="true"
      ></landing-page-card>
    </div>
    <div class="col-4 mt-3" *appIfRoles="[Role.sv_admin]">
      <landing-page-card
        [title]="'System Alerts'"
        [content]="
          '(Admin Only) Create and Update System Wide messages used to inform the User Community'
        "
        [imgSrc]="'./../../../assets/images/Alerts.png'"
        [routerLink]="'systemalerts'"
        [clickable]="true"
      ></landing-page-card>
    </div>
    <div class="col-4 mt-3" *appIfRoles="[Role.sv_admin, Role.sv_org_admin]">
      <landing-page-card
        [title]="'User Administration'"
        [content]="userAdminContent"
        [imgSrc]="'./../../../assets/images/Users.png'"
        [routerLink]="'admin'"
        [clickable]="true"
      ></landing-page-card>
    </div>
  </div>
</div>
