import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { TooltipPosition } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import {
  ColumnChooserComponent,
  ConfirmDialogComponent,
} from '@app/shared/components';
import { ColumnChooserEventObservable } from '@app/shared/components/column-chooser/column-chooser-observable.service';
import {
  FilterParams,
  OrgLocation,
  SavedUserPreference,
  UserPreference,
  UserPreferenceBlob,
} from '@app/shared/models';
import {
  FarViewRoles,
  LocViewGeoCodeRoles,
} from '@app/shared/models/role-permissions';
import { UserPreferenceService } from '@app/shared/services/user-preference.service';
import {
  AlertService,
  AppConfigService,
  OrgLocationService,
  OrgLocationSortField,
  OrgLocationSortLabels,
} from '@shared/services';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

export const LocationTableColumns: string[] = [
  OrgLocationSortField.NAME,
  OrgLocationSortField.ADDRESS,
  OrgLocationSortField.CITY_STATE_PROVINCE,
  OrgLocationSortField.POSTAL_CODE,
  OrgLocationSortField.COUNTRY,
  OrgLocationSortField.ORG,
  OrgLocationSortField.NETWORK,
  OrgLocationSortField.FAR_COUNT,
  'actions',
];

export const LocationTableName = 'locationTable';

@Component({
  selector: 'app-location-collection-table',
  templateUrl: './location-collection-table.component.html',
  styleUrls: ['./location-collection-table.component.scss'],
})
export class LocationCollectionTableComponent implements OnInit, OnDestroy {
  @Input() locations: OrgLocation[] = [];
  @Input() filterParams: FilterParams;
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  position: TooltipPosition = 'right';
  deletionMessageCheckEnabled?: boolean = true;
  canViewFAR: boolean = false;
  user = inject(AuthService);
  private ngUnsubscribe = new Subject<void>();

  OrgLocationSortField = OrgLocationSortField;
  OrgLocationSortLabels = OrgLocationSortLabels;

  locationColumns = LocationTableColumns;

  locationColumnsDisplay: { [key: string]: boolean } =
    this.locationColumns.reduce(
      (val, col) => ({ [col]: true, ...val }),
      {} as { [key: string]: boolean }
    );

  LocViewGeoCodeRoles = LocViewGeoCodeRoles;

  constructor(
    public dialog: MatDialog,
    public locationService: OrgLocationService,
    public alert: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private _ColumnChooserEventObservable: ColumnChooserEventObservable,
    private config: AppConfigService,
    private userPreferenceService: UserPreferenceService,
    private authService: AuthService
  ) {
    let lowToggle = this.config.get('lowEnvFeatures', true);
    this.deletionMessageCheckEnabled = lowToggle;
  }

  ngOnInit(): void {
    const userPrefs: UserPreference = this.userPreferenceService.userPreference$
      .value as UserPreference;
    if (!userPrefs) return;
    const userPrefsBlob: UserPreferenceBlob = JSON.parse(
      userPrefs.userPreferenceBlob
    );
    let locationArray: Array<string> = [];
    let tabDetails = userPrefsBlob.tabs.at(3);
    tabDetails?.tabColumns.filter((x) => {
      if (x.selected === true) {
        locationArray.push(x.label);
      }
    });
    this.userPreferenceService.loadDisplayColumns(
      OrgLocationSortLabels,
      locationArray,
      LocationTableColumns,
      LocationTableName,
      this.authService?.getUser()?.roles!,
      this.authService.getUser()?.networkDomain!
    );

    const savedUserPreferences: SavedUserPreference =
      this.userPreferenceService.userPreferenceDisplaySubject$.value;
    if (
      savedUserPreferences.hasPreference &&
      savedUserPreferences.tableName === LocationTableName
    ) {
      this.locationColumns = savedUserPreferences.columnNames!;
      this.locationColumnsDisplay = savedUserPreferences.selectedColumns!;
    }
    this._ColumnChooserEventObservable.ColumnChooserObservable$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((event) => {
      if (event.tableName === LocationTableName) {
        this.locationColumnsDisplay = ColumnChooserComponent.updateTableDisplay(
          event,
          this.locationColumns,
          this.locationColumnsDisplay
        );
      }
    });

    const userRoles = this.user.getRoles();
    let roleFound = userRoles.find((r) => FarViewRoles.includes(r)) || false;
    if (roleFound) this.canViewFAR = true;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onEdited(result: OrgLocation) {
    const location = this.locations.find((l) => l.id === result.id);

    if (location) {
      Object.assign(location, result);
    }
  }

  onSortChange(sort: Sort) {
    this.filterParams.sortBy = sort.active;
    this.filterParams.direction = sort.direction.toUpperCase();
    this.filterParamsChange.emit(this.filterParams);
  }

  deleteLocation(id?: string) {
    if (this.deletionMessageCheckEnabled) {
      let data = {
        icon: 'alert-octagon',
        color: 'warn',
        title: `STOP`,
        message: `Before confirming the deletion of the record, make sure this record still exists on the high side.  If the record does not exist on the high side, cancel this action.  Deletion of this record without it existing on the high side will cause replication to fail.`,
        performAction: () => this.confirmLocationDelete(id),
        confirmText: 'Confirm',
      };

      return this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '600px',
        data,
      });
    } else {
      this.confirmLocationDelete(id);
    }
  }

  confirmLocationDelete(id?: string) {
    this.alert
      .confirmDelete({
        title: `Confirm Delete Location`,
        message: `Are you sure you want to delete this location?`,
        performAction: () => this.locationService.delete(id),
      })
      .pipe(take(1))
      .subscribe(() => {
        this.filterParamsChange.emit(this.filterParams);
      });
  }

  navigateToFARs(
    count: any,
    destination: string,
    queryField?: string,
    queryParam?: string | boolean
  ) {
    if (count) {
      let filter: any = {};

      if (this.router.url === '/') destination = 'fars';

      if (queryField) {
        filter[queryField] = [queryParam];
        this.router.navigate([destination], {
          relativeTo: this.route,
          queryParams: filter,
        });
      } else {
        this.router.navigate([destination], {
          relativeTo: this.route,
        });
      }
    }
  }
}
