<div class="far-review-container d-flex flex-row" *ngIf="!formInvalid">
  <div class="col">
    <div class="review-container">
      <h5 class="review-header">When and where will the visitors go?</h5>
      <div class="data-row">
        <div class="data-key">Event Title</div>
        <div class="data-value">{{ reviewFAR?.eventTitle }}</div>
      </div>
      <div class="data-row">
        <div class="data-key">Purpose Type</div>
        <div class="data-value">{{ reviewFAR?.purposeType }}</div>
      </div>
      <div class="data-row">
        <div class="data-key">Description</div>
        <div class="data-value">{{ reviewFAR?.description }}</div>
      </div>
      <div class="data-row">
        <div class="data-key">Itinerary</div>
        <div class="data-value">
          <div
            *ngFor="
              let item of reviewFAR?.foreignAccessRequestLocations
                | orderByImpure: ['startDate', 'endDate'];
              let i = index
            "
          >
            <div *ngIf="item.organizationLocation">
              {{ item.organizationLocation?.name }}
              -
              {{
                item.organizationLocation && item.organizationLocation.address
                  ? (item.organizationLocation.address | address: "cityState")
                  : null
              }}
            </div>
            <div>
              <ng-container
                *ngIf="
                  !item?.startDate && !item?.endDate;
                  then noDates;
                  else hasDates
                "
              >
              </ng-container>
              <ng-template #noDates>N/A</ng-template>
              <ng-template #hasDates>
                {{ item?.startDate | date | default }} -
                {{ item?.endDate | date | default }}
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="review-container">
      <h5 class="review-header">Who is sponsoring the visitors?</h5>
      <div class="data-row">
        <div class="data-key">Given Name</div>
        <div class="data-value">{{ reviewFAR.sponsor?.givenName }}</div>
      </div>
      <div class="data-row">
        <div class="data-key">Surname</div>
        <div class="data-value">
          {{ reviewFAR.sponsor?.surname }}
        </div>
      </div>
      <div class="data-row">
        <div class="data-key">Email</div>
        <div class="data-value">{{ reviewFAR?.sponsor?.email }}</div>
      </div>
      <div class="data-row">
        <div class="data-key">Phone</div>
        <div class="data-value">{{ reviewFAR?.sponsor?.phone }}</div>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="review-container">
      <h5 class="review-header">Who is this request for?</h5>
      <div class="data-row">
        <div class="data-key">Foreign Nationals</div>
        <div class="data-value">
          <div
            *ngFor="
              let fv of reviewFAR?.foreignVisitors
                | orderByImpure: ['surname', 'givenName']
            "
          >
            {{ fv | fullname: "surnameFirst" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="form-not-valid text-center m-5 text-muted" *ngIf="formInvalid">
  <mat-icon class="md-36">warning</mat-icon>
  <h5>
    Foreign Access Request invalid, please go back and fix any errors before
    submitting.
  </h5>
</div>
