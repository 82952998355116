<app-system-alerts-collection
  [systemAlertsCollection]="systemAlerts$ | async"
  [filterParams]="filterParams$ | async"
  (filterParamsChange)="onFilterParamChange($event)"
  (delete)="onDelete($event)"
  (edit)="onEdit($event)"
  (create)="onCreate()"
  position="fixed"
  [loading]="loading"
>
</app-system-alerts-collection>
