<h1 mat-dialog-title *ngIf="documentId?.documentType">
  {{ typeLabel[documentId.documentType!] }} Id
</h1>
<mat-dialog-content>
  <app-document-id
    #documentForm
    id="documentForm"
    name="documentId"
    [(ngModel)]="documentId"
    [showClearButton]="false"
    [type]="documentId.documentType!"
  ></app-document-id>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-end">
  <button
    mat-button
    color="primary"
    id="diplomatic-id-edit-cancel"
    mat-dialog-close
  >
    Cancel
  </button>
  <button
    [disabled]="documentForm?.documentForm?.invalid || busy"
    id="updateDocumentId"
    mat-raised-button
    color="primary"
    (click)="submitDocumentId()"
  >
    <span *ngIf="!busy">Submit</span>
    <mat-spinner
      class="mr-1 ml-1"
      *ngIf="busy"
      color="primary"
      diameter="35"
    ></mat-spinner>
  </button>
</mat-dialog-actions>
