import {
  Component,
  ViewChild,
  forwardRef,
  AfterViewInit,
  Input,
} from '@angular/core';
import {
  AbstractControl,
  NgForm,
  ValidationErrors,
  Validator,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { hasValues } from '@app/shared/helpers/has-values';
import { EmailType, ContactEmail } from '@app/shared/models';
import { BaseControlComponent } from '../base-control.component';

@Component({
  selector: 'app-email-control',
  templateUrl: './email-control.component.html',
  styleUrls: ['./email-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EmailControlComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmailControlComponent),
      multi: true,
    },
  ],
})
export class EmailControlComponent
  extends BaseControlComponent<ContactEmail>
  implements AfterViewInit, Validator
{
  @Input() addressRequired: boolean = true;
  value: ContactEmail = {};
  onValidationChange: any = () => {};
  emailTypes = EmailType;
  @ViewChild('emailForm') emailForm: NgForm;

  constructor() {
    super();
  }

  ngAfterViewInit() {
    this.emailForm.valueChanges?.subscribe(() => {
      this.onValidationChange();
      this.emitChangeEvent();
    });
  }

  hasData(): boolean {
    return hasValues(this.value);
  }

  writeValue(_value: ContactEmail): void {
    super.writeValue(_value);
    this.value = _value || {};
  }

  validate(_control: AbstractControl<any, any>): ValidationErrors | null {
    if (this.addressRequired || this.hasData()) {
      return this.emailForm?.valid ? null : { email: true };
    } else {
      return null;
    }
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }
}
