import {
  Component,
  Input,
  forwardRef,
  ViewChild,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  AbstractControl,
  NgForm,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { Passport, PassportTypes } from '@app/shared/models';
import { BaseControlComponent } from '@app/shared/controls/base-control.component';
import { CountrySelectComponent } from '@app/shared/controls';
import { hasValues } from '@shared/helpers/has-values';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'app-passport',
  templateUrl: './passport.component.html',
  styleUrls: ['passport.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PassportComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PassportComponent),
      multi: true,
    },
  ],
})
export class PassportComponent
  extends BaseControlComponent<Passport>
  implements Validator, OnChanges
{
  @Input() editable: boolean = true;
  @Input() isPassport: boolean = false;
  @Input() showClearButton: boolean = true;
  @Input() minIssueDate?: string;
  @Output() validPassport = new EventEmitter<boolean>();
  @ViewChild('passportForm')
  passportForm: NgForm;
  @ViewChild('issueCountryControl')
  issueCountryControl: CountrySelectComponent;

  appearance: MatFormFieldAppearance = 'outline';
  today = new Date();
  value: Passport = {};

  gender: any = ['Male', 'Female', 'Other'];
  types = PassportTypes;

  onValidationChange: any = () => {};

  constructor() {
    super();
  }

  addPassport() {
    this.emitChangeEvent();
  }

  checkPassport() {
    // Setting isPassport for visa componenet
    this.isPassport = this.hasPassportData() ? false : true;
    this.emitChangeEvent();
  }

  getEmitValue(): Passport | null {
    return this.hasPassportData() ? this.value : null;
  }

  hasPassportData() {
    return hasValues(this.value);
  }

  ngOnChanges(changes: SimpleChanges) {
    /* Detects value changes from Baseline component to determine disabling of
    remaining fields*/
    if (changes.isPassport !== undefined) {
      this.isPassport = changes.isPassport.currentValue;
      if (changes.isPassport.currentValue === false) {
        this.value = {};
      }
    }
  }

  writeValue(_value: Passport) {
    super.writeValue(_value);
    this.value = this.value || {};
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    return this.passportForm?.valid ? null : { passport: true };
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }
}
