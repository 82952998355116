<h1 mat-dialog-title>Update Address Information</h1>
<mat-dialog-content>
  <div class="container mt-2">
    <app-address
      data-cy="addressForm"
      #addressForm
      name="addressForm"
      [(ngModel)]="data.address"
    ></app-address>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-end">
  <button
    mat-button
    mat-dialog-close
    color="primary"
    data-cy="close-address-button"
  >
    Close
  </button>
  <button
    data-cy="submit-address-button"
    mat-raised-button
    [disabled]="(addressForm.form && !addressForm.form.valid) || busy"
    (click)="save(data.address)"
    color="primary"
  >
    Submit
  </button>
</mat-dialog-actions>
