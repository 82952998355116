<div class="container mt-3" style="border-left: 2px solid">
  <div ngForm #form="ngForm">
    <mat-form-field appearance="outline">
      <input
        matInput
        [(ngModel)]="name.firstName"
        placeholder="Name"
        name="firstName"
      />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <input
        matInput
        [(ngModel)]="name.lastName"
        placeholder="Name"
        name="lastName"
      />
    </mat-form-field>

    <!-- Checking to see if the address emits as a control -->
    <app-address [(ngModel)]="address" name="address"></app-address>
  </div>
</div>
<div class="d-flex">
  <mat-icon [flagIcon]="'USA'"></mat-icon>
  <div>United states</div>
</div>

<pre class="debug">
Dirty: {{ form.dirty | json }}
Touched: {{ form.touched | json }}
{{ form.valueChanges | async | json }}
</pre>
<h2>Multiple Entry Control Example</h2>
<div class="container">
  <div class="row">
    <div class="col"></div>
  </div>
</div>
<pre class="debug">
  {{ name | json }}
  {{ array | json }}
  {{ emailArray | json }}
</pre>
<h2>AutoComplete Sandbox</h2>
