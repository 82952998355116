<div mat-dialog-title>
  <mat-icon class="mr-3" svgIcon="account-switch-outline"></mat-icon>
  <span>Switch Roles</span>
</div>
<ng-container *ngIf="!allRoles?.length || loading">
  <div class="container">
    <div class="row justify-content-center">
      <mat-spinner diameter="16"></mat-spinner>
    </div>
  </div>
</ng-container>
<div class="container">
  <div class="row justify-content-center">
    <app-roles-select-control
      *ngIf="allRoles?.length && !loading"
      [unlocked]="true"
      [roles]="allRoles"
      [(ngModel)]="selectedRoles"
    ></app-roles-select-control>
  </div>
</div>
<mat-dialog-actions class="flex-row">
  <button mat-button mat-dialog-close>Cancel</button>
  <span class="flex-grow-1"></span>
  <button
    *ngIf="userService.filteredRoles?.length || isValid"
    mat-button
    (click)="reset()"
  >
    Reset
  </button>
  <button
    [disabled]="!isValid"
    mat-raised-button
    color="primary"
    type="submit"
    form="switchRolesForm"
    (click)="submit()"
  >
    Apply
  </button>
</mat-dialog-actions>
