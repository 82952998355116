import { Component, Input } from '@angular/core';
import { emailIcons, ContactEmail } from '@app/shared/models';

@Component({
  selector: 'app-fv-email-display',
  templateUrl: './fv-email-display.component.html',
  styleUrls: ['./fv-email-display.component.scss'],
})
export class FvEmailDisplayComponent {
  @Input() editable?: boolean = false;
  @Input() emails: ContactEmail[];
  @Input() fvId: string;
  emailIcons = emailIcons;
}
