import { Injectable } from '@angular/core';
import {
  throwError as observableThrowError,
  Observable,
  OperatorFunction,
  Subject,
} from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import { Alert, AlertType } from '@app/shared/models';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { get, isString } from 'lodash';
import { ConfirmDialogComponent, ConfirmDialogData } from '@shared/components';
import { MatDialog } from '@angular/material/dialog';
import { AuthSummary } from '@shared/models/auth-event.model';
import { DatePipe } from '@angular/common';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerOverlayComponent } from '../components/spinner-overlay/spinner-overlay.component';

@Injectable({ providedIn: 'root' })
export class AlertService {
  private subject = new Subject<Alert>();
  private defaultId = 'default-alert';

  private datePipe = new DatePipe('en-US');

  private loginInfoShown = false;
  private overlayRef: OverlayRef;

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private overlay: Overlay
  ) {}

  confirm(data: ConfirmDialogData): Observable<any> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '500px',
      data,
    });

    return dialogRef.afterClosed().pipe(
      filter((result) => !!result),
      tap((result) => {
        if (data?.successMsg?.length) this.successAlert(data.successMsg);
      })
    );
  }

  confirmDelete(data: ConfirmDialogData): Observable<any> {
    return this.confirm({
      confirmColor: 'warn',
      confirmText: 'Delete',
      ...data,
    });
  }

  errorAlert(msg: string, config: MatSnackBarConfig = {}) {
    this.snackBar.open(msg, undefined, {
      duration: 5000,
      panelClass: 'error-snackbar',
      ...config,
    });
  }

  successAlert(msg: string, config: MatSnackBarConfig = {}) {
    this.snackBar.open(msg, undefined, {
      duration: 5000,
      panelClass: 'success-snackbar',
      ...config,
    });
  }

  infoAlert(msg: string, config: MatSnackBarConfig = {}) {
    this.snackBar.open(msg, undefined, {
      duration: 5000,
      panelClass: 'info-snackbar',
      ...config,
    });
  }

  messageAlert(msg: string, config: MatSnackBarConfig = {}) {
    this.snackBar.open(msg, 'Dismiss', {
      duration: 5000,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
      ...config,
    });
  }

  crudAlert(entity: string, action: string) {
    this.successAlert(`${entity} has been ${action}.`);
  }

  alertLoginInfo(summary: AuthSummary) {
    if (this.loginInfoShown) return;
    this.loginInfoShown = true;

    const lastLogin = summary.lastLogin
      ? this.datePipe.transform(summary.lastLogin, 'short')
      : 'N/A';
    const failures =
      summary.failuresSince > 0
        ? ` [${summary.failuresSince} Failed Attempt${
            summary.failuresSince > 1 ? 's' : ''
          }]`
        : '';
    this.snackBar
      .open(`Last login: ${lastLogin}${failures}`, 'Details', {
        duration: 20000,
      })
      .onAction()
      .subscribe(() => {
        AuthEventsDialogComponent.openDialog(this.dialog);
      });
  }

  catchAlertHttpError = catchError((err, caught) => {
    this.hideLoadingOverlay();
    let msg = get(err, 'error.apierror.message') || get(err, 'error.message');

    msg = isString(msg) ? msg : 'Internal Server Error';

    const url = get(err, 'url', 'api');

    console.error(`Error in http call to ${url}: ${msg}`, err);
    this.errorAlert(msg);

    return observableThrowError(err);
  });

  alertHttpError<T>(): OperatorFunction<T, T> {
    return this.catchAlertHttpError as OperatorFunction<T, T>;
  }

  // enable subscribing to alerts observable
  public onAlert(id = this.defaultId): Observable<Alert> {
    return this.subject.asObservable().pipe(filter((x) => x && x.id === id));
  }

  // convenience methods
  public success(message: string, options?: any) {
    this.alert(new Alert({ ...options, type: AlertType.Success, message }));
  }

  public error(message: string, options?: any) {
    this.alert(new Alert({ ...options, type: AlertType.Error, message }));
  }

  public info(message: string, options?: any) {
    this.alert(new Alert({ ...options, type: AlertType.Info, message }));
  }

  public warn(message: string, options?: any) {
    this.alert(new Alert({ ...options, type: AlertType.Warning, message }));
  }

  // main alert method
  public alert(alert: Alert) {
    alert.id = alert.id || this.defaultId;
    this.subject.next(alert);
  }

  // clear alerts
  public clear(id = this.defaultId) {
    this.subject.next(new Alert({ id }));
  }

  //Show Citadel Loading Overlay
  public showLoadingOverlay() {
    // Returns an OverlayRef (which is a PortalHost)

    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create();
    }

    // Create ComponentPortal that can be attached to a PortalHost
    const spinnerOverlayPortal = new ComponentPortal(SpinnerOverlayComponent);
    this.overlayRef.attach(spinnerOverlayPortal); // Attach ComponentPortal to PortalHost
  }

  //Hide Citadel Loading Overlay
  public hideLoadingOverlay() {
    if (!!this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}
