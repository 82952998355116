<div [formGroup]="fnForm">
  <div class="py-2 spacing">
    <mat-checkbox
      formArrayName="fnCheckboxes"
      id="fnCheckboxID"
      *ngFor="let item of displayCheckedColumns; let i = index"
      (change)="selectedColumns($event)"
      value="{{ item.label }}"
      name="{{ item.label }}"
      [checked]="item.selected"
      #fnCheckBox
    >
      {{ item.label }}
    </mat-checkbox>
  </div>
  <div class="mt-2" #fnGroupButton id="fnSelectButton">
    <button
      mat-raised-button
      color="primary"
      class="mr-2"
      (click)="toggleAll(true)"
    >
      Select All
    </button>
    <button mat-raised-button color="primary" (click)="toggleAll(false)">
      Deselect All
    </button>
  </div>
  <div class="mt-5" #fnViewButton id="fnViewButton">
    <mat-label class="ml-2 mt-2 row">
      Select how to display {{ tabLabel }}
    </mat-label>
    <mat-button-toggle-group
      [hideSingleSelectionIndicator]="true"
      class="mt-2 mb-4"
      [(value)]="_fnViewType"
      formControlName="fnViewType"
    >
      <mat-button-toggle
        #card
        value="card"
        matTooltip="Card View"
        (change)="chooseView($event)"
      >
        <mat-icon>apps</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle
        value="table"
        matTooltip="Table View"
        (change)="chooseView($event)"
      >
        <mat-icon>list</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
