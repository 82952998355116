import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[validPostalCode]',
})
export class PostalCodeDirective {
  validChars = /[a-zA-Z0-9- ]/;
  maxLength: number = 15;

  constructor(private _element: ElementRef) {}

  @HostListener('keypress', ['$event'])
  onKeyPress(event: any) {
    let inp = String.fromCharCode(event.keyCode);
    if (
      this.validChars.test(inp) &&
      this._element.nativeElement.value.length < this.maxLength
    ) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  @HostListener('paste', ['$event'])
  checkPaste(event: ClipboardEvent) {
    this.validatePasteInput(event);
  }

  validatePasteInput(event: ClipboardEvent) {
    event.preventDefault();
    let pasteData: string | null = event.clipboardData
      ? event.clipboardData.getData('text/plain').replace(/[^a-zA-Z0-9- ]/g, '')
      : null;

    if (pasteData) {
      pasteData =
        pasteData.length > this.maxLength
          ? pasteData.substring(0, this.maxLength)
          : pasteData;
      this._element.nativeElement.value = pasteData;
      this._element.nativeElement.dispatchEvent(new Event('input'));
    }
  }
}
