import { Component, Input } from '@angular/core';
import {
  Role,
  RoleColors,
  RoleDescriptions,
  RoleIcons,
  RoleLabels,
} from '@shared/models';

@Component({
  selector: 'app-user-role-chips',
  templateUrl: './user-role-chips.component.html',
  styleUrls: ['./user-role-chips.component.scss'],
})
export class UserRoleChipsComponent {
  RoleLabels = RoleLabels;
  RoleColors = RoleColors;
  RoleDescriptions = RoleDescriptions;
  RoleIcons = RoleIcons;
  @Input() selectable: boolean = true;

  @Input() stacked = false;

  @Input() roles?: Role[];
}
