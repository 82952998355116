<div class="about-dialog mt-3">
  <div
    mat-dialog-title
    class="d-flex flex-row justify-content-center align-items-center"
  >
    <mat-icon [citadelLogo]="'horizontal'"></mat-icon>
    <span class="pl-2 font-weight-bold text-primary">SUPPORT</span>
  </div>
  <mat-dialog-content>
    <mat-nav-list class="mt-3 mb-3">
      <a
        style="display: flex"
        mat-list-item
        href="mailto:{{ supportEmail }}?subject=CITADEL Support"
      >
        <mat-icon
          class="mt-0 align-self-center"
          matListItemIcon
          svgIcon="email-outline"
          color="primary"
        ></mat-icon>
        <div matListItemTitle class="font-weight-bold">Email Support</div>
        <div matListItemLine>{{ supportEmail }}</div>
      </a>
      <a style="display: flex" mat-list-item href="tel:{{ supportPhone }}">
        <mat-icon
          class="mt-0 align-self-center"
          matListItemIcon
          svgIcon="phone-outline"
          color="primary"
        ></mat-icon>
        <div matListItemTitle class="font-weight-bold">Phone Support</div>
        <div matListItemLine>{{ supportPhone }}</div>
      </a>
    </mat-nav-list>
    <div class="d-flex flex-column pt-2">
      <p class="font-italic m-0 text-muted small">
        * <strong>CITADEL</strong> Support is available
        {{ startTime | date: "shortTime" }} -
        {{ endTime | date: "shortTime" }} ET, Monday-Friday except federal
        holidays; please email CITADEL Support at
        <strong>{{ supportEmail }}</strong> or leave a voicemail at
        <strong>{{ supportPhone }}</strong
        >, outside of these hours.
      </p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="d-flex flex-row">
    <span class="col"></span>
    <button mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions>
</div>
