import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FAR, ForeignVisitor } from '@app/shared/models';
import { FvService } from '@app/shared/services';
import { take } from 'rxjs';

@Component({
  selector: 'app-add-far-dialog',
  templateUrl: './add-far-dialog.component.html',
  styleUrls: ['./add-far-dialog.component.scss'],
})
export class AddFarDialogComponent implements OnInit {
  farsToAssociate: FAR[] = [];
  fvId: string = '';
  fvName: string = '';
  fvOrganizationId: string = '';
  busy: boolean = false;
  ok: boolean = false;

  constructor(
    private fvService: FvService,
    @Inject(MAT_DIALOG_DATA) private fv: ForeignVisitor,
    private dialog: MatDialogRef<AddFarDialogComponent>
  ) {}

  ngOnInit(): void {
    this.ok = false;
    this.fvName = this.fv.givenName + ' ' + this.fv.surname;
    this.fvId = this.fv.id;
    if (this.fv && this.fv.ownerOrganizationId)
      this.fvOrganizationId = this.fv.ownerOrganizationId;
  }

  onSubmit() {
    this.ok = true;
    this.busy = true;
    this.fvService
      .addFarToFv(this.fv.id, this.farsToAssociate)
      .pipe(take(1))
      .subscribe((fv) => {
        this.onClose();
      });
  }
  onClose() {
    this.dialog.close();
  }
  onRemove(fv: ForeignVisitor) {
    const filtered = this.farsToAssociate.filter((x) => {
      return fv.id != x.id;
    });
    this.farsToAssociate = filtered;
  }
}
