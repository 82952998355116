import { Component, Input } from '@angular/core';
import { Screening } from '@app/shared/models';

@Component({
  selector: 'app-fn-latest-screening',
  templateUrl: './fn-latest-screening.component.html',
  styleUrls: ['./fn-latest-screening.component.scss'],
})
export class FnLatestScreeningComponent {
  @Input() screening: Screening;

  get modifiedDate() {
    return this.screening?.lastModifiedDate;
  }
}
