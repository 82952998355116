import { Input, Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Contact } from '@app/shared/models';

@Component({
  selector: 'app-contact-table',
  templateUrl: './contact-table.component.html',
  styleUrls: ['./contact-table.component.scss'],
})
export class ContactTableComponent implements OnInit {
  @Input() contacts: Contact[] = [];
  @Input() showActions: boolean = false;
  @Output() appContactEdited = new EventEmitter<Contact>();

  innerDisplayedColumns: string[] = [
    'givenName',
    'surname',
    'groupName',
    'type',
    'email',
    'phone',
    'address',
  ];

  constructor() {}

  ngOnInit(): void {
    if (this.showActions) this.innerDisplayedColumns.push('contactActions');
  }

  refresh() {
    this.appContactEdited.emit();
  }

  isContactAddress(contact: Contact): boolean {
    if (
      contact.address &&
      (contact.address.city ||
        contact.address.line1 ||
        contact.address.line2 ||
        contact.address.stateProvince ||
        contact.address.countryCode)
    )
      return true;
    return false;
  }
}
