<mat-card
  appearance="outlined"
  class="activity d-flex flex-column"
  style="min-width: 526px"
>
  <app-paginator
    [filterParams]="filterParams"
    (filterParamsChange)="onFilterParamChange($event)"
    [pageIndex]="fars?.number"
    [pageSize]="fars?.size"
    [totalElements]="fars?.totalElements"
    class="w-100"
    [class]="fars.content.length ? '' : 'mb-2'"
    [showPaginator]="fars?.totalElements! > 0"
    [hideBorderTop]="true"
    [hidePageSize]="true"
    [showFirstLastButtons]="false"
    [showWarningHeader]="fv.activeFarCount === 0 && farScreeningEligibility"
    [icon]="
      fv.activeFarCount === 0 && farScreeningEligibility
        ? 'map-marker-alert-outline'
        : 'map-marker-multiple-outline'
    "
    title="Foreign Access Requests"
    [matTooltip]="
      fv.activeFarCount === 0 && farScreeningEligibility
        ? 'Active FAR Required for Screening Eligibility'
        : ''
    "
  ></app-paginator>
  <mat-toolbar
    *ngIf="fars.content.length"
    class="action-bar justify-content-between"
  >
    <div class="pill-section">
      <mat-chip-listbox
        [(ngModel)]="activeFilter"
        [value]="activeFilter"
        #matChipListbox
        name="matChipListBox"
      >
        <mat-chip-option
          value="all"
          color="accent"
          [selectable]="activeFilter !== 'all'"
          (click)="doSearch('all')"
          >All ({{ fv.farCount }})</mat-chip-option
        >
        <mat-chip-option
          value="upcoming"
          color="accent"
          [selectable]="activeFilter !== 'upcoming'"
          [disabled]="!fv.activeFarCount"
          (click)="doSearch('upcoming')"
          >Active ({{ fv.activeFarCount }})
        </mat-chip-option>
      </mat-chip-listbox>
    </div>
    <div class="d-flex align-items-center">
      <button
        mat-icon-button
        *ngIf="isDecending"
        color="primary"
        (click)="changeSort(false)"
        matTooltip="Start Date Sort: Latest to Earliest"
      >
        <mat-icon svgIcon="calendar-export"></mat-icon>
      </button>
      <button
        mat-icon-button
        color="primary"
        *ngIf="!isDecending"
        (click)="changeSort(true)"
        matTooltip="Start Date Sort: Earliest to Latest"
      >
        <mat-icon svgIcon="calendar-import"></mat-icon>
      </button>
      <button
        data-cy="new-far-button"
        *ngIf="fv.isEditable && fars.content.length"
        mat-button
        color="primary"
        #addFarTrigger="matMenuTrigger"
        [matMenuTriggerFor]="addFarMenu"
      >
        <div class="d-flex align-items-center gap-1">
          <mat-icon svgIcon="plus-circle-outline"></mat-icon>
          <span>Add</span>
        </div>
      </button>
    </div>
  </mat-toolbar>
  <mat-card-content class="fill-height overflow-auto h-100" *ngIf="!loading">
    <app-far-list
      data-cy="fars-list"
      *ngIf="fars.content.length; else noData"
      [fars]="fars.content"
    ></app-far-list>
    <ng-template #noData>
      <app-empty-state data-cy="no-far-state" message="No FAR Records">
        <button
          data-cy="add-far-button"
          *ngIf="fv.isEditable"
          mat-flat-button
          color="accent"
          #addFarTrigger="matMenuTrigger"
          [matMenuTriggerFor]="addFarMenu"
          class="align-self-baseline"
        >
          Add to FAR
        </button>
      </app-empty-state>
    </ng-template>
  </mat-card-content>
  <mat-spinner
    *ngIf="loading"
    diameter="50"
    class="ml-auto mr-auto mt-5"
  ></mat-spinner>
</mat-card>
<mat-menu #addFarMenu>
  <button
    id="add-available-far"
    mat-menu-item
    (click)="onAdd()"
    matTooltip="Add Foreign National to a Foreign Access Request that is currently available"
    matTooltipPosition="after"
  >
    <mat-icon svgIcon="content-copy"></mat-icon>
    <span> Add Available </span>
  </button>
  <button
    id="quick-create-far"
    mat-menu-item
    appCreateFar
    [fv]="fv"
    matTooltip="Create a new Foreign Access Request to add the Foreign National too"
    matTooltipPosition="after"
  >
    <mat-icon svgIcon="file-document-plus-outline"></mat-icon>
    <span>Add New</span>
  </button>
</mat-menu>
