<h1 mat-dialog-title>{{ comment?.id ? "Update" : "Add" }} Comment</h1>
<mat-dialog-content>
  <form
    #editCommentForm="ngForm"
    id="editCommentForm"
    class="d-flex flex-column flex-grow-1"
  >
    <app-classification-banner
      class="d-block mb-2"
      [classificationModel]="comment"
    ></app-classification-banner>
    <mat-form-field appearance="outline">
      <mat-label>Comment</mat-label>
      <textarea
        required
        id="comment"
        maxlength="4000"
        matInput
        rows="10"
        rows="10"
        name="comment"
        [(ngModel)]="comment.comment"
      ></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-end">
  <button mat-button color="primary" id="comment-edit-cancel" mat-dialog-close>
    Cancel
  </button>
  <span class="col"></span>
  <button
    mat-flat-button
    type="button"
    id="comment-dialog-classify-btn"
    appClassify
    *appClassifyShow
    [(classificationModel)]="comment"
    color="accent"
  >
    Classify
  </button>
  <button
    [disabled]="!editCommentForm || editCommentForm.invalid"
    id="comment-edit-save"
    cdkFocusInitial
    mat-raised-button
    color="primary"
    (click)="save()"
  >
    <span *ngIf="!saving">{{ comment?.id ? "Update" : "Submit" }}</span>
    <mat-spinner color="primary" *ngIf="saving" [diameter]="35"></mat-spinner>
  </button>
</mat-dialog-actions>
