<mat-form-field>
  <mat-label>Approver</mat-label>
  <mat-select
    #matSelect
    [(ngModel)]="value"
    (ngModelChange)="emitChangeEvent()"
    [required]="required"
    [disabled]="disabled"
    (selectionChange)="emitChangeEvent()"
  >
    <mat-option
      [appContactEdit]="{
        type: ContactType.ORG_ADMIN_APPROVER,
        organization: { id: orgId }
      }"
      (appContactEdited)="newContactCreated($event)"
    >
      <mat-icon svgIcon="plus-circle-outline"></mat-icon> New
      Approver</mat-option
    >
    <mat-divider *ngIf="(filteredValues | async)?.length!"></mat-divider>
    <mat-option
      *ngFor="let contact of filteredValues | async"
      [value]="contact.id"
      ><strong>{{
        contact?.groupName ? contact.groupName : (contact | fullname)
      }}</strong>
      | {{ contact.email }}</mat-option
    >
  </mat-select>
</mat-form-field>
