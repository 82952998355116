import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AdditionalInfo } from '../models';
import { Crud, CrudService } from './crud.service';

@Injectable({ providedIn: 'root' })
@Crud({ apiUrl: `${environment.apiUrl}/otherNames`, hasIdPathUpdate: true })
export class OtherNamesService extends CrudService<AdditionalInfo> {
  constructor(http: HttpClient) {
    super(http);
  }
}
