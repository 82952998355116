import {
  FilterConfig,
  FilterConfigType,
} from '@shared/components/advanced-search/filter-config';
import { Role } from '@shared/models';

export const OrgAdvancedSearch: FilterConfig[] = [
  {
    type: FilterConfigType.INPUT,
    label: 'Name',
    name: 'name',
  },
  {
    type: FilterConfigType.INPUT,
    label: 'Short Name',
    name: 'shortName',
  },
  {
    type: FilterConfigType.TOGGLE,
    label: 'Orgs with FN Records',
    name: 'hasFNRecs',
  },
  {
    type: FilterConfigType.ORG,
    label: 'Parent Organization',
    name: 'parentOrganizationId',
    col: 12,
    ifRole: [
      Role.sv_admin,
      Role.sv_help_desk,
      Role.sv_vetter,
      Role.sv_org_admin,
    ],
    parentsOnly: true,
  },
];
