import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class MetricsObservable {
  private _MetricsObservable$ = new Subject<any>();
  MetricsObservable$: Observable<any> = this._MetricsObservable$.asObservable();

  send(data: any) {
    this._MetricsObservable$.next(data);
  }
}
