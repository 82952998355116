import { Pipe, PipeTransform } from '@angular/core';
import { Status, SystemAlert } from '@app/shared/models/system-alert';

@Pipe({
  name: 'status',
})
export class StatusPipe implements PipeTransform {
  transform(value: SystemAlert): Status {
    let status: Status = 'planned';
    const now = Date.now();
    const endDate = new Date(value.endDate).valueOf();
    const startDate = new Date(value.startDate).valueOf();

    const started = startDate < now;
    const ended = endDate < now;

    if (started) status = 'current';
    if (ended) status = 'expired';

    return status;
  }
}
