import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-related-associates',
  templateUrl: './related-associates.component.html',
  styleUrls: ['./related-associates.component.scss'],
})
export class RelatedAssociatesComponent {
  @Input() count: number = 0;
  @Input() color: string;
  @Input() icon: string;
  @Input() toolTip: string;
}
