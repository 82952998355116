import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ColumnToggleEvent } from './column-chooser.component';

@Injectable()
export class ColumnChooserEventObservable {
  private _ColumnChooserObservable$ = new Subject<any>();
  ColumnChooserObservable$: Observable<any> =
    this._ColumnChooserObservable$.asObservable();

  sendEvent(event: ColumnToggleEvent) {
    this._ColumnChooserObservable$.next(event);
  }
}
