import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FAR } from '@app/shared/models';
import { FarService } from '@app/shared/services';
@Component({
  selector: 'app-far-description-dialog',
  templateUrl: './far-description-dialog.component.html',
  styleUrls: ['./far-description-dialog.component.scss'],
})
export class FarDescriptionDialogComponent {
  busy: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public far: FAR,
    private farService: FarService,
    private dialogRef: MatDialogRef<FarDescriptionDialogComponent>
  ) {}

  onSubmit() {
    this.busy = true;
    this.farService.save(this.far).subscribe((_far) => {
      this.dialogRef.close();
    });
  }
}
