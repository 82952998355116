<ng-container *ngIf="display === 'status' && screening">
  <button
    mat-button
    type="button"
    #statusTrigger="matMenuTrigger"
    [matMenuTriggerFor]="hasMenu ? statusMenu : null"
    [matTooltip]="screening | screeningLabel: 'status' | default"
    [matTooltipDisabled]="!showTooltip"
    matTooltipPosition="below"
  >
    <mat-icon
      *ngIf="showStatusIcon && !screening.result"
      class="status align-self-center"
      [class]="screening | screeningClassList | default"
      [ngClass]="iconSize === 'large' ? 'large-icon' : ''"
      [svgIcon]="screening | screeningIcon | default"
    >
    </mat-icon>
    <mat-icon
      *ngIf="screening?.result"
      class="status align-self-center"
      [class]="screening?.status | statusMapper: 'color' | default"
      [ngClass]="iconSize === 'large' ? 'large-icon' : ''"
      [svgIcon]="screening?.status | statusMapper: 'icon' | default"
    >
    </mat-icon>
    <span *ngIf="showStatusText">{{
      screening | screeningLabel: "status" | default
    }}</span>
  </button>
  <mat-menu #statusMenu="matMenu">
    <dl class="m-3 small">
      <dt>Screening Status</dt>
      <dd>
        {{ screening | screeningLabel: "status" | default }}
      </dd>
      <ng-container *ngIf="screening">
        <dt>Requested Date</dt>
        <dd>
          {{ screening?.requestedDate | date | default }}
        </dd>
        <dt>Last Completed Screening</dt>
        <dd>
          {{ screening?.completedDate | date | default }}
        </dd>
      </ng-container>
    </dl>
  </mat-menu>
</ng-container>
<ng-container *ngIf="display === 'result'">
  <button
    mat-button
    type="button"
    #resultTrigger="matMenuTrigger"
    [matMenuTriggerFor]="hasMenu ? resultMenu : null"
    matTooltip="{{
      screening?.result | statusMapper: 'result':screening?.result | default
    }}"
    [matTooltipDisabled]="!showTooltip"
    matTooltipPosition="below"
  >
    <mat-icon
      *ngIf="showStatusIcon"
      class="status"
      [style]="screening?.result === 'YELLOW' ? { color: 'black' } : ''"
      [ngClass]="iconSize === 'large' ? 'large-icon' : ''"
      [class]="
        screening?.result | statusMapper: 'color':screening?.result | default
      "
      [svgIcon]="
        screening?.result | statusMapper: 'icon':screening?.result | default
      "
    >
    </mat-icon>
    <span *ngIf="showResultText">{{
      getResult(screening?.result) | default
    }}</span>
    <ng-container *ngIf="!showResultText">
      <span *ngIf="!screening?.result">N/A</span>
    </ng-container>
  </button>

  <mat-menu #resultMenu="matMenu">
    <dl class="m-3 small">
      <dt>Screening Result</dt>
      <dd>
        {{
          screening?.result | statusMapper: "result":screening?.result | default
        }}
      </dd>
    </dl>
  </mat-menu>
</ng-container>
<ng-container *ngIf="display === 'rollup'">
  <button
    mat-button
    type="button"
    #resultTrigger="matMenuTrigger"
    [matMenuTriggerFor]="rollupMenu"
    matTooltip="{{
      rollup?.screeningStatus | statusMapper: 'status' | titlecase | default
    }}"
    [matTooltipDisabled]="!showTooltip"
    matTooltipPosition="below"
  >
    <mat-icon
      *ngIf="showStatusIcon"
      class="status"
      [class]="rollup?.screeningStatus | statusMapper: 'color' | default"
      [svgIcon]="rollup?.screeningStatus | statusMapper: 'icon' | default"
    >
    </mat-icon>
    {{
      rollup?.screeningResult
        ? (rollup?.screeningResult | titlecase)
        : (rollup?.screeningStatus | titlecase)
    }}
  </button>
  <mat-menu #rollupMenu="matMenu">
    <dl class="m-3 small">
      <ng-container *ngIf="!rollup?.screeningResult; else result">
        <dt>Screening Status</dt>
        <dd>
          {{
            rollup?.screeningStatus
              | statusMapper: "status"
              | titlecase
              | default
          }}
        </dd>
      </ng-container>
      <ng-template #result>
        <dt>Screening Result</dt>
        <dd>
          {{
            rollup?.screeningResult
              | statusMapper: "status"
              | titlecase
              | default
          }}
        </dd>
      </ng-template>
    </dl>
  </mat-menu>
</ng-container>
