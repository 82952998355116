import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-multi-item-container',
  templateUrl: './multi-item-container.component.html',
  styleUrls: ['./multi-item-container.component.scss'],
})
export class MultiItemContainerComponent {
  @Input() data: any[] | null = null;
  @Input() menuIcon: string = '';
  @Input() menuTitle: string = '';
  @Input() entryHeader: string = '';
  @Input() entryContent: string = '';
  @Input() buttonText: string = '';
  @Input() type: 'location' | 'date';
}
