import { Component, Inject, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import {
  Screening,
  ForeignVisitor,
  ScreeningStatus,
  ScreeningResult,
} from '@app/shared/models';
import { ScreeningService } from '@app/shared/services';
import { ScreeningRoles } from '@shared/models/role-permissions';
import { cloneDeep } from 'lodash';
import { ConfirmDialogComponent } from '../../confirm/confirmdialog.component';
import { take } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-screening-dialog',
  templateUrl: './screening-dialog.component.html',
  styleUrls: ['./screening-dialog.component.scss'],
})
export class ScreeningDialogComponent implements OnInit {
  busy: boolean = false;
  cacheScreening: Partial<Screening> = {};
  confirmUsp: boolean = false;
  latestScreening!: Screening;
  overrideResults: boolean = false;
  ScreeningRoles = ScreeningRoles;
  status = ScreeningStatus;
  ScreeningResult = ScreeningResult;

  constructor(
    @Inject(MAT_DIALOG_DATA) public fv: ForeignVisitor,
    private dialog: MatDialog,
    private location: Location,
    private router: Router,
    private dialogRef: MatDialogRef<ScreeningDialogComponent>,
    private screeningService: ScreeningService
  ) {}

  ngOnInit(): void {
    this.latestScreening = cloneDeep(this.fv.latestScreening) as Screening;
    this.confirmUsp = false;
  }

  onOverrideResults() {
    this.cacheScreening = cloneDeep(this.latestScreening);
    this.overrideResults = true;
  }

  onCancel() {
    this.latestScreening = this.cacheScreening;
    this.cacheScreening = {};
    this.overrideResults = false;
  }

  onSubmit() {
    if (this.latestScreening.result === ScreeningResult.ReviewedRestricted) {
      this.openConfirmationDialog();
    } else this.save();
  }

  save(sendBack?: boolean) {
    this.busy = true;
    this.screeningService
      .save(this.latestScreening)
      .pipe(take(1))
      .subscribe({
        next: (_) => {
          if (sendBack && location.pathname !== '/screening')
            this.location.back();
          this.dialogRef.close();
        },
        error: (err) => (this.busy = false),
      });
  }

  openConfirmationDialog(): MatDialogRef<ConfirmDialogComponent> {
    let data = {
      icon: 'alert-octagon',
      color: 'warn',
      title: `ARE YOU SURE?`,
      message: `This will cause the Foreign National to be deleted`,
      performAction: () => this.save(true),
      confirmText: 'Confirm',
    };

    return this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data,
    });
  }

  getErrorMessage(error: any) {
    if (error.legacyValue) {
      return `Result contains a Legacy Value`;
    }
  }
}
