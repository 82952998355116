<ng-container *ngIf="item">
  <button
    mat-mini-fab
    [ngClass]="[item.status | statusMapper: 'color', size + '__fab']"
    [matTooltip]="
      customTooltip
        ? customTooltip
        : (item.status | statusMapper: 'status' | titlecase) + ' Visitors'
    "
    disableRipple
    #screeningFab
    [matBadgeSize]="size"
    [matBadge]="item.total"
    matBadgeColor="primary"
    class="screening__fab mat-elevation-z0"
  >
    <mat-icon
      class="screening__fab__icon {{ size }}"
      [svgIcon]="item.status | statusMapper: 'icon' | default"
      [style]="item?.status === 'YELLOW' ? { color: 'black' } : ''"
    >
    </mat-icon>
  </button>
</ng-container>
