<div
  class="row panel-banner p-2 mx-0 my-2 justify-content-between cursor-pointer"
  id="toggleOrganizationGraphs"
  (click)="display = !display"
>
  <div class="align-items-center d-flex">
    <mat-icon class="mr-2" svgIcon="chart-line"></mat-icon> Screening Status
  </div>
  <mat-icon svgIcon="chevron-up" *ngIf="display"></mat-icon>
  <mat-icon svgIcon="chevron-down" *ngIf="!display"></mat-icon>
</div>
<mat-card class="mb-2" *ngIf="display">
  <div class="d-flex screening-results-picker">
    <mat-form-field appearance="outline">
      <mat-label>Select Dates</mat-label>
      <mat-date-range-input [rangePicker]="screeningDatePicker" [max]="today">
        <input
          id="lineDateStart"
          name="lineDateStart"
          matStartDate
          [(ngModel)]="startDate"
          placeholder="MM-DD-YYYY"
        />
        <input
          id="lineDateEnd"
          name="lineDateEnd"
          matEndDate
          [(ngModel)]="endDate"
          placeholder="MM-DD-YYYY"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="screeningDatePicker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #screeningDatePicker></mat-date-range-picker>
    </mat-form-field>
    <button
      mat-mini-fab
      class="mx-1 mt-1"
      color="accent"
      style="border-radius: 5px"
      matTooltip="Search"
      (click)="getScreeningResultsMetrics()"
    >
      <mat-icon class="text-black">search</mat-icon>
    </button>
    <div>
      <mat-button-toggle-group
        [hideSingleSelectionIndicator]="true"
        name="datepickerType"
        aria-label="Datepicker Unit"
        [(ngModel)]="datepickerUnit"
        (ngModelChange)="dateRangeExceeded = false"
      >
        <mat-button-toggle value="DAY">Day</mat-button-toggle>
        <mat-button-toggle value="WEEK">Week</mat-button-toggle>
        <mat-button-toggle value="MONTH">Month</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div *ngIf="dateRangeExceeded" class="range-error">
    {{ datepickerUnit }} cannot exceed a range of
    {{ datepickerUnitRange[datepickerUnit] }} days between Start and End Dates
  </div>
</mat-card>

<div class="row mb-2" *ngIf="display">
  <div class="col-12">
    <mat-card>
      <mat-card-content>
        <div *ngIf="!loading; else dataLoading">
          <ngx-charts-line-chart
            [scheme]="lineColorScheme"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [timeline]="timeline"
            [results]="multiline"
          >
          </ngx-charts-line-chart>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<ng-template #dataLoading>
  <div class="my-4" style="margin-left: 45%">
    <mat-spinner color="primary"></mat-spinner></div
></ng-template>
