<form #sponsorForm="ngForm" class="mw-400">
  <mat-form-field appearance="outline" class="d-block mt-2">
    <mat-label>Given Name</mat-label>
    <input
      id="far-sponsor-given-name-input"
      matInput
      [required]="isRequired()"
      #firstNameControl="ngModel"
      placeholder="Given Name"
      name="givenName"
      maxlength="50"
      [(ngModel)]="value.givenName"
      (ngModelChange)="emitChangeEvent()"
    />
    <mat-error>Please Enter a Valid Given Name.</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="d-block">
    <mat-label>Surname</mat-label>
    <input
      id="far-sponsor-surname-input"
      matInput
      [required]="isRequired()"
      #lastNameControl="ngModel"
      placeholder="Surname"
      name="surname"
      maxlength="50"
      [(ngModel)]="value.surname"
      (ngModelChange)="emitChangeEvent()"
    />
    <mat-error>Please Enter a Valid Surname.</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="d-block">
    <mat-label>Email Address</mat-label>
    <input
      id="far-email-input"
      matInput
      [required]="isRequired()"
      [email]="true"
      #emailControl="ngModel"
      placeholder="Email Address"
      name="email"
      type="email"
      maxlength="320"
      [(ngModel)]="value.email"
      (ngModelChange)="emitChangeEvent()"
    />
    <mat-error>Please Enter a Valid Email Address</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" class="d-block">
    <mat-label>Phone Number</mat-label>
    <input
      id="far-phone-input"
      matInput
      maxlength="15"
      #phoneControl="ngModel"
      placeholder="Phone Number"
      [pattern]="PhoneValidation"
      name="phone"
      [(ngModel)]="value.phone"
      (ngModelChange)="emitChangeEvent()"
    />
    <mat-error>Please Enter a Valid Phone Number</mat-error>
  </mat-form-field>
</form>
