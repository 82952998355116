import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { FilterParams, PageableCollection } from '@app/shared/models';
import { SystemAlert } from '@app/shared/models/system-alert';

@Component({
  selector: 'app-system-alerts-collection',
  templateUrl: './system-alerts-collection.component.html',
  styleUrls: ['./system-alerts-collection.component.scss'],
})
export class SystemAlertsCollectionComponent implements OnInit {
  @Input() loading: boolean = false;
  @Input() position: 'fixed' | 'sticky' = 'sticky';
  @Input()
  systemAlertsCollection: PageableCollection<SystemAlert> | null = null;

  @Input() filterParams: FilterParams | null;
  @Output() filterParamsChange = new EventEmitter<FilterParams>();

  @Output() create = new EventEmitter<void>();
  @Output() edit = new EventEmitter<SystemAlert>();
  @Output() delete = new EventEmitter<SystemAlert>();

  displayedColumns: string[] = [
    'status',
    'message',
    'startDateTime',
    'endDateTime',
    'actions',
  ];

  ngOnInit(): void {
    // initialize the filterparams to the default values returned from the api
    if (this.filterParams) {
      this.filterParams.pageSize = this.systemAlertsCollection?.size;
      this.filterParams.pageNum =
        this.systemAlertsCollection?.pageable?.pageNumber;
    }
  }

  onPageChange(page: PageEvent) {
    if (this.filterParams) {
      this.filterParams.pageNum = page.pageIndex;
      this.filterParams.pageSize = page.pageSize;
      this.filterParamsChange.emit(this.filterParams);
    }
  }

  onCreate() {
    this.create.emit();
  }

  onEdit(systemAlert: SystemAlert) {
    this.edit.emit(systemAlert);
  }

  onDelete(systemAlert: SystemAlert) {
    this.delete.emit(systemAlert);
  }
}
