import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterParams, Group, Role } from '@app/shared/models';
import { PageableCollection } from '@app/shared/models/pageable-collection.model';
import { GroupSortLabels, GroupSorts } from '@shared/services';
import { ActionButton } from '@app/shared/models/actionbutton.model';
import { GroupAdvancedSearch } from '@app/groups/collection/group-advanced-search';
import {
  GroupTableColumns,
  GroupTableName,
} from './group-collection-table/group-collection-table.component';

const actionButtons: ActionButton[] = [
  {
    label: 'New Group',
    menuIcon: 'group',
    groupCreate: true,
    roles: [Role.sv_admin, Role.sv_org_admin, Role.sv_org_user],
    color: 'primary',
  },
];
@Component({
  selector: 'app-groups-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
})
export class GroupsCollectionComponent {
  @Input() loading: boolean = false;
  @Input() position: 'fixed' | 'sticky' = 'sticky';
  @Input() pageableCollection: PageableCollection<Group> =
    {} as PageableCollection<Group>;
  @Input() placeholder: string = 'Filter Criteria (e.g): Name, Desciption';
  @Input() workspaceView: boolean = false;
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();

  actionButtons: ActionButton[] = actionButtons;
  GroupSorts = GroupSorts;

  onFilterParamsChange(filterParams: FilterParams) {
    this.filterParamsChange.emit(filterParams);
  }

  protected readonly GroupAdvancedSearch = GroupAdvancedSearch;
  protected readonly GroupTableColumns = GroupTableColumns;
  protected readonly GroupSortLabels = GroupSortLabels;
  protected readonly GroupTableName = GroupTableName;
}
