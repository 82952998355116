import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreeningTransitionComponent } from './screening-transition/screening-transition.component';
import { MaterialModule } from '@app/shared/material/material.module';
import { TimelineModule } from '../timeline/timeline.module';
import { TransitionPipe } from './screening-transition/transition.pipe';
import { CmtModule } from '@app/shared/cmt/cmt.module';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { DirectivesModule } from '@app/shared/directives/directives.module';
import { NgPipesModule } from 'ngx-pipes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlsModule } from '@app/shared/controls/controls.module';
import {
  ScreeningDialogComponent,
  ScreeningFabButtonComponent,
  ScreeningHistoryDialogComponent,
  ScreeningHistoryDirective,
  ScreeningIndicatorComponent,
  ScreeningResultsDialogComponent,
  ScreeningUpdateDirective,
} from './';

const SHARED_COMPONENTS = [
  ScreeningFabButtonComponent,
  ScreeningHistoryDialogComponent,
  ScreeningHistoryDirective,
  ScreeningIndicatorComponent,
  ScreeningResultsDialogComponent,
  ScreeningTransitionComponent,
  ScreeningUpdateDirective,
  TransitionPipe,
  ScreeningDialogComponent,
];

@NgModule({
  declarations: SHARED_COMPONENTS,
  imports: [
    CommonModule,
    MaterialModule,
    TimelineModule,
    CmtModule,
    PipesModule,
    DirectivesModule,
    NgPipesModule,
    FormsModule,
    ControlsModule,
    ReactiveFormsModule,
  ],
  exports: SHARED_COMPONENTS,
})
export class ScreeningModule {}
