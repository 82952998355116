import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FAR, Group } from '@app/shared/models';
import { FarService } from '@app/shared/services';
import { take } from 'rxjs';

@Component({
  selector: 'app-add-group-dialog',
  templateUrl: './add-group-dialog.component.html',
  styleUrls: ['./add-group-dialog.component.scss'],
})
export class AddGroupDialogComponent implements OnInit {
  groupToAssociate: Group = {};
  id: string = '';
  organizationId: string = '';
  busy: boolean = false;
  ok: boolean = false;

  constructor(
    private farService: FarService,
    @Inject(MAT_DIALOG_DATA) private data: FAR,
    private dialog: MatDialogRef<AddGroupDialogComponent>
  ) {}

  ngOnInit(): void {
    this.groupToAssociate = {};
    this.ok = false;
    this.id = this.data.id;

    if (this.data && this.data.ownerOrganizationId)
      this.organizationId = this.data.ownerOrganizationId;
  }

  onSubmit() {
    this.ok = true;
    this.busy = true;

    this.farService
      .addGroupToFar(this.data.id, [this.groupToAssociate.id!])
      .pipe(take(1))
      .subscribe((far) => {
        this.onClose();
      });
  }
  onClose() {
    this.dialog.close();
  }
}
