<div class="login container-fluid full-height postition-relative">
  <div class="row full-height">
    <div class="col-6"></div>

    <div class="col-4 align-self-center">
      <mat-card appearance="outlined">
        <mat-card-header class="justify-content-center">
          <mat-card-title>
            <mat-icon
              citadelLogo="horizontal"
              style="--logoSize: 60px"
            ></mat-icon>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <router-outlet></router-outlet>
        </mat-card-content>
        <mat-card-footer class="p-3 small">
          <p>
            You are accessing a U.S. Government information system, which
            includes (1) this computer, (2) this computer network, (3) all
            computers connected to this network, and (4) all devices and storage
            media attached to this network or to a computer on this network.
            This information system is provided for U.S. Government-authorized
            use only. All information on this system is the property of the U.S.
            Government and may not be accessed without prior authorization.
            <!--
                            You are accessing a U.S. Government Information System. This system operates at the
                            UNCLASSIFIED level. Use of this system is restricted to users who hold appropriate accounts and
                            may
                            be used only for a U.S. Government authorized purpose. All information contained on this system
                            is
                            the property of the U.S. Government and may not be accessed without proper authorization. By
                            using
                            this information system, you understand and expressly consent to the following 1) you have no
                            reasonable expectation of privacy regarding any communication of data (which includes personal
                            communications or data) that is transmitting or transmitted through or stored on this
                            information
                            system, 2) use of this U.S. Government System, authorized or unauthorizd constitutes consent to
                            monitoring on this system, 3) at any or all times, the communications or data contained in this
                            system may be intercepted recorded, read, copied or captured in any manner by authorized U.S.
                            Government personnel for any lawful purpose and 4) authorized users are permitted access to
                            informationin in this system on an official business/need to know basis. Unauthorizd use, such
                            as
                            searching for persons with whom you have personal interaction, for personal interest, or for any
                            purpose other than as required by your officical duties, is strictly prohibited.
                            -->
          </p>
        </mat-card-footer>
      </mat-card>
    </div>
  </div>
</div>
