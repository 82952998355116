<div
  class="profile-pic"
  [matTooltip]="
    photoProcessing
      ? 'Processing Profile Photo'
      : imageUnavailable
      ? 'Image Currently Unavailable'
      : ''
  "
>
  <mat-icon *ngIf="!profilePicSrc" [svgIcon]="attachmentIcon"></mat-icon>
  <img *ngIf="profilePicSrc" [attr.src]="profilePicSrc" />
</div>
<div
  class="profile-pic-edit"
  [appProfilePicSelect]="fv"
  (appProfilePicChanged)="profilePicUpdate($event)"
  matTooltip="Edit Profile Photo"
>
  <mat-icon svgIcon="camera"></mat-icon>
</div>
