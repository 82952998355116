import {
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Injector,
  Input,
  Output,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Comment, CommentType } from '@shared/models/comment.model';
import {
  CommentService,
  FarCommentService,
  FVCommentService,
} from '@shared/services/comment.service';
import { AlertService } from '@shared/services';
import { finalize } from 'rxjs/operators';

@Directive({
  selector: '[appCommentDialog]',
})
export class CommentDialogDirective {
  @Input()
  comment: Comment;

  @Input()
  parentId: string;

  @Input()
  appCommentDialog: CommentType;

  @Output() commentSaved = new EventEmitter<Comment>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    if (!this.appCommentDialog) {
      throw new Error('Missing CommentDialog object type');
    }

    const dialog = CommentDialogComponent.openDialog(this.dialog, {
      type: this.appCommentDialog,
      parentId: this.parentId,
      comment: this.comment,
    });
    dialog.afterClosed().subscribe((comment) => {
      if (comment) this.commentSaved.emit(comment);
    });
  }
}

@Component({
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss'],
})
export class CommentDialogComponent {
  commentService: CommentService;

  comment: Comment;

  saving = false;

  constructor(
    private injector: Injector,
    private alert: AlertService,
    private dialogRef: MatDialogRef<CommentDialogComponent, Comment>,
    @Inject(MAT_DIALOG_DATA) public data: CommentDialogData
  ) {
    this.commentService =
      this.data.type === 'FAR'
        ? this.injector.get<FarCommentService>(FarCommentService)
        : this.injector.get<FVCommentService>(FVCommentService);

    this.comment = {
      ...this.data?.comment,
    } as Comment;

    if (this.data.parentId && this.data.type === 'FAR') {
      this.comment.foreignAccessRequest = { id: this.data.parentId };
    } else if (this.data.parentId) {
      this.comment.foreignVisitor = { id: this.data.parentId };
    }
  }

  save() {
    this.saving = true;
    this.commentService
      .save(this.comment)
      .pipe(finalize(() => (this.saving = false)))
      .subscribe((result) => {
        this.alert.successAlert('Comment successfully saved.');
        this.dialogRef.close(result);
      });
  }

  static openDialog(
    dialog: MatDialog,
    data?: CommentDialogData
  ): MatDialogRef<CommentDialogComponent, Comment> {
    return dialog.open<CommentDialogComponent, CommentDialogData, Comment>(
      CommentDialogComponent,
      {
        data,
        width: '600px',
      }
    );
  }
}

export interface CommentDialogData {
  type?: CommentType;
  parentId?: string;
  comment?: Comment;
}
