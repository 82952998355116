import { formatDate } from '@angular/common';
import {
  Component,
  Directive,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  NgForm,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { isMoment, Moment } from 'moment';
import { BaseControlComponent } from '../base-control.component';

@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: DateTimeComponent, multi: true },
  ],
})
export class DateTimeComponent extends BaseControlComponent<Date> {
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() label = 'start';
  @Input() minDate: Date | null = null;
  @Input() timeStep: number = 5 * 60;

  year: number;
  month: number;
  date: number;
  fulldate: Date;

  hours: number = 0;
  minutes: number = 0;
  time: string = '00:00';

  now = Date.now();

  constructor(@Inject(LOCALE_ID) private locale: string) {
    super();
  }

  writeValue(_value: Date): void {
    if (!_value) return;
    this.value = new Date(_value);
    this.year = this.value.getFullYear();
    this.month = this.value.getMonth();
    this.date = this.value.getDate();
    this.hours = this.value.getHours();
    this.minutes = this.value.getMinutes();

    this.fulldate = new Date(this.year, this.month, this.date);
    this.time = formatDate(this.value, 'HH:mm', this.locale);
  }

  setTime(time: string) {
    this.hours = +time.split(':')[0];
    this.minutes = +time.split(':')[1];
    this.value = this.getDateTime();
    this.emitChangeEvent();
  }

  setDate(event: Moment) {
    if (!isMoment(event)) return;
    let date = event.toDate();
    this.year = date.getFullYear();
    this.month = date.getMonth();
    this.date = date.getDate();
    this.value = this.getDateTime();
    this.emitChangeEvent();
  }

  getDateTime() {
    return new Date(this.year, this.month, this.date, this.hours, this.minutes);
  }

  hasError(form: NgForm, control: string, error: string) {
    if (!form) return;
    return form.form.controls[control]?.hasError(error);
  }
}

@Directive({
  selector: '[minDateTime]',
  providers: [
    { provide: NG_VALIDATORS, multi: true, useExisting: MinDateDirective },
  ],
})
export class MinDateDirective implements Validator, OnChanges {
  @Input('minDateTime') minDate: Date | null;
  onValidatorChange = () => {};

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.minDate) {
      this.onValidatorChange();
    }
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    if (!control.value || !this.minDate) return null;
    const valid = control.value.valueOf() - this.minDate.valueOf() >= 0;

    return valid
      ? null
      : { minDate: { min: this.minDate, actal: control.value } };
  }
}
