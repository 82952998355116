import { Component, Input } from '@angular/core';
import {
  defaultPasswordPolicy,
  PasswordPolicy,
  PasswordValidationErrors,
} from '@shared/validators/password.validator';
import { AbstractControl, NgModel } from '@angular/forms';

@Component({
  selector: 'app-password-rules',
  templateUrl: './password-rules.component.html',
  styleUrls: ['./password-rules.component.scss'],
})
export class PasswordRulesComponent {
  @Input() control: AbstractControl | NgModel;

  _policy = defaultPasswordPolicy;
  @Input()
  set policy(_policy: PasswordPolicy) {
    this._policy = { ...defaultPasswordPolicy, ..._policy };
  }

  get policy(): PasswordPolicy {
    return this._policy;
  }

  get errors(): PasswordValidationErrors | null {
    return this.control?.errors;
  }

  get touched(): boolean {
    return this.control?.touched || !this.control?.pristine;
  }
}
