import { Injectable } from '@angular/core';
import { Crud, CrudService } from './crud.service';
import { environment } from '@environments/environment';
import { Approval } from '../models/approval';
import { HttpClient } from '@angular/common/http';

export enum ApprovalSortField {
  NAME = 'systemUser.surname',
  EMAIL = 'systemUser.email',
  ORGANIZATION = 'orgId.shortName',
  APPROVER = 'contact.surname',
  APPROVAL_DATE = 'approvalDate',
  EXPIRATION_DATE = 'expirationDate',
  CREATED_DATE = 'createdDate',
  LAST_MODIFIED_DATE = 'lastModifiedDate',
}

export const ApprovalSortFieldLabels: { [key in ApprovalSortField]: string } = {
  [ApprovalSortField.NAME]: 'Name',
  [ApprovalSortField.EMAIL]: 'Email',
  [ApprovalSortField.ORGANIZATION]: 'Organization',
  [ApprovalSortField.APPROVER]: 'Approver',
  [ApprovalSortField.APPROVAL_DATE]: 'Approval Date',
  [ApprovalSortField.EXPIRATION_DATE]: 'Approval Expiration',
  [ApprovalSortField.CREATED_DATE]: 'Created Date',
  [ApprovalSortField.LAST_MODIFIED_DATE]: 'Last Modified Date',
};

@Injectable({
  providedIn: 'root',
})
@Crud({
  apiUrl: `${environment.apiUrl}/orgAdminApproval`,
  hasIdPathUpdate: true,
  entity: 'Approval',
})
export class ApprovalService extends CrudService<Approval> {
  constructor(http: HttpClient) {
    super(http);
  }

  getBySystemUserId(userId: string) {
    return this.http.get(`${this.apiUrl}/bySystemUserId/${userId}`, {
      context: this.context,
    });
  }
}
