import { Pipe, PipeTransform } from '@angular/core';
import { Role, RoleLabels } from '../models';

@Pipe({
  name: 'roleLabel',
})
export class RolePipe implements PipeTransform {
  transform(data: Role): string {
    return RoleLabels[data];
  }
}
