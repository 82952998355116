import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { FAR, FarPurposeType, FarPurposeTypeIcons } from '@app/shared/models';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-far-list',
  standalone: true,
  imports: [CommonModule, SharedModule, RouterModule],
  templateUrl: './far-list.component.html',
  styleUrls: ['./far-list.component.scss'],
})
export class FarListComponent {
  @Input() fars: FAR[] = [];
  @Input() routerNavEnabled: boolean = true;
  FarPurposeType = FarPurposeType;
  FarPurposeTypeIcons = FarPurposeTypeIcons;
}
