/* eslint-disable prettier/prettier */
import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ScreeningService } from '@app/shared/services';
import { Color, LegendPosition, ScaleType } from '@swimlane/ngx-charts';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-derogratory-graph',
  templateUrl: './derogratory-graph.component.html',
  styleUrls: ['./derogratory-graph.component.scss'],
})
export class DerogratoryGraphComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  derogMulti: any[] = [];
  originalMulti: any[];
  loading: boolean = false;
  startDate: string = '';
  endDate: string = '';
  today: Date = new Date();
  datepickerUnit: 'DAY' | 'WEEK' | 'MONTH' = 'DAY';
  datepickerUnitRange: any = {
    DAY: 31,
    WEEK: 180,
    MONTH: 365,
  };
  dateRangeExceeded: boolean = false;
  display: boolean = true;

  derogatoryCounts: any = {
    offenseCodeTypeMACount: 0,
    offenseCodeTypeAMCount: 0,
    offenseCodeTypeKTCount: 0,
    offenseCodeTypeKACount: 0,
    offenseCodeTypeTSCount: 0,
    offenseCodeTypeTACount: 0,
    offenseCodeTypeCACount: 0,
    offenseCodeTypeCYCount: 0,
    offenseCodeTypeEPCount: 0,
    offenseCodeTypeOTCount: 0,
    offenseCodeTypeEFCount: 0,
    offenseCodeTypeNCCount: 0,
    offenseCodeTypeNTCount: 0,
    offenseCodeTypeSMCount: 0,
    offenseCodeTypeTRCount: 0,
    offenseCodeTypeVCCount: 0,
  };

  derogatoryResultDataPoints: DerogatoryResultDataPointType[] = [
    {
      label: 'Main Subject',
      value: 'offenseCodeTypeMACount',
      show: true,
    },
    {
      label: 'Main Subject Associate',
      value: 'offenseCodeTypeAMCount',
      show: true,
    },
    {
      label: 'Known Terrorist',
      value: 'offenseCodeTypeKTCount',
      show: true,
    },
    {
      label: 'Known Associate',
      value: 'offenseCodeTypeKACount',
      show: true,
    },
    {
      label: 'Terrorism Support',
      value: 'offenseCodeTypeTSCount',
      show: true,
    },
    {
      label: 'Tenuous Associate',
      value: 'offenseCodeTypeTACount',
      show: true,
    },
    {
      label: 'WMD materials',
      value: 'offenseCodeTypeCACount',
      show: true,
    },
    {
      label: 'Malicious Cyber',
      value: 'offenseCodeTypeCYCount',
      show: true,
    },
    {
      label: 'Foreign Intel Officer',
      value: 'offenseCodeTypeEPCount',
      show: true,
    },
    {
      label: 'Domestic Terrorism',
      value: 'offenseCodeTypeOTCount',
      show: true,
    },
    {
      label: 'Explosives or Firearms',
      value: 'offenseCodeTypeEFCount',
      show: true,
    },
    {
      label: 'Non-Violent Criminal',
      value: 'offenseCodeTypeNCCount',
      show: true,
    },
    {
      label: 'Narcotics Trafficker',
      value: 'offenseCodeTypeNTCount',
      show: true,
    },
    {
      label: 'Smuggling',
      value: 'offenseCodeTypeSMCount',
      show: true,
    },
    {
      label: 'Trafficking',
      value: 'offenseCodeTypeTRCount',
      show: true,
    },
    {
      label: 'Violent Crime',
      value: 'offenseCodeTypeVCCount',
      show: true,
    },
  ];

  // // options bar
  view: [number, number] = [999, 455];
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Date';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Count';
  below: LegendPosition = LegendPosition.Below;
  legendTitle: string = 'Derogatory Categories';
  timeline: boolean = true;
  totalCount: number = 0;

  colorScheme: Color = {
    name: 'myScheme',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: [
      '#00acc1',
      '#4caf50',
      '#f44336',
      '#fdd835',
      '#6a1b9a',
      '#4CBB17',
      '#006400',
      '#0037c1',
      '#c100c1',
      '#c1007a',
      '#b4c100',
      '#c1a700',
      '#c18400',
      '#c13a00',
      '#81c100',
      '#3000c1',
    ],
  };

  constructor(private screenings: ScreeningService) {}

  ngOnInit() {
    this.dateRangeExceeded = false;
    this.endDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US', 'GMT');
    let calcStart = new Date();
    calcStart.setDate(calcStart.getDate() - 7);
    this.startDate = formatDate(calcStart, 'yyyy-MM-dd', 'en-US', 'GMT');
    this.getScreeningResultsMetrics();
  }

  getScreeningResultsMetrics() {
    this.startDate = formatDate(
      new Date(this.startDate),
      'yyyy-MM-dd',
      'en-US',
      'GMT'
    );
    this.endDate = formatDate(
      new Date(this.endDate),
      'yyyy-MM-dd',
      'en-US',
      'GMT'
    );

    if (this.dateRangeValidCheck()) {
      this.loading = true;
      this.screenings.findFvsMetricsByDerogatory(
        this.startDate,
        this.endDate,
        this.datepickerUnit
      )
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((metrics) => {
          this.derogMulti = this.translatetoBarGraph(metrics);
          this.originalMulti = JSON.parse(JSON.stringify(this.derogMulti));
          this.loading = false;
        });
    }
  }

  dateRangeValidCheck() {
    this.dateRangeExceeded = false;
    let maxRange = this.datepickerUnitRange[this.datepickerUnit];
    let futureDate = new Date(this.startDate);
    futureDate.setDate(futureDate.getDate() + maxRange);

    if (new Date(this.endDate) < futureDate) return true;

    this.dateRangeExceeded = true;
    return false;
  }

  translatetoBarGraph(metrics: any) {
    let metricsData = Object.keys(metrics);
    metricsData = Array.from(new Set(metricsData));
    metricsData.sort((a, b) => (a > b ? 1 : -1));
    let formattedData: any[] = [];
    let series: { name: string; value: any; }[] = [];
    let item: any = {};
    this.resetScreeningCount();
   for (let mData of metricsData) {

          Object.entries(metrics).filter((x) => {

              if ( x[0] === mData ) {
                this.totalCount = 0;

                for ( let derogPoints of this.derogatoryResultDataPoints) {
                  this.derogatoryCounts[derogPoints.value] = this.derogatoryCounts[derogPoints.value] + metrics[mData][derogPoints.value];
                  this.totalCount = this.totalCount + metrics[mData][derogPoints.value];
                  series.push({
                    name: derogPoints.label,
                    value: metrics[mData][derogPoints.value] ,
                  });

                }

                if (this.totalCount > 0) {
                  item = {
                    name: mData,
                    series: series,
                  };
                  formattedData.push(item);
                  this.totalCount = 0;
                } else {
                  item = this.zeroOutValues(mData, metricsData);
                  this.totalCount = 0;
                  formattedData.push(item);
                }



              }
              series = [];

          });



    }
    return formattedData;
  }

  zeroOutValues(uData: string, metrics: any): any {
    let metricsData = Object.keys(metrics);
    metricsData = Array.from(new Set(metricsData));
    metricsData.sort((a, b) => (a > b ? 1 : -1));
    let series = [];
    for (let mData of metricsData) {
      let index = this.derogatoryResultDataPoints.findIndex(
        (item: { value: string }) => item.value === mData
      );
      if (index > -1) {
        let seriesItem = this.derogatoryResultDataPoints.at(index)!;
        series.push({
          name: seriesItem?.label,
          value: 0,
        });
      }
    }

    return { name: uData, series: series };
  }

  resetScreeningCount() {
    this.derogatoryCounts.offenseCodeTypeMACount = 0;
    this.derogatoryCounts.offenseCodeTypeAMCount = 0;
    this.derogatoryCounts.offenseCodeTypeKTCount = 0;
    this.derogatoryCounts.offenseCodeTypeKACount = 0;
    this.derogatoryCounts.offenseCodeTypeTSCount = 0;
    this.derogatoryCounts.offenseCodeTypeTACount = 0;
    this.derogatoryCounts.offenseCodeTypeCACount = 0;
    this.derogatoryCounts.offenseCodeTypeCYCount = 0;
    this.derogatoryCounts.offenseCodeTypeEPCount = 0;
    this.derogatoryCounts.offenseCodeTypeOTCount = 0;
    this.derogatoryCounts.offenseCodeTypeEFCount = 0;
    this.derogatoryCounts.offenseCodeTypeNCCount = 0;
    this.derogatoryCounts.offenseCodeTypeNTCount = 0;
    this.derogatoryCounts.offenseCodeTypeSMCount = 0;
    this.derogatoryCounts.offenseCodeTypeTRCount = 0;
    this.derogatoryCounts.offenseCodeTypeVCCount = 0;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSelect(data: any): void {
    if (typeof data === 'string') {
      let tempData = JSON.parse(JSON.stringify(this.derogMulti));
      let selected = this.findDataParameter(data);
      if (selected) {
        selected.show = !selected.show;
        if (!selected.show) {
          for (let item of tempData) {
            for (let i = 0; i < item.series.length; ++i) {
              if (item.series[i].name === data) {
                item.series[i].value = 0;
              }
            }
          }
        } else {
          for (let i = 0; i < tempData.length; ++i) {
            for (let j = 0; j < tempData[i].series.length; ++j) {
              if (tempData[i].series[j].name === data) {
                tempData[i].series[j].value =
                  this.originalMulti[i].series[j].value;
                break;
              }
            }
          }
        }
      }
      this.derogMulti = tempData;
    }
  }

  findDataParameter(event: any) {
    const selectedBar = this.derogatoryResultDataPoints.find((model: any) => {
      return model.label === event;
    });

    return selectedBar;
  }
}

export interface DerogatoryResultDataPointType {
  label: string;
  value: string;
  show: boolean;
}
