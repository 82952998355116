import { Directive, ElementRef, AfterViewInit, Input } from '@angular/core';

@Directive({
  selector: '[stopPropagation]',
})
export class StopPropagationDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  @Input() stopPropagation?: string[];

  public ngAfterViewInit() {
    const eventTypes =
      !this.stopPropagation || !this.stopPropagation.length
        ? ['click']
        : this.stopPropagation;
    eventTypes.forEach((eventName) => {
      this.elementRef.nativeElement.addEventListener(
        eventName,
        (event: Event) => {
          event.stopPropagation();
        }
      );
    });
  }
}
