<mat-card
  appearance="outlined"
  [class]="value | screeningClassList | default"
  class="text-center text-color-white"
  [ngClass]="{
    'text-color-black': value.result && value.result === ScreeningResult.Yellow
  }"
>
  <mat-card-header class="justify-content-center">
    <mat-icon
      class="clickable-icon text-color-white"
      [ngClass]="{
        'text-color-black':
          value.result && value.result === ScreeningResult.Yellow
      }"
      >open_in_new</mat-icon
    >
    <mat-card-title></mat-card-title>
    <mat-card-subtitle
      data-cy="insight-value"
      class="text-color-white"
      [ngClass]="{
        'text-color-black':
          value.result && value.result === ScreeningResult.Yellow
      }"
      ><h5>
        {{ value | screeningLabel: "result" | default }}
      </h5></mat-card-subtitle
    >
  </mat-card-header>
  <mat-card-content
    data-cy="insight-label"
    class="text-color-white"
    [ngClass]="{
      'text-color-black':
        value.result && value.result === ScreeningResult.Yellow
    }"
    ><span>{{ getDateType(value.status) }}</span
    >{{ value | screeningDate | date: ScreeningDateFormat }}
  </mat-card-content>
</mat-card>
