<div
  class="
    row
    justify-content-between
    align-items-center
    panel-banner
    px-2
    header-padding-override
  "
  id="toggleInsights"
>
  <div class="d-flex">
    <mat-icon class="mr-2">device_hub</mat-icon> Insights
  </div>
  <button
    class="text-white"
    [disabled]="loading"
    mat-button
    matTooltip="Refresh"
    matTooltipPosition="left"
    (click)="refresh()"
  >
    <ng-container *ngIf="!loading">
      <div class="d-flex align-items-center">
        <mat-icon
          svgIcon="refresh"
          class="d-flex align-self-baseline"
        ></mat-icon>
        <span class="ml-1">Refresh</span>
      </div>
    </ng-container>
    <mat-spinner color="accent" *ngIf="loading" [diameter]="35"></mat-spinner>
  </button>
</div>

<div class="row mt-2 ml-1 mr-1">
  <div class="col pl-1 pr-1">
    <insight-card
      id="fv-count"
      [label]="'Foreign Nationals'"
      [value]="metrics.totalCount || 0"
      [clickable]="metrics.totalCount > 0"
      [routerLink]="metrics.totalCount ? ['fvs'] : []"
      matTooltip="Click to view all Foreign Nationals."
    ></insight-card>
  </div>
  <div class="col pl-1 pr-1">
    <insight-card
      id="far-count"
      [label]="'Foreign Access Requests'"
      [value]="metrics.farCount || 0"
      [clickable]="metrics.farCount > 0"
      [routerLink]="metrics.farCount ? ['fars'] : []"
      matTooltip="Click to view all Foreign Access Requests."
    ></insight-card>
  </div>
  <div class="col pl-1 pr-1">
    <insight-card
      id="fv-derog-count"
      [label]="'Foreign Nationals with Derogatory Findings'"
      [clickable]="metrics.redResultCount > 0"
      [value]="metrics.redResultCount || 0"
      [routerLink]="metrics.redResultCount ? ['fvs'] : []"
      [queryParams]="metrics.redResultCount ? { result: 'RED' } : null"
      matTooltip="Click to view all Foreign Nationals with Derogatory Findings."
    ></insight-card>
  </div>
  <div class="col pl-1 pr-1">
    <insight-card
      id="fv-screen-count"
      [label]="'Foreign Nationals - Screened'"
      [value]="metrics.completedStatusCount || 0"
      [clickable]="metrics.completedStatusCount > 0"
      [routerLink]="metrics.completedStatusCount ? ['fvs'] : []"
      [queryParams]="
        metrics.completedStatusCount ? { status: 'Completed' } : null
      "
      matTooltip="Click to view all screened Foreign Nationals."
    ></insight-card>
  </div>
  <div class="col pl-1 pr-1">
    <insight-card
      id="fv-unscreened-count"
      [label]="'Foreign Nationals - Ineligible Screenings'"
      [value]="metrics.ineligibleCount || 0"
      [clickable]="metrics.ineligibleCount > 0"
      [routerLink]="metrics.ineligibleCount ? ['fvs'] : []"
      [queryParams]="
        metrics.ineligibleCount ? { screeningEligible: false } : null
      "
      matTooltip="Click to view all Foreign Nationals - Ineligible."
    ></insight-card>
  </div>
  <div class="col pl-1 pr-1">
    <insight-card
      id="fv-inprocess-count"
      [label]="'Foreign Nationals - Screening In Process'"
      [value]="metrics.inProcessStatusCount || 0"
      [clickable]="metrics.inProcessStatusCount > 0"
      [routerLink]="metrics.inProcessStatusCount ? ['fvs'] : []"
      [queryParams]="
        metrics.inProcessStatusCount ? { status: 'In Process' } : null
      "
      matTooltip="Click to view all Foreign Nationals whose screening is in process."
    ></insight-card>
  </div>
  <div class="col pl-1 pr-1">
    <insight-card
      id="fv-inprocess-count"
      [label]="'Foreign Nationals - Screening Errors'"
      [value]="metrics.errorStatusCount || 0"
      [clickable]="metrics.errorStatusCount > 0"
      [routerLink]="metrics.errorStatusCount ? ['fvs'] : []"
      [queryParams]="metrics.errorStatusCount ? { status: 'Error' } : null"
      matTooltip="Click to view all Foreign Nationals whose screening has an error."
    ></insight-card>
  </div>
  <div class="col pl-1 pr-1">
    <insight-card
      id="fv-inprocess-count"
      [label]="'Foreign Nationals - Machine Red'"
      [value]="metrics.machineRedResultCount || 0"
      [clickable]="metrics.machineRedResultCount > 0"
      [routerLink]="metrics.machineRedResultCount ? ['fvs'] : []"
      [queryParams]="
        metrics.machineRedResultCount ? { result: 'MACHINE_RED' } : null
      "
      matTooltip="Click to view all Foreign Nationals whose screening is Machine Red."
    ></insight-card>
  </div>
  <div class="col pl-1 pr-1">
    <insight-card
      id="fv-vip-count"
      [label]="'Expedited Foreign National Requests'"
      [value]="metrics.vipCount || 0"
      [clickable]="metrics.vipCount > 0"
      [routerLink]="metrics.vipCount ? ['fvs'] : []"
      [queryParams]="metrics.vipCount ? { vip: 'true' } : null"
      matTooltip="Click to view all Expedited Foreign National Requests."
    ></insight-card>
  </div>
</div>
