<ng-container *ngIf="value">
  <mat-form-field appearance="outline">
    <mat-label>Event Title</mat-label>
    <input
      matInput
      placeholder="Enter a descriptive title"
      [(ngModel)]="value.eventTitle"
      (ngModelChange)="emitChangeEvent()"
      required
      maxlength="40"
      data-cy="event-title"
    />
    <mat-error>Event title is required</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Purpose Type</mat-label>
    <mat-select
      id="purposeType"
      required
      [(ngModel)]="value.purposeType"
      (ngModelChange)="emitChangeEvent(); checkLocationRequirement()"
      aria-label="Please select an option"
      color="primary"
    >
      <mat-select-trigger
        *ngIf="value.purposeType === FarPurposeType.FACILITY_ACCESS"
      >
        <span class="d-flex align-items-center"
          >USG {{ FarPurposeType.FACILITY_ACCESS | titlecase }}
          <mat-icon
            class="ml-1"
            [matTooltip]="FarPurposeTypeTooltip[FarPurposeType.FACILITY_ACCESS]"
            >help</mat-icon
          ></span
        >
      </mat-select-trigger>
      <mat-option selected disabled>Select a Purpose Type</mat-option>
      <mat-option [value]="FarPurposeType.IN_PERSON_MEETING">
        {{ FarPurposeType.IN_PERSON_MEETING | titlecase }} ({{
          FarPurposeTypeTooltip[FarPurposeType.IN_PERSON_MEETING]
        }})
      </mat-option>
      <mat-option [value]="FarPurposeType.VIRTUAL_MEETING">
        {{ FarPurposeType.VIRTUAL_MEETING | titlecase }}
      </mat-option>
      <mat-option [value]="FarPurposeType.SYSTEM_ACCESS">
        {{ FarPurposeType.SYSTEM_ACCESS | titlecase }} ({{
          FarPurposeTypeTooltip[FarPurposeType.SYSTEM_ACCESS]
        }})
      </mat-option>
      <mat-option [value]="FarPurposeType.FACILITY_ACCESS">
        <span class="d-flex align-items-center"
          >USG {{ FarPurposeType.FACILITY_ACCESS | titlecase }}
          <mat-icon
            class="ml-1"
            [matTooltip]="FarPurposeTypeTooltip[FarPurposeType.FACILITY_ACCESS]"
            >help</mat-icon
          ></span
        >
      </mat-option>
      <!--<mat-option [value]="FarPurposeType.OTHER">
        {{ FarPurposeType.OTHER | titlecase }}
      </mat-option>-->
    </mat-select>
    <mat-error>Please select a Purpose Type</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" floatLabel="always">
    <mat-label>Event Description</mat-label>
    <textarea
      matInput
      [(ngModel)]="value.description"
      placeholder="General details of the purpose of visit"
      maxlength="2000"
      data-cy="event-purpose"
      rows="5"
    ></textarea>
  </mat-form-field>
</ng-container>
