import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EmptyStateComponent {
  @Input() message: string = 'No Information Available';
}
