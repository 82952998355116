import { Component } from '@angular/core';

@Component({
  selector: 'app-no-result-found',
  template: `
    <div class="text-center">
      <div class="mat-headline-3">No Results Found</div>
      <mat-icon svgIcon="table-search" class="md-64"></mat-icon>
    </div>
  `,
})
export class NoResultFoundComponent {}
