import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Inject,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FarLocation } from '@app/shared/models';
import { FarLocationService } from '@app/shared/services';
import { ItineraryControlComponent } from './itinerary-control.component';

@Component({
  selector: 'app-itinerary-dialog',
  templateUrl: './itinerary-dialog.component.html',
})
export class ItineraryDialogComponent implements AfterViewChecked {
  @ViewChild('itineraryControl') itineraryControl: ItineraryControlComponent;
  busy: boolean = false;
  public location: FarLocation;
  public locationRequired: boolean = true;
  public farOwningOrgId: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public locationData: any,
    private locationService: FarLocationService,
    private dialogRef: MatDialogRef<ItineraryDialogComponent>,
    private changeDetect: ChangeDetectorRef
  ) {
    this.location = locationData.location;
    this.locationRequired = locationData.locationRequired;
    this.farOwningOrgId = locationData.farOwningOrgId;
  }

  ngAfterViewChecked(): void {
    this.changeDetect.detectChanges();
  }

  onSubmit() {
    this.busy = true;
    this.locationService.save(this.location).subscribe(
      (location) => {
        this.dialogRef.close(location);
      },
      () => {
        this.busy = false;
      }
    );
  }
}
