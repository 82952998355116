<ng-container *ngIf="fv.otherNames && fv.otherNames.length > 0; else noNames">
  <div class="d-flex justify-content-end">
    <button
      data-cy="reset-additional-info-button"
      *ngIf="editMode"
      id="reset-additional-info-button"
      mat-button
      matTooltip="Reset Additional Information"
      matTooltipPosition="left"
      (click)="onReset()"
      class="text-danger"
    >
      <div class="d-flex align-items-center gap-1">
        <mat-icon svgIcon="close-circle-outline"></mat-icon>
        <span>Reset</span>
      </div>
    </button>
    <button
      data-cy="add-additional-info-button"
      *ngIf="fv.isEditable && !editMode"
      id="additional-info-button"
      mat-button
      matTooltip="Add Additional Information"
      matTooltipPosition="left"
      (click)="onAdd()"
      color="primary"
    >
      <div class="d-flex align-items-center gap-1">
        <mat-icon svgIcon="plus-circle-outline"></mat-icon>
        <span>Add</span>
      </div>
    </button>
  </div>
  <div class="container">
    <ng-container *ngFor="let info of fv.otherNames; index as i">
      <form #additionalInfoForm="ngForm">
        <div
          class="row mr-0"
          [style]="!fv.isEditable && i === 0 ? 'margin-top: 0.5rem' : ''"
        >
          <div class="col-3 pr-1 pl-0">
            <mat-form-field [appearance]="'outline'" class="w-100">
              <mat-label>Type</mat-label>
              <mat-select
                [id]="'type-' + i"
                [name]="'type-' + i"
                #infoType
                [(ngModel)]="info.type"
                (ngModelChange)="clearInfoModel(info)"
                [disabled]="!info.editMode"
                aria-label="Info Type"
              >
                <mat-option value="name">Name</mat-option>
                <mat-option value="dob">Birth Date</mat-option>
                <mat-option value="birthCountry">Birth Country</mat-option>
              </mat-select>
              <mat-error> Please select an info type</mat-error>
            </mat-form-field>
          </div>
          <ng-container *ngIf="info.type === 'name'">
            <div class="col-4 px-1">
              <mat-form-field [appearance]="'outline'" class="w-100">
                <mat-label>Given Name</mat-label>
                <input
                  [disabled]="!info.editMode"
                  matInput
                  [id]="'givenName-' + i"
                  [name]="'givenName-' + i"
                  placeholder="Given Name"
                  maxlength="50"
                  [(ngModel)]="info.givenName"
                  required
                />
                <mat-error>Add a given name</mat-error>
              </mat-form-field>
            </div>
            <div class="col-4 px-1">
              <mat-form-field [appearance]="'outline'" class="w-100">
                <mat-label>Surname</mat-label>
                <input
                  [disabled]="!info.editMode"
                  matInput
                  [id]="'surname-' + i"
                  [name]="'surname-' + i"
                  placeholder="Surname"
                  maxlength="50"
                  [(ngModel)]="info.surname"
                  required
                />
                <mat-error>Add a surname</mat-error>
              </mat-form-field>
            </div>
          </ng-container>
          <div class="col-8 px-1" *ngIf="info.type === 'dob'">
            <mat-form-field [appearance]="'outline'" class="w-100">
              <mat-label for="dateOfBirth">Date Of Birth</mat-label>
              <input
                id="dateOfBirth"
                required
                matInput
                #dateOfBirthControl="ngModel"
                [matDatepicker]="dobPicker"
                [(ngModel)]="info.dateOfBirth"
                name="dateOfBirth"
                [max]="today"
                placeholder="MM/DD/YYYY"
                aria-label="date of birth"
                [disabled]="!info.editMode"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="dobPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #dobPicker></mat-datepicker>
              <mat-error> Please add a valid date</mat-error>
            </mat-form-field>
          </div>
          <div class="col-8 px-1" *ngIf="info.type === 'birthCountry'">
            <app-country-select
              [id]="'birthCountry-' + i"
              [required]="true"
              #birthCountryCtl="ngModel"
              [(ngModel)]="info.birthCountry"
              [name]="'birthCountry-' + i"
              [placeholder]="'Country of Birth'"
              floatLabel="auto"
              label="Country of Birth"
              [disabled]="!info.editMode"
              [excludeUS]="true"
              [includeOrgs]="false"
            >
            </app-country-select>
          </div>
          <div class="col-1 pl-1 pr-0">
            <button
              *ngIf="!info.editMode && !editMode"
              mat-icon-button
              (click)="onEdit(info)"
              matTooltip="Edit"
              [disabled]="!fv.isEditable"
            >
              <mat-icon svgIcon="square-edit-outline"></mat-icon>
            </button>
            <button
              matTooltip="Submit"
              *ngIf="info.editMode"
              [disabled]="hasError(info)"
              mat-mini-fab
              color="primary"
              class="mt-1"
              style="border-radius: 5px"
              (click)="onSubmit(info)"
            >
              <mat-icon
                class="text-white"
                svgIcon="check-circle-outline"
              ></mat-icon>
            </button>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
</ng-container>
<ng-template #noNames>
  <app-empty-state data-cy="empty-state-alias" message="No Records">
    <button
      data-cy="add-fv-name-button"
      *ngIf="fv.isEditable"
      mat-flat-button
      color="accent"
      id="add-fv-alias"
      (click)="onAdd()"
    >
      <span>Add Additional Information</span>
    </button>
  </app-empty-state>
</ng-template>
