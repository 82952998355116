import { Component, Input } from '@angular/core';
import { FAR, ForeignVisitor, Role } from '@app/shared/models';

@Component({
  selector: 'app-info-banner',
  templateUrl: './info-banner.component.html',
  styleUrls: ['./info-banner.component.scss'],
})
export class InfoBannerComponent {
  @Input() far?: FAR;
  @Input() fv?: ForeignVisitor;
  Role = Role;
}
