import { Component, Input, ViewChild } from '@angular/core';
import { Role, Organization } from '@app/shared/models';
import { NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import { BaseControlComponent } from '@app/shared/controls/base-control.component';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'app-organization-form',
  templateUrl: './organization-form.component.html',
  styleUrls: ['./organization-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: OrganizationFormComponent,
      multi: true,
    },
  ],
})
export class OrganizationFormComponent extends BaseControlComponent<Organization> {
  @ViewChild('organizationForm') organizationForm: NgForm;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  Role = Role;
  invalid: boolean = true;

  constructor() {
    super();
  }

  writeValue(value: Organization): void {
    this.value = value || {};
  }

  get valid(): boolean {
    return this.organizationForm?.valid ?? true;
  }
}
