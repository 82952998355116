import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { PageableCollection } from '@app/shared/models/pageable-collection.model';
import { FilterParams, Group, UserPreferenceBlob } from '@app/shared/models';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import {
  CrudService,
  GroupService,
  GroupSortField,
  GroupSortLabels,
  GroupTableColumns,
  Repository,
} from '@app/shared/services';
import { debounceTime, take, tap } from 'rxjs/operators';
import { UserTableName } from '@app/admin/accounts/accounts-collection/account-user-table/account-user-table.component';
import { UserPreferenceService } from '@app/shared/services/user-preference.service';
import { AuthService } from '@app/auth/auth.service';
import { GroupTableName } from '../collection/group-collection-table/group-collection-table.component';

@Component({
  selector: 'app-groups',
  templateUrl: 'groups.component.html',
  styleUrls: ['groups.component.scss'],
  providers: [Repository, { provide: CrudService, useClass: GroupService }],
})
export class GroupsComponent implements OnInit, AfterViewChecked {
  loading: boolean = false;
  filterParams: FilterParams = new FilterParams({
    pageNum: 0,
    pageSize: 20,
    sortBy: 'createdDate',
    direction: 'DESC',
  });
  groups$: Observable<PageableCollection<Group>> = this.repo.collection$.pipe(
    tap((_) => {
      this.loading = false;
    })
  );
  queryParamsArray: Array<string> = [];
  hasUserPreference: boolean = false;
  userPreference: UserPreferenceBlob | undefined;
  constructor(
    private repo: Repository<Group>,
    private changeDetect: ChangeDetectorRef,
    private auth: AuthService,
    private userPreferenceService: UserPreferenceService
  ) {}
  ngOnInit(): void {
    let userId = this.auth.getUser()?.id || '';
    this.filterParams.createdBy = userId;
    Object.assign(this.filterParams, this.queryParamsSnapshot);
    this.filterParams.direction = 'DESC';
    this.onFilterParamsChange(this.filterParams);
    this.getUserPreferences();
  }
  getUserPreferences() {
    this.userPreferenceService
      .getCurrentUserId(this.auth?.getUser()?.id!)
      .pipe(debounceTime(1000), take(1))
      .subscribe(
        (result) => {
          if (result !== null && result !== undefined) {
            this.hasUserPreference = true;
            this.userPreference = JSON.parse(result.userPreferenceBlob!);
            let tabDetails = this.userPreference?.tabs.at(4);
            let groupArray: Array<string> = [];
            tabDetails?.tabColumns.filter((x) => {
              if (x.selected === true) {
                groupArray.push(x.label);
              }
            });

            Object.entries(GroupSortLabels).forEach((key, value) => {
              if (groupArray.includes(key[1])) {
                this.queryParamsArray.push(key[0]);
              }
            });
            if (
              this.userPreference !== null &&
              this.userPreference !== undefined
            ) {
              this.userPreferenceService.loadDisplayColumns(
                GroupSortLabels,
                groupArray,
                GroupTableColumns,
                GroupTableName,
                this.auth?.getUser()?.roles!,
                this.auth.getUser()?.networkDomain!
              );
            }
          }
        },
        (err) => {}
      );
  }
  ngAfterViewChecked(): void {
    this.changeDetect.detectChanges();
  }

  get queryParamsSnapshot(): Params {
    return this.repo.getQueryParamsSnapshot();
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.navigateWithParams(filterParams);
  }
}
