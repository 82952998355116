import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { BaseControlComponent } from '@app/shared/controls';
import { hasValues } from '@app/shared/helpers';
import { DocumentId } from '@app/shared/models/document-id.model';

@Component({
  selector: 'app-document-id',
  templateUrl: './document-id.component.html',
  styleUrls: ['./document-id.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DocumentIdComponent,
      multi: true,
    },
  ],
})
export class DocumentIdComponent
  extends BaseControlComponent<DocumentId>
  implements OnInit
{
  @Input() editable: boolean = true;
  @Input() deletable?: boolean = false;
  isDiplomaticPassport: boolean = false;
  @Input() showClearButton: boolean = true;
  @Input() minIssueDate?: string;
  @ViewChild('documentForm')
  documentForm: NgForm;
  appearance: MatFormFieldAppearance = 'outline';
  today = new Date();
  value: DocumentId = {};
  @Input() parentId: string;
  @Input() type: 'DIPLOMAT' | 'DACA' = 'DIPLOMAT';
  typeLabel = DocumentTypeLabel;

  constructor() {
    super();
  }

  addDocumentData() {
    this.emitChangeEvent();
  }

  checkDocumentData() {
    // Setting isPassport for diplomatic passport component
    this.isDiplomaticPassport = this.hasData() ? false : true;
    this.emitChangeEvent();
  }

  getEmitValue(): DocumentId | null {
    return this.hasData() ? this.value : null;
  }

  hasData() {
    return hasValues(this.value);
  }

  ngOnInit() {
    this.value = {};
  }

  writeValue(_value: DocumentId): void {
    super.writeValue(_value);
    this.value = this.value || {};
  }
}

export enum DocumentTypeLabel {
  DIPLOMAT = 'Diplomatic',
  DACA = 'DACA',
}
