import { Component, Input, ViewChild } from '@angular/core';
import { DerogatoryInformation } from '@app/shared/models';
import { AppConfigService } from '@app/shared/services';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-screening-code-display',
  templateUrl: './screening-code-display.component.html',
  styleUrls: ['./screening-code-display.component.scss'],
})
export class ScreeningCodeDisplayComponent {
  @ViewChild('derogTable') derogTable: MatTable<DerogatoryInformation>;
  @Input() derogItems: DerogatoryInformation[] = [];
  @Input() overrideCode: boolean = false;

  screeningCodeUrl = this.config.get('screeningCodeLink');
  screeningCodeColumns: string[] = [
    'classification',
    'program',
    'code',
    'rationale',
  ];
  overrideCodeCol: string[] = [...this.screeningCodeColumns, 'actions'];

  constructor(private config: AppConfigService) {}

  onAdd(code: DerogatoryInformation) {
    this.derogItems.push(code);
    this.refreshCodes();
  }

  onEdit(derogCode: DerogatoryInformation, index: number) {
    this.derogItems[index] = derogCode;
    this.refreshCodes();
  }

  refreshCodes() {
    this.derogTable.renderRows();
  }

  removeCode(index: number) {
    this.derogItems.splice(index, 1);
    this.refreshCodes();
  }
}
