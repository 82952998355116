import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { MatSortModule } from '@angular/material/sort';
import { OrganizationsRoutingModule } from './organization-routing.module';
import { OrganizationCollectionTableComponent } from './collection/organization-collection-table/organization-collection-table.component';
import { OrganizationDialogComponent } from './collection/organization-dialog/organization-dialog.component';
import { OrganizationFormComponent } from './collection/organization-form/organization-form.component';
import { OrganizationsCollectionComponent } from '../organizations/collection/collection.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { ContactDialogComponent } from './collection/contact-dialog/contact-dialog.component';
import { ContactFormComponent } from './collection/contact-form/contact-form.component';
import { ContactTableComponent } from './collection/organization-collection-table/contact-table/contact-table.component';
@NgModule({
  declarations: [
    ContactDialogComponent,
    ContactFormComponent,
    OrganizationCollectionTableComponent,
    OrganizationDialogComponent,
    OrganizationFormComponent,
    OrganizationsComponent,
    OrganizationsCollectionComponent,
    ContactTableComponent,
  ],
  imports: [
    SharedModule,
    OrganizationsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
  ],
  exports: [OrganizationsComponent, OrganizationsCollectionComponent],
})
export class OrganizationsModule {}
