<span>
  <mat-form-field appearance="outline" subscriptSizing="dynamic">
    <button
      *ngIf="activeSort"
      id="sort-direction-location-btn"
      matPrefix
      mat-icon-button
      class="sort-toggle-btn"
      color="primary"
      type="button"
      [disableRipple]="true"
      [matTooltip]="isAsc ? 'Ascending' : 'Descending'"
      [stopPropagation]
      (click)="toggleDirection()"
    >
      <mat-icon
        [svgIcon]="
          isAsc ? 'sort-alphabetical-ascending' : 'sort-alphabetical-descending'
        "
      ></mat-icon>
    </button>
    <mat-label>Sort By</mat-label>
    <mat-select
      id="toolbar-sort-by"
      *ngIf="sortFields"
      [(ngModel)]="activeSort"
      class="select"
    >
      <mat-option
        *ngFor="let field of sortFields"
        #matOption
        ngDefaultControl
        [value]="field.value"
        >{{ field.label }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</span>
