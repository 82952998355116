import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

export type LogoOrientation = 'vertical' | 'horizontal' | null;
export type LogoClass =
  | 'citadel-logo'
  | 'citadel-logo--vertical'
  | 'citadel-logo--horizontal';

/**
 * An attribute directive used to render a citadel-logo with the mat-icon
 * directive.
 *
 * ## Examples:
 *
 * ### Logo only
 *
 * ```html
 *   <mat-icon citadelLogo></mat-icon>
 * ```
 * ### vertical orientation
 * ```html
 *   <mat-icon [citadelLogo]="'vertical'"></mat-icon>
 * ```
 *
 * ### horizontal orientation
 * ```html
 *   <mat-icon [citadelLogo]="'horizontal'"></mat-icon>
 * ```

 */
@Directive({
  selector: 'mat-icon[citadelLogo]',
})
export class CitadelLogoDirective implements OnInit, OnChanges {
  @Input() citadelLogo: LogoOrientation = null;

  constructor(
    private readonly _matIcon: MatIcon,
    private elRef: ElementRef<HTMLElement>
  ) {}
  ngOnInit(): void {
    // adds a base class to all elements with the mat-icon[citadelLogo] selector
    this.elRef.nativeElement.classList.add('citadel-logo');
    this.setSVGLogo();
  }

  ngOnChanges(): void {
    this.setSVGLogo();
  }

  setSVGLogo() {
    const SVGName = this.citadelLogo ? `${this.citadelLogo}-logo` : 'logo';
    this._matIcon.svgIcon = SVGName.toLocaleLowerCase();
    this.elRef.nativeElement.classList.add(
      `${this.getClass(this.citadelLogo)}`
    );
  }

  /**
   * Determines if the class list will need to be updated with BEM modifers
   * --horizontal, or --vertical
   * @param orientation describes the orientation of the citadel logo
   * @returns retrurns a string representing a BEM class modifier
   */
  getClass(orientation: LogoOrientation): LogoClass | undefined {
    if (orientation) {
      const isVertical: boolean = orientation.includes('vertical');
      const isHorizontal: boolean = orientation.includes('horizontal');
      if (!isHorizontal && !isVertical) {
        return;
      }
      return isVertical ? 'citadel-logo--vertical' : 'citadel-logo--horizontal';
    } else {
      return;
    }
  }
}
