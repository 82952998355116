<div class="account">
  <ng-container *ngIf="user$ | async as user; else spinner">
    <h1 mat-dialog-title *ngIf="!readOnly">
      {{ user.id ? "Edit" : "Create" }} an Account
      <app-resource-user-details
        *ngIf="user?.id"
        [data]="user"
        [hideOwnerOrg]="true"
        #resourceUserDetails
      ></app-resource-user-details>
    </h1>
    <h1 mat-dialog-title *ngIf="readOnly">
      View Account
      <app-resource-user-details
        *ngIf="user?.id"
        [data]="user"
        [hideOwnerOrg]="true"
        #resourceUserDetails
      ></app-resource-user-details>
    </h1>
    <mat-dialog-content>
      <app-user-form
        *ngIf="user"
        #appUserForm
        [readOnly]="readOnly"
        [currentUser]="currentUser"
        [value]="user"
      ></app-user-form>
    </mat-dialog-content>
    <mat-dialog-actions
      class="d-flex justify-content-end"
      *ngIf="appUserForm?.userForm as userForm"
    >
      <button
        data-cy="accountCancelButton"
        mat-button
        mat-dialog-close
        color="primary"
      >
        Cancel
      </button>
      <button
        data-cy="accountSubmitButton"
        id="account-dialog-submit-btn"
        *ngIf="!readOnly"
        mat-raised-button
        (click)="onSubmit(user)"
        color="primary"
        [disabled]="userForm.invalid || userForm.pristine || busy"
      >
        <span *ngIf="!busy">Submit</span>
        <mat-spinner
          class="mr-1 ml-1"
          *ngIf="busy"
          color="primary"
          diameter="35"
        ></mat-spinner>
      </button>
    </mat-dialog-actions>
  </ng-container>
</div>

<ng-template #spinner>
  <div class="d-flex m-5 justify-content-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
