import {
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { AsyncService } from '@app/shared/services';
import { take } from 'rxjs';

@Directive({
  selector: '[appAsyncDetailsDialog]',
})
export class AsyncDetailsDialogDirective {
  @Input() appAsyncDetailsDialog: any;
  @Output() asyncUpdated = new EventEmitter<any>();
  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    const dialog = AsyncDetailsDialogComponent.openDialog(
      this.dialog,
      this.appAsyncDetailsDialog
    );
    dialog.afterClosed().subscribe((result) => {
      if (result) return this.asyncUpdated.emit(result);
    });
  }
}

@Component({
  selector: 'app-async-details-dialog',
  templateUrl: './async-details-dialog.component.html',
  styleUrls: ['./async-details-dialog.component.scss'],
})
export class AsyncDetailsDialogComponent {
  value: any = {};
  errorTableColumns = ['row', 'messages'];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private asyncService: AsyncService,
    public asyncDialog: MatDialogRef<AsyncDetailsDialogComponent>
  ) {}

  markAsRead() {
    this.asyncService
      .markAsRead([this.data.id])
      .pipe(take(1))
      .subscribe((res) => {
        this.asyncDialog.close(true);
      });
  }

  getHeaderColor(status: string) {
    if (status === 'Error') return 'bg-danger text-white';
    if (status === 'Complete') return 'bg-primary text-white';
  }

  static openDialog(
    dialog: MatDialog,
    item: any
  ): MatDialogRef<AsyncDetailsDialogComponent> {
    return dialog.open<AsyncDetailsDialogComponent>(
      AsyncDetailsDialogComponent,
      {
        data: item,
        width: '800px',
      }
    );
  }
}
