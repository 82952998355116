import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FilterParams } from '@shared/models';
import { Observable } from 'rxjs';
import { Country } from '@shared/models/country.model';
import { environment } from '@environments/environment';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  apiUrl = `${environment.apiUrl}/countries`;

  countriesCache$: { [key: string]: Observable<Country[]> } = {};

  constructor(private http: HttpClient) {}

  public find(filterParams?: FilterParams): Observable<Country[]> {
    const key = filterParams ? filterParams.toString() : '';
    if (!this.countriesCache$[key]) {
      this.countriesCache$[key] = this.http
        .get<Country[]>(`${this.apiUrl}`, {
          params: filterParams?.httpParams,
        })
        .pipe(shareReplay(1));
    }

    return this.countriesCache$[key];
  }
}
