import {
  Component,
  Input,
  Injector,
  ViewChild,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { FilterParams, PageableCollection } from '@app/shared/models';
import { Observable } from 'rxjs';
import { Organization } from '@app/shared/models/organization.model';
import { OrganizationService } from '@app/shared/services/organization.service';

import { MatSelect } from '@angular/material/select';
import {
  FloatLabelType,
  MatFormFieldAppearance,
} from '@angular/material/form-field';

import { BaseMatSelectComponent } from '../base-mat-select.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-organization-select',
  templateUrl: './organization-select.component.html',
  styleUrls: ['./organization-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationSelectComponent extends BaseMatSelectComponent<Organization> {
  @Input() placeholder = 'Organization';
  @ViewChild('matSelect', { static: true })
  matSelect: MatSelect;
  @Input() floatLabel: FloatLabelType = 'auto';
  @Input() label: string = 'Organization';
  @Input() filterLabel: string = 'Search';
  @Input() parentsOnly = false;
  @Input() excludeChilds = false;
  @Input() excludeOrg: string | string[] | undefined;
  @Input()
  appearance: MatFormFieldAppearance = 'outline';
  @Input() isLoading: boolean = false;
  @Output() isLoadingChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Output() orgIdChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  set orgId(_orgId: string) {
    this.loadOrgId(_orgId);
  }

  get orgId(): string {
    return this.value?.id!;
  }

  baseFilterParams: FilterParams = new FilterParams({
    sort: {
      active: 'name',
      direction: 'asc',
    },
  });

  constructor(
    public injector: Injector,
    private organizationService: OrganizationService
  ) {
    super(injector);
  }

  loadOrgId(orgId: string) {
    if ((!orgId && !this.value) || orgId === this.value?.id) return;

    if (!orgId) {
      // @ts-ignore
      this.value = null;
      super.emitChangeEvent();
      return;
    }

    this.value = { id: orgId };
    this.organizationService
      .get(orgId)
      .pipe(take(1))
      .subscribe((data) => {
        this.value = data;
        super.emitChangeEvent();
      });
  }

  emitChangeEvent() {
    super.emitChangeEvent();
    this.orgIdChange.emit(this.orgId);
  }

  searchItems(
    params: FilterParams
  ): Observable<PageableCollection<Organization>> {
    if (this.parentsOnly) params.hasSubOrgs = true;
    if (this.excludeChilds) params.hasParent = false;
    if (this.excludeOrg) params.excludeOrg = this.excludeOrg;
    return this.organizationService.find(params);
  }
}
