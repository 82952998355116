import {
  AfterViewInit,
  ComponentRef,
  createComponent,
  Directive,
  ElementRef,
  EnvironmentInjector,
  HostBinding,
  Injector,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatBadge } from '@angular/material/badge';

@Directive({
  selector: '[matBadgeIcon]',
})
export class MatBadgeIconDirective implements OnInit, OnChanges {
  @HostBinding('class') role = 'mat-badge-icon';

  @Input() matBadgeIcon: string;

  @HostBinding('class.mat-badge-icon-hidden')
  @Input()
  matBadgeIconHidden?: boolean = false;

  iconRef: ComponentRef<MatIcon>;

  constructor(
    private environmentInjector: EnvironmentInjector,
    private viewContainer: ViewContainerRef,
    private matBadge: MatBadge,
    private el: ElementRef
  ) {}

  ngOnInit() {
    if (!this.matBadgeIcon?.length || !this.matBadge) return;
    this.matBadge.content = '_';
    this.iconRef = createComponent(MatIcon, {
      environmentInjector: this.environmentInjector,
    });
    const badgeContentEl$ =
      this.el.nativeElement.querySelector('.mat-badge-content');
    badgeContentEl$.append(this.iconRef.instance._elementRef.nativeElement);

    this.checkChanges();
  }

  ngOnChanges() {
    this.checkChanges();
  }

  checkChanges() {
    if (!this.iconRef) return;
    this.iconRef.instance.svgIcon = this.matBadgeIcon;
    this.iconRef.changeDetectorRef.detectChanges();
  }
}
