import { Component, Input } from '@angular/core';
import { ForeignVisitor, AdditionalInfo } from '@app/shared/models';
import { AlertService, OtherNamesService } from '@app/shared/services';
import { take } from 'rxjs/operators';
import { without } from 'lodash';

@Component({
  selector: 'app-fv-view-additional-info',
  templateUrl: './fv-view-additional-info.component.html',
  styleUrls: ['./fv-view-additional-info.component.scss'],
})
export class FvViewAdditionalInfoComponent {
  @Input() fv: ForeignVisitor;
  @Input() editable?: boolean = true;
  hidden: boolean = true;
  editMode: boolean = false;
  backupOtherNames: AdditionalInfo[] = [];
  today = new Date();

  constructor(
    private alert: AlertService,
    private otherNameService: OtherNamesService
  ) {}

  //Delete Currently disabled - Only full FN or FAR Objects can currently be deleted to guard
  //against potential cross domain and automated screening syncing issues
  deleteName(name: AdditionalInfo): void {
    this.alert
      .confirmDelete({
        title: `Delete Name Record`,
        message: 'Are you sure you want to delete this record?',
        performAction: () => this.otherNameService.delete(name.id),
        successMsg: `Record has successfully been deleted.`,
      })
      .subscribe(() => {
        this.fv.otherNames = without(this.fv.otherNames, name);
      });
  }

  onAdd() {
    let newInfo: AdditionalInfo = {
      type: 'name',
      givenName: null,
      surname: null,
      birthCountry: null,
      dateOfBirth: null,
      foreignVisitor: { id: this.fv.id },
      editMode: true,
    };

    this.backupOtherNames = JSON.parse(JSON.stringify(this.fv.otherNames));
    this.fv.otherNames?.unshift(newInfo);
    this.editMode = true;
  }

  onEdit(info: AdditionalInfo) {
    this.editMode = true;
    info.editMode = true;
    this.backupOtherNames = JSON.parse(JSON.stringify(this.fv.otherNames));
  }

  onReset() {
    this.editMode = false;
    this.fv.otherNames = JSON.parse(JSON.stringify(this.backupOtherNames));
    this.backupOtherNames = [];
    this.clearEditModes();
  }

  clearEditModes() {
    if (this.fv && this.fv.otherNames) {
      for (let info of this.fv.otherNames) {
        info.editMode = false;
      }
    }
  }

  onSubmit(info: AdditionalInfo) {
    this.otherNameService
      .save(info)
      .pipe(take(1))
      .subscribe(() => {
        info.editMode = false;
        this.editMode = false;
        this.alert.successAlert('Additional Information Updated');
      });
  }

  clearInfoModel(info: AdditionalInfo) {
    info.birthCountry = null;
    info.dateOfBirth = null;
    info.givenName = null;
    info.surname = null;
  }

  hasError(info: AdditionalInfo) {
    if (info.type === 'name' && (!info.surname || !info.givenName)) {
      return true;
    }
    if (info.type === 'dob' && !info.dateOfBirth) {
      return true;
    }
    if (info.type === 'birthCountry' && !info.birthCountry) {
      return true;
    }
    return false;
  }
}
