import { Component, Input } from '@angular/core';
import {
  ContactPhoneNumber,
  phoneIcons,
} from '@app/shared/models/contact-phone-number.model';
import { ForeignVisitorPhoneService } from '@app/shared/services/foreign-visitor-phone.service';

@Component({
  selector: 'app-fv-phone-display',
  templateUrl: './fv-phone-display.component.html',
  styleUrls: ['./fv-phone-display.component.scss'],
})
export class FvPhoneDisplayComponent {
  @Input() phones: ContactPhoneNumber[];
  @Input() fvId: string;
  @Input() editable?: boolean = false;
  phoneIcons = phoneIcons;

  constructor(private phoneService: ForeignVisitorPhoneService) {}

  lockPrimary(phone: ContactPhoneNumber): boolean {
    return this.phoneService.lockPrimary(this.phones, phone);
  }
}
