<ng-container *ngIf="!isEmpty; else emptyCollectionButtonStyle">
  <mat-expansion-panel
    class="flex-fill mt-3 panel-200-mh"
    #socialMediaPanel="matExpansionPanel"
    id="fv-view-social-media-panel"
    *ngIf="!isEmpty"
  >
    <mat-expansion-panel-header>
      <mat-panel-title class="d-flex justify-content-between py-2">
        <span>Social Media</span>
        <mat-icon
          svgIcon="share-variant-outline"
          [matBadge]="_data.length"
          matBadgeSize="small"
          matBadgeColor="accent"
        ></mat-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-divider class="panel-header-divider"></mat-divider>
    <div class="social-media-body">
      <ng-container *ngFor="let item of _data; index as i">
        <div class="content row">
          <div class="col">
            <div class="row">
              <div class="col-6">
                <dl style="line-break: anywhere">
                  <dt>Type</dt>
                  <dd>{{ item.type | default }}</dd>
                  <dt>URL</dt>
                  <dd>
                    {{ item.url | default }}
                  </dd>
                </dl>
              </div>
              <div class="col-6">
                <dl style="line-break: anywhere">
                  <dt>Username</dt>
                  <dd>{{ item.username | default }}</dd>
                  <dt>Notes</dt>
                  <dd>
                    {{ item.notes | default }}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <mat-divider
            [vertical]="true"
            class="panel-vertical-divider"
          ></mat-divider>
          <div
            class="col-2 d-flex justify-content-center align-items-center"
            style="flex-direction: column"
          >
            <button
              *ngIf="editable"
              mat-icon-button
              (click)="openSocialMedia(item, i)"
              matTooltip="Edit Social Media"
            >
              <mat-icon svgIcon="square-edit-outline"></mat-icon>
            </button>

            <div class="flex-fill"></div>
            <div *ngIf="i === 0 && editable" class="mb-2">
              <button
                mat-mini-fab
                matTooltip="Add Social Media"
                class="bg-warning"
                style="color: white"
                (click)="newSocialMedia()"
              >
                <mat-icon svgIcon="plus-circle-outline"></mat-icon>
              </button>
            </div>
          </div>
        </div>
        <mat-divider
          *ngIf="i + 1 < _data.length"
          class="panel-header-divider"
        ></mat-divider>
      </ng-container>
    </div>
  </mat-expansion-panel>
</ng-container>
<ng-template #emptyCollectionButtonStyle>
  <button
    class="mt-3 w-100"
    style="height: 48px"
    mat-raised-button
    matTooltip="Add Social Media"
    color="primary"
    (click)="newSocialMedia()"
    [disabled]="!editable"
  >
    <div class="d-flex align-items-center">
      <span>Add Social Media</span>
      <mat-icon class="ml-2" svgIcon="share-variant-outline"></mat-icon>
    </div>
  </button>
</ng-template>
