<form ngForm #passportForm="ngForm" id="passport">
  <div class="row">
    <div class="col-4">
      <!--Passport Numberrequired field-->
      <mat-form-field appearance="outline">
        <mat-label>Passport Number</mat-label>
        <input
          [disabled]="!editable"
          id="passportNumber"
          matInput
          [required]="hasPassportData() && editable"
          #passportNumberControl="ngModel"
          [(ngModel)]="value.number"
          name="passportNumber"
          maxlength="20"
          aria-label="passport number"
          placeholder="Enter Passport Number"
          (ngModelChange)="checkPassport()"
        />
        <mat-error>Please add a valid Passport number</mat-error>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field class="mat-form-field" [appearance]="appearance">
        <mat-label>Passport Type</mat-label>
        <!-- dropdown for country selection -->
        <mat-select
          id="passportType"
          #passportType
          [(ngModel)]="value.type"
          (ngModelChange)="emitChangeEvent()"
          name="type"
          [disabled]="!editable"
        >
          <mat-option selected disabled
            >Please select a Passport Type</mat-option
          >
          <mat-option *ngFor="let type of types" value="{{ type }}">{{
            type
          }}</mat-option>
        </mat-select>
        <mat-error>Please select a passport type.</mat-error>
      </mat-form-field>
    </div>
    <div class="col-4">
      <!--required field-->
      <app-country-select
        id="passportCountrySelect"
        #issueCountryControl
        [disabled]="!editable"
        [required]="hasPassportData() && editable"
        [(ngModel)]="value.issuingCountryCode"
        (ngModelChange)="emitChangeEvent()"
        name="issuingCountryCode"
        placeholder="Select Issuing Country"
        label="Passport Issuing Country"
        floatLabel="auto"
        [excludeUS]="true"
        [includeOrgs]="true"
      >
      </app-country-select>
    </div>
    <div class="col-4">
      <mat-form-field [appearance]="appearance">
        <mat-label>Passport Issue Authority</mat-label>
        <!-- dropdown for country selection -->
        <input
          id="issueAuthority"
          #issueAuthorityControl
          matInput
          [disabled]="!hasPassportData() || !editable"
          [(ngModel)]="value.issueAuthority"
          (ngModelChange)="emitChangeEvent()"
          name="issueAuthority"
          maxlength="30"
          aria-label="issue authority"
          placeholder="Enter Issuing Authority"
        />
        <mat-error> Please add a valid authority</mat-error>
      </mat-form-field>
    </div>

    <div class="col-4">
      <mat-form-field [appearance]="appearance">
        <mat-label>Passport Issue Date</mat-label>
        <input
          id="passportIssueDate"
          #issueDateControl
          [disabled]="!hasPassportData() || !editable"
          [(ngModel)]="value.issueDate"
          (ngModelChange)="emitChangeEvent()"
          matInput
          [max]="today"
          [min]="minIssueDate"
          [matDatepicker]="issueDatePicker"
          name="issueDate"
          aria-label="issue date"
          placeholder="MM/DD/YYYY"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="issueDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker
          #issueDatePicker
          [disabled]="!hasPassportData() || !editable"
        ></mat-datepicker>
        <mat-error> Please add a valid date</mat-error>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field [appearance]="appearance">
        <mat-label>Passport Expiration</mat-label>
        <input
          id="passportExpirationDate"
          #expirationDateControl
          matInput
          [disabled]="!hasPassportData() || !editable"
          [(ngModel)]="value.expirationDate"
          (ngModelChange)="checkPassport()"
          [matDatepicker]="expirationDatePicker"
          [min]="value.issueDate"
          name="expirationDate"
          aria-label="expiration date"
          placeholder="MM/DD/YYYY"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="expirationDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker
          #expirationDatePicker
          [disabled]="!hasPassportData() || !editable"
        ></mat-datepicker>
        <mat-error>Please select an expiration date</mat-error>
      </mat-form-field>
    </div>
  </div>
  <!-- NOTE: uncomment when attachments are complete -->
  <!-- </div>
      <div class="col"><app-attachments></app-attachments></div>
    </div> -->
</form>
