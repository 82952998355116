<div
  class="row panel-banner p-2 mx-0 my-2 justify-content-between cursor-pointer"
  id="toggleScreeningGraphs"
  (click)="display = !display"
>
  <div class="align-items-center d-flex">
    <mat-icon class="mr-2" svgIcon="chart-bar"></mat-icon> Screening Results
  </div>
  <mat-icon svgIcon="chevron-up" *ngIf="display"></mat-icon>
  <mat-icon svgIcon="chevron-down" *ngIf="!display"></mat-icon>
</div>
<mat-card class="mb-2" *ngIf="display">
  <div class="d-flex screening-results-picker">
    <mat-form-field appearance="outline">
      <mat-label>Select Dates</mat-label>
      <mat-date-range-input [rangePicker]="screeningDatePicker" [max]="today">
        <input
          id="screeningDateStart"
          name="screeningDateStart"
          matStartDate
          [(ngModel)]="startDate"
          placeholder="MM-DD-YYYY"
        />
        <input
          id="screeningDateEnd"
          name="screeningDateEnd"
          matEndDate
          [(ngModel)]="endDate"
          placeholder="MM-DD-YYYY"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="screeningDatePicker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #screeningDatePicker></mat-date-range-picker>
    </mat-form-field>
    <button
      mat-mini-fab
      class="mx-1 mt-1"
      color="accent"
      style="border-radius: 5px"
      matTooltip="Search"
      (click)="getScreeningResultsMetrics()"
    >
      <mat-icon class="text-black">search</mat-icon>
    </button>
    <div>
      <mat-button-toggle-group
        [hideSingleSelectionIndicator]="true"
        name="datepickerType"
        aria-label="Datepicker Unit"
        [(ngModel)]="datepickerUnit"
        (ngModelChange)="dateRangeExceeded = false"
      >
        <mat-button-toggle value="DAY">Day</mat-button-toggle>
        <mat-button-toggle value="WEEK">Week</mat-button-toggle>
        <mat-button-toggle value="MONTH">Month</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="ml-2" style="margin-top: 0.6rem">
      <mat-icon
        color="primary"
        svgIcon="information"
        style="height: 30px; width: 30px"
        matTooltip="Click on Legend entry to toggle visibility."
      ></mat-icon>
    </div>
  </div>
  <div *ngIf="dateRangeExceeded" class="range-error">
    {{ datepickerUnit }} cannot exceed a range of
    {{ datepickerUnitRange[datepickerUnit] }} days between Start and End Dates
  </div>
</mat-card>
<div class="row mb-2" *ngIf="display">
  <div class="col-9" [class]="multi.length > 0 ? 'pr-0' : ''">
    <mat-card>
      <mat-card-content>
        <div *ngIf="!loading; else dataLoading">
          <ngx-charts-bar-vertical-2d
            [scheme]="colorScheme"
            [results]="multi"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [legendTitle]="legendTitle"
            [animations]="false"
            (select)="onSelect($event)"
          >
          </ngx-charts-bar-vertical-2d>
        </div>
        <ng-template #dataLoading>
          <div class="my-4" style="margin-left: 45%">
            <mat-spinner color="primary"></mat-spinner></div
        ></ng-template>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-3" *ngIf="multi.length > 0 && !loading">
    <mat-card class="h-100">
      <mat-card-title class="text-center" style="background-color: whitesmoke"
        >Details</mat-card-title
      >
      <mat-divider></mat-divider>
      <mat-card-content class="h-100">
        <div class="row h-100">
          <div class="col h-100">
            <dl class="mt-2">
              <dt>Total FNS</dt>
              <dd>{{ screeningCounts.total }}</dd>
              <dt>Total Greens</dt>
              <dd>{{ screeningCounts.greenResultCount }}</dd>
              <dt>Machine Green</dt>
              <dd>{{ screeningCounts.machineGreenResultCount }}</dd>
              <dt>Reviewed Green</dt>
              <dd>{{ screeningCounts.reviewedGreenResultCount }}</dd>
            </dl>
          </div>
          <mat-divider vertical></mat-divider>
          <div class="col">
            <dl class="mt-2">
              <dt>DEROGATORY</dt>
              <dd>{{ screeningCounts.redResultCount }}</dd>
              <dt>INCONCLUSIVE</dt>
              <dd>{{ screeningCounts.yellowResultCount }}</dd>
              <dt>Machine Red</dt>
              <dd>{{ screeningCounts.machineRedResultCount }}</dd>
              <dt>USP</dt>
              <dd>{{ screeningCounts.USP }}</dd>
              <dt>
                Total Human Review
                <mat-icon
                  matTooltip="Total count of Reviewed Green, Reviewed Red, Machine Red, and USP"
                  >help</mat-icon
                >
              </dt>
              <dd>{{ screeningCounts.humanReviewCount }}</dd>
            </dl>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
