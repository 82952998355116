import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/shared/helpers';
import { SystemAlertsComponent } from './system-alerts/system-alerts.component';
import { SystemAlertsEditRoles } from '@shared/models/role-permissions';

const routes: Routes = [
  {
    path: 'systemalerts',
    title: 'System Alerts',
    component: SystemAlertsComponent,
    canActivate: [AuthGuard],
    data: { roles: SystemAlertsEditRoles },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SystemAlertsRoutingModule {}
