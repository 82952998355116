<h2 mat-dialog-title>
  {{ systemAlert.id ? "Update" : "Create" }} a System Alert
</h2>
<mat-dialog-content>
  <form #form="ngForm" class="mt-2">
    <app-date-time
      class="system-alert__startDateTime"
      #startDate
      [(ngModel)]="systemAlert.startDate"
      label="Start Date"
      name="startDateTime"
      [required]="true"
      ngDefaultControl
    ></app-date-time>
    <app-date-time
      class="system-alert__endDateTime"
      [(ngModel)]="systemAlert.endDate"
      label="End Date"
      name="endDateTime"
      [required]="true"
      [minDate]="minEndDate"
      ngDefaultControl
    ></app-date-time>
    <mat-form-field [appearance]="appearance">
      <mat-label>Message</mat-label>
      <textarea
        class="system-alert__message"
        #message
        matInput
        rows="4"
        [(ngModel)]="systemAlert.message"
        name="message"
        required
        maxlength="128"
      ></textarea>
      <mat-hint>{{ 128 - message.value.length }} characters left</mat-hint>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-button
    color="primary"
    class="system-alert__close-action"
    mat-dialog-close
    data-cy="close-dialog"
  >
    Close
  </button>
  <button
    mat-flat-button
    class="system-alert__submit-action"
    color="primary"
    (click)="save()"
    [disabled]="
      (!systemAlert.startDate &&
        !systemAlert.endDate &&
        !systemAlert.message) ||
      busy
    "
    data-cy="submit-dialog"
  >
    <span *ngIf="!busy">Submit</span>
    <mat-spinner
      class="mr-1 ml-1"
      *ngIf="busy"
      color="primary"
      diameter="35"
    ></mat-spinner>
  </button>
</mat-dialog-actions>
