import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
export interface ViewDetails {
  name: string;
  viewType: string;
}
@Injectable({
  providedIn: 'root',
})
export class ViewService {
  viewChangeSubject = new BehaviorSubject<ViewDetails>({
    name: '',
    viewType: '',
  });

  sendViewChange(data: ViewDetails) {
    this.viewChangeSubject.next(data);
  }
}
