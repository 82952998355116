import { isArray, isEmpty, isNil, isString, result } from 'lodash';
import { FilterParams, Role } from '@shared/models';
import { AppIfConfig } from '@shared/directives';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

/** Takes a property path or map function */
export type PropResolver = string | ((opt: any) => string);
export function resolveProp(
  opt: any,
  resolve?: PropResolver,
  defaultVal = opt
) {
  if (!resolve || !opt) return defaultVal;
  if (isString(resolve)) return result(opt, resolve);
  return resolve(opt);
}

export function resolveOpt(
  options: any[],
  value: any,
  valResolve?: PropResolver
) {
  return options.find((opt) => resolveProp(opt, valResolve) === value);
}

export function clearField(config: FilterConfig, params: FilterParams) {
  if (config.type === FilterConfigType.DATE_RANGE) {
    delete params[config.name + 'Start'];
    delete params[config.name + 'End'];
  }

  delete params[config.name];
}

export function isFilterActive(config: FilterConfig, params: FilterParams) {
  if (config.type === FilterConfigType.DATE_RANGE) {
    return (
      !isNil(params[config.name + 'Start']) ||
      !isNil(params[config.name + 'End'])
    );
  }

  if (config.type === FilterConfigType.TOGGLE) {
    return coerceBooleanProperty(params[config.name]);
  }

  if (isArray(params[config.name]) || isString(params[config.name])) {
    return !isEmpty(params[config.name]);
  }

  return !isNil(params[config.name]);
}

export function isArrayVal(config: FilterConfig) {
  return !!(<any>config).multiple;
}

export function getArrayKeys(configs: FilterConfig[]) {
  return (configs || [])
    .filter((config) => isArrayVal(config))
    .map((config) => config.name);
}

export enum FilterConfigType {
  INPUT = 'INPUT',
  DATE = 'DATE',
  DATE_RANGE = 'DATE_RANGE',
  SELECT = 'SELECT',
  TOGGLE = 'TOGGLE',
  BUTTON_TOGGLE = 'BUTTON_TOGGLE',
  COUNTRY = 'COUNTRY',
  ORG = 'ORG',
  FN = 'FN',
  USER = 'USER',
  USER_ROLE = 'USER_ROLE',
  LOCATION_ID = 'LOCATION_ID',
  GROUP_ID = 'GROUP_ID',
}

interface BaseFilterConfig {
  /** Type of Filter */
  type: FilterConfigType;
  /** Name of Filter Property */
  name: string;
  /** Filter Label */
  label: string;
  /** Filter Placeholder display */
  placeholder?: string;
  /** Filter Col Display Width (default: 6) */
  col?: number;
  /** Only show if User as provided Role(s) */
  ifRole?: Role | Role[];
  /** Only show if app config value set */
  ifConfig?: AppIfConfig<any>;
  /** Only Hide on Filter Form if set to true */
  formHidden?: boolean;
}

export interface InputFilterConfig extends BaseFilterConfig {
  type: FilterConfigType.INPUT;
  /** Max Input Length */
  maxLength?: number;
  /** Optional Help text */
  helpText?: string;
}

export interface DateFilterConfig extends BaseFilterConfig {
  type: FilterConfigType.DATE;
  /** Min Date */
  min?: Date;
  /** Max Date */
  max?: Date;
}

export interface DateRangeFilterConfig extends BaseFilterConfig {
  type: FilterConfigType.DATE_RANGE;
  /** Min Date */
  min?: Date;
  /** Max Date */
  max?: Date;
}

export interface BaseOptionFilter extends BaseFilterConfig {
  /** Available Options */
  options: any[];
  /** Optional resolver for mapping option Value */
  optVal?: PropResolver;
  /** Optional resolver for mapping option Label */
  optLabel?: PropResolver;
  /** Optional resolver for mapping option Tooltip */
  optTooltip?: PropResolver;
}

export interface SelectFilterConfig extends BaseOptionFilter {
  type: FilterConfigType.SELECT;
  /** Allow multiple option select */
  multiple?: boolean;
}

export interface ButtonToggleFilterConfig extends BaseOptionFilter {
  type: FilterConfigType.BUTTON_TOGGLE;
}

export interface ToggleFilterConfig extends BaseFilterConfig {
  type: FilterConfigType.TOGGLE;
  /** Filter tooltip */
  tooltip?: string;
}

export interface CountryFilterConfig extends BaseFilterConfig {
  type: FilterConfigType.COUNTRY;
  multiple?: boolean;
  /** Exclude US countries (default: false) */
  excludeUS?: boolean;
  includeOrgs?: boolean;
}

export interface OrgFilterConfig extends BaseFilterConfig {
  type: FilterConfigType.ORG;
  /** Only include Parent Orgs (default: false) */
  parentsOnly?: boolean;
}

export interface FNFilterConfig extends BaseFilterConfig {
  type: FilterConfigType.FN;
  /** Restrict to only current Org FNs (default: false) */
  restrictCurrentOrganization?: boolean;
}

export interface LocationIDFilterConfig extends BaseFilterConfig {
  type: FilterConfigType.LOCATION_ID;
  multiple?: boolean;
}

export interface GroupIDFilterConfig extends BaseFilterConfig {
  type: FilterConfigType.GROUP_ID;
  multiple?: boolean;
}

export interface UserFilterConfig extends BaseFilterConfig {
  type: FilterConfigType.USER;
}

export interface UserRoleFilterConfig extends BaseFilterConfig {
  type: FilterConfigType.USER_ROLE;
}

export type FilterConfig =
  | InputFilterConfig
  | DateFilterConfig
  | DateRangeFilterConfig
  | SelectFilterConfig
  | ButtonToggleFilterConfig
  | ToggleFilterConfig
  | CountryFilterConfig
  | OrgFilterConfig
  | FNFilterConfig
  | LocationIDFilterConfig
  | GroupIDFilterConfig
  | UserFilterConfig
  | UserRoleFilterConfig;
