import { Component, Input, OnInit } from '@angular/core';
import { ScreeningResult } from '@shared/models';

@Component({
  selector: 'app-far-fvs-rollup',
  templateUrl: './far-fvs-rollup.component.html',
  styleUrls: ['./far-fvs-rollup.component.scss'],
})
export class FarFvsRollupComponent implements OnInit {
  @Input() foreignAccessRequestScreeningCounts?: any;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() hideBackgroundColor: boolean = false;
  ScreeningResult = ScreeningResult;
  reviewedRedRollup: any = {
    status: 'RED',
    total: 0,
  };

  machineRedRollup: any = {
    status: 'MACHINE_RED',
    total: 0,
  };

  greenRollup: any = {
    status: 'GREEN',
    total: 0,
  };

  otherRollup: any = {
    status: 'OTHER',
    total: 0,
  };

  ngOnInit(): void {
    if (this.foreignAccessRequestScreeningCounts) {
      this.machineRedRollup.total =
        this.foreignAccessRequestScreeningCounts.machineRedResultCount;
      this.greenRollup.total =
        this.foreignAccessRequestScreeningCounts.greenResultCount;
      this.reviewedRedRollup.total =
        this.foreignAccessRequestScreeningCounts.redResultCount;
      this.otherRollup.total =
        this.foreignAccessRequestScreeningCounts.otherResultCount;
    }
  }
}
