import {
  ChangeDetectorRef,
  Component,
  Directive,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppConfigService } from '@shared/services/app-config.services';
import { UserService } from '@shared/services';
import { AuthService } from '@app/auth/auth.service';
import { Role, RoleLabels, Roles, User } from '@shared/models';
import { without, xor } from 'lodash';

@Directive({
  selector: '[appSwitchRoles]',
})
export class SwitchRolesDirective {
  constructor(
    private dialog: MatDialog,
    private auth: AuthService,
    private user: UserService,
    private config: AppConfigService,
    private viewContainer: ViewContainerRef,
    private changeDetector: ChangeDetectorRef,
    private templateRef: TemplateRef<any>,
    private renderer: Renderer2
  ) {
    this.viewContainer.clear();
    const show = this.config.get('showRoleSelector');

    // Only show when config enabled, and roles are filtered
    // or has more then one role
    if (show && (this.user.filteredRoles?.length || auth.getRoles()?.length)) {
      const el =
        this.viewContainer.createEmbeddedView(templateRef).rootNodes[0];
      this.changeDetector.markForCheck();
      this.renderer.listen(el, 'click', this.onClick.bind(this));
    }
  }

  onClick() {
    const dialog = SwitchRolesDialogComponent.openDialog(this.dialog);
    dialog.afterClosed().subscribe((result) => {
      if (result) window.location.reload();
    });
  }
}

@Component({
  selector: 'app-switch-roles-dialog',
  templateUrl: './switch-roles-dialog.component.html',
  styleUrls: ['./switch-roles-dialog.component.scss'],
})
export class SwitchRolesDialogComponent {
  RoleLabels = RoleLabels;

  allRoles: Role[] = [];
  Roles: Role[] = Roles;
  loading: boolean = false;

  prevSelected: Role[];

  selectedRoles: Role[];

  constructor(
    public dialogRef: MatDialogRef<SwitchRolesDialogComponent>,
    public userService: UserService
  ) {
    userService.getCurrent(true).subscribe((user: User) => {
      if (user.roles) {
        this.allRoles = user.roles;
        this.selectedRoles = userService.filteredRoles?.length
          ? userService.filteredRoles
          : this.allRoles;
        this.prevSelected = [...this.selectedRoles];
        this.loading = false;
      }
    });
    this.loading = true;
  }

  onRemove(role: Role) {
    this.selectedRoles = without(this.selectedRoles, role);
  }

  get isValid(): boolean {
    return (
      !!this.selectedRoles?.length &&
      !!xor(this.prevSelected, this.selectedRoles)?.length
    );
  }

  reset() {
    this.userService.filteredRoles = [];
    this.dialogRef.close(this.userService.filteredRoles);
  }

  submit() {
    if (
      !this.selectedRoles.length ||
      this.selectedRoles.length === this.allRoles.length
    ) {
      return this.reset();
    }

    this.userService.filteredRoles = this.selectedRoles;
    this.dialogRef.close(this.userService.filteredRoles);
  }

  static openDialog(
    dialog: MatDialog
  ): MatDialogRef<SwitchRolesDialogComponent> {
    return dialog.open<SwitchRolesDialogComponent>(SwitchRolesDialogComponent, {
      width: '600px',
    });
  }
}
