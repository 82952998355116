import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { OrganizationService } from '@app/shared/services';
import { AuthService } from '@app/auth/auth.service';
import { Organization, Role, User } from '@app/shared/models';
import { cloneDeep } from 'lodash';
import { OrganizationFormComponent } from '../organization-form/organization-form.component';
import { finalize, take } from 'rxjs/operators';
@Component({
  selector: 'app-organization-dialog',
  templateUrl: './organization-dialog.component.html',
  styleUrls: ['./organization-dialog.component.scss'],
})
export class OrganizationDialogComponent implements OnInit {
  public Role = Role;
  organization: Organization = {};
  user: User | null = this.authService.getUser();
  @ViewChild('organizationForm') organizationForm: OrganizationFormComponent;

  /**
   * This needs to be disabled until API can be updated.
   * Errors occur in other environments because there is no access to
   * GET api/v2/systemUsers/{id} endpoint.
   *
   @ViewChild('resourceUserDetails')
   resourceUserDetails: ResourceUserDetailsComponent;
   *
   */

  constructor(
    @Inject(MAT_DIALOG_DATA) public id: string | null,
    public organizationDialog: MatDialogRef<OrganizationDialogComponent>,
    private orgService: OrganizationService,
    public authService: AuthService
  ) {}

  public disabledSubmit: boolean = false;

  ngOnInit(): void {
    this.disabledSubmit = false;
    if (this.id) {
      this.getOrganization(this.id);
    }
  }

  displayResourceDetails(): boolean {
    if (this.user?.roles) {
      if (this.user?.roles.includes(Role.sv_admin) && this.id) {
        return true;
      }
      return false;
    }
    return false;
  }

  getOrganization(id: string) {
    this.orgService
      .get(id)
      .pipe(take(1))
      .subscribe((data) => {
        this.organization = cloneDeep(data);
      });
  }

  // Handles location changes emitted from from component
  onOrganizationChange(organization: any) {
    this.organization = organization;
  }

  onSubmit() {
    this.disabledSubmit = true;
    this.orgService
      .save(this.organization)
      .pipe(
        take(1),
        finalize(() => (this.disabledSubmit = false))
      )
      .subscribe((data) => {
        this.organizationDialog.close(data);
      });
  }

  static openDialog(
    dialog: MatDialog,
    organization?: Organization
  ): MatDialogRef<OrganizationDialogComponent> {
    return dialog.open<OrganizationDialogComponent>(
      OrganizationDialogComponent,
      {
        data: organization,
        width: '750px',
      }
    );
  }
}
