import { Pipe, PipeTransform } from '@angular/core';
import { Organization } from '@app/shared/models/organization.model';

@Pipe({
  name: 'getOrgName',
})
export class GetOrgNamePipe implements PipeTransform {
  transform(
    organization?: Organization,
    type: 'long' | 'short' | 'combo' = 'short'
  ): string | undefined {
    if (organization) {
      switch (type) {
        case 'combo': {
          if (organization.shortName) {
            return organization.name + ' (' + organization.shortName + ')';
          } else {
            return organization.name;
          }
        }
        case 'long':
          return organization.name;
        default:
          return organization.shortName
            ? organization.shortName
            : organization.name;
      }
    }
  }
}
