import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

export const DEFAULT_TITLE = 'Citadel 2.0';

@Injectable()
export class AppTitleStrategy extends TitleStrategy {
  constructor(private title: Title) {
    super();
  }

  setTitle(title: string | undefined): void {
    if (!!title?.length) {
      this.title.setTitle(`${title} - ${DEFAULT_TITLE}`);
    } else {
      this.title.setTitle(DEFAULT_TITLE);
    }
  }

  updateTitle(snapshot: RouterStateSnapshot): void {
    this.setTitle(this.buildTitle(snapshot));
  }
}
