<mat-form-field [appearance]="appearance" [floatLabel]="floatLabel">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    #matSelect
    id="fv-select"
    [required]="required"
    [disabled]="disabled"
    [(ngModel)]="value"
    (selectionChange)="emitChangeEvent()"
    (openedChange)="monitorScroll($event)"
    [name]="label"
    [multiple]="multiple"
    panelClass="fv-select"
  >
    <mat-select-trigger>
      <span> {{ getSelectedText(value) }}</span>
    </mat-select-trigger>

    <mat-option class="search-option">
      <ngx-mat-select-search
        [searching]="searching"
        noEntriesFoundLabel="No FNs Found"
        [placeholderLabel]="filterLabel"
        [formControl]="searchCtrl"
        [disableScrollToActiveOnOptionsChanged]="true"
        [clearSearchInput]="false"
      >
      </ngx-mat-select-search>
    </mat-option>
    <mat-option
      class="flex-fill"
      *ngFor="let item of filteredValues | async"
      [value]="item"
    >
      <ng-container
        *ngTemplateOutlet="
          fvSelectCard;
          context: { value: this.item, searchCtrl: this.searchCtrl }
        "
      ></ng-container>
    </mat-option>
  </mat-select>
  <button
    [hidden]="!clearable"
    mat-icon-button
    matSuffix
    *ngIf="value && !disabled"
    matTooltip="Clear"
    (click)="clearValue($event)"
  >
    <mat-icon>clear</mat-icon>
  </button>
  <mat-error *ngIf="required">Foreign National is required</mat-error>
</mat-form-field>

<ng-template #fvSelectCard let-value="value" let-searchCtrl="searchCtrl">
  <div class="fv-option">
    <div [appHighlight]="searchCtrl?.value" class="text-truncate">
      {{ value | fullname: "surnameFirst" }}
    </div>
    <div class="d-flex align-items-center gap-1 justify-content-between">
      <ng-container
        *ngIf="value | currentPassport as passport; else noPassport"
      >
        <app-field
          alignItems="start"
          label="Passport"
          value="{{ passport.number }} | {{
            passport.issuingCountryCode | country: 'name'
          }}"
        ></app-field>
      </ng-container>
      <ng-template #noPassport>
        <app-field alignItems="start" label="Passport" value="N/A"></app-field>
      </ng-template>
      <div class="screening-indicator d-flex align-items-center gap-1">
        <div
          classList="{{
            value.latestScreening | screeningClassList
          }} d-flex rounded-circle"
        >
          <mat-icon
            style="margin: auto"
            [svgIcon]="value.latestScreening | screeningIcon"
          ></mat-icon>
        </div>
      </div>
    </div>
  </div>
</ng-template>
