<div class="container">
  <ng-container *ngIf="fv || far">
    <ng-container *ngIf="(fv && !fv.isEditable) || (far && !far.isEditable)">
      <mat-card
        data-cy="read-only-banner"
        class="mt-3 p-2 flex-fill bg-info text-white"
      >
        <div class="fv-view-banner-container px-3 align-items-center read-only">
          <mat-card-title class="d-flex"
            ><mat-icon class="align-self-center mr-1">visibility</mat-icon
            ><span> Read-Only Record </span>
          </mat-card-title>
          <div class="d-flex justify-content-center">
            This {{ fv ? "Foreign National" : "Foreign Access Request" }} record
            is read-only.
          </div>
        </div>
      </mat-card>
    </ng-container>
    <ng-container *ngIf="fv && !fv.screeningEligible">
      <div class="my-3">
        <mat-accordion data-cy="ineligible-alert-accordion" class="flex-fill">
          <mat-expansion-panel
            data-cy="ineligible-alert-panel"
            class="bg-danger text-white"
          >
            <mat-expansion-panel-header data-cy="ineligible-alert-panel-header">
              <div class="fv-view-banner-container align-items-center">
                <mat-card-title class="d-flex"
                  ><mat-icon class="align-self-center mr-1">warning</mat-icon
                  ><span> Ineligible For Screening </span>
                </mat-card-title>
                <div class="d-flex px-5 justify-content-center">
                  This Foreign National record is ineligible for Screening.
                </div>
              </div>
              <div class="d-flex"></div>
            </mat-expansion-panel-header>
            <dl class="text-white">
              <dt data-cy="fv-missing-info" class="text-white">
                Missing Information
              </dt>
              <dd data-cy="dob-missing" *ngIf="!fv.dateOfBirth">
                Date Of Birth
              </dd>
              <dd
                data-cy="passport-number-missing"
                *ngIf="
                  !fv.passports || !fv.passports[0] || !fv.passports[0].number
                "
              >
                Passport Number
              </dd>
              <dd
                data-cy="passport-country-missing"
                *ngIf="!fv.passports || !fv.passports[0]"
              >
                Passport Issuing Country Code
              </dd>
            </dl>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </ng-container>
    <ng-container *appIfRoles="Role.sv_admin">
      <mat-card
        *ngIf="fv && fv.isScreeningTimeOutstanding"
        class="my-3 p-2 flex-fill bg-warning"
      >
        <div
          class="
            fv-view-banner-container
            px-3
            align-items-center
            exceeded-description
          "
        >
          <mat-card-title class="d-flex"
            ><mat-icon
              class="align-self-center mr-1"
              matTooltip="Average Response Time Exceeded"
              >warning</mat-icon
            ><span> Average Response Time Exceeded </span>
          </mat-card-title>
          <div class="d-flex justify-content-center">
            This Foreign National is taking longer than normal to get a response
            back from the Screening Center
          </div>
        </div>
      </mat-card>
    </ng-container>
    <ng-template #panelTitle>
      <mat-panel-title class="text-white align-items-center">
        <mat-icon class="mr-1">warning</mat-icon>
        <h5 class="d-flex mb-0">Ineligible For Screening</h5>
      </mat-panel-title>
      <mat-panel-description class="text-white">
        Missing Data Required for Screening
      </mat-panel-description>
    </ng-template>
  </ng-container>
</div>
