<header
  class="d-flex p-2 justify-content-between"
  [class]="latestScreening | screeningClassList"
>
  <h4 class="d-flex flex-row align-items-center m-0">
    <div class="d-flex gap-1 align-items-center">
      <mat-icon [svgIcon]="latestScreening | screeningIcon"></mat-icon>
      {{ latestScreening | screeningLabel }}
    </div>
  </h4>
  <button
    *ngIf="fv?.allScreenings?.length! > 1"
    mat-icon-button
    [matMenuTriggerFor]="screeningHistory"
    matTooltip="All Screening Events"
  >
    <mat-icon
      svgIcon="timeline-clock-outline"
      [matBadge]="fv.allScreenings?.length"
      matBadgeColor="accent"
    ></mat-icon>
  </button>
  <mat-menu #screeningHistory="matMenu" class="screening-events">
    <button
      mat-menu-item
      *ngFor="let screeningEvent of fv.allScreenings; let i = index"
      (click)="latestScreening = screeningEvent"
      [disabled]="screeningEvent.id === latestScreening.id"
    >
      <div class="d-flex align-items-center gap-1">
        <div
          class="rounded-circle p-1 d-flex"
          [class]="screeningEvent | screeningClassList"
        >
          <mat-icon [svgIcon]="screeningEvent | screeningIcon"></mat-icon>
        </div>
        <div>
          {{ screeningEvent | screeningLabel }} -
          {{ screeningEvent | screeningDate | date: "short" }}
        </div>
      </div>
    </button>
  </mat-menu>
  <div hidden>
    <div *ngIf="latestScreening.requestedDate">
      <strong>Requested on</strong>
      {{ latestScreening.requestedDate | date: "medium" }}
    </div>
    <div *ngIf="latestScreening.completedDate">
      <strong>Completed on</strong>
      {{ latestScreening.completedDate | date: "medium" }}
    </div>
  </div>
</header>

<div class="d-flex justify-content-between align-items-center">
  <h2 mat-dialog-title [matTooltip]="fv | fullname | titlecase">
    {{ fv | fullname: "surnameFirst" | titlecase | shorten: 40:"..." }}
  </h2>
  <screening-transition
    class="pr-3"
    [screeningId]="latestScreening.id!"
  ></screening-transition>
</div>

<mat-dialog-content data-cy="screening-dialog-form">
  <form #form="ngForm">
    <screening-control
      #screening="ngModel"
      [(ngModel)]="latestScreening"
      [overrideResults]="overrideResults"
      name="latestScreening"
    ></screening-control>
    <ng-container *ngIf="screening.hasError('screening')">
      <div *ngFor="let error of screening.getError('screening')">
        {{ getErrorMessage(error) }}
      </div>
    </ng-container>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="d-flex gap-1 flex-fill align-items-center">
    <mat-icon
      color="primary"
      [svgIcon]="
        latestScreening.networkDomain === 'U' ? 'alpha-u-box' : 'alpha-j-box'
      "
      [matTooltip]="'Network ' + latestScreening.networkDomain"
    ></mat-icon>
    <mat-icon
      *ngIf="latestScreening.inheritedFromFlag"
      svgIcon="circle-multiple-outline"
      class="text-special"
      matTooltip="Inherited"
    ></mat-icon>
    <mat-icon
      *ngIf="fv.vip"
      svgIcon="star-circle"
      color="accent"
      matTooltip="Expedited Foreign National"
    ></mat-icon>
  </div>
  <ng-container *ngIf="!overrideResults">
    <ng-container *appIfRoles="ScreeningRoles">
      <ng-container *ngIf="latestScreening.status === status.Completed">
        <button
          *highEnvFeatures="true"
          mat-flat-button
          color="accent"
          (click)="onOverrideResults()"
        >
          Override Results
        </button>
      </ng-container>
    </ng-container>
    <button mat-flat-button mat-dialog-close color="primary">Close</button>
  </ng-container>
  <ng-container *ngIf="overrideResults">
    <button mat-flat-button (click)="onCancel()">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      (click)="onSubmit()"
      [disabled]="form.form.invalid || busy"
      *appIfRoles="ScreeningRoles"
    >
      Submit
    </button>
  </ng-container>
</mat-dialog-actions>
