<h5 mat-dialog-title>Delete Foreign National</h5>
<mat-dialog-content data-cy="delete-fv-form">
  <form #deleteFVForm="ngForm">
    Are you sure you want to delete {{ data.givenName }} {{ data.surname }}?
    This action cannot be undone.
  </form>
</mat-dialog-content>
<mat-dialog-actions data-cy="delete-actions" class="row justify-content-end">
  <button
    data-cy="cancel-delete"
    mat-button
    [mat-dialog-close]
    id="cancel-button"
  >
    Cancel
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="confirmDelete()"
    id="continue-button"
    data-cy="confirm-delete-button"
  >
    Continue
  </button>
</mat-dialog-actions>
