import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CountrySelectComponent } from '@app/shared/controls';
import {
  CrudChange,
  DialogCloseMessage,
  DialogData,
  ForeignVisitor,
} from '@app/shared/models';
import { FvService } from '@app/shared/services';
import { take } from 'rxjs/operators';
import { BiographicFormComponent } from './biographic-form/biographic-form.component';
@Component({
  selector: 'app-biographic-dialog',
  templateUrl: './biographic-dialog.component.html',
  styleUrls: ['./biographic-dialog.component.scss'],
})
export class BiographicDialogComponent implements OnInit {
  @ViewChild('bioForm') bioForm: BiographicFormComponent;
  fv: ForeignVisitor;
  mode: CrudChange;
  busy: boolean = false;
  vipTouched: boolean = false;
  @ViewChild('countrySelect') countrySelect: CountrySelectComponent;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData<ForeignVisitor>,
    private fvService: FvService,
    public dialogRef: MatDialogRef<BiographicDialogComponent>
  ) {}

  ngOnInit(): void {
    this.vipTouched = false;
    if (this.data.model.id) this.getLatest(this.data.model.id);
    this.mode = this.data.mode;
  }

  getLatest(id: string) {
    this.fvService.get(id).subscribe((data) => {
      this.fv = data;
    });
  }

  onSubmit() {
    if (!this.bioForm.bioForm.pristine || this.vipTouched) {
      this.busy = true;
      if (this.fv) {
        this.fvService
          .save(this.fv)
          .pipe(take(1))
          .subscribe(
            (fv: ForeignVisitor) => {
              this.onClose({
                update: fv,
                message: `Updated Biographic Information for ${fv.givenName} ${fv.surname} `,
              });
            },
            (err) => (this.busy = false)
          );
      }
    } else {
      this.dialogRef.close();
    }
  }

  onClose(message: DialogCloseMessage<ForeignVisitor>) {
    this.dialogRef.close(message);
  }
}
