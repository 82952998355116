<h3 mat-dialog-title>Employment</h3>
<form #form="ngForm" (ngSubmit)="onSubmit()" class="employment-form">
  <mat-dialog-content *ngIf="!(mode === 'delete'); else delete">
    <fieldset *ngIf="employmentRecord">
      <legend>Employee Information</legend>
      <div class="row row-cols-3">
        <mat-form-field [appearance]="appearance" class="col">
          <mat-label>Occupation Title</mat-label>
          <input
            type="text"
            matInput
            [(ngModel)]="employmentRecord.occupationTitle"
            name="positionTitle"
            maxlength="256"
            id="employment-position"
          />
        </mat-form-field>

        <mat-form-field [appearance]="appearance" class="col">
          <mat-label>Phone Number</mat-label>
          <input
            type="text"
            matInput
            [(ngModel)]="employmentRecord.employeePhone"
            name="employeePhone"
            [pattern]="PhoneValidation"
            maxlength="15"
            id="employment-employee-phone"
          />
          <mat-error>Enter a Valid Phone Number</mat-error>
        </mat-form-field>
        <mat-form-field [appearance]="appearance" class="col">
          <mat-label>Email</mat-label>
          <input
            type="text"
            matInput
            [(ngModel)]="employmentRecord.employeeEmail"
            name="employeeEmail"
            maxlength="320"
            id="employment-employee-email"
            email
          />
          <mat-error>Must enter a valid email address</mat-error>
        </mat-form-field>
      </div>
    </fieldset>
    <mat-divider></mat-divider>
    <fieldset *ngIf="employmentRecord">
      <legend>Employer Information</legend>
      <div class="row row-cols-2">
        <mat-form-field [appearance]="appearance" class="col">
          <mat-label>Employer Name</mat-label>
          <input
            type="text"
            matInput
            [(ngModel)]="employmentRecord.employerName"
            name="employerName"
            maxlength="256"
            required
            id="employment-employer-name"
          />
          <mat-error>Employer Name is Required</mat-error>
        </mat-form-field>

        <mat-form-field [appearance]="appearance" class="col">
          <mat-label>Employer Phone Number</mat-label>
          <input
            type="text"
            matInput
            [(ngModel)]="employmentRecord.employerPhone"
            name="employerPhone"
            maxlength="15"
            id="employment-employer-phone"
          />
        </mat-form-field>

        <mat-form-field [appearance]="appearance" class="col">
          <mat-label>Employer Type</mat-label>
          <mat-select
            name="employerType"
            id="employer-type-select"
            [(ngModel)]="employmentRecord.employerType"
          >
            <mat-option>Select an Employer Type</mat-option>
            <mat-option
              *ngFor="let type of employerType | values"
              [value]="type"
              >{{ type }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field [appearance]="appearance" class="col">
          <mat-label>Organizational Ownership</mat-label>
          <mat-select
            name="organizationalOwnership"
            id="organization-ownership-select"
            [(ngModel)]="employmentRecord.organizationalOwnership"
          >
            <mat-option
              *ngFor="let ownership of orgOwnership | values"
              [value]="ownership"
              >{{ ownership }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <app-address
        #address
        [(ngModel)]="employmentRecord.address"
        name="address"
        [columns]="3"
        id="employment-employer-address"
      ></app-address>
    </fieldset>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-end">
    <button
      type="button"
      mat-button
      color="primary"
      mat-dialog-close
      id="employment-cancel"
    >
      Cancel
    </button>
    <button
      mat-raised-button
      [disabled]="!isValid() || form.invalid || busy"
      type="submit"
      color="primary"
      id="employment-submit"
    >
      <span *ngIf="!busy">Submit</span>
      <mat-spinner
        class="mr-1 ml-1"
        *ngIf="busy"
        color="primary"
        diameter="35"
      ></mat-spinner>
    </button>
  </mat-dialog-actions>
</form>

<ng-template #delete> This action can not be undone </ng-template>
