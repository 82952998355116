<ng-container *ngIf="currentUser">
  <form #userForm="ngForm" class="userForm mt-2">
    <mat-form-field [appearance]="appearance">
      <mat-label>Given Name</mat-label>
      <input
        data-cy="givenName"
        [disabled]="limitedUserAdmin"
        matInput
        id="givenName"
        placeholder="Given Name"
        name="givenNameControl"
        maxlength="50"
        [(ngModel)]="value.givenName"
        (ngModelChange)="emitChangeEvent()"
        required
      />
    </mat-form-field>
    <mat-form-field [appearance]="appearance">
      <mat-label>Surname</mat-label>
      <input
        data-cy="surname"
        [disabled]="limitedUserAdmin"
        matInput
        id="surname"
        placeholder="Surname"
        name="surnameControl"
        maxlength="50"
        [(ngModel)]="value.surname"
        (ngModelChange)="emitChangeEvent()"
        required
      />
    </mat-form-field>
    <mat-form-field [appearance]="appearance">
      <mat-label>Email Address</mat-label>
      <input
        data-cy="email"
        id="email"
        type="email"
        [disabled]="limitedUserAdmin"
        matInput
        [(ngModel)]="value.email"
        (ngModelChange)="emitChangeEvent()"
        placeholder="abc@enterprise.com"
        name="emailControl"
        maxlength="320"
        required
      />
    </mat-form-field>
    <mat-form-field [appearance]="appearance">
      <mat-label>Work Phone</mat-label>
      <input
        data-cy="workPhone"
        [disabled]="limitedUserAdmin"
        id="workPhone"
        matInput
        placeholder="Phone Number"
        name="phoneControl"
        [(ngModel)]="value.phone"
        (ngModelChange)="emitChangeEvent()"
        maxlength="15"
        required
      />
    </mat-form-field>
    <mat-form-field [appearance]="appearance">
      <mat-label>Mobile Phone</mat-label>
      <input
        data-cy="mobilePhone"
        [disabled]="limitedUserAdmin"
        id="cellPhone"
        matInput
        name="mobilePhoneControl"
        [(ngModel)]="value.mobilePhone"
        (ngModelChange)="emitChangeEvent()"
        placeholder="Phone Number"
        maxlength="15"
      />
    </mat-form-field>
    <app-organization-select
      data-cy="organization"
      class="admin__org__select"
      *appIfRoles="UserCrossOrgEditRoles"
      [required]="true"
      name="orgControl"
      [(ngModel)]="value.organization"
      (ngModelChange)="emitChangeEvent()"
    >
    </app-organization-select>
    <div
      class="mat-form-field-wrapper"
      *ngIf="!limitedUserAdmin && !hideRoleSelect"
    >
      <fieldset>
        <legend>
          <h6>Roles<span style="color: red">*</span></h6>
        </legend>
        <div class="d-flex justify-content-center">
          <app-roles-select-control
            data-cy="roles"
            [readOnly]="readOnly"
            [(ngModel)]="value.roles"
            (ngModelChange)="emitChangeEvent()"
            name="roleSelect"
            [required]="true"
            [currentUserRoles]="
              currentUser?.roles?.length ? currentUser.roles! : []
            "
            [roles]="roles || []"
          ></app-roles-select-control>
        </div>
      </fieldset>
    </div>
  </form>
</ng-container>
