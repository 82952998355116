import { Component, OnInit, Inject } from '@angular/core';
import { DialogCloseMessage, ForeignVisitor } from '@app/shared/models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  AlertService,
  ForeignVisitorEmailService,
  FvService,
} from '@app/shared/services';
import { AddressService } from '@app/shared/services/address.service';

@Component({
  selector: 'app-fv-contact-dialog',
  templateUrl: './fv-contact-dialog.component.html',
  styleUrls: ['./fv-contact.component.scss'],
})
export class FvContactDialogComponent implements OnInit {
  fv: ForeignVisitor;
  isValid: boolean = true;
  busy: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<FvContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fvService: FvService,
    public foreignVisitorEmailService: ForeignVisitorEmailService,
    public addressService: AddressService,
    public alertService: AlertService
  ) {}

  /**
   * Get the latest information about the Foreign Visitor
   * 7-6-22 set the province to a default value.
   */
  ngOnInit() {
    this.fvService.get(this.data.id).subscribe((result) => {
      this.fv = result;
    });
  }

  isAddressValid(): boolean {
    return true;
  }

  /**
   * @param fv - FV that is getting saved.
   */
  save(fv: ForeignVisitor) {
    this.busy = true;
    this.fvService.save(fv).subscribe(
      (result: ForeignVisitor) => {
        this.onClose({
          update: result,
          message: `Successfully saved Foreign National #${fv.id}`,
        });
      },
      (err) => (this.busy = false)
    );
  }

  /**
   *
   * @param message Object that is sent back when the dialog is closed.
   */
  onClose(message: DialogCloseMessage<ForeignVisitor>) {
    this.dialogRef.close(message);
  }
}
