<ng-container *ngIf="employment$ | async as employment">
  <ng-container
    *ngIf="employment && employment.length; else emptyCollectionButtonStyle"
  >
    <mat-expansion-panel
      class="flex-fill mt-3 panel-250-mh"
      #employmentPanel="matExpansionPanel"
      id="fv-view-employment-panel"
      *ngIf="employment.length"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="d-flex justify-content-between py-2">
          <span>Employment</span>
          <mat-icon
            svgIcon="briefcase-outline"
            [matBadge]="employment.length"
            matBadgeSize="small"
            matBadgeColor="accent"
          ></mat-icon>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-divider class="panel-header-divider"></mat-divider>
      <div class="employment-body">
        <ng-container *ngFor="let item of employment; index as i">
          <div class="content row">
            <div class="col">
              <div class="row">
                <div class="col">
                  <dl>
                    <dt>Title</dt>
                    <dd>
                      {{ item.occupationTitle | default }}
                    </dd>
                    <dt>Email</dt>
                    <dd style="line-break: anywhere">
                      {{ item.employeeEmail | default }}
                    </dd>
                    <dt>Phone</dt>
                    <dd>
                      {{ item.employeePhone | default }}
                    </dd>
                  </dl>
                </div>
                <mat-divider
                  [vertical]="true"
                  class="panel-vertical-divider"
                ></mat-divider>
                <div class="col-6">
                  <dl>
                    <dt>Employer</dt>
                    <dd class="d-flex align-items-center">
                      <div>
                        <strong>{{ item.employerName | default }}</strong>
                      </div>
                    </dd>
                    <dd *ngIf="item.address && isAddress(item.address)">
                      {{ item.address | address: "street" | shorten: 50:"..." }}
                      <br />
                      {{
                        item.address
                          | address: "cityStateCountryPostalCode"
                          | shorten: 50:"..."
                      }}
                    </dd>
                    <dt>Employer Type</dt>
                    <dd
                      *ngIf="item.employerType || item.organizationalOwnership"
                    >
                      {{ item.employerType | default }},
                      {{ item.organizationalOwnership | default }}
                    </dd>
                    <dd
                      *ngIf="
                        !item.employerType && !item.organizationalOwnership
                      "
                    >
                      N/A
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <mat-divider
              [vertical]="true"
              class="panel-vertical-divider"
            ></mat-divider>
            <div
              class="col-2 d-flex justify-content-center align-items-center"
              style="flex-direction: column"
            >
              <button
                *ngIf="editable"
                mat-icon-button
                (click)="onEdit(item)"
                matTooltip="Edit Employment"
              >
                <mat-icon svgIcon="square-edit-outline"></mat-icon>
              </button>

              <div class="flex-fill"></div>
              <div *ngIf="i === 0 && editable" class="mb-2">
                <button
                  mat-mini-fab
                  matTooltip="Add Employment"
                  class="bg-warning"
                  style="color: white"
                  (click)="onAdd()"
                >
                  <mat-icon svgIcon="plus-circle-outline"></mat-icon>
                </button>
              </div>
            </div>
          </div>
          <mat-divider
            *ngIf="i + 1 < employment.length"
            class="panel-header-divider"
          ></mat-divider>
        </ng-container>
      </div>
    </mat-expansion-panel>
  </ng-container>
  <ng-template #emptyCollectionButtonStyle>
    <button
      class="mt-3 w-100"
      style="height: 48px"
      mat-raised-button
      matTooltip="Add New Employment"
      color="primary"
      (click)="onAdd()"
      [disabled]="!editable"
    >
      <div class="d-flex align-items-center">
        <span>Add Employment</span>
        <mat-icon class="ml-2" svgIcon="briefcase-outline"></mat-icon>
      </div>
    </button>
  </ng-template>
</ng-container>
