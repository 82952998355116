<h1 mat-dialog-title>{{ data.email.id ? "Edit" : "Add" }} Email Address</h1>
<mat-dialog-content>
  <div class="container mt-2">
    <app-email-control
      #emailForm
      name="emailForm"
      [(ngModel)]="data.email"
    ></app-email-control>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-end">
  <button mat-button mat-dialog-close color="primary">Close</button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="(emailForm.emailForm && !emailForm.emailForm?.valid) || busy"
    (click)="save(data.email)"
  >
    Submit
  </button>
</mat-dialog-actions>
