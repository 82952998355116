import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { MatSortModule } from '@angular/material/sort';
import { LocationRoutingModule } from './location-routing.module';
import { LocationCollectionTableComponent } from '../locations/collection/location-collection-table/location-collection-table.component';
import { LocationDialogComponent } from '../locations/collection/location-dialog/location-dialog.component';
import { LocationFormComponent } from '../locations/collection/location-form/location-form.component';
import { LocationsCollectionComponent } from '../locations/collection/collection.component';
import { LocationsComponent } from './locations/locations.component';
@NgModule({
  declarations: [
    LocationCollectionTableComponent,
    LocationDialogComponent,
    LocationFormComponent,
    LocationsComponent,
    LocationsCollectionComponent,
  ],
  imports: [
    SharedModule,
    LocationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
  ],
  exports: [LocationsComponent, LocationsCollectionComponent],
})
export class LocationsModule {}
