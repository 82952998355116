<form #documentForm="ngForm" [id]="typeLabel[type] + '-Id'">
  <div class="row">
    <div class="col-4">
      <!-- Document ID Number -->
      <mat-form-field appearance="outline">
        <mat-label>{{ typeLabel[type] }} ID Number</mat-label>
        <input
          [disabled]="!editable"
          type="text"
          [pattern]="type === 'DIPLOMAT' ? '[0-9\\-]+' : ''"
          [id]="typeLabel[type] + 'Number'"
          matInput
          [required]="hasData()"
          #documentNumberControl="ngModel"
          [(ngModel)]="value.documentId"
          [name]="typeLabel[type] + 'Number'"
          maxlength="25"
          aria-label="id number"
          [placeholder]="'Enter ' + typeLabel[type] + ' ID Number'"
          (ngModelChange)="emitChangeEvent()"
        />
        <mat-icon
          color="primary"
          *ngIf="type === 'DIPLOMAT'"
          svgIcon="information"
          matTooltip="Numbers and Dashes only"
          matSuffix
        ></mat-icon>
        <mat-error>Please enter a valid {{ typeLabel[type] }} ID</mat-error>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field [appearance]="appearance">
        <mat-label>Issue Date</mat-label>
        <input
          [id]="typeLabel[type] + 'IssueDate'"
          #issueDateControl
          [disabled]="!hasData() || !editable"
          [(ngModel)]="value.issueDate"
          (ngModelChange)="emitChangeEvent()"
          matInput
          [max]="today"
          [min]="minIssueDate"
          [matDatepicker]="issueDatePicker"
          name="issueDate"
          aria-label="issue date"
          placeholder="MM/DD/YYYY"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="issueDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker
          #issueDatePicker
          [disabled]="!hasData() || !editable"
        ></mat-datepicker>
        <mat-error> Please add a valid date</mat-error>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field [appearance]="appearance">
        <mat-label>Expiration Date</mat-label>
        <input
          [id]="typeLabel[type] + 'ExpirationDate'"
          #expirationDateControl
          matInput
          [disabled]="!hasData() || !editable"
          [(ngModel)]="value.expirationDate"
          (ngModelChange)="checkDocumentData()"
          [matDatepicker]="expirationDatePicker"
          [min]="value.issueDate"
          name="expirationDate"
          aria-label="expiration date"
          placeholder="MM/DD/YYYY"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="expirationDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker
          #expirationDatePicker
          [disabled]="!hasData() || !editable"
        ></mat-datepicker>
        <mat-error>Please select an expiration date</mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
