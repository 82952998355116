import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';

import { CognitoAuthRoutingModule } from '../cognito-auth-routing.module';
import { LoginComponent } from './login/login.component';
import { ForgotaccountComponent } from './forgot-account/forgotaccount.component';
import { LoginDashboardComponent } from '../../login-dashboard/login-dashboard.component';
import { MultiFactorAuthenticationComponent } from './multi-factor-authentication/multi-factor-authentication.component';
import { NewPasswordRequiredComponent } from './new-password-required/new-password-required.component';

@NgModule({
  declarations: [
    LoginComponent,
    ForgotaccountComponent,
    LoginDashboardComponent,
    MultiFactorAuthenticationComponent,
    NewPasswordRequiredComponent,
  ],
  imports: [SharedModule, CognitoAuthRoutingModule, ReactiveFormsModule],
  exports: [
    LoginComponent,
    ForgotaccountComponent,
    LoginDashboardComponent,
    MultiFactorAuthenticationComponent,
    NewPasswordRequiredComponent,
  ],
})
export class LoginModule {}
