<h3 mat-dialog-title>Edit Foreign Access Request (FAR) Details</h3>
<mat-dialog-content>
  <div class="mt-3"></div>
  <app-far-details-control
    [hideDescription]="hideDescription"
    [(ngModel)]="far"
  ></app-far-details-control>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-button
    color="primary"
    id="updateFarDetailsCancel"
    mat-dialog-close
  >
    Cancel
  </button>
  <button
    id="updateFarDetails"
    [disabled]="!far.eventTitle || !far.purposeType || busy"
    mat-raised-button
    color="primary"
    (click)="onSubmit()"
  >
    <span *ngIf="!busy; else loading">Submit</span>
  </button>
</mat-dialog-actions>

<ng-template #loading>
  <mat-spinner diameter="35" color="primary"></mat-spinner>
</ng-template>
