import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { FarLocation } from '@app/shared/models';
import { Crud, CrudService } from '@shared/services/crud.service';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
@Crud({
  apiUrl: `${environment.apiUrl}/farLocs`,
  entity: 'Itinerary item',
  hasIdPathUpdate: true,
})
export class FarLocationService extends CrudService<FarLocation> {
  constructor(http: HttpClient) {
    super(http);
  }
}
