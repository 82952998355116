import { Pipe, PipeTransform } from '@angular/core';
import {
  Screening,
  ScreeningDisplayMap,
  ScreeningStatus,
} from '@app/shared/models';
import { TimelineEvent } from '../../timeline';
import { formatDate } from '@angular/common';
import { orderBy } from 'lodash';

@Pipe({
  name: 'transition',
})
export class TransitionPipe implements PipeTransform {
  transform(value: Screening[], ...args: unknown[]): TimelineEvent[] {
    const ordered = orderBy(
      value || [],
      [(s) => s.seq ?? 0, 'lastModifiedDate'],
      ['asc', 'asc']
    );
    return ordered.map((item) => {
      const prop =
        item.result && item.status !== ScreeningStatus.Error
          ? item.result
          : item.status;
      const display = ScreeningDisplayMap.get(prop!);
      const displayDate =
        item.status === ScreeningStatus.Completed && !!item.completedDate
          ? item.completedDate
          : item.status === ScreeningStatus.InProcess && !!item.requestedDate
          ? item.requestedDate
          : item.lastModifiedDate;

      return {
        displayDate,
        icon: display?.icon,
        classList: display?.classList,
        tooltip: getToolTip(item.status!, displayDate, item.result),
      };
    });
  }
}
function getToolTip(status: string, date: any, result?: string) {
  let tooltip = `
  Status: ${status}
  Date: ${formatDate(date, 'medium', 'en-us')}
  ${result ? 'Result: ' + result : ''}
  `;
  return tooltip;
}
