<div class="login__forgot-account">
  <form #emailForm *ngIf="!success">
    <div class="forgot-account-container">
      <h4>Forgot Password</h4>
      <div *ngIf="!codeRequested">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input
            id="login-email-input"
            matInput
            placeholder="Enter Email"
            [formControl]="email"
          />
          <mat-hint
            >If your email is registered with CITADEL, a one-time password will
            be sent to your inbox.</mat-hint
          >
        </mat-form-field>
        <div class="d-flex justify-content-between mt-4">
          <button
            id="forgot-password-cancel"
            type="button"
            mat-button
            (click)="triggerViewChange(AuthFlow.LOGIN)"
          >
            Cancel
          </button>
          <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="!isEmailValid || (busy$ | async)"
            (click)="requestCode(email.value)"
          >
            <mat-spinner *ngIf="busy$ | async" [diameter]="25"></mat-spinner>
            <div *ngIf="(busy$ | async) === false">Request Code</div>
          </button>
        </div>
      </div>
    </div>
  </form>
  <form *ngIf="codeRequested && !success">
    <div class="forgot-account-container">
      <span
        >Enter the six digit code sent to
        <strong>{{ email.value }}</strong></span
      >
      <mat-form-field appearance="outline">
        <mat-label>Code</mat-label>
        <input
          id="login-code-input"
          matInput
          placeholder="Code"
          [formControl]="code"
          type="code"
          name="code"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="mb-3">
        <mat-label>Password</mat-label>
        <input
          #newPasswordInput="ngModel"
          id="new-password-input"
          matInput
          required
          placeholder="New Password"
          [(ngModel)]="newPassword"
          [type]="hide.newPassword ? 'password' : 'text'"
          name="newPassword"
          [validPassword]
        />
        <mat-icon
          matSuffix
          style="cursor: pointer"
          (click)="hide.newPassword = !hide.newPassword"
          >{{ hide.newPassword ? "visibility_off" : "visibility" }}</mat-icon
        >
        <mat-hint>
          <app-password-rules
            #passwordRules
            [control]="newPasswordInput"
          ></app-password-rules>
        </mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mt-3">
        <mat-label>Confirm Password</mat-label>
        <input
          #confirmPasswordInput="ngModel"
          id="confirm-password-input"
          matInput
          required
          placeholder="Confirm Password"
          [(ngModel)]="confirmPassword"
          [type]="hide.confirmPassword ? 'password' : 'text'"
          name="confirmPassword"
          [validPassword]
        />
        <mat-icon
          matSuffix
          style="cursor: pointer"
          (click)="hide.confirmPassword = !hide.confirmPassword"
          >{{
            hide.confirmPassword ? "visibility_off" : "visibility"
          }}</mat-icon
        >
        <mat-hint
          [class.text-danger]="
            confirmPassword !== newPassword || confirmPasswordInput.errors
          "
          [class.text-success]="
            confirmPassword === newPassword || !confirmPasswordInput.errors
          "
        >
          <mat-icon
            class="text-success"
            *ngIf="
              confirmPassword === newPassword && !confirmPasswordInput.errors
            "
            >check_circle</mat-icon
          >
          Passwords must match in order to submit.
        </mat-hint>
      </mat-form-field>
    </div>
    <div class="d-flex justify-content-between mt-3">
      <button
        id="forgot-password-cancel"
        mat-button
        type="button"
        (click)="triggerViewChange(AuthFlow.LOGIN)"
      >
        Cancel
      </button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="passwordRules.errors"
        (click)="updatePassword(email.value, code.value, newPassword)"
      >
        <mat-spinner *ngIf="busy$ | async" [diameter]="25"></mat-spinner>
        <div *ngIf="(busy$ | async) === false">Update Password</div>
      </button>
    </div>
  </form>
  <div *ngIf="success">
    <h4 class="d-flex justify-content-center">Password Reset!</h4>
    <div class="mx-3">
      <div class="row justify-content-center">Returning to Login...</div>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
</div>
