import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from '@app/shared/shared.module';
import { MonitoringRoutingModule } from './monitoring-routing.module';

@NgModule({
  declarations: [DashboardComponent],
  imports: [SharedModule, MonitoringRoutingModule],
  exports: [],
})
export class MonitoringModule {}
