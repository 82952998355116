<h2 mat-dialog-title>Override Derogatory Information</h2>

<app-classification-banner
  [classificationModel]="derogCode"
></app-classification-banner>
<mat-dialog-content>
  <app-screening-code-control
    #derogCodeControl
    [(ngModel)]="derogCode"
    name="derogCodeControl"
  ></app-screening-code-control>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-between">
  <div>
    <button
      mat-flat-button
      color="accent"
      appClassify
      *appClassifyShow
      [(classificationModel)]="derogCode"
    >
      Classify
    </button>
  </div>
  <div>
    <button mat-button [mat-dialog-close] color="primary">Cancel</button>
    <button
      appClassify
      [(classificationModel)]="derogCode"
      [confirmClassified]="true"
      mat-flat-button
      color="primary"
      (classificationConfirmed)="onAddCode(derogCode)"
      [disabled]="!derogCodeControl.derogCodeForm?.valid"
    >
      {{ derogCode.id ? "Update" : "Add" }}
    </button>
    <!-- DEVNOTE: Not sure if we are still using USP selection as a vetter updating a screening record -->
    <!-- <ng-container *ngIf="!derogCode.classification">
      <button
        appClassify
        (classificationModelChange)="onAddCode(derogCode)"
        [(classificationModel)]="derogCode"
        mat-flat-button
        color="primary"
        [disabled]="!derogCodeControl.derogCodeForm?.valid"
      >
        Add Code
      </button> -->
  </div>
</mat-dialog-actions>
