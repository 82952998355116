import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/shared/helpers';

//Components
import { AdminViewRoles } from '@shared/models/role-permissions';
import { AccountsComponent } from './accounts/accounts.component';

const routes: Routes = [
  {
    path: 'admin',
    title: 'User Administration',
    component: AccountsComponent,
    canActivate: [AuthGuard],
    data: { roles: AdminViewRoles },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
