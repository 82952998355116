import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BaseControlComponent } from '@shared/controls/base-control.component';
import { FarLocation } from '@shared/models';
import { ItineraryControlComponent } from '@app/shared/components';
import { Subject, takeUntil } from 'rxjs';
import { ItineraryControlObservable } from '../../services/itinerary-control-observable.service';

@Component({
  selector: 'app-itinerary-display',
  templateUrl: './itinerary-display.component.html',
  styleUrls: ['./itinerary-display.component.scss'],
})
export class ItineraryDisplayComponent
  extends BaseControlComponent<FarLocation[]>
  implements OnInit, AfterContentChecked, OnDestroy
{
  editMode: boolean = false;
  indexToUpdate: number = -1;
  location: FarLocation = {};
  origLocation: FarLocation = {};
  locationRequired: boolean = true;
  private ngUnsubscribe = new Subject<void>();
  @Input() owningOrg: string = '';

  @ViewChild('itineraryControl')
  itineraryControl: ItineraryControlComponent;

  constructor(
    injector: Injector,
    private _ItineraryControlObservable: ItineraryControlObservable,
    private changeDetect: ChangeDetectorRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this._ItineraryControlObservable.ItineraryControlObservable$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((event) => {
      this.locationRequired = event;
    });
  }

  ngAfterContentChecked(): void {
    this.changeDetect.detectChanges();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  edit(index?: number) {
    this.editMode = true;
    if (index !== undefined && index > -1) {
      this.indexToUpdate = index;
      this.origLocation = JSON.parse(JSON.stringify(this.value[index]));
      this.location = JSON.parse(JSON.stringify(this.value[index]));
    } else {
      this.location = {};
      this.origLocation = {};
      this.indexToUpdate = -1;
    }
  }

  cancel() {
    this.editMode = false;
    this.value[this.indexToUpdate] = JSON.parse(
      JSON.stringify(this.origLocation)
    );
    this.origLocation = {};
    this.location = {};
    this.indexToUpdate = -1;
  }

  remove(index: number) {
    this.value.splice(index, 1);
  }

  isValidDates() {
    if (
      this.location &&
      this.location.startDate &&
      this.location.endDate &&
      this.location.startDate > this.location.endDate
    ) {
      return false;
    }
    return true;
  }

  submit() {
    if (this.indexToUpdate === -1) {
      this.value.push(this.location);
    } else {
      this.value[this.indexToUpdate] = JSON.parse(
        JSON.stringify(this.location)
      );
      this.indexToUpdate = -1;
    }

    this.editMode = false;
    this.location = {};
    this.origLocation = {};
  }
}
