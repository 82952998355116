<form #emailForm="ngForm">
  <mat-form-field class="col-4 p-0" appearance="outline">
    <mat-label>Email Type</mat-label>
    <mat-select
      id="emailType"
      [(ngModel)]="value.type"
      name="type"
      placeholder="Email Type"
    >
      <mat-option>Select a Type</mat-option>
      <mat-option *ngFor="let item of emailTypes | values" value="{{ item }}">
        {{ item }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="col-8 pr-0" appearance="outline">
    <mat-label>Email Address</mat-label>
    <input
      #emailAddress="ngModel"
      matInput
      [required]="addressRequired || hasData()"
      email
      [(ngModel)]="value.emailAddress"
      name="valueEmailAddress"
      maxlength="320"
      placeholder="Email Address"
    />
    <button
      *ngIf="value.emailAddress?.length"
      matTooltip="Clear"
      matSuffix
      mat-icon-button
      (click)="value.emailAddress = ''"
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-error>Please enter a valid email address.</mat-error>
  </mat-form-field>
</form>
