import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { SharedModule } from '@app/shared/shared.module';
import { MetricsDashboardRoutingModule } from './metrics-dashboard-routing.module';
import { MetricsInsightsComponent } from './insights/insights.component';
import { GraphContainerComponent } from './graph-container/graph-container.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ScreeningGraphComponent } from './graph-container/screening-graph/screening-graph.component';
import { OrganizationGraphComponent } from './graph-container/organizations-graph/organization-graph.component';
import { LineGraphComponent } from './graph-container/line-graph/line-graph.component';
import { DerogratoryGraphComponent } from './graph-container/derogratory-graph/derogratory-graph.component';

@NgModule({
  declarations: [
    DashboardComponent,
    MetricsInsightsComponent,
    GraphContainerComponent,
    ScreeningGraphComponent,
    OrganizationGraphComponent,
    LineGraphComponent,
    DerogratoryGraphComponent,
  ],
  imports: [
    SharedModule,
    MetricsDashboardRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
    NgxChartsModule,
  ],
  exports: [],
})
export class MetricsDashboardModule {}
