import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { Role, FAR, ForeignVisitor, Sponsor } from '@app/shared/models';
import {
  AlertService,
  CrudChangeEvent,
  CrudChangeType,
  FarService,
} from '@app/shared/services';
import { map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { ClassificationModel } from '@shared/models/classification.model';
import { MatDialog } from '@angular/material/dialog';
import { AddFvsDialogComponent } from './far-view-associated-fvs/add-fvs-dialog/add-fvs-dialog.component';
import { ComponentType } from '@angular/cdk/portal';
import { FarDetailsDialogComponent } from './edit-far-details/far-details-dialog.component';
import { FVEditRoles } from '@shared/models/role-permissions';
import { DEFAULT_TITLE } from '@shared/helpers/app-title.strategy';
import { Title } from '@angular/platform-browser';
import { FarDescriptionDialogComponent } from './edit-far-description/far-description-dialog.component';
import { FarViewAssociatedFvsComponent } from './far-view-associated-fvs/far-view-associated-fvs.component';
import { CancelFarDialogComponent } from './cancel-far/cancel-far-dialog.component';
import { CommentListComponent } from '@app/shared/components';
import { AddGroupDialogComponent } from './far-view-associated-fvs/add-group-dialog/add-group-dialog.component';

@Component({
  selector: 'app-farview',
  templateUrl: './farview.component.html',
  styleUrls: ['./farview.component.scss'],
})
export class FarViewComponent implements OnInit, OnDestroy {
  modelChanges$ = this.farService.modelChanges$.pipe(
    startWith({} as CrudChangeEvent<FAR>)
  );

  farId$ = this.route.params.pipe(
    map((params) => {
      return params.id;
    })
  );
  private ngUnsubscribe = new Subject<void>();
  far$ = combineLatest([this.farId$, this.modelChanges$]).pipe(
    switchMap(([farId, updatedModel]: [string, CrudChangeEvent<FAR>]) => {
      let far: Observable<FAR> = this.farService.get(farId);
      if (updatedModel.type == 'update' && updatedModel.modelId === farId) {
        far = of(updatedModel.model) as Observable<FAR>;
      }
      return far;
    })
  );

  Role = Role;
  FVEditRoles = FVEditRoles;
  attachmentsCount: number = 0;
  @ViewChild('associatedFvs') associatedFvs: FarViewAssociatedFvsComponent;
  @ViewChild('commentList') commentList: CommentListComponent;

  /**
   * This needs to be disabled until API can be updated.
   * Errors occur in other environments because there is no access to
   * GET api/v2/systemUsers/{id} endpoint.
   *
  @ViewChild('resourceUserDetails')
  resourceUserDetails: ResourceUserDetailsComponent;
  */

  constructor(
    private route: ActivatedRoute,
    private farService: FarService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private title: Title
  ) {}

  ngOnInit(): void {
    this.far$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((far) => {
      const titleSuffix = `FAR - ${DEFAULT_TITLE}`;

      if (!!far?.eventTitle?.length) {
        this.title.setTitle(`${far?.eventTitle} - ${titleSuffix}`);
      } else {
        this.title.setTitle(titleSuffix);
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onClassify(model: ClassificationModel) {
    this.farService
      .save(model as FAR)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((far: FAR) => {
        this.alertService.successAlert(`Updated Classification for FAR`);
      });
  }

  onAddFv(far: FAR) {
    let dialogRef = this.dialog.open(AddFvsDialogComponent, {
      data: far,
      width: '600px',
    });

    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((data) => {
        let ok = dialogRef.componentInstance.ok;
        this.far$.pipe(take(1)).subscribe((far) => {
          if (this.associatedFvs && ok) this.associatedFvs.doSearch();
        });
      });
  }

  onAddGroup(far: FAR) {
    let dialogRef = this.dialog.open(AddGroupDialogComponent, {
      data: far,
      width: '600px',
    });

    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((data) => {
        let ok = dialogRef.componentInstance.ok;
        this.far$.pipe(take(1)).subscribe((far) => {
          if (this.associatedFvs && ok) this.associatedFvs.doSearch();
        });
      });
  }

  onEdit(far: FAR, hideDescription?: boolean) {
    this.openDialog({ ...far }, FarDetailsDialogComponent, hideDescription);
  }

  onChangeCancelState(far: FAR) {
    this.openDialog({ ...far }, CancelFarDialogComponent);
  }

  onDescriptionEdit(far: FAR) {
    this.openDialog({ ...far }, FarDescriptionDialogComponent);
  }

  onRemoveFv(fv: ForeignVisitor) {
    const fullName = `${fv.surname}, ${fv.givenName}`;
    this.alertService.confirmDelete({
      title: `Removing ${fullName}`,
      message: `Are you sure you would like to remove ${fullName} from this Foreign Access Request?`,
      confirmText: 'Remove',
      performAction: () =>
        this.farId$.pipe(
          switchMap((farId) => {
            return this.farService.removeFvFromFar(farId, [fv]);
          })
        ),
    });
  }

  refresh() {
    this.farService.changeEvent$.emit({ type: CrudChangeType.update });
    this.commentList.refresh();
  }

  openDialog(
    far: FAR,
    dialogComponent: ComponentType<any>,
    hideDescription?: boolean
  ) {
    let data;
    if (hideDescription) {
      data = {
        far: far,
        hideDescription: hideDescription,
      };
    } else data = far;

    let dialogRef = this.dialog.open(dialogComponent, {
      data: data,
      width: '600px',
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((data) => {
        this.far$.pipe(take(1)).subscribe((far) => {
          /**
           * This needs to be disabled until API can be updated.
           * Errors occur in other environments because there is no access to
           * GET api/v2/systemUsers/{id} endpoint.
           *
          this.resourceUserDetails.data = far;
          this.resourceUserDetails.refreshModifiedUser();
          */
          this.refresh();
        });
      });
  }

  protected readonly parseInt = parseInt;
}
