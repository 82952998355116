import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ClassificationBannerComponent,
  ClassifyDirective,
  ClassifyShowDirective,
} from '@shared/cmt';

const SHARED_COMPONENTS: any[] = [
  ClassificationBannerComponent,
  ClassifyDirective,
  ClassifyShowDirective,
];

@NgModule({
  declarations: SHARED_COMPONENTS,
  imports: [CommonModule],
  exports: SHARED_COMPONENTS,
})
export class CmtModule {}
