<h3 mat-dialog-title>Password Reset</h3>
<mat-dialog-content>
  <div class="row">
    <div class="col">
      <dl>
        <dt>Given Name</dt>
        <dd>{{ user.givenName }}</dd>
      </dl>
      <dl>
        <dt>Email</dt>
        <dd>{{ user.email }}</dd>
      </dl>
    </div>
    <div class="col">
      <dl>
        <dt>Surname</dt>
        <dd>{{ user.surname }}</dd>
      </dl>
      <dl>
        <dt>New Password</dt>
        <dd>
          {{ newPwd }}
          <button mat-icon-button [cdkCopyToClipboard]="newPwd">
            <mat-icon matTooltip="Copy">content_copy</mat-icon>
          </button>
        </dd>
      </dl>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
  <button mat-button mat-dialog-close cdkFocusInitial>Close</button>
</mat-dialog-actions>
