<form ngForm #fvContactForm="ngForm" *ngIf="value">
  <mat-tab-group>
    <mat-tab label="Emails" bodyClass="mat-dialog-content">
      <div
        class="container"
        #emailGroup="ngModelGroup"
        [ngModelGroup]="'emailGroup'"
      >
        <ng-container *ngIf="value.emails">
          <ng-container
            *ngFor="let email of value.emails; index as i; last as isLast"
          >
            <div class="row align-items-baseline mt-3 justify-content-around">
              <div class="col">
                <app-email-control
                  name="fv-email-{{ i }}"
                  id="fv-email-control-{{ i }}"
                  (ngModelChange)="emitChangeEvent()"
                  [(ngModel)]="value.emails[i]"
                >
                </app-email-control>
              </div>
              <div *restrictDelete class="col-1 align-self-baseline">
                <button
                  *ngIf="value.isDeletable"
                  id="remove-email-button-{{ i }}"
                  mat-icon-button
                  color="warn"
                  matTooltip="Remove Email"
                  (click)="deleteEmailDialog(email)"
                >
                  <mat-icon>remove_circle_outline</mat-icon>
                </button>
              </div>
              <div class="col-1 align-self-baseline">
                <button
                  *ngIf="isLast"
                  mat-icon-button
                  color="primary"
                  matTooltip="Add New Email"
                  (click)="addItem(value.emails)"
                  id="add-email-button"
                >
                  <mat-icon svgIcon="plus-circle-outline"></mat-icon>
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <ng-container *ngIf="!value.emails || value.emails?.length === 0">
        <app-empty-state [message]="'No Emails Available'"
          ><button
            *ngIf="value.emails"
            mat-flat-button
            color="accent"
            id="new-email-button"
            (click)="addItem(value.emails)"
          >
            <span>Add New Email</span>
          </button></app-empty-state
        >
      </ng-container>
    </mat-tab>
    <mat-tab label="Phone Number" bodyClass="mat-dialog-content">
      <div
        *ngIf="value.phoneNumbers && value.phoneNumbers!.length > 0"
        class="container"
        #phoneNumberGroup="ngModelGroup"
        [ngModelGroup]="'phoneNumberGroup'"
      >
        <ng-container
          *ngFor="
            let phoneNumber of value.phoneNumbers;
            index as i;
            first as isFirst;
            last as isLast
          "
        >
          <div *ngIf="value.phoneNumbers" class="row align-items-baseline mt-3">
            <div class="col-auto flex-fill">
              <app-phone-number-control
                name="fv-phone-number-{{ i }}"
                id="fv-phone-number-control-{{ i }}"
                [(ngModel)]="value.phoneNumbers[i]"
                [required]="required"
                (ngModelChange)="updatePrimary($event, i)"
              ></app-phone-number-control>
            </div>
            <div *restrictDelete class="col-auto">
              <button
                *ngIf="value.isDeletable"
                id="remove-phone-button={{ i }}"
                mat-icon-button
                color="warn"
                matTooltip="Remove Phone Number"
                (click)="deletePhoneDialog(phoneNumber)"
              >
                <mat-icon>remove_circle_outline</mat-icon>
              </button>
            </div>
            <div class="col-auto">
              <button
                *ngIf="isLast"
                mat-icon-button
                color="primary"
                matTooltip="Add New Phone Number"
                (click)="addItem(value.phoneNumbers)"
                id="add-phone-button"
              >
                <mat-icon svgIcon="plus-circle-outline"></mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
      <ng-container
        *ngIf="!value.phoneNumbers || value.phoneNumbers?.length === 0"
      >
        <app-empty-state [message]="'No Phone Numbers Available'"
          ><button
            *ngIf="value.phoneNumbers"
            mat-flat-button
            color="accent"
            id="new-phone-number-button"
            (click)="addItem(value.phoneNumbers)"
          >
            <span>Add New Phone Number</span>
          </button></app-empty-state
        >
      </ng-container>
    </mat-tab>
    <mat-tab label="Address">
      <app-address
        #address
        class="d-flex mx-3 mt-3"
        [(ngModel)]="value.address"
        (ngModelChange)="emitChangeEvent()"
        name="contact-address"
        [columns]="2"
        id="contact-address"
        ngDefaultControl
      ></app-address>
    </mat-tab>
  </mat-tab-group>
</form>
