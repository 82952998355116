import { Component, OnInit } from '@angular/core';
import {
  FilterParams,
  ForeignVisitor,
  PageableCollection,
} from '@app/shared/models';
import { CrudService, FvService, Repository } from '@app/shared/services';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-fv-workspace',
  templateUrl: './fv-workspace.component.html',
  styleUrls: ['./fv-workspace.component.scss'],
  providers: [Repository, { provide: CrudService, useExisting: FvService }],
})
export class FvWorkspaceComponent implements OnInit {
  filterParams = this.repo.defaultFilterParams;
  fvs$: Observable<PageableCollection<ForeignVisitor>> =
    this.repo.collection$.pipe(
      tap(() => {
        this.loading = false;
      })
    );

  loading = false;
  constructor(private repo: Repository<ForeignVisitor>) {}

  ngOnInit(): void {
    this.loading = true;
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.onFilterParamChange(this.filterParams);
  }
}
