<div
  class="timeline"
  [class]="{
    left: left,
    right: !left,
    alternate: alternate,
    horizontal: horizontal,
    vertical: !horizontal
  }"
>
  <div class="timeline__events" [style]="horizontal ? 'flex-wrap: wrap' : ''">
    <ng-container *ngFor="let item of data; let even = even; let i = index">
      <div class="timeline__event">
        <div class="timeline__vertical-line" *ngIf="!horizontal"></div>
        <ng-template #defaultBadge>
          <div class="timeline__badge">
            <mat-icon
              *ngIf="item.icon || icon"
              [svgIcon]="item.icon || icon"
              class="timeline__badge__icon"
              [matTooltip]="generateIconTooltip(item)"
            ></mat-icon>
          </div>
        </ng-template>
        <ng-container
          [ngTemplateOutlet]="badgeTemplate?.tempRef || defaultBadge"
          [ngTemplateOutletContext]="{ item: item }"
        ></ng-container>

        <div class="timeline__horizontal-line" *ngIf="horizontal"></div>

        <ng-container *ngIf="!horizontal">
          <div class="timeline__triangle" *ngIf="triangle"></div>
          <div class="timeline__content">
            <ng-template #defaultContentTemplate>
              <mat-card appearance="outlined">
                <mat-card-header>
                  <mat-card-subtitle>{{
                    item.startDate | date
                  }}</mat-card-subtitle>
                  <mat-card-title *ngIf="item.title">{{
                    item.title
                  }}</mat-card-title>
                </mat-card-header>
                <mat-card-content *ngIf="item.content">{{
                  item.content
                }}</mat-card-content>
              </mat-card>
            </ng-template>
            <ng-template
              [ngTemplateOutlet]="contentTemplate || defaultContentTemplate"
              [ngTemplateOutletContext]="{
                item: item,
                title: item.title
              }"
            ></ng-template>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
