import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/shared/helpers';
import { GroupsComponent } from './groups/groups.component';
import { GroupViewRoles } from '@shared/models/role-permissions';

const routes: Routes = [
  {
    path: 'groups',
    title: 'Groups',
    component: GroupsComponent,
    canActivate: [AuthGuard],
    data: { roles: GroupViewRoles },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GroupsRoutingModule {}
