import { Address } from './address.model';
import { BaseModel } from './base.model';
import { ForeignVisitor } from './foreign-visitor.model';
import { Organization } from './organization.model';

export interface Contact extends BaseModel {
  id?: string;
  givenName?: string;
  surname?: string;
  groupName?: string;
  type?: ContactType;
  nameType?: 'group' | 'person';
  email?: string;
  phone?: string;
  organization?: Organization;
  organizationId?: Organization;
  foreignVisitors?: ForeignVisitor[];
  address?: Address;
}

export enum ContactType {
  ORG_ADMIN_APPROVER = 'OrgAdminApprover',
  SCREENING = 'Screening',
}
