import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { NgPipesModule } from 'ngx-pipes';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  AddressComponent,
  ApprovalControlComponent,
  ContactSelectComponent,
  CountrySelectComponent,
  DateTimeComponent,
  EmailControlComponent,
  MinDateDirective,
  NationalIdControlComponent,
  OrganizationSelectComponent,
  PhoneNumberControlComponent,
  RolesSelectControlComponent,
  ScreeningCodeControlComponent,
  ScreeningCodeDialogComponent,
  ScreeningCodeDirective,
  ScreeningCodeDisplayComponent,
  ScreeningControlComponent,
} from '@shared/controls';
import { CmtModule } from '../cmt/cmt.module';

const SHARED_CONTROLS: any[] = [
  AddressComponent,
  ApprovalControlComponent,
  ContactSelectComponent,
  CountrySelectComponent,
  DateTimeComponent,
  EmailControlComponent,
  MinDateDirective,
  NationalIdControlComponent,
  OrganizationSelectComponent,
  PhoneNumberControlComponent,
  RolesSelectControlComponent,
  ScreeningCodeControlComponent,
  ScreeningCodeDialogComponent,
  ScreeningCodeDirective,
  ScreeningCodeDisplayComponent,
  ScreeningControlComponent,
];

@NgModule({
  declarations: SHARED_CONTROLS,
  imports: [
    CmtModule,
    CommonModule,
    DirectivesModule,
    FormsModule,
    MaterialModule,
    NgPipesModule,
    NgxMatSelectSearchModule,
    PipesModule,
    ReactiveFormsModule,
  ],
  exports: SHARED_CONTROLS,
})
export class ControlsModule {}
