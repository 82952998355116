import { Component, Input } from '@angular/core';
@Component({
  selector: 'insight-card',
  templateUrl: 'insight-card.component.html',
  styleUrls: ['insight-card.component.scss'],
})
export class InsightCardComponent {
  @Input() public value: any;
  @Input() public icon?: string;
  @Input() public svgIcon?: string;
  @Input() public label: string;
  @Input() public clickable? = false;
  @Input() public showClickable? = false;
  @Input() public filterQueryParams?: any;
  @Input() public filterRouterLink?: string;
  @Input() public result?: string;
  @Input() public filterMode?: boolean = false;
  @Input() public isToggled?: boolean = false;
}
