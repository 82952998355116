import {
  Component,
  Injector,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  OrgLocation,
  PageableCollection,
  FilterParams,
} from '@app/shared/models';
import { OrgLocationService } from '@app/shared/services';
import { Observable, take } from 'rxjs';
import { BaseMatSelectComponent } from '@app/shared/controls/base-mat-select.component';
import { AuthService } from '@app/auth/auth.service';

@Component({
  selector: 'app-org-location-select',
  templateUrl: './org-location-select.component.html',
  styleUrls: ['./org-location-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgLocationSelectComponent
  extends BaseMatSelectComponent<OrgLocation>
  implements OnDestroy, OnInit
{
  constructor(
    public injector: Injector,
    private auth: AuthService,
    private locationService: OrgLocationService
  ) {
    super(injector);
  }

  @Input() errorMessage: string = '';
  @Input() organizationId: string = '';
  @Input() multiple: boolean = false;
  @Input()
  set locationId(_locationId: string) {
    this.loadLocationId(_locationId);
  }

  get locationId(): string {
    return this.value?.id!;
  }

  @Input() showCreate: boolean = true;
  @Output() locationIdChange: EventEmitter<string> = new EventEmitter<string>();

  baseFilterParams: FilterParams = new FilterParams({
    currentNetworkDomain: true,
    sort: {
      active: 'name',
      direction: 'asc',
    },
  });

  ngOnInit(): void {
    if (this.baseFilterParams && this.organizationId)
      this.baseFilterParams.organizationId = this.organizationId;
    this.search();
    let authOrg = this.auth.getOrganization();
    if (this.organizationId && authOrg && authOrg.id !== this.organizationId)
      this.showCreate = false;
  }

  searchItems(
    params?: FilterParams
  ): Observable<PageableCollection<OrgLocation>> {
    return this.locationService.find(params);
  }

  loadLocationId(locId: string) {
    if ((!locId && !this.value) || locId === this.value?.id) return;

    if (!locId) {
      // @ts-ignore
      this.value = null;
      super.emitChangeEvent();
      return;
    }

    this.value = { id: locId };
    this.locationService
      .get(locId)
      .pipe(take(1))
      .subscribe((data) => {
        this.value = data;
        super.emitChangeEvent();
      });
  }

  emitChangeEvent() {
    super.emitChangeEvent();
    this.locationIdChange.emit(this.locationId);
  }

  onCreateLocation(location: OrgLocation) {
    this.value = location;
    this.searchCtrl.setValue(location.name);
    this.matSelect.selectionChange.emit();
  }

  trackBy(idx: number, location: OrgLocation): string {
    return location?.id;
  }
}
