import { Pipe, PipeTransform } from '@angular/core';
import { ContactPhoneNumber } from '../models/contact-phone-number.model';

@Pipe({
  name: 'phoneNumber',
  pure: false,
})
export class PhoneNumberPipe implements PipeTransform {
  transform(
    phone: ContactPhoneNumber | null,
    display?: 'number' | 'label'
  ): string | undefined {
    if (!phone) return undefined;
    if (display == 'label') {
      return this.findLabel(phone);
    }
    let phoneStr: string | undefined = phone.number;
    phoneStr = this.addCountryCode(phone, phoneStr);
    phoneStr = this.addExtension(phone, phoneStr);
    if (display == 'number') {
      return phoneStr;
    }
    phoneStr = `${this.findLabel(phone)}: ${phoneStr}`;
    return phoneStr;
  }

  findLabel(phone: ContactPhoneNumber): string | undefined {
    return phone?.type ? phone.type : 'None';
  }

  addCountryCode(
    phone: ContactPhoneNumber,
    phoneStr: string | undefined
  ): string {
    return phone.countryCode
      ? `${phone.countryCode} ${phoneStr}`
      : `${phoneStr}`;
  }

  addExtension(
    phone: ContactPhoneNumber,
    phoneStr: string | undefined
  ): string | undefined {
    return phone.extension ? `${phoneStr} ${phone.extension}` : `${phoneStr}`;
  }
}
