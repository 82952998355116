<mat-form-field
  appearance="outline"
  floatLabel="always"
  subscriptSizing="dynamic"
>
  <mat-label *ngIf="label !== ''">{{ label }}</mat-label>
  <button mat-icon-button matPrefix>
    <mat-icon>search</mat-icon>
  </button>
  <input
    id="term-input"
    type="text"
    matInput
    [formControl]="filter"
    [placeholder]="placeholder"
  />
  <button
    id="clear-input"
    mat-icon-button
    matSuffix
    (click)="clearSearchField()"
    *ngIf="hasValue"
  >
    <mat-icon>close</mat-icon>
  </button>
  <button
    mat-icon-button
    *ngIf="filterFields?.length"
    [appAdvancedSearch]="filterFields"
    [filterParams]="filterParams"
    (appSearchUpdated)="onAdvancedSearchChange($event)"
    matSuffix
    matTooltip="Advanced Search"
  >
    <mat-icon>tune</mat-icon>
  </button>
</mat-form-field>
