<div
  class="
    profile-container
    d-flex
    container
    align-items-center
    justify-content-center
    p-3
  "
>
  <mat-card appearance="outlined" class="col p-3 m-auto">
    <mat-card-content>
      <useracct></useracct>
    </mat-card-content>
  </mat-card>
</div>
