import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User, Role, UserPreferenceBlob } from '@app/shared/models';
import { ActionButton } from '@app/shared/models/actionbutton.model';
import { FilterParams } from '@app/shared/models/filter-params.model';
import { PageableCollection } from '@app/shared/models/pageable-collection.model';
import { AppConfigValue, UserSortFieldLabels } from '@shared/services';
import { UserEditRoles } from '@shared/models/role-permissions';
import { AccountsAdvancedSearch } from '@app/admin/accounts/accounts-collection/accounts-advanced-search';
import {
  UserTableColumns,
  UserTableName,
} from '@app/admin/accounts/accounts-collection/account-user-table/account-user-table.component';

import { AuthService } from '@app/auth/auth.service';
import { UserPreferenceService } from '@app/shared/services/user-preference.service';
import { fvColumns } from '@app/screening/collection/screening-table/screening-table.component';
import { ScreeningFieldLabels } from '@app/shared/components/screening';
import { debounceTime, take } from 'rxjs';

const newUserAction: ActionButton = {
  label: 'New User Account',
  menuIcon: 'note_add',
  userDialog: true,
  roles: UserEditRoles,
};

const allActions: ActionButton[] = [newUserAction];

@Component({
  selector: 'app-accounts-collection',
  templateUrl: 'accounts-collection.component.html',
  styleUrls: ['accounts-collection.component.scss'],
})
export class AccountsCollectionsComponent implements OnInit {
  @AppConfigValue('limitedUserAdmin')
  limitedUserAdmin: boolean = false;
  userPreference: UserPreferenceBlob | undefined;
  @Input() loading: boolean = false;
  @Input() position: 'fixed' | 'sticky' = 'sticky';
  @Input() pageableCollection: PageableCollection<User>;
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  @Input() workspaceView: boolean = false;
  actionButtons: ActionButton[];
  showingInactive: boolean = false;
  hasUserPreference: boolean = false;

  constructor(
    private auth: AuthService,
    private userPreferenceService: UserPreferenceService
  ) {
    this.actionButtons = this.limitedUserAdmin ? [] : allActions;
  }

  ngOnInit(): void {
    this.getUserPreferences();
  }

  getUserPreferences() {
    this.userPreferenceService
      .getCurrentUserId(this.auth?.getUser()?.id!)
      .pipe(debounceTime(600), take(1))
      .subscribe(
        (result) => {
          if (result !== null && result !== undefined) {
            this.userPreference = JSON.parse(result.userPreferenceBlob!);
            let tabDetails = this.userPreference?.tabs.at(6);
            let adminArray: Array<string> = [];
            tabDetails?.tabColumns!.filter((x) => {
              if (x.selected === true) {
                adminArray.push(x.label);
              }
            });
            if (
              this.userPreference !== null &&
              this.userPreference !== undefined
            ) {
              this.userPreferenceService.loadDisplayColumns(
                UserSortFieldLabels,
                adminArray,
                UserTableColumns,
                UserTableName,
                this.auth?.getUser()?.roles!,
                this.auth.getUser()?.networkDomain!
              );
            }
          }
        },
        (err) => {}
      );
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParamsChange.emit(filterParams);
  }

  protected readonly AccountsAdvancedSearch = AccountsAdvancedSearch;
  protected readonly UserTableColumns = UserTableColumns;
  protected readonly UserSortFieldLabels = UserSortFieldLabels;
  protected readonly UserTableName = UserTableName;
}
