export enum AttachmentType {
  DOCUMENT = 'Document',
  IMAGE = 'Image',
}

export enum AttachmentStatus {
  SCAN_PENDING = 'Scan Pending',
  CLEAN = 'Clean',
  INFECTED = 'Infected',
}

export interface AttachmentData {
  id?: string;
  parentId?: string;
  fileHash?: string; // (64)
  name?: string; // (255)
  description?: string; // (1000)
  type?: AttachmentType;
  mimeType?: string; // (125)
  status?: AttachmentStatus;
  presignUrl?: string;
  xAmzServerSideEncryption?: string;
  xAmzServerSideEncryptionId?: string;
}
