<ng-container
  *ngIf="fv && (!fv.dateOfBirth || !documentsCheck() || !checkForActiveFar())"
>
  <mat-accordion data-cy="ineligible-alert-accordion" class="my-3 flex-fill">
    <mat-expansion-panel data-cy="ineligible-alert-panel" class="bg-warning">
      <mat-expansion-panel-header data-cy="ineligible-alert-panel-header">
        <div class="fv-view-banner-container align-items-center">
          <mat-card-title class="d-flex"
            ><mat-icon class="align-self-center mr-1">warning</mat-icon
            ><span> Ineligible For Screening </span>
          </mat-card-title>
          <div class="pl-3">
            This Foreign National record will be ineligible for Screening.
          </div>
        </div>
        <div class="d-flex"></div>
      </mat-expansion-panel-header>
      <dl class="mb-0">
        <dt style="color: black">Missing Information</dt>
        <dd *ngIf="!fv.givenName">Given Name</dd>
        <dd *ngIf="!fv.surname">Surname</dd>
        <dd data-cy="dob-missing" *ngIf="!fv.dateOfBirth">Date Of Birth</dd>
        <ng-container *ngIf="!additionalDocumentsScreeningEligibility">
          <dd
            data-cy="passport-number-missing"
            *ngIf="!fv.passports || !fv.passports[0] || !fv.passports[0].number"
          >
            Passport Number
          </dd>
          <dd
            data-cy="passport-country-missing"
            *ngIf="
              !fv.passports ||
              !fv.passports[0] ||
              !fv.passports[0].issuingCountryCode
            "
          >
            Passport Issuing Country Code
          </dd>
        </ng-container>
        <dd *ngIf="!checkForActiveFar() && farScreeningEligibility">
          No Active FAR (FAR with Current or Future Visit Date)
        </dd>
      </dl>
      <dl *ngIf="additionalDocumentsScreeningEligibility && !isDocumentData()">
        <dd><b>One of the following is required:</b></dd>
        <dd class="ml-2">Both Passport Number and Issue Country</dd>
        <dd class="ml-2">Visa Number</dd>
        <dd class="ml-2">Diplomatic ID Number</dd>
      </dl>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
