import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { Contact, FilterParams, Organization, Role } from '@app/shared/models';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import {
  AlertService,
  OrganizationService,
  OrgSortField,
  AppConfigService,
  OrgSortFieldLabels,
} from '@shared/services';
import { TooltipPosition } from '@angular/material/tooltip';
import { take, takeUntil } from 'rxjs/operators';
import { ColumnChooserEventObservable } from '@app/shared/components/column-chooser/column-chooser-observable.service';
import { ColumnChooserComponent } from '@app/shared/components';
import { Subject } from 'rxjs';
import { AuthService } from '@app/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FVViewRoles } from '@app/shared/models/role-permissions';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { UserPreferenceService } from '@app/shared/services/user-preference.service';

export const OrgTableColumns: string[] = [
  OrgSortField.NAME,
  OrgSortField.SHORT_NAME,
  OrgSortField.PARENT_ORG,
  OrgSortField.SUB_ORGS,
  OrgSortField.CREATED_DATE,
  OrgSortField.LAST_MODIFIED_DATE,
  OrgSortField.FN_RECORD_COUNT,
  OrgSortField.CONTACTS_COUNT,
  OrgSortField.RESTRICTED_ACCESS,
];

export const OrgTableName = 'organizationTable';

@Component({
  selector: 'app-organization-collection-table',
  templateUrl: './organization-collection-table.component.html',
  styleUrls: ['./organization-collection-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*', padding: '5px 0' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class OrganizationCollectionTableComponent implements OnInit, OnDestroy {
  @Input() organization: Organization[] = [];
  @Input() filterParams: FilterParams;
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  position: TooltipPosition = 'right';
  organizationEditEnabled?: boolean = false;
  user = inject(AuthService);
  canViewFN: boolean = false;
  protected readonly OrgSortField = OrgSortField;
  private ngUnsubscribe = new Subject<void>();
  expandedOrg: Organization | null;
  showContactActions: boolean = false;

  organizationColumns = OrgTableColumns;

  organizationColumnsDisplay: { [key: string]: boolean } =
    this.organizationColumns.reduce(
      (val, col) => ({ [col]: true, ...val }),
      {} as { [key: string]: boolean }
    );

  constructor(
    public dialog: MatDialog,
    public orgService: OrganizationService,
    public alert: AlertService,
    private _ColumnChooserEventObservable: ColumnChooserEventObservable,
    private config: AppConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private userPreferenceService: UserPreferenceService
  ) {}

  ngOnInit(): void {
    this.toggleOrgEdit(this.user.getRoles());
    const userRoles = this.user.getRoles();
    let roleFound = userRoles.find((r) => FVViewRoles.includes(r)) || false;
    if (roleFound) this.canViewFN = true;
    this.userPreferenceService.userPreferenceDisplaySubject$.subscribe((x) => {
      if (x.hasPreference && x.tableName === OrgTableName) {
        this.organizationColumns = x.columnNames!;
        this.organizationColumnsDisplay = x.selectedColumns!;
      }
    });
    this._ColumnChooserEventObservable.ColumnChooserObservable$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((event) => {
      if (event.tableName === OrgTableName) {
        this.organizationColumnsDisplay =
          ColumnChooserComponent.updateTableDisplay(
            event,
            this.organizationColumns,
            this.organizationColumnsDisplay
          );
      }
    });
  }

  // Actions are visible only on low environment and when a SV_ADMIN Role.
  toggleOrgEdit(roles: Role[]) {
    const lowToggle = this.config.get('lowEnvFeatures', false);
    if (
      lowToggle &&
      this.user.isAdmin &&
      this.organizationColumns.indexOf('actions') === -1
    ) {
      this.organizationColumns.push('actions');
      this.organizationColumnsDisplay['actions'] = true;
      this.showContactActions = true;
    }
  }

  toggleRow(org: Organization) {
    org.contacts && org.contacts.length > 0
      ? (this.expandedOrg = this.expandedOrg === org ? null : org)
      : null;
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onEdited(result: Organization) {
    const organization = this.organization.find((o) => o.id === result.id);

    if (organization) {
      Object.assign(organization, result);
    }
  }

  onSortChange(sort: Sort) {
    this.filterParams.sortBy = sort.active;
    this.filterParams.direction = sort.direction.toUpperCase();
    this.filterParamsChange.emit(this.filterParams);
  }

  applyParentOrgFilter(parentOrgId: string) {
    this.filterParams = new FilterParams({
      parentOrganizationId: parentOrgId,
    });
    this.filterParamsChange.emit(this.filterParams);
  }

  navigateToFNs(
    count: any,
    destination: string,
    queryField?: string,
    queryParam?: string | boolean
  ) {
    if (count) {
      let filter: any = {};
      if (queryField) {
        filter[queryField] = queryParam;
        this.router.navigate([destination], {
          relativeTo: this.route,
          queryParams: filter,
        });
      } else {
        this.router.navigate([destination], {
          relativeTo: this.route,
        });
      }
    }
  }

  deleteOrganization(id?: string) {
    this.alert
      .confirmDelete({
        title: `Confirm Delete Organization`,
        message: `Are you sure you want to delete this Organization?`,
        performAction: () => this.orgService.delete(id),
      })
      .pipe(take(1))
      .subscribe(() => {
        this.filterParamsChange.emit(this.filterParams);
      });
  }

  refresh() {
    this.filterParamsChange.emit(this.filterParams);
    this.expandedOrg = null;
  }

  protected readonly OrgSortFieldLabels = OrgSortFieldLabels;
}
