import { Component } from '@angular/core';
import { FilterParams } from '@app/shared/models';
import { Approval } from '@app/shared/models/approval';
import { CrudService, Repository } from '@app/shared/services';
import {
  ApprovalService,
  ApprovalSortField,
} from '@app/shared/services/approval.service';
import { tap } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-approvals',
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.scss'],
  providers: [
    Repository,
    { provide: CrudService, useExisting: ApprovalService },
  ],
})
export class ApprovalsComponent {
  loading: boolean = false;
  refresh$ = this.repo.refresh$.pipe(startWith({}));
  collection$ = this.refresh$.pipe(
    switchMap(() => {
      return this.repo.collection$;
    }),
    tap((_) => {
      this.loading = false;
    })
  );

  filterParams: FilterParams = new FilterParams({
    pageNum: 0,
    pageSize: 20,
    acctApprovalIsValid: true,
    sortBy: ApprovalSortField.EXPIRATION_DATE,
    direction: 'ASC',
  });
  constructor(private repo: Repository<Approval>) {
    const mergedFilterParams = Object.assign(
      this.filterParams,
      this.repo.getQueryParamsSnapshot()
    );
    this.onFilterParamChange(mergedFilterParams);
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.navigateWithParams(filterParams);
  }
}
