<div class="about-dialog mt-2">
  <div mat-dialog-title class="text-center">
    <mat-icon [citadelLogo]="'horizontal'"></mat-icon>
  </div>
  <mat-dialog-content>
    <div class="d-flex flex-column">
      <p>
        The
        <strong>
          Counterintelligence Threat Actor Discovery Exploitation Landscape
          (CITADEL) Foreign Access Management Enterprise (FAME) </strong
        >provides a mechanism for U.S. Government (USG) partners to submit
        foreign nationals to the national vetting enterprise for screening and
        vetting. CITADEL enables USG counterintelligence (CI) and security
        components to more efficiently screen foreign nationals requesting
        access to USG facilities, systems, and personnel while providing
        information to operational elements on known or suspected intelligence
        actors.
      </p>
    </div>
    <div *ngIf="!apiInfo || !uiInfo" class="d-flex m-5 justify-content-center">
      <mat-spinner diameter="24"></mat-spinner>
    </div>
    <div class="row mt-3 mb-3">
      <mat-list *ngIf="apiInfo && uiInfo" class="col">
        <mat-list-item class="ml-3">
          <div matListItemTitle class="font-weight-bold">Citadel API</div>
          <div matListItemLine>
            Version: {{ apiInfo.build.version }} ({{ apiInfo.git.commit.id }})
          </div>
          <div
            *ngIf="apiInfo.build.time | date: 'medium' as apiBuildTime"
            matListItemLine
            [matTooltip]="apiBuildTime"
          >
            Build: {{ apiBuildTime | date }}
          </div>
        </mat-list-item>
      </mat-list>
      <mat-list *ngIf="apiInfo && uiInfo" class="col">
        <mat-list-item>
          <div matListItemTitle class="font-weight-bold">Citadel UI</div>
          <div matListItemLine>
            Version: {{ uiInfo.version }} ({{ uiInfo.hash }})
          </div>
          <div
            *ngIf="uiInfo.date | date: 'medium' as uiBuildDate"
            matListItemLine
            [matTooltip]="uiBuildDate"
          >
            Build: {{ uiBuildDate | date }}
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="d-flex flex-row">
    <span class="col"></span>
    <button mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions>
</div>
