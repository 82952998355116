<h1 mat-dialog-title>Visa</h1>
<mat-dialog-content>
  <app-visa
    #visaForm
    id="visaForm"
    name="visa"
    [(ngModel)]="visa"
    [showClearButton]="false"
  ></app-visa>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-end">
  <button mat-button color="primary" id="visa-edit-cancel" mat-dialog-close>
    Cancel
  </button>
  <button
    [disabled]="visaForm?.visaForm?.invalid || busy"
    id="updateVisa"
    mat-raised-button
    color="primary"
    (click)="submitVisa()"
  >
    <span *ngIf="!busy">Submit</span>
    <mat-spinner
      class="mr-1 ml-1"
      *ngIf="busy"
      color="primary"
      diameter="35"
    ></mat-spinner>
  </button>
</mat-dialog-actions>
