<ng-container *ngIf="data">
  <h3 mat-dialog-title>National IDs</h3>
  <mat-dialog-content>
    <div class="mt-2">
      <app-nationalId-control
        ngDefaultControl
        #nationalIdCtl="ngModel"
        [(ngModel)]="value"
      >
      </app-nationalId-control>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-end">
    <button mat-button mat-dialog-close color="primary">Cancel</button>
    <button
      mat-flat-button
      (click)="onSubmit()"
      [disabled]="!nationalIdCtl.valid"
      color="primary"
    >
      Submit
    </button>
  </mat-dialog-actions>
</ng-container>
