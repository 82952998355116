<div
  *ngIf="showFarChangeWarning"
  class="bg-warning mb-3 py-1 pl-2 date-warning"
>
  Editing Dates could affect assigned, Unscreened Foreign Nationals
</div>
<div class="itinerary-control">
  <form
    #itineraryForm="ngForm"
    class="itinerary-edit d-flex flex-column"
    *ngIf="value"
  >
    <div class="row">
      <!--Start Date Picker-->
      <mat-form-field [appearance]="'outline'" class="col">
        <mat-label>Visit Start Date</mat-label>
        <input
          id="farStartDate"
          matInput
          required
          #startDateCtl="ngModel"
          [matDatepicker]="startDatePicker"
          [(ngModel)]="value.startDate"
          (ngModelChange)="emitChangeEvent(); showWarning()"
          name="farStartDate"
          placeholder="Start date"
          aria-label="start date"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="startDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
        <mat-error> Please add a valid start date</mat-error>
      </mat-form-field>
      <!--End Date Picker-->
      <mat-form-field [appearance]="'outline'" class="col">
        <mat-label>Visit End Date</mat-label>
        <input
          id="farEndDate"
          matInput
          required
          #endDateCtl="ngModel"
          [matDatepicker]="endDatePicker"
          [(ngModel)]="value.endDate"
          (ngModelChange)="emitChangeEvent(); showWarning()"
          name="farEndDate"
          placeholder="End date"
          aria-label="end date"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="endDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
        <mat-error> Please add a valid end date</mat-error>
      </mat-form-field>
    </div>
    <mat-card
      class="bg-warning mb-2"
      style="margin-top: -15px"
      *ngIf="!isValidDates(itineraryForm)"
    >
      <div class="px-3 my-2 align-items-center d-flex">
        <mat-icon
          svgIcon="alert-circle"
          class="align-self-center mr-1"
        ></mat-icon
        ><span> <b>Invalid Date Range:</b> {{ errorMessage }}</span>
      </div>
    </mat-card>

    <app-org-location-select
      [required]="locationRequired"
      [errorMessage]="'Location is required'"
      name="organizationLocation"
      [(ngModel)]="value.organizationLocation"
      (ngModelChange)="emitChangeEvent()"
      [organizationId]="owningFarId"
    ></app-org-location-select>
    <mat-form-field
      *ngIf="value.description || descriptionExisted"
      appearance="outline"
      style="width: 100%"
      floatLabel="always"
    >
      <mat-label>Visit Details Description</mat-label>
      <textarea
        id="far-event-description"
        placeholder="Specific details of Activites at this Location"
        maxlength="2000"
        matInput
        rows="5"
        name="description"
        [(ngModel)]="value.description"
        (ngModelChange)="emitChangeEvent(); descriptionExisted = true"
      ></textarea>
    </mat-form-field>
  </form>
</div>
