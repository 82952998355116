import {
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IVersion } from '@environments/iversion';
import { APIInfo } from '@shared/models/app-info.model';
import { AboutService } from '@shared/services/about.service';

@Directive({
  selector: '[appAboutDialog]',
})
export class AboutDialogDirective {
  @Output() appAboutClosed = new EventEmitter<void>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    const dialog = AboutDialogComponent.openDialog(this.dialog);
    dialog.afterClosed().subscribe(() => {
      return this.appAboutClosed.emit();
    });
  }
}

@Component({
  selector: 'app-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss'],
})
export class AboutDialogComponent {
  apiInfo: APIInfo;
  uiInfo: IVersion;

  constructor(about: AboutService) {
    this.uiInfo = about.getUIInfo();
    about.getAPIInfo().subscribe((info) => (this.apiInfo = info));
  }

  static openDialog(dialog: MatDialog): MatDialogRef<AboutDialogComponent> {
    return dialog.open<AboutDialogComponent>(AboutDialogComponent, {
      maxWidth: 620,
    });
  }
}
