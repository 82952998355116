import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FilterParams,
  ForeignVisitor,
  Role,
  ScreeningDateFormat,
} from '@app/shared/models';
import { Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import {
  AppConfigService,
  FvSortField,
  FvSortFieldLabels,
} from '@app/shared/services';
import { take, takeUntil } from 'rxjs/operators';
import { ColumnChooserComponent } from '@app/shared/components';
import { ColumnChooserEventObservable } from '@app/shared/components/column-chooser/column-chooser-observable.service';
import { Subject } from 'rxjs';
import { getName } from 'i18n-iso-countries';
import { NetworkDomain } from '@app/shared/models/network-domain';
import { UserPreferenceService } from '@app/shared/services/user-preference.service';
import { AuthService } from '@app/auth/auth.service';

export const FVTableColumns: string[] = [
  'select',
  FvSortField.ORG,
  FvSortField.SURNAME,
  FvSortField.CITIZENSHIP_COUNTRY,
  FvSortField.DATE_OF_BIRTH,
  FvSortField.PASSPORT,
  FvSortField.LAST_SCREENING_MODIFIED,
  FvSortField.SCREENING_STATUS,
  FvSortField.LAST_SCREENING_RESULT,
  'additionalData',
  'actions',
];

export const FVTableName = 'fvTable';

@Component({
  selector: 'app-fv-table',
  templateUrl: './fv-table.component.html',
  styleUrls: ['./fv-table.component.scss'],
})
export class FvTableComponent implements OnInit, OnDestroy {
  @Input() fvs: ForeignVisitor[] = [];
  @Input() filterParams: FilterParams;
  @Input() farScreeningEligibility: boolean = false;
  @Input() currentNetwork: NetworkDomain = NetworkDomain.U;
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  currentOrgId: string;
  Role = Role;
  FvSortField = FvSortField;
  FvSortFieldLabel = FvSortFieldLabels;
  ScreeningDateFormat = ScreeningDateFormat;
  @Input() groupSelectionMode: boolean = false;
  dataColumns: string[] = FVTableColumns;

  fvColumnsDisplay: { [key: string]: boolean } = this.dataColumns.reduce(
    (val, col) => ({ [col]: true, ...val }),
    {} as { [key: string]: boolean }
  );

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private router: Router,
    private _ColumnChooserEventObservable: ColumnChooserEventObservable,
    private configService: AppConfigService,
    private userPreferenceService: UserPreferenceService,
    private auth: AuthService
  ) {
    this.currentOrgId = this.auth.getOrganization()?.id!;
  }

  viewRow(id: string) {
    this.router.navigateByUrl(`fv/${id}`);
  }

  ngOnInit(): void {
    this.userPreferenceService.userPreferenceDisplaySubject$
      .pipe(take(1))
      .subscribe((x) => {
        if (x.hasPreference && x.tableName === FVTableName) {
          this.dataColumns = x.columnNames!;
          this.fvColumnsDisplay = x.selectedColumns!;
        }
      });
    this._ColumnChooserEventObservable.ColumnChooserObservable$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((event) => {
      if (event.tableName === FVTableName) {
        this.fvColumnsDisplay = ColumnChooserComponent.updateTableDisplay(
          event,
          this.dataColumns,
          this.fvColumnsDisplay
        );
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSortChange(sort: Sort) {
    this.filterParams.sortBy = sort.active;
    this.filterParams.direction = sort.direction.toUpperCase();
    this.filterParamsChange.emit(this.filterParams);
  }

  getCountryName(country: string) {
    if (country) return getName(country, 'en');
    return null;
  }

  isAllSelected() {
    const numSelected = this.getNumSelected();
    const numSelectableRows = this.fvs.filter(
      (fv) => !this.isSelectDisabled(fv)
    ).length;
    return numSelected === numSelectableRows;
  }

  isSelectDisabled(fn: ForeignVisitor) {
    return (
      fn.networkDomain !== this.currentNetwork ||
      fn.ownerOrganizationId !== this.currentOrgId
    );
  }

  getNumSelected() {
    let cnt = 0;
    for (let fn of this.fvs) if (fn.selected) ++cnt;
    return cnt;
  }

  setSelection(isSelected: boolean) {
    for (let fn of this.fvs) {
      if (!this.isSelectDisabled(fn)) fn.selected = isSelected;
    }
  }

  toggleSelection(fv: ForeignVisitor) {
    if (!this.isSelectDisabled(fv)) fv.selected = !fv.selected;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) this.setSelection(false);
    else this.setSelection(true);
  }
}
