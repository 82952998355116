import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { DialogData } from '@app/shared/models';
import { SystemAlert } from '@app/shared/models/system-alert';
import { SystemAlertsCrudService } from '../system-alerts-crud.service';

@Component({
  selector: 'app-system-alert-dialog',
  templateUrl: './system-alert-dialog.component.html',
  styleUrls: ['./system-alert-dialog.component.scss'],
})
export class SystemAlertDialogComponent implements OnInit {
  appearance: MatFormFieldAppearance = 'outline';
  systemAlert: SystemAlert;
  busy: boolean = false;

  get minEndDate() {
    const now = new Date();
    let min = now;

    if (this.systemAlert.startDate) {
      const startDate = new Date(this.systemAlert.startDate);
      min = startDate > now ? addMinutes(startDate, 5) : now;
    }
    return min;
  }

  constructor(
    private systemAlertService: SystemAlertsCrudService,
    public dialogRef: MatDialogRef<SystemAlertDialogComponent>,
    @Inject(LOCALE_ID) private locale: string,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData<SystemAlert>
  ) {}

  ngOnInit(): void {
    this.systemAlert = { ...this.dialogData.model };
  }

  save() {
    this.busy = true;
    this.systemAlertService.save(this.systemAlert).subscribe(
      (systemAlert) => {
        this.dialogRef.close();
      },
      (err) => (this.busy = false)
    );
  }
}

function addMinutes(date: Date, minutes: number) {
  const minutesInMiliseconds = minutes * 60 * 1000;
  return new Date(+date + minutesInMiliseconds);
}
