import { ClassificationModel } from '@shared/models/classification.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-classification-banner',
  templateUrl: 'classification-banner.component.html',
})
export class ClassificationBannerComponent {
  @Input() classificationModel: ClassificationModel;
}
