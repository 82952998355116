<h1 mat-dialog-title *ngIf="group" class="d-flex align-items-center mt-1">
  <ng-container
    *ngIf="
      !id ||
        (group?.isEditable &&
          group?.foreignVisitorGroupType !== 'excel_import');
      else readOnly
    "
    >{{ id ? "Edit" : "Create" }} Group</ng-container
  >
  <ng-template #readOnly>View Group (Read-only) </ng-template>
  <div style="flex: 1"></div>
  <mat-icon
    [svgIcon]="'alpha-' + group.networkDomain?.toLowerCase() + '-box'"
    matTooltip="Network"
    aria-hidden="false"
  ></mat-icon>
</h1>
<mat-dialog-content>
  <app-group-form
    class="group-form"
    #groupForm
    [(ngModel)]="group"
    (emitChangeEvent)="onGroupChange($event)"
  ></app-group-form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
  <button mat-button color="primary" mat-dialog-close id="cancel-group-dialog">
    {{
      !id ||
      (group?.isEditable && group?.foreignVisitorGroupType !== "excel_import")
        ? "Cancel"
        : "Close"
    }}
  </button>
  <span class="col"></span>
  <button
    appDebounceClick
    [disabled]="
      !groupForm.hasValidValues() || !hasRequiredData() || disabledSubmit
    "
    *ngIf="
      !id ||
      (group?.isEditable && group?.foreignVisitorGroupType !== 'excel_import')
    "
    mat-raised-button
    color="primary"
    (debounceClick)="onSubmit()"
    id="submit-group"
  >
    <div *ngIf="!disabledSubmit">Submit</div>
    <mat-spinner
      color="primary"
      *ngIf="disabledSubmit"
      [diameter]="35"
    ></mat-spinner>
  </button>
</mat-dialog-actions>
