import { Component } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { BaseControlComponent } from '@app/shared/controls';
import { FAR, FarPurposeType, FarPurposeTypeTooltip } from '@app/shared/models';
import { ItineraryControlObservable } from '@app/shared/services/itinerary-control-observable.service';

@Component({
  selector: 'app-quick-far-control',
  templateUrl: './quick-far-control.component.html',
  styleUrls: ['./quick-far-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: QuickFarControlComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: QuickFarControlComponent,
      multi: true,
    },
  ],
})
export class QuickFarControlComponent
  extends BaseControlComponent<FAR>
  implements Validator
{
  FarPurposeType = FarPurposeType;
  FarPurposeTypeTooltip = FarPurposeTypeTooltip;
  constructor(private itineraryControl: ItineraryControlObservable) {
    super();
  }
  validate(control: AbstractControl<FAR, any>): ValidationErrors | null {
    if (!control?.value?.eventTitle) return { eventTitleRequired: true };
    return null;
  }

  checkLocationRequirement() {
    if (
      this.value.purposeType === FarPurposeType.FACILITY_ACCESS ||
      this.value.purposeType === FarPurposeType.IN_PERSON_MEETING
    ) {
      this.itineraryControl.locationRequired();
    } else if (
      this.value.purposeType === FarPurposeType.OTHER ||
      this.value.purposeType === FarPurposeType.SYSTEM_ACCESS ||
      this.value.purposeType === FarPurposeType.VIRTUAL_MEETING
    ) {
      this.itineraryControl.locationOptional();
    }
  }
}
