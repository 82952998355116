<div class="d-flex flex-column h-100 profile-pic-select-container mt-2">
  <div mat-dialog-title class="d-flex flex-row">
    <span>{{ data.fv | fullname: "surnameFirst" }} - Profile Picture</span>
  </div>
  <div
    mat-dialog-content
    class="
      d-flex
      flex-column
      justify-content-center
      align-items-center
      flex-grow-1
    "
  >
    <div
      [class.file-over-drop]="fileHoverDrop"
      (fileOver)="fileHoverDrop = $event"
      (onFileDrop)="fileHoverDrop = false"
      [uploader]="uploader"
      ng2FileDrop
      *ngIf="!camCapture && !hasFile"
      class="upload-pic col p-5"
    >
      <div class="d-flex flex-column upload-pic-wrapper">
        <span class="col"></span>
        <div class="file-upload d-flex flex-column justify-content-center">
          <h2 class="text-center">Drag photo here</h2>
          <h6 class="text-center">— or —</h6>
          <div class="d-flex justify-content-around flex-row">
            <button
              mat-stroked-button
              (click)="fileInput.click()"
              color="primary"
              class="mr-1"
            >
              <mat-icon svgIcon="laptop"></mat-icon> Upload from computer
            </button>
            <button
              mat-stroked-button
              (click)="camCapture = true"
              color="primary"
              class="ml-1"
            >
              <mat-icon svgIcon="camera"></mat-icon> Take a picture
            </button>
          </div>
        </div>
        <span class="col"></span>
      </div>
    </div>
    <div
      class="d-flex flex-column cam-capture justify-content-center"
      *ngIf="camCapture && !hasFile"
    >
      <webcam
        [height]="camHeight"
        [captureImageData]="true"
        [trigger]="triggerObservable"
        (initError)="onCaptureError($event)"
        (imageCapture)="onImageCapture($event)"
      ></webcam>
      <div
        class="
          cam-capture-error
          d-flex
          flex-column
          justify-content-center
          align-items-center
        "
        *ngIf="camError"
      >
        <mat-icon color="warn" svgIcon="camera-off"></mat-icon>
        <h3>{{ camError.message }}</h3>
      </div>
    </div>
    <div
      class="d-flex flex-column pic-cropper justify-content-center"
      *ngIf="hasFile"
    >
      <image-cropper
        *ngIf="webcamImage"
        class="p-0"
        [style.max-height]="cropHeight + 'px'"
        [imageURL]="webcamImage.imageAsDataUrl"
        [resizeToHeight]="512"
        [resizeToWidth]="512"
        [roundCropper]="true"
        format="png"
        output="base64"
        (imageCropped)="onImageCropped($event)"
      ></image-cropper>
      <image-cropper
        *ngIf="fileItem"
        class="p-0"
        [style.max-height]="cropHeight + 'px'"
        [imageFile]="fileItem._file"
        [resizeToHeight]="512"
        [resizeToWidth]="512"
        [roundCropper]="true"
        format="png"
        output="base64"
        (imageCropped)="onImageCropped($event)"
      ></image-cropper>
    </div>
    <ng-container *restrictDelete>
      <div
        *ngIf="
          !camCapture &&
          !hasFile &&
          data.fv?.profilePhotoAttachment &&
          data.fv.isEditable
        "
        class="d-flex justify-content-around flex-row"
      >
        <button mat-button color="warn" (click)="deleteProfilePic()">
          <mat-icon svgIcon="delete"></mat-icon> Remove Current Photo
        </button>
      </div>
    </ng-container>
    <input
      #fileInput
      type="file"
      ng2FileSelect
      [attr.accept]="allowedMimeType.join(',')"
      [uploader]="uploader"
      style="display: none"
    />
    <mat-progress-bar
      *ngIf="uploader?.isUploading"
      class="file-upload-progress w-100"
      color="primary"
      [mode]="uploader.progress === 100 ? 'indeterminate' : 'buffer'"
      [value]="uploader.progress"
    >
    </mat-progress-bar>
  </div>
  <div mat-dialog-actions class="flex-row">
    <button mat-button mat-dialog-close>Cancel</button>
    <span class="flex-grow-1"></span>
    <button mat-button *ngIf="camCapture || hasFile" (click)="clear()">
      Back
    </button>
    <button
      mat-raised-button
      color="accent"
      *ngIf="camCapture && !hasFile"
      [disabled]="camError"
      (click)="imageCapture()"
    >
      Capture
    </button>
    <button
      mat-raised-button
      color="primary"
      *ngIf="hasFile"
      [disabled]="!croppedImage || uploader?.isUploading"
      (click)="submit()"
    >
      Submit
    </button>
  </div>
</div>
