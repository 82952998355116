import {
  Component,
  OnInit,
  Inject,
  AfterContentChecked,
  ChangeDetectorRef,
} from '@angular/core';
import { DocumentId, ForeignVisitor } from '@app/shared/models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { FvService } from '@app/shared/services';
import { DocumentTypeLabel } from '@app/foreign-visitors/fv/document-id/document-id.component';

@Component({
  selector: 'app-document-id-dialog',
  templateUrl: './document-id-dialog.component.html',
})
export class DocumentIdDialogComponent implements OnInit, AfterContentChecked {
  documentId: DocumentId = {
    documentType: 'DIPLOMAT',
  };
  typeLabel = DocumentTypeLabel;
  busy: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DocumentId,
    public dialogRef: MatDialogRef<DocumentIdDialogComponent>,
    public fvService: FvService,
    private changeDetect: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.documentId = this.data;
  }

  //Supressing "ExpressionChangedAfterItHasBeenCheckedError" occurring due to documentForm Component values changing
  ngAfterContentChecked(): void {
    this.changeDetect.detectChanges();
  }

  submitDocumentId() {
    this.busy = true;
    !this.documentId.id
      ? this.fvService
          .addDocIdToFv(this.documentId.foreignVisitor?.id!, [this.documentId])
          .pipe(take(1))
          .subscribe(
            (result) => {
              this.onClose(result as DocumentId);
            },
            (err) => (this.busy = false)
          )
      : this.fvService
          .updateDocId(this.documentId.foreignVisitor?.id!, this.documentId!)
          .pipe(take(1))
          .subscribe(
            (result: ForeignVisitor) => {
              this.onClose(result as DocumentId);
            },
            (err) => (this.busy = false)
          );
  }

  onClose(diplomaticId: DocumentId) {
    this.dialogRef.close(diplomaticId);
  }
}
