import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FAR,
  FarLocation,
  FarPurposeType,
  ForeignVisitor,
} from '@app/shared/models';
import { FarService, MetricsObservable } from '@app/shared/services';
import { tap } from 'rxjs/operators';
import { ItineraryControlComponent } from '../itinerary-control/itinerary-control.component';

@Component({
  selector: 'app-far-from-fv-dialog',
  templateUrl: './far-from-fv-dialog.component.html',
  styleUrls: ['./far-from-fv-dialog.component.scss'],
})
export class FarFromFvDialogComponent implements OnInit {
  far: FAR = {
    foreignAccessRequestLocations: [],
  };
  location: FarLocation = {
    startDate: '',
    endDate: '',
  };
  loading: boolean = false;

  @ViewChild('itineraryControl')
  itineraryControl: ItineraryControlComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public fv: Partial<ForeignVisitor>,
    private dialogRef: MatDialogRef<FarFromFvDialogComponent>,
    private farService: FarService,
    private _MetricsObservable: MetricsObservable
  ) {}

  ngOnInit(): void {
    this.far.foreignVisitors = [this.fv];
    this.far.foreignAccessRequestLocations?.push(this.location);
  }
  onSubmit() {
    if (!this.fv.id) this.far.foreignVisitors = [];
    this.farService
      .save(this.far)
      .pipe(
        tap((_) => {
          this.loading = true;
        })
      )
      .subscribe((far) => {
        this._MetricsObservable.send('update');
        this.dialogRef.close(far);
      });
  }

  hasRequirements() {
    if (
      this.far.eventTitle &&
      this.far.purposeType &&
      this.location.startDate &&
      this.location.endDate &&
      this.itineraryControl.isValidDates(this.itineraryControl.itineraryForm) &&
      this.isLocationValid()
    )
      return true;
    return false;
  }

  isLocationValid() {
    if (
      this.far.purposeType === FarPurposeType.FACILITY_ACCESS ||
      this.far.purposeType === FarPurposeType.IN_PERSON_MEETING
    ) {
      if (!this.location.organizationLocation) return false;
    }

    return true;
  }
}
