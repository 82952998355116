import { Component, OnInit } from '@angular/core';
import { User, Role } from '@app/shared/models';
import { Observable } from 'rxjs';
import { AuthService } from '@app/auth/auth.service';
import { AppConfigService } from '@app/shared/services/app-config.services';

@Component({
  templateUrl: 'landing-page.component.html',
  styleUrls: ['landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  systemUser$: Observable<User | null> = this.authService.user$;
  Role = Role;
  vetterContent =
    '(Vetter Only) Allows for the Tracking, Updating, and Overriding of Screening Results';
  userAdminContent =
    '(Admin | Org Admin Only) Maintenance of users across the platform or Organization, to allow for Activation, Deactivation, User Data Updates, and Resending of Temporary Passwords';

  constructor(
    private appConfigService: AppConfigService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    //Placeholder example code to retrieve data from environment config files
    let classification = this.appConfigService.get('classification');
    console.log('classification:' + classification);
  }
}
