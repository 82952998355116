import { User } from '@app/shared/models/user.model';
import { BaseModel } from '@app/shared/models/base.model';
import { DerogatoryInformation, ForeignVisitor } from '.';

export interface Screening extends BaseModel {
  id?: string;
  status?: ScreeningStatus; // (30)
  screener?: User;
  notes?: string; // (256)
  requestedDate?: Date | number | null;
  requestedTime?: string | null;
  completedDate?: Date | number | null;
  completedTime?: string | null;
  screeningEligible?: boolean;
  code?: string | null;
  derogatoryInformation?: DerogatoryInformation[] | null;
  displayResult?: ScreeningResult;
  result?: ScreeningResult;
  inheritedFromFlag?: boolean;
  foreignVisitor?: Pick<ForeignVisitor, 'id'>;
  fvCurrentlyScreeningEligible?: boolean;
  fvGivenName?: string;
  fvSurname?: string;
  identificationVerified?: boolean;
  delayed?: boolean;
  seq?: number;
}

export interface StatusTotal {
  state?: ScreeningStatus | ScreeningResult;
  count?: number;
}

export class ScreeningUtils {
  public static isLegacyResult(value: ScreeningResult | undefined) {
    if (!value) return;
    const legacyResults = [
      ScreeningResult.Green,
      ScreeningResult.Red,
      ScreeningResult.Yellow,
      ScreeningResult.Purple,
    ];
    if (legacyResults.includes(value)) {
      return true;
    }
    return false;
  }
}

export enum ScreeningStatus {
  New = 'New',
  Historical = 'Historical',
  InProcess = 'In Process',
  Delayed = 'Delayed',
  Completed = 'Completed',
  Error = 'Error',
  Ineligible = 'Ineligible',
}

export enum ScreeningResult {
  Green = 'GREEN',
  Yellow = 'YELLOW',
  Red = 'RED',
  MachineRed = 'MACHINE_RED',
  MachineGreen = 'MACHINE_GREEN',
  ReviewedGreen = 'REVIEWED_GREEN',
  ReviewedRed = 'REVIEWED_RED_WITH_DEROG',
  ReviewedRestricted = 'REVIEWED_RESTRICTED',
  Purple = 'USP',
}

export const ScreeningResultLabels: { [key in ScreeningResult]: string } = {
  [ScreeningResult.Green]: 'Green - No Derogatory Data',
  [ScreeningResult.Yellow]: 'Yellow - Inconclusive',
  [ScreeningResult.Red]: 'Red - Derogatory Data Found',
  [ScreeningResult.MachineRed]: 'Machine Red - Interim Red',
  [ScreeningResult.MachineGreen]: 'Machine Green',
  [ScreeningResult.ReviewedGreen]: 'Reviewed Green',
  [ScreeningResult.ReviewedRed]: 'Reviewed Red',
  [ScreeningResult.ReviewedRestricted]: 'Reviewed Restricted',
  [ScreeningResult.Purple]: 'USP',
};

export const ScreeningStatuses = Object.values(ScreeningStatus);
export const ScreeningDateFormat = 'MMM d, y, HH:mm:ss';

export const ScreeningStatusLabels: { [key in ScreeningStatus]: string } = {
  [ScreeningStatus.New]: 'Not Submitted',
  [ScreeningStatus.Historical]: 'Historical',
  [ScreeningStatus.InProcess]: 'In Process',
  [ScreeningStatus.Delayed]: 'Delayed',
  [ScreeningStatus.Completed]: 'Completed',
  [ScreeningStatus.Error]: 'Review Pending',
  [ScreeningStatus.Ineligible]: 'Ineligible',
};

export type ScreeningIcons =
  | 'circle-outline'
  | 'checkbox-blank-circle-outline'
  | 'autorenew'
  | 'alert-circle-outline'
  | 'checkbox-marked-circle-outline'
  | 'file-find'
  | 'account-search';

export const ScreeningDisplayMap = new Map<string, any>([
  [
    ScreeningStatus.New,
    {
      icon: 'checkbox-blank-circle-outline',
      color: 'bg-secondary',
      classList: 'bg-secondary text-white',
      statusLabel: ScreeningStatusLabels[ScreeningStatus.New],
    },
  ],
  [
    ScreeningStatus.Ineligible,
    {
      icon: 'smart-card-off-outline',
      color: 'bg-secondary',
      classList: 'bg-secondary text-white',
      statusLabel: 'Ineligible',
    },
  ],
  [
    ScreeningStatus.Historical,
    {
      icon: 'history',
      color: 'bg-dark',
      classList: 'bg-dark text-white',
      statusLabel: ScreeningStatusLabels[ScreeningStatus.Historical],
    },
  ],
  [
    ScreeningStatus.InProcess,
    {
      icon: 'autorenew',
      color: 'bg-info',
      classList: 'bg-info text-white',
      statusLabel: ScreeningStatusLabels[ScreeningStatus.InProcess],
    },
  ],
  [
    ScreeningStatus.Delayed,
    {
      icon: 'clock-alert-outline',
      color: 'bg-warning',
      classList: 'bg-warning text-white',
      statusLabel: ScreeningStatusLabels[ScreeningStatus.Delayed],
    },
  ],
  [
    ScreeningStatus.Completed,
    {
      icon: 'progress-check',
      color: 'bg-primary',
      classList: 'bg-primary text-white',
      statusLabel: ScreeningStatusLabels[ScreeningStatus.Completed],
    },
  ],
  [
    ScreeningStatus.Error,
    {
      icon: 'file-find',
      color: 'bg-primary',
      classList: 'bg-primary text-white',
      statusLabel: ScreeningStatusLabels[ScreeningStatus.Error],
    },
  ],
  [
    ScreeningResult.Green,
    {
      icon: 'checkbox-marked-circle-outline',
      color: 'bg-success',
      classList: 'bg-success text-white',
      statusLabel: 'No Derogatory',
    },
  ],
  [
    ScreeningResult.MachineGreen,
    {
      icon: 'checkbox-marked-circle-outline',
      color: 'bg-success',
      classList: 'bg-success text-white',
      statusLabel: 'No Derogatory',
    },
  ],
  [
    ScreeningResult.ReviewedGreen,
    {
      icon: 'checkbox-marked-circle-outline',
      color: 'bg-success',
      classList: 'bg-success text-white',
      statusLabel: 'No Derogatory',
    },
  ],
  [
    ScreeningResult.Yellow,
    {
      icon: 'help-circle-outline',
      color: 'bg-yellow',
      classList: 'bg-yellow text-black',
      statusLabel: 'Inconclusive',
    },
  ],
  [
    ScreeningResult.Red,
    {
      icon: 'alert-circle-outline',
      color: 'bg-danger',
      classList: 'bg-danger text-white',
      statusLabel: 'Derogatory',
    },
  ],
  [
    ScreeningResult.MachineRed,
    {
      icon: 'account-search',
      color: 'bg-machine-red',
      classList: 'bg-machine-red text-white',
      statusLabel: 'Machine Red',
    },
  ],
  [
    ScreeningResult.ReviewedRed,
    {
      icon: 'alert-circle-outline',
      color: 'bg-danger',
      classList: 'bg-danger text-white',
      statusLabel: 'Derogatory',
    },
  ],
  [
    ScreeningResult.Purple,
    {
      icon: 'home-circle-outline',
      color: 'bg-special',
      classList: 'bg-special text-white',
      statusLabel: 'US Person',
    },
  ],
  [
    ScreeningResult.ReviewedRestricted,
    {
      icon: 'home-circle-outline',
      color: 'bg-special',
      classList: 'bg-special text-white',
      statusLabel: 'US Person',
    },
  ],
]);
