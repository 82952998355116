import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ControlContainer,
  FormArray,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';

import { MatCheckboxChange } from '@angular/material/checkbox';
import { CheckBoxProperties, Role } from '@app/shared/models';
import { UserSortFieldLabels } from '@app/shared/services';
import { AdditionalTableColumns } from '../user-preferences.component';
import { AuthService } from '@app/auth/auth.service';
import { UserPreferenceService } from '@app/shared/services/user-preference.service';

@Component({
  selector: 'app-admin-tab-checkbox',
  templateUrl: './admin-tab-checkbox.component.html',
  styleUrls: ['./admin-tab-checkbox.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class AdminTabCheckboxComponent implements OnInit, OnChanges {
  @Input() updated: boolean;
  @Input() checkedColumns: Array<string>;
  @Input() userRoles: Role[];
  @Input() tabTitle: string;
  @Input() previewName: string;
  tabLabel: string = 'User Administration';
  tabIndex: number;
  checkedColumnss: Array<string>;
  userCheckedColumns: Array<CheckBoxProperties> = [];
  userColumnNames: Array<string> = [];
  displayCheckedColumns: Array<CheckBoxProperties> = [];
  _columnNames: string[];
  form!: FormGroup;
  userCheckBoxes: any;

  constructor(
    private authService: AuthService,
    private userPreferenceService: UserPreferenceService,
    private rootFormGroup: FormGroupDirective
  ) {}

  ngOnInit(): void {
    this.loadCheckboxes();
    this.form = this.rootFormGroup.control.get('admin') as FormGroup;
  }

  loadCheckboxes(): void {
    this.tabIndex = 5;

    if (this.userColumnNames.length === 0) {
      Object.values(UserSortFieldLabels).forEach((value) => {
        if (value !== 'Created Date') {
          this.userColumnNames.push(value);
          this.userCheckedColumns.push({ selected: true, label: value });
        }
      });
      if (
        this.authService.getUser()?.roles?.includes(Role.sv_admin) &&
        !this.userColumnNames.includes(AdditionalTableColumns.at(1)!)
      ) {
        this.userColumnNames.push(AdditionalTableColumns.at(1)!);
        this.userCheckedColumns.push({
          selected: true,
          label: AdditionalTableColumns.at(1)!,
        });
      }
    }

    // if (
    //   !this.authService.getUser()?.roles?.includes(Role.sv_admin) &&
    //   this.userColumnNames.includes(AdditionalTableColumns.at(1)!)
    // ) {
    //   this.userCheckedColumns = this.userCheckedColumns.filter(
    //     (item) => item.label !== 'Actions'
    //   );
    //   this.userColumnNames = this.userColumnNames.filter(
    //     (item) => item !== 'Actions'
    //   );
    // } else {
    //   if (!this.userColumnNames.includes('Actions')) {
    //     this.userCheckedColumns.push({ selected: true, label: 'Actions' });
    //     this.userColumnNames.push('Actions');
    //   }
    // }

    this.displayCheckedColumns = this.userCheckedColumns;
    this._columnNames = this.userColumnNames;

    this.displayCheckedColumns = this.setDisplayCheckedColumns(
      this.userCheckedColumns,
      this.setCheckedColumns(this.userCheckedColumns)
    );
  }
  selectedColumns(selectedOption: MatCheckboxChange) {
    this.displayCheckedColumns.forEach((c) => {
      if (selectedOption.source.name === c.label) {
        selectedOption.checked ? (c.selected = true) : (c.selected = false);
      }
    });

    this.adminCheckboxes.controls.forEach((value) => {
      const newValue = value.value;
      if (newValue.label === selectedOption.source.name) {
        if (selectedOption.checked) {
          value.patchValue({ selected: true, label: newValue.label });
        } else {
          value.patchValue({ selected: false, label: newValue.label });
        }
      }
    });
    this.checkedColumnss = this.setCheckedColumns(this.displayCheckedColumns);
  }

  toggleAll(toggle: boolean) {
    this.userCheckedColumns = [];
    this.checkedColumnss = [];
    if (toggle) {
      this.adminCheckboxes.controls.forEach((value, index) => {
        const newValue = value.value;
        this.userCheckedColumns.push({ selected: true, label: newValue.label });
        value.patchValue({ selected: true, label: newValue.label });
        this.checkedColumnss.push(newValue.label);
      });
    } else {
      this.adminCheckboxes.controls.forEach((value, index) => {
        const newValue = value.value;
        this.userCheckedColumns.push({
          selected: false,
          label: newValue.label,
        });
        value.patchValue({ selected: false, label: newValue.label });
      });

      this.checkedColumnss = [];
    }
    this.displayCheckedColumns = this.userCheckedColumns;
  }

  get adminCheckboxes(): FormArray {
    return this.form.get('adminCheckboxes') as FormArray;
  }
  setDisplayCheckedColumns(
    checkedArray: Array<CheckBoxProperties>,
    checkedArrayColumns: string[]
  ): Array<CheckBoxProperties> {
    let trueCheckedColumns: Array<CheckBoxProperties> = [];
    checkedArray.forEach((c) => {
      if (checkedArrayColumns.includes(c.label)) {
        trueCheckedColumns.push({
          selected: true,
          label: c.label,
        });
      } else {
        trueCheckedColumns.push({
          selected: false,
          label: c.label,
        });
      }
    });

    return trueCheckedColumns;
  }

  setCheckedColumns(checkedArray: Array<CheckBoxProperties>): string[] {
    let trueCheckedColumns: string[] = [];
    checkedArray.forEach((c) => {
      if (c.selected) {
        trueCheckedColumns.push(c.label);
      }
    });
    return trueCheckedColumns;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.userPreferenceService.updated.subscribe((result) => {
      if (result) {
        this.userPreferenceService.updateCheckboxes.subscribe((checkit) => {
          if (this.tabLabel === checkit.viewName) {
            this.checkedColumnss = this.setCheckedColumns(checkit.checkboxes!);
            this.userCheckedColumns = checkit.checkboxes!;
            this.checkedColumnss = this.setCheckedColumns(
              this.userCheckedColumns
            );
            this.displayCheckedColumns = this.setDisplayCheckedColumns(
              this.userCheckedColumns,
              this.checkedColumnss
            );
            this.adminCheckboxes.controls.forEach((value, index) => {
              const newValue = value.value;
              checkit.checkboxes?.filter((x) => {
                if (x.label === newValue.label) {
                  value.patchValue({ selected: x.selected, label: x.label });
                }
              });
            });
          }
        });
      }
    });
    if (changes['tabTitle']) {
      if (changes['tabTitle'].currentValue !== null) {
        this.tabTitle = changes['tabTitle'].currentValue;
      }
    }
  }
}
