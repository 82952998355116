import { Component } from '@angular/core';
import { ForeignVisitor } from '@app/shared/models';
import { CrudService, FvService, Repository } from '@app/shared/services';

@Component({
  selector: 'app-foreign-nationals',
  templateUrl: './foreign-nationals.component.html',
  styleUrls: ['./foreign-nationals.component.scss'],
  providers: [Repository, { provide: CrudService, useExisting: FvService }],
})
export class ForeignNationalsComponent {
  fn$ = this.repo.collection$;

  constructor(private repo: Repository<ForeignVisitor>) {}
}
