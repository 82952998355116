<form ngForm #fvEducationForm="ngForm" *ngIf="education">
  <div class="container mt-3">
    <div class="row align-items-baseline justify-content-around">
      <mat-form-field appearance="outline" class="col-12">
        <mat-label>School Name</mat-label>
        <input
          required
          matInput
          name="school-name-input"
          id="school-name-input"
          placeholder="School Name"
          [(ngModel)]="education.schoolName"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-6">
        <mat-label>Attendance Dates</mat-label>
        <mat-date-range-input [rangePicker]="schoolDatesPicker">
          <input
            name="school-start-date-input"
            id="school-start-date-input"
            #startDateCtl="ngModel"
            matStartDate
            placeholder="Start Date"
            [(ngModel)]="education.educationDates[0].startDate"
          />
          <input
            name="school-end-date-input"
            id="school-end-date-input"
            #endDateCtl="ngModel"
            matEndDate
            placeholder="End Date"
            [(ngModel)]="education.educationDates[0].endDate"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matSuffix
          [for]="schoolDatesPicker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #schoolDatesPicker></mat-date-range-picker>
        <mat-error *ngIf="startDateCtl.invalid || endDateCtl.invalid">
          Start/End Date Required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-6">
        <mat-label>Degree</mat-label>
        <mat-select id="degree" [(ngModel)]="education.degree" name="degree">
          <mat-option value=""></mat-option>
          <mat-option value="High School Diploma"
            >High School Diploma</mat-option
          >
          <mat-option value="Associate">Associate</mat-option>
          <mat-option value="Bachelor's">Bachelor's</mat-option>
          <mat-option value="Master's">Master's</mat-option>
          <mat-option value="Doctoral">Doctoral</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row align-items-baseline justify-content-around">
      <div class="col">
        <app-address
          #address
          name="fv-education-address"
          id="fv-education-address-control"
          legend="Address"
          [(ngModel)]="education.campusLocation"
          [columns]="2"
          name="address"
          #addressControl
        ></app-address>
      </div>
    </div>
  </div>
</form>
