import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { FilterParams } from '@app/shared/models';
import { Country } from '@app/shared/models/country.model';
import { CountryService } from '@app/shared/services/country.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-search-locations-dialog',
  templateUrl: './search-locations-dialog.component.html',
  styleUrls: ['./search-locations-dialog.component.scss'],
})
export class SearchLocationsDialogComponent implements OnInit {
  @Input() includeOrgs: boolean = false;
  countries: Country[];
  filterParams: FilterParams = new FilterParams();
  countriesHeaders: string[] = ['flag', 'name', 'iso-3'];
  loading: boolean = false;
  constructor(private countryService: CountryService) {}

  ngOnInit(): void {
    this.onFilterParamChange(this.filterParams);
  }

  onFilterParamChange(params: FilterParams) {
    this.includeOrgs
      ? (params.includeNonCountryOrgs = true)
      : (params.includeNonCountryOrgs = false);
    this.loading = true;
    this.countryService
      .find(params)
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.countries = data;
          this.loading = false;
        },
        () => (this.loading = false)
      );
  }
}
