import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterParams, ForeignVisitor, Role } from '@app/shared/models';
import {
  AppConfigService,
  FvService,
  MetricsObservable,
  UserService,
} from '@app/shared/services';
import { forkJoin, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { UspMetricsService } from '../usp-metrics.service';
import { AuthService } from '@app/auth/auth.service';

@Component({
  selector: 'app-metrics-insights',
  templateUrl: 'insights.component.html',
  styleUrls: ['insights.component.scss'],
})
export class MetricsInsightsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  display: boolean = true;
  latestFvName: string = '';
  latestFv: ForeignVisitor = {};
  metrics: any = {}; //FVS Metrics Data from homepage.component
  userMetrics: any = {};
  Role = Role;
  isPendingDeletesActive: boolean = false;
  isUserStatsActive: boolean = false;
  isHighSide: boolean = false;

  constructor(
    private fvService: FvService,
    private _MetricsObservable: MetricsObservable,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private config: AppConfigService,
    private uspService: UspMetricsService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.isPendingDeletesActive = this.config.get('lowEnvFeatures') ?? false;
    this.isUserStatsActive = this.config.get('lowEnvFeatures') ?? false;
    this.isHighSide = this.config.get('highEnvFeatures') ?? false;

    this.getMetrics();

    this._MetricsObservable.MetricsObservable$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((data: any) => {
      this.getMetrics();
    });
  }

  getMetrics() {
    this.getMetricsScreeningInsightsOnly();
    if (
      this.authService.hasAnyRole(
        Role.sv_admin,
        Role.sv_help_desk,
        Role.sv_org_admin
      )
    ) {
      this.getUspMetrics();
      this.getLowSideStats();
    }
    if (this.authService.hasAnyRole(Role.sv_admin, Role.sv_help_desk))
      this.getOrgMetrics();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getMetricsScreeningInsightsOnly() {
    this.fvService
      .getFvsMetrics()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((fvMetrics: any) => {
        let categories = Object.keys(fvMetrics);
        for (let cat of categories) this.metrics[cat] = fvMetrics[cat];

        if (this.metrics.oldestInProcessNoResultScreening) {
          this.latestFvName =
            this.metrics.oldestInProcessNoResultScreening.givenName +
            ' ' +
            this.metrics.oldestInProcessNoResultScreening.surname;
        } else this.latestFvName = '';
      });
  }

  processMetrics(metrics: any) {
    let categories = Object.keys(metrics);
    let holdObj: any = {};
    for (let cat of categories) holdObj[cat] = metrics[cat];
    return holdObj;
  }

  getOrgMetrics() {
    this.fvService
      .getOrgMetrics()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((orgs) => {
        this.metrics['orgCount'] = orgs['orgCount'];
        this.metrics['orgCountWithFN'] = orgs['orgCountWithFN'];
      });
  }

  getUspMetrics() {
    let calcEnd = new Date();
    calcEnd.setDate(calcEnd.getDate() + 1);
    let endDate = calcEnd.toISOString().split('T')[0];

    let calcStart = new Date();
    calcStart.setDate(calcStart.getDate() - 7);
    let startDate = calcStart.toISOString().split('T')[0];

    this.uspService
      .findUspMetrics(startDate, endDate, 'DAY')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((uspMetrics) => {
        let dates = Object.keys(uspMetrics);
        let uspTotalCount = 0;
        for (let date of dates) uspTotalCount += uspMetrics[date].totalCount;
        this.metrics['uspTotalCount'] = uspTotalCount;
      });
  }

  getLowSideStats() {
    let calcEnd = new Date();
    calcEnd.setDate(calcEnd.getDate() + 7);
    let endDate = calcEnd.toISOString().split('T')[0];

    let calcStart = new Date();
    let startDate = calcStart.toISOString().split('T')[0];

    let deleteParams = new FilterParams({
      pageNum: 0,
      pageSize: 20,
      deletionDateStart: startDate,
      deletionDateEnd: endDate,
    });

    //Low Side Only
    if (this.isUserStatsActive && this.isPendingDeletesActive) {
      forkJoin([
        this.userService.getUserMetrics(),
        this.fvService.find(deleteParams),
      ])
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(([userMetrics, pendingDeletes]) => {
          this.userMetrics = userMetrics;
          this.metrics['pendingDeletes'] = pendingDeletes.totalElements;
        });
    }
  }

  navPendingDeletions() {
    if (this.metrics.pendingDeletes > 0) {
      let endDate = new Date();
      endDate.setDate(endDate.getDate() + 7);
      let filterParams = {
        sortBy: 'createdDate',
        direction: 'DESC',
        pageNum: 0,
        pageSize: 20,
        deletionDateStart: new Date().toISOString().split('T')[0],
        deletionDateEnd: endDate.toISOString().split('T')[0],
      };

      this.router.navigate(['../fvs'], {
        relativeTo: this.route,
        queryParams: filterParams,
      });
    }
  }

  navigateUsers() {
    let params = {
      sortBy: 'lastLogin',
      direction: 'ASC',
      pageNum: 0,
      pageSize: 20,
    };

    this.router.navigate(['../admin'], {
      relativeTo: this.route,
      queryParams: params,
    });
  }

  navigateTo(
    count: any,
    destination: string,
    queryField?: string,
    queryParam?: string | boolean
  ) {
    if (count) {
      let filter: any = {};
      if (queryField) {
        filter[queryField] = queryParam;
        this.router.navigate([destination], {
          relativeTo: this.route,
          queryParams: filter,
        });
      } else {
        this.router.navigate([destination], {
          relativeTo: this.route,
        });
      }
    }
  }
}
