<div mat-dialog-title>
  Adding {{ fvName }} to {{ farsToAssociate.length }} Foreign Access Requests
</div>
<mat-dialog-content>
  <form #form="ngForm" class="mt-3">
    <app-select-far
      #farSelect
      [(ngModel)]="farsToAssociate"
      name="farsToAssociate"
      [hideInputDisplay]="true"
      [multiple]="true"
      [clearable]="false"
      [excludeFvId]="fvId"
      ngDefaultControl
    ></app-select-far>
  </form>
  <app-far-list
    data-cy="fars-list"
    *ngIf="farsToAssociate.length"
    [fars]="farsToAssociate"
    [routerNavEnabled]="false"
  ></app-far-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onClose()" data-cy="cancel">Cancel</button>
  <button
    mat-flat-button
    (click)="onSubmit()"
    color="primary"
    [disabled]="!farsToAssociate.length || busy"
    data-cy="submit"
  >
    <span *ngIf="!busy; else loading">Submit</span>
  </button>
</mat-dialog-actions>
<ng-template #loading>
  <mat-spinner diameter="35"></mat-spinner>
</ng-template>
