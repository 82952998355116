import { Component, OnInit } from '@angular/core';
import { FAR, FilterParams } from '@app/shared/models';
import { CrudService, FarService, Repository } from '@app/shared/services';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-far-workspace',
  templateUrl: './far-workspace.component.html',
  styleUrls: ['./far-workspace.component.scss'],
  providers: [Repository, { provide: CrudService, useExisting: FarService }],
})
export class FarWorkspaceComponent implements OnInit {
  filterParams = this.repo.defaultFilterParams;
  far$ = this.repo.collection$.pipe(
    tap((_) => {
      this.loading = false;
    })
  );
  loading: boolean = false;

  constructor(private repo: Repository<FAR>) {}

  ngOnInit(): void {
    this.loading = true;
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.onFilterParamChange(this.filterParams);
  }
}
