import { Component, OnInit } from '@angular/core';
import { UserAcceptedObservable } from '@app/shared/services/user-accepted-observable.service';
@Component({
  selector: 'app-login-dashboard',
  templateUrl: './login-dashboard.component.html',
  styleUrls: ['./login-dashboard.component.scss'],
})
export class LoginDashboardComponent implements OnInit {
  constructor(private userAccept: UserAcceptedObservable) {}
  ngOnInit(): void {
    localStorage.removeItem('userAccepted');
    localStorage.removeItem('userAcceptedDate');
    this.userAccept.notAccepted();
  }
}
