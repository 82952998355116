import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/shared/helpers/auth.guard';
import { DashboardComponent } from '@app/monitoring/dashboard/dashboard.component';
import { MonitoringViewRoles } from '@shared/models/role-permissions';

const routes: Routes = [
  {
    path: 'monitoring',
    title: 'Monitoring',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { roles: MonitoringViewRoles },
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MonitoringRoutingModule {}
