<div
  class="row panel-banner p-2 mx-0 mt-2 justify-content-between cursor-pointer"
  id="toggleMetricInsights"
  (click)="display = !display"
>
  <div class="align-items-center d-flex">
    <mat-icon class="mr-2">device_hub</mat-icon> Insights
  </div>
  <mat-icon svgIcon="chevron-up" *ngIf="display"></mat-icon>
  <mat-icon svgIcon="chevron-down" *ngIf="!display"></mat-icon>
</div>

<div class="row mx-1" *ngIf="display">
  <ng-container
    *appIfRoles="[Role.sv_admin, Role.sv_org_admin, Role.sv_help_desk]"
  >
    <div class="col pl-1 pr-1 mt-2">
      <insight-card
        id="usps-deleted-week"
        [label]="'USP Deletion last 7 Days'"
        [value]="metrics.uspTotalCount || 0"
        [clickable]="false"
        matTooltip="Number of USPs Deleted past 7 Days"
      ></insight-card>
    </div>
    <div class="col pl-1 pr-1 mt-2" *ngIf="isPendingDeletesActive">
      <insight-card
        id="pending-deletions"
        [label]="'Pending 90 Day Deletions'"
        [value]="metrics.pendingDeletes || 0"
        [showClickable]="metrics.pendingDeletes > 0"
        (click)="navPendingDeletions()"
        matTooltip="Number of 90 Day Deletions to occur within next 7 Days."
      ></insight-card>
    </div>
    <div
      class="col pl-1 pr-1 mt-2"
      *appIfRoles="[Role.sv_admin, Role.sv_help_desk]"
    >
      <insight-card
        id="current-orgs"
        [label]="'Current Organizations'"
        [value]="metrics.orgCount || 0"
        [showClickable]="metrics.orgCount > 0"
        (click)="navigateTo(metrics.orgCount, '../organizations')"
        matTooltip="Number of Organizations."
      ></insight-card>
    </div>
    <div
      class="col pl-1 pr-1 mt-2"
      *appIfRoles="[Role.sv_admin, Role.sv_help_desk]"
    >
      <insight-card
        id="orgs-fn-records"
        [label]="'Organizations with FNs'"
        [value]="metrics.orgCountWithFN || 0"
        [showClickable]="metrics.orgCountWithFN > 0"
        (click)="
          navigateTo(
            metrics.orgCountWithFN,
            '../organizations',
            'hasFNRecs',
            true
          )
        "
        matTooltip="Number of Organizations with FN Records."
      ></insight-card>
    </div>
    <ng-container *ngIf="isUserStatsActive">
      <div class="col pl-1 pr-1 mt-2">
        <insight-card
          id="current-users"
          [label]="'Current Users'"
          [value]="userMetrics.totalCount || 0"
          [showClickable]="true"
          (click)="navigateUsers()"
          matTooltip="Number of Users"
        ></insight-card>
      </div>
      <div class="col pl-1 pr-1 mt-2">
        <insight-card
          id="pending-users"
          [label]="'Pending Users'"
          [value]="userMetrics.preRegisteredAccountStatusCount || 0"
          [showClickable]="userMetrics.preRegisteredAccountStatusCount > 0"
          (click)="
            navigateTo(
              userMetrics.preRegisteredAccountStatusCount,
              '../admin',
              'accountStatus',
              'pre-registered'
            )
          "
          matTooltip="Number of Users awaiting Access"
        ></insight-card>
      </div>
      <div class="col pl-1 pr-1 mt-2">
        <insight-card
          id="expiring-users"
          [label]="'Expiring Users'"
          [value]="userMetrics.expiringCount || 0"
          [showClickable]="userMetrics.expiringCount > 0"
          (click)="
            navigateTo(
              userMetrics.expiringCount,
              '../admin',
              'expiringSoon',
              'true'
            )
          "
          matTooltip="Number of Users About to Expire"
        ></insight-card>
      </div>
      <div
        class="col pl-1 pr-1 mt-2"
        *appIfRoles="[Role.sv_admin, Role.sv_help_desk]"
      >
        <insight-card
          id="expiring-org-admins"
          [label]="'Expiring Org Admin Approvals'"
          [value]="userMetrics.expiringOrgAdminsCount || 0"
          [showClickable]="true"
          [routerLink]="'/approvals'"
          matTooltip="Number of Users with the Organization Admin Role whose Approval to have that role is about to expire"
        ></insight-card>
      </div>
      <div class="w-100"></div>
    </ng-container>
  </ng-container>
  <div class="col pl-1 pr-1 mt-2">
    <insight-card
      id="oldest-in-process-screening"
      [label]="'Oldest In Process Screening'"
      [value]="latestFvName | default"
      [showClickable]="latestFvName !== ''"
      (click)="
        navigateTo(
          latestFvName,
          '../fv/' + metrics.oldestInProcessNoResultScreening?.id
        )
      "
      matTooltip="Oldest non-Machine Red FN with In Process Status"
    ></insight-card>
  </div>
  <div
    class="col pl-1 pr-1 mt-2"
    *appIfRoles="[Role.sv_admin, Role.sv_help_desk]"
  >
    <insight-card
      id="fv-unscreened-count"
      [label]="'Foreign Nationals - Screening Not Submitted'"
      [value]="metrics.newStatusCount || 0"
      [showClickable]="metrics.newStatusCount > 0"
      (click)="navigateTo(metrics.newStatusCount, '../fvs', 'status', 'New')"
      matTooltip="Click to view all Foreign Nationals - not submitted."
    ></insight-card>
  </div>
  <div class="col pl-1 pr-1 mt-2">
    <insight-card
      id="fv-inprocess-count"
      [label]="'Foreign Nationals - Screening In Process'"
      [value]="metrics.inProcessStatusCount || 0"
      [showClickable]="metrics.inProcessStatusCount > 0"
      (click)="
        navigateTo(
          metrics.inProcessStatusCount,
          '../fvs',
          'status',
          'In Process'
        )
      "
      matTooltip="Click to view all Foreign Nationals whose screening is in process."
    ></insight-card>
  </div>
  <div class="w-100" *ngIf="isHighSide"></div>
  <div class="col pl-1 pr-1 mt-2">
    <insight-card
      id="fv-screen-count"
      [label]="'Foreign Nationals - Screened'"
      [value]="metrics.completedStatusCount || 0"
      [showClickable]="metrics.completedStatusCount > 0"
      (click)="
        navigateTo(
          metrics.completedStatusCount,
          '../fvs',
          'status',
          'Completed'
        )
      "
      matTooltip="Click to view all screened Foreign Nationals."
    ></insight-card>
  </div>
  <div class="col pl-1 pr-1 mt-2">
    <insight-card
      id="fv-ineligible-count"
      [label]="'Foreign Nationals Ineligible for Screening'"
      [value]="metrics.ineligibleCount || 0"
      [showClickable]="metrics.ineligibleCount > 0"
      (click)="
        navigateTo(
          metrics.ineligibleCount,
          '../fvs',
          'screeningEligible',
          false
        )
      "
      matTooltip="Foreign Nationals Ineligible for Screening."
    ></insight-card>
  </div>
  <div class="col pl-1 pr-1 mt-2">
    <insight-card
      id="fv-machine-red-count"
      [label]="'Foreign Nationals - Machine Red'"
      [value]="metrics.machineRedResultCount || 0"
      [showClickable]="metrics.machineRedResultCount > 0"
      (click)="
        navigateTo(
          metrics.machineRedResultCount,
          '../fvs',
          'result',
          'MACHINE_RED'
        )
      "
      matTooltip="Machine Red Foreign Nationals."
    ></insight-card>
  </div>
  <div class="col pl-1 pr-1 mt-2">
    <insight-card
      id="fv-derog-count"
      [label]="'Foreign Nationals with Derogatory Findings'"
      [showClickable]="metrics.redResultCount > 0"
      [value]="metrics.redResultCount || 0"
      (click)="navigateTo(metrics.redResultCount, '../fvs', 'result', 'RED')"
      matTooltip="Click to view all Foreign Nationals with Derogatory Findings."
    ></insight-card>
  </div>
  <div
    class="col pl-1 pr-1 mt-2"
    *appIfRoles="[Role.sv_admin, Role.sv_help_desk]"
  >
    <insight-card
      id="fv-error-screening-count"
      [label]="'Foreign Nationals - Reviews Pending'"
      [showClickable]="metrics.errorStatusCount > 0"
      [value]="metrics.errorStatusCount || 0"
      (click)="
        navigateTo(metrics.errorStatusCount, '../fvs', 'status', 'Error')
      "
      matTooltip="Number of Foreign Nationals that need Review."
    ></insight-card>
  </div>
</div>
