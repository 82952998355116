import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogData } from '@app/shared/components';
import { EmploymentRecord, FvWork, Address } from '@app/shared/models';
import {
  AlertService,
  CrudChangeEvent,
  CrudChangeType,
} from '@app/shared/services';
import { EmploymentService } from '@app/shared/services/employment.service';
import { map, startWith, take } from 'rxjs/operators';
import { EmploymentFormComponent } from './employment-form/employment-form.component';
import { Observable } from 'rxjs';

export interface DialogData<T> {
  mode: 'create' | 'update' | 'delete';
  model: T;
}
@Component({
  selector: 'fvview-employment',
  templateUrl: './fvview-employment.component.html',
  styleUrls: ['./fvview-employment.component.scss'],
})
export class FvviewEmploymentComponent {
  @Input() deletable?: boolean = false;
  @Input() editable?: boolean = true;

  @Input() employment?: FvWork[] = [];
  @Output() employmentChange = new EventEmitter<FvWork[]>();
  refresh$: Observable<CrudChangeEvent<EmploymentRecord>> =
    this.employmentService.changeEvent$.pipe(
      startWith({} as CrudChangeEvent<EmploymentRecord>)
    );

  /**
   * Template uses this observable to react to CRUD Changes.
   * If there are no crud changes then input employment property
   * is emitted.
   */

  employment$ = this.refresh$.pipe(
    map((refresh: CrudChangeEvent<FvWork>) => {
      if (refresh.type === CrudChangeType.delete)
        this.removeEmploymentRecord(refresh.modelId as string);
      if (refresh.type === CrudChangeType.create)
        this.addEmploymentRecord(refresh.model as FvWork);
      if (refresh.type === CrudChangeType.update)
        this.updateEmploymentRecord(refresh.model as FvWork);
      this.employmentChange.emit(this.employment);
      return this.employment;
    })
  );

  @Input() fvId: string = '';

  constructor(
    public dialog: MatDialog,
    private alert: AlertService,
    private employmentService: EmploymentService
  ) {}

  onAdd() {
    this.openDialog({
      model: { foreignVisitor: { id: this.fvId } },
      mode: 'create',
    });
  }
  onEdit(employment: FvWork) {
    this.openDialog({
      model: this.getEmploymentRecord(employment),
      mode: 'update',
    });
  }
  onDelete(employment: EmploymentRecord) {
    const employerName = employment.employerName;
    let confirmData: ConfirmDialogData = {
      title: `Delete ${employerName}`,
      message: `Are you sure? This action cannot be undone.`,
      performAction: () => this.employmentService.delete(employment.id),
    };
    this.alert.confirmDelete(confirmData).pipe(take(1)).subscribe();
  }

  // helper to add the foreign visitor assiciation to the employment object
  getEmploymentRecord(employment: FvWork) {
    return {
      ...employment,
      foreignVisitor: { id: this.fvId },
      entity: 'Foreign National',
    };
  }

  openDialog(employmentRecord: DialogData<EmploymentRecord>) {
    this.dialog.open(EmploymentFormComponent, {
      data: employmentRecord,
      disableClose: true,
    });
  }

  addEmploymentRecord(employmentRecord: FvWork) {
    if (this.employment) {
      this.employment = [...this.employment, employmentRecord];
    }
  }

  updateEmploymentRecord(employmentRecord: FvWork) {
    if (this.employment) {
      const index = this.employment.findIndex((employment) => {
        return employment.id === employmentRecord.id;
      });
      this.employment[index] = employmentRecord;
      this.employment = [...this.employment];
    }
  }
  removeEmploymentRecord(id: string) {
    if (this.employment) {
      this.employment = this.employment.filter((x) => x.id !== id);
    }
  }

  isAddress(location?: Address) {
    if (!location) return false;
    if (
      !location.line1 &&
      !location.line2 &&
      !location.city &&
      !location.stateProvince &&
      !location.countryCode &&
      !location.postalCode
    )
      return false;
    return true;
  }
}
