import { APP_INITIALIZER, NgModule } from '@angular/core';
import { environment } from '@environments/environment';
import { CitadelOauthModule } from '@app/auth/oauth/citadel-oauth.module';
import { CognitoAuthModule } from '@app/auth/cognito-auth/cognito-auth.module';
import { authAppInitializerFactory } from '@app/auth/auth-app-initializer.factory';
import { AuthService } from '@app/auth/auth.service';
import {
  ChangePasswordDialogComponent,
  ChangePasswordDirective,
} from './change-password-dialog/change-password-dialog.component';
import { SharedModule } from '@shared/shared.module';
import {
  SwitchRolesDialogComponent,
  SwitchRolesDirective,
} from './switch-roles-dialog/switch-roles-dialog.component';

const baseAuthModule = environment.oauth
  ? CitadelOauthModule
  : CognitoAuthModule;

@NgModule({
  declarations: [
    ChangePasswordDialogComponent,
    ChangePasswordDirective,
    SwitchRolesDirective,
    SwitchRolesDialogComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: authAppInitializerFactory,
      deps: [AuthService],
      multi: true,
    },
  ],
  imports: [SharedModule, baseAuthModule],
  exports: [
    baseAuthModule,
    ChangePasswordDialogComponent,
    ChangePasswordDirective,
    SwitchRolesDirective,
    SwitchRolesDialogComponent,
  ],
})
export class AuthModule {}
