<form id="contactForm" #contactForm="ngForm">
  <div class="row mt-2">
    <div class="col-6">
      <mat-form-field [appearance]="appearance">
        <mat-label>Contact Type</mat-label>
        <mat-select
          id="contact-type"
          #contactTypeControl="ngModel"
          [(ngModel)]="value.type"
          (ngModelChange)="emitChangeEvent()"
          name="gender"
          required
          aria-label="Please select an option"
          color="primary"
        >
          <mat-option selected disabled>Select a Type</mat-option>
          <mat-option [value]="ContactType.ORG_ADMIN_APPROVER">
            Organization Admin Approver
          </mat-option>
          <mat-option [value]="ContactType.SCREENING"> Screening </mat-option>
        </mat-select>
        <mat-error>Contact Type is required</mat-error>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field [appearance]="appearance">
        <mat-label>Name Type</mat-label>
        <mat-select
          id="name-type"
          #nameTypeControl="ngModel"
          [(ngModel)]="value.nameType"
          (ngModelChange)="emitChangeEvent(); clearNameFields()"
          name="name-type"
          aria-label="Please select an option"
          color="primary"
        >
          <mat-option selected disabled>Select a Type</mat-option>
          <mat-option [value]="'group'"> Group </mat-option>
          <mat-option [value]="'person'"> Person </mat-option>
        </mat-select>
        <mat-error>Name Type is required</mat-error>
      </mat-form-field>
    </div>
    <div class="col-6" *ngIf="value.nameType === 'person'">
      <mat-form-field [appearance]="appearance">
        <mat-label>Given Name</mat-label>
        <input
          matInput
          required
          [(ngModel)]="value.givenName"
          name="givenName"
          (ngModelChange)="emitChangeEvent()"
          id="organization-short-name"
        />
        <mat-error>Given Name is required</mat-error>
      </mat-form-field>
    </div>
    <div class="col-6" *ngIf="value.nameType === 'person'">
      <mat-form-field [appearance]="appearance">
        <mat-label>Surname</mat-label>
        <input
          matInput
          required
          [(ngModel)]="value.surname"
          name="surname"
          (ngModelChange)="emitChangeEvent()"
          id="org-contact-surname"
        />
        <mat-error>Surname is required</mat-error>
      </mat-form-field>
    </div>
    <div class="col-12" *ngIf="value.nameType === 'group'">
      <mat-form-field [appearance]="appearance">
        <mat-label>Group Name</mat-label>
        <input
          matInput
          required
          [(ngModel)]="value.groupName"
          name="groupName"
          (ngModelChange)="emitChangeEvent()"
          id="org-contact-group-name"
        />
        <mat-error>Group Name is required</mat-error>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field [appearance]="appearance">
        <mat-label>Phone Number</mat-label>
        <input
          type="text"
          matInput
          [(ngModel)]="value.phone"
          name="employeePhone"
          [pattern]="PhoneValidation"
          maxlength="15"
          id="employment-employee-phone"
        />
        <mat-error>Enter a Valid Phone Number</mat-error>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field [appearance]="appearance">
        <mat-label>Email</mat-label>
        <input
          type="text"
          required
          matInput
          [(ngModel)]="value.email"
          name="employeeEmail"
          maxlength="320"
          id="employment-employee-email"
          email
        />
        <mat-error>Must enter a valid email address</mat-error>
      </mat-form-field>
    </div>
  </div>
  <app-address
    #address
    [columns]="2"
    [countryPlaceholder]="'Choose Country'"
    [(ngModel)]="value.address"
    (ngModelChange)="emitChangeEvent()"
    name="location"
  ></app-address>
</form>
