import { Component } from '@angular/core';
import { AuthFlow } from '@app/auth/cognito-auth/cognito-auth.service';
@Component({
  selector: 'app-new-password-required',
  templateUrl: './new-password-required.component.html',
  styleUrls: ['./new-password-required.component.scss'],
})
export class NewPasswordRequiredComponent {
  authFlow = AuthFlow;
}
