import {
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ForeignVisitorGroup } from '@app/shared/models';
import { GroupService } from '@app/shared/services';
import { take } from 'rxjs';

@Directive({
  selector: '[appFnGroupDialog]',
})
export class FnGroupDialogDirective {
  @Input() data: any;
  @Output() fvGroupUpdated = new EventEmitter<any>();
  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    const dialog = FnGroupDialogComponent.openDialog(this.dialog, this.data);
    dialog.afterClosed().subscribe((result) => {
      if (result) return this.fvGroupUpdated.emit(result);
    });
  }
}

@Component({
  selector: 'app-fn-group-dialog',
  templateUrl: './fn-group-dialog.component.html',
  styleUrls: ['./fn-group-dialog.component.scss'],
})
export class FnGroupDialogComponent {
  value: ForeignVisitorGroup = {};
  busy: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public groupService: GroupService,
    public groupDialog: MatDialogRef<FnGroupDialogComponent>
  ) {}

  save() {
    this.busy = true;
    this.value.foreignVisitors = this.getSelectedFNs();
    this.groupService
      .save(this.value)
      .pipe(take(1))
      .subscribe((res) => {
        this.busy = false;
        this.groupDialog.close();
      });
  }

  getSelectedFNs() {
    let selected: any = [];
    if (this.data) {
      for (let fn of this.data) {
        if (fn.selected) selected.push({ id: fn.id });
      }
    }
    return selected;
  }

  static openDialog(
    dialog: MatDialog,
    item: any
  ): MatDialogRef<FnGroupDialogComponent> {
    return dialog.open<FnGroupDialogComponent>(FnGroupDialogComponent, {
      data: item,
      width: '800px',
    });
  }
}
