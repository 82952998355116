import { Injectable } from '@angular/core';
import {
  Crud,
  CrudChangeType,
  CrudService,
} from '@shared/services/crud.service';
import { environment } from '@environments/environment';
import { Sponsor } from '@shared/models';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
@Crud({
  apiUrl: `${environment.apiUrl}/sponsors`,
  hasIdPathUpdate: true,
  entity: 'Sponsor',
})
export class SponsorService extends CrudService<Sponsor> {
  constructor(http: HttpClient) {
    super(http);
  }

  /**
   * saves a resource - calls createForFar for new, and
   * update for existing
   *
   * @param {Sponsor} model body of the request
   *
   * @return {Observable<Sponsor>} saved resource
   */
  public saveForFar(farId: string, model: Sponsor): Observable<Sponsor> {
    return !model.id
      ? this.createForFar(farId, model)
      : this.update(model.id, model);
  }

  /**
   * fires a request to create a sponsor for a FAR
   *
   * @param {Sponsor} model body of the request
   *
   * @return {Observable<Sponsor>} created resource
   */
  public createForFar(farId: string, model: Sponsor): Observable<Sponsor> {
    return this.http
      .post<Sponsor>(`${this.apiUrl}/${farId}`, model, {
        context: this.context,
      })
      .pipe(
        tap((m) => {
          this.changeEvent$.emit({
            type: CrudChangeType.create,
            model: m,
            modelId: m.id,
          });
        })
      );
  }
}
