import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, HostBinding, Input } from '@angular/core';
import { ForeignVisitor } from '@app/shared/models/foreign-visitor.model';
import { getName } from 'i18n-iso-countries';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
  @HostBinding('class') class = 'avatar avatar__component';
  @HostBinding('style.--base-size.px')
  @Input()
  baseSize = 100;
  @Input() showShadow: boolean = true;

  @Input() enableBorderOutline: boolean = false;

  private _showPills: boolean = false;
  @Input()
  public set showPills(value: BooleanInput) {
    this._showPills = coerceBooleanProperty(value);
  }
  public get showPills(): boolean {
    return this._showPills;
  }

  @Input() fv: ForeignVisitor;
  @Input() horizontal: boolean = false;
  @Input() size: 'xsmall' | 'small' | 'medium' | 'large' = 'medium';
  @Input() picEditable: boolean = false;

  get country() {
    return this.getCountryName(this.fv);
  }

  get isHorizontal(): boolean {
    return this.horizontal;
  }

  get isXSmall(): boolean {
    return this.size === 'xsmall';
  }

  get isSmall(): boolean {
    return this.size === 'small';
  }

  get isMedium(): boolean {
    return this.size === 'medium';
  }

  get isLarge(): boolean {
    return this.size === 'large';
  }

  /**
   * Uses the code from the fv.citizenshipCounty if not null, otherwise use the first passport.issuingCountruCode, otherwise use 'XX'
   * @param fv
   * @returns string representing a 3 character country code
   */
  chooseFlag(fv: ForeignVisitor): string {
    let country = 'XX';
    if (fv.passports?.length) {
      country = fv.passports[0].issuingCountryCode!;
    }
    if (fv.citizenshipCountry) {
      country = fv.citizenshipCountry;
    }
    if (fv.nationalIds?.length && country === 'XX')
      country = fv.nationalIds[0].issuingCountryCode!;
    return country;
  }

  getCountryName(fv: ForeignVisitor) {
    let flag = this.chooseFlag(fv);
    if (flag !== 'UNN' && flag !== 'EUR') return getName(flag, 'en') || null;
    else if (flag === 'UNN') return 'United Nations';
    else if (flag === 'EUR') return 'European Union';
  }
}
