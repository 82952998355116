import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  CognitoAuthService,
  AuthFlow,
  ChallengeNameType,
} from '@app/auth/cognito-auth/cognito-auth.service';
import { AlertService } from '@app/shared/services';
import { environment } from '@environments/environment';
import { CognitoUser } from '@aws-amplify/auth';
import { trim } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

// TODO: Remove this ErrorMessage Enum once PME environment has been configured to use
// // attributes in cognito user pool
// export enum ErrorMessage {
//   'PreAuthentication failed with error This username does not exist.' = 'Incorrect username or password.',
// }

interface UserAttributes {
  email: string;
  family_name: string;
  given_name: string;
  phone_number: string;
  email_verified?: boolean;
}
interface CitadelCognitoUser extends CognitoUser {
  challengeParam: {
    email?: string;
    requiredAttributes?: string[];
    userAttributes?: UserAttributes;
  };
}

@Component({
  selector: 'app-login-form',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
})
export class LoginComponent implements OnInit {
  env = environment;
  @Output() viewChange = new EventEmitter<AuthFlow>();
  public email = new UntypedFormControl('', Validators.required);
  public password = new UntypedFormControl('', Validators.required);
  public preAuthUsernameError =
    'PreAuthentication failed with error This username does not exist.';
  public incorrectUsernameError = 'Incorrect username or password.';

  private busy_ = new BehaviorSubject(false);
  public busy = this.busy_.asObservable();

  public subscription: Subscription;
  signInForm: UntypedFormGroup;
  hide = {
    password: true,
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    public auth: CognitoAuthService,
    private alert: AlertService,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.signInForm = this.formBuilder.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
    });

    // Add shortcut fragment to go to forgot password
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment === 'forgot-password') {
        this.goToForgotPassword();
        this.location.replaceState(this.location.path(false));
      }
    });
  }

  goToForgotPassword() {
    this.viewChange.emit(AuthFlow.FORGOT_PASSWORD);
  }

  public async signIn() {
    const email = trim(this.signInForm.controls.userName.value);
    const password = this.signInForm.controls.password.value;

    this.busy_.next(true);
    try {
      const user = await this.auth.signIn(email, password);
      this.nextChallenge(user);
    } catch (err) {
      if (err.message && err.message == this.preAuthUsernameError) {
        err.message = this.incorrectUsernameError;
      }
      const msg = err.message || err;
      this.alert.errorAlert(msg);
    } finally {
      this.busy_.next(false);
    }
  }

  public async nextChallenge(user: CitadelCognitoUser) {
    const challenge = user.challengeName;

    // **Flow** - Login - No new challenges, will login into the Application
    if (!challenge) {
      await this.auth.isAuthenticated();
      // User is Authenticated, Get user info from API
      // navigate back to prev location
      this.auth.redirectFromLogin();
    }

    // **Flow** - Initial Login - navigate to form to update temporary password
    if (challenge == ChallengeNameType.NEW_PASSWORD_REQUIRED) {
      // Navigate to New Password Required
      this.auth.getRequiredAttributes();
      this.viewChange.emit(AuthFlow.CHANGE_PASSWORD);
    }

    // **Flow** - Login with MFA - Code sent via email, then navigate to MFA Form
    if (challenge == ChallengeNameType.CUSTOM_CHALLENGE) {
      this.viewChange.emit(AuthFlow.LOGIN_WITH_MFA);
    }
  }
}
