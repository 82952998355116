<mat-toolbar class="bg-light">
  <span> Sandbox </span>
</mat-toolbar>
<div class="container">
  <div class="row">
    <div class="col-3">
      <mat-card appearance="outlined">
        <mat-card-title>Bootstrap Background Colors</mat-card-title>
        <div class="p-3 mb-2 bg-primary text-white">.bg-primary</div>
        <div class="p-3 mb-2 bg-secondary text-white">.bg-secondary</div>
        <div class="p-3 mb-2 bg-success text-white">.bg-success</div>
        <div class="p-3 mb-2 bg-danger text-white">.bg-danger</div>
        <div class="p-3 mb-2 bg-warning text-dark">.bg-warning</div>
        <div class="p-3 mb-2 bg-info text-white">.bg-info</div>
        <div class="p-3 mb-2 bg-light text-dark">.bg-light</div>
        <div class="p-3 mb-2 bg-dark text-white">.bg-dark</div>
        <div class="p-3 mb-2 bg-white text-dark">.bg-white</div>
      </mat-card>
    </div>
    <div class="col-3">
      <mat-card appearance="outlined">
        <mat-card-title>Bootsrap Text Colors</mat-card-title>
        <p class="text-primary">.text-primary</p>
        <p class="text-secondary">.text-secondary</p>
        <p class="text-success">.text-success</p>
        <p class="text-danger">.text-danger</p>
        <p class="text-warning">.text-warning</p>
        <p class="text-info">.text-info</p>
        <p class="text-light bg-dark">.text-light</p>
        <p class="text-dark">.text-dark</p>
        <p class="text-muted">.text-muted</p>
        <p class="text-white bg-dark">.text-white</p>
      </mat-card>
    </div>
    <div class="col">
      <mat-card appearance="outlined">
        <mat-card-title>Material Buttons</mat-card-title>
        <div class="div p-2">
          <p>basic button</p>
          <button mat-button color="primary">Primary</button>
          <button mat-button color="accent">Accent</button>
          <button mat-button color="success">Success</button>
          <button mat-button color="info">Info</button>
          <button mat-button color="danger">Danger</button>
          <button mat-button color="warning">Warning</button>
          <button mat-button color="light">Light</button>
          <button mat-button color="dark">Dark</button>
        </div>
        <mat-divider></mat-divider>
        <div class="div p-2">
          <p>raised button</p>
          <button mat-raised-button color="primary">Primary</button>
          <button mat-raised-button color="accent">Accent</button>
          <button mat-raised-button color="success">Success</button>
          <button mat-raised-button color="info">Info</button>
          <button mat-raised-button color="danger">Danger</button>
          <button mat-raised-button color="warning">Warning</button>
          <button mat-raised-button color="light">Light</button>
          <button mat-raised-button color="dark">Dark</button>
        </div>
        <mat-divider></mat-divider>

        <div class="div p-2">
          <p>stroked button</p>
          <button mat-stroked-button color="primary">Primary</button>
          <button mat-stroked-button color="accent">Accent</button>
          <button mat-stroked-button color="success">Success</button>
          <button mat-stroked-button color="info">Info</button>
          <button mat-stroked-button color="danger">Danger</button>
          <button mat-stroked-button color="warning">Warning</button>
          <button mat-stroked-button color="light">Light</button>
          <button mat-stroked-button color="dark">Dark</button>
        </div>
        <mat-divider></mat-divider>

        <div class="div p-2">
          <p>flat button</p>
          <button mat-flat-button color="primary">Primary</button>
          <button mat-flat-button color="accent">Accent</button>
          <button mat-flat-button color="success">Success</button>
          <button mat-flat-button color="info">Info</button>
          <button mat-flat-button color="danger">Danger</button>
          <button mat-flat-button color="warning">Warning</button>
          <button mat-flat-button color="light">Light</button>
          <button mat-flat-button color="dark">Dark</button>
        </div>
        <mat-divider></mat-divider>

        <div class="div p-2">
          <p>icon button</p>
          <button mat-icon-button color="primary">
            <mat-icon>home</mat-icon>
          </button>
          <button mat-icon-button color="accent">
            <mat-icon>star</mat-icon>
          </button>
          <button mat-icon-button color="success">
            <mat-icon>more_vert</mat-icon>
          </button>
          <button mat-icon-button color="success">
            <mat-icon svgIcon="dots-vertical"></mat-icon>
          </button>
          <button mat-icon-button color="info">
            <mat-icon>home</mat-icon>
          </button>
          <button mat-icon-button color="danger">
            <mat-icon>menu</mat-icon>
          </button>
          <button mat-icon-button color="warning">
            <mat-icon>favorite</mat-icon>
          </button>
          <button mat-icon-button color="light">
            <mat-icon>open_in_new</mat-icon>
          </button>
          <button mat-icon-button color="dark">
            <mat-icon>favorite</mat-icon>
          </button>
        </div>
        <mat-divider></mat-divider>

        <div class="div p-2">
          <p>fab button</p>
          <button mat-fab color="primary">
            <mat-icon>home</mat-icon>
          </button>
          <button mat-fab color="accent">
            <mat-icon>star</mat-icon>
          </button>
          <button mat-fab color="success">
            <mat-icon>more_vert</mat-icon>
          </button>
          <button mat-fab color="info">
            <mat-icon>home</mat-icon>
          </button>
          <button mat-fab color="danger">
            <mat-icon>menu</mat-icon>
          </button>
          <button mat-fab color="warning">
            <mat-icon>favorite</mat-icon>
          </button>
          <button mat-fab color="light">
            <mat-icon>open_in_new</mat-icon>
          </button>
          <button mat-fab color="dark">
            <mat-icon>favorite</mat-icon>
          </button>
        </div>
        <mat-divider></mat-divider>

        <div class="div p-2">
          <p>mini fab button</p>
          <button mat-mini-fab color="primary">
            <mat-icon>home</mat-icon>
          </button>
          <button mat-mini-fab color="accent">
            <mat-icon>star</mat-icon>
          </button>
          <button mat-mini-fab color="success">
            <mat-icon>more_vert</mat-icon>
          </button>
          <button mat-mini-fab color="info">
            <mat-icon>home</mat-icon>
          </button>
          <button mat-mini-fab color="danger">
            <mat-icon>menu</mat-icon>
          </button>
          <button mat-mini-fab color="warning">
            <mat-icon>favorite</mat-icon>
          </button>
          <button mat-mini-fab color="light">
            <mat-icon>open_in_new</mat-icon>
          </button>
          <button mat-mini-fab color="dark">
            <mat-icon>favorite</mat-icon>
          </button>
        </div>
      </mat-card>
    </div>
  </div>
  <mat-toolbar class="bg-light">
    <mat-toolbar-row>Timeline Component</mat-toolbar-row>
    <mat-toolbar-row class="gap-1">
      <span class="example-label-container">
        <label id="example-name-label" class="example-name-label"
          >Badge Size {{ badgeSize.value }}
        </label>
        <mat-slider min="20" max="75" discrete>
          <input matSliderThumb #badgeSize value="44" />
        </mat-slider>
      </span>
      <mat-slide-toggle [checked]="left" (toggleChange)="left = !left"
        >Left</mat-slide-toggle
      >
      <mat-slide-toggle
        [checked]="alternate"
        (toggleChange)="alternate = !alternate"
        >Alternate</mat-slide-toggle
      >
      <mat-slide-toggle #horizontalToggle="matSlideToggle"
        >horizontal</mat-slide-toggle
      >
      <mat-slide-toggle [checked]="dbug" (toggleChange)="dbug = !dbug"
        >Debug</mat-slide-toggle
      >
      <i class="flex-fill"></i>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-select #col [value]="'col-6'">
          <mat-option
            *ngFor="let option of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
            value="col-{{ option }}"
            >col-{{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-toolbar-row>
  </mat-toolbar>
  <!-- #region dev -->
  <section>
    <div [style.display]="!dbug ? 'none' : undefined" class="row py-3">
      <form #form="ngForm" (ngSubmit)="addEvent(form)" class="col-6">
        <ng-container *ngFor="let prop of itinerary[0] | keyvalue">
          <mat-form-field style="width: 40%" appearance="outline">
            <mat-label>{{ prop.key }}</mat-label>
            <input
              matInput
              [type]="getInputType(prop.key)"
              ngModel
              name="{{ prop.key }}"
              id="{{ prop.key }}"
            />
          </mat-form-field>
          <ng-template #date>
            <input
              matInput
              type="date"
              ngModel
              name="{{ prop.key }}"
              id="{{ prop.key }}"
            />
          </ng-template>
        </ng-container>
        <div class="d-flex justify-content-end">
          <button mat-raised-button color="primary">Add Event</button>
        </div>
      </form>

      <pre class="debug col-6">
        left:  {{ left }}
        alternate:  {{ alternate }}
        
form: {{ form.valueChanges | async | json }}
        </pre
      >
    </div>
  </section>

  <!-- #endregion dev -->
  <div class="row">
    <div [class]="col.value">
      <h3>Custom Template</h3>
      <app-timeline
        #timeline
        [horizontal]="horizontalToggle.checked"
        [left]="left"
        [alternate]="alternate"
        [data]="itinerary"
        [icon]="'home'"
        [badgeSize]="badgeSize.value"
      >
        <ng-template timeline-content-tmp let-item="item">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>{{ item.startDate | date }} </mat-panel-title>
              <mat-panel-description>
                <span [title]="item.content">{{
                  item.subject | shorten: descriptionSize:"..."
                }}</span>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p>
              {{ item.content }}
            </p>
          </mat-expansion-panel>
        </ng-template>
      </app-timeline>
    </div>
    <div [class]="col.value">
      <h3>Default Template</h3>
      <app-timeline [data]="itinerary"></app-timeline>
    </div>

    <div class="col-12">
      <h3>Horizontal Timeline</h3>
      <app-timeline [data]="screeningTransitions" horizontal>
        <ng-template timeline-badge let-item="item">
          <div
            class="rounded-circle p-1"
            [class]="item.classList"
            style="display: grid"
            matTooltip="Some Text"
          >
            <mat-icon [svgIcon]="item.icon"></mat-icon>
          </div>
        </ng-template>
      </app-timeline>
    </div>
  </div>
</div>
