import { Pipe, PipeTransform } from '@angular/core';
import { ForeignVisitor } from '@app/shared/models/foreign-visitor.model';
import { User, Sponsor } from '@app/shared/models';

@Pipe({
  name: 'fullname',
  pure: false,
})
export class FullnamePipe implements PipeTransform {
  transform(
    person: ForeignVisitor | User | Sponsor,
    format: 'givenFirst' | 'surnameFirst' = 'givenFirst'
  ): string {
    if (person && format === 'surnameFirst') {
      const parts = [person.surname?.trim(), person.givenName?.trim()].filter(
        (v) => !!v?.length
      );
      return parts.join(', ');
    }
    if (person && format === 'givenFirst') {
      const parts = [person.givenName?.trim(), person.surname?.trim()].filter(
        (v) => !!v?.length
      );
      return parts.join(' ');
    }
    return '';
  }
}
