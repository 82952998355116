<form
  #phoneNumberForm="ngForm"
  *ngIf="value"
  class="d-flex align-items-baseline"
>
  <span *ngIf="showPrimaryToggle">
    <mat-slide-toggle
      class="mr-3"
      color="accent"
      [(ngModel)]="value.primaryPhone"
      (ngModelChange)="emitChangeEvent()"
      name="primaryNumber"
      >Primary</mat-slide-toggle
    >
  </span>
  <mat-form-field appearance="outline" class="mr-3">
    <mat-label>Type</mat-label>
    <mat-select
      #phoneNumberType="ngModel"
      id="phoneNumberType"
      [(ngModel)]="value.type"
      (ngModelChange)="emitChangeEvent()"
      name="phoneNumberType"
      placeholder="Type"
    >
      <mat-option>Select a Type</mat-option>
      <mat-option *ngFor="let type of phoneTypes | values" value="{{ type }}">
        {{ type }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="mr-3 country-code">
    <mat-label>Country Code</mat-label>
    <input
      name="phoneCountryCode"
      #phoneCountryCode="ngModel"
      id="phoneCountryCode"
      matInput
      class="country-code"
      [(ngModel)]="value.countryCode"
      (ngModelChange)="emitChangeEvent()"
      placeholder="Country Code"
    />
  </mat-form-field>

  <mat-form-field appearance="outline" class="mr-3 flex-fill">
    <mat-label>Phone Number</mat-label>
    <input
      name="phoneNumber"
      #phoneNumber="ngModel"
      id="phoneNumber"
      matInput
      maxlength="15"
      [required]="numberRequired || hasData()"
      [pattern]="PhoneValidation"
      placeholder="Phone Number"
      [(ngModel)]="value.number"
      (ngModelChange)="emitChangeEvent()"
      aria-label="phone number"
    />
    <mat-error>Invalid Phone Number</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="m-0">
    <mat-label>Extension</mat-label>
    <input
      name="extension"
      #phoneExtension="ngModel"
      id="extension"
      matInput
      [(ngModel)]="value.extension"
      (ngModelChange)="emitChangeEvent()"
      placeholder="Extension"
    />
  </mat-form-field>
</form>
