<h3 mat-dialog-title>Education</h3>
<app-fv-education-form
  #educationForm
  ngForm
  name="education"
  [education]="education"
></app-fv-education-form>
<mat-dialog-actions align="end">
  <button
    mat-button
    color="primary"
    id="education-dialog-cancel"
    mat-dialog-close
  >
    Cancel
  </button>
  <button
    [disabled]="!educationForm.valid || busy"
    id="submitEducation"
    mat-raised-button
    color="primary"
    (click)="save()"
  >
    <span *ngIf="!busy">Submit</span>
    <mat-spinner
      class="mr-1 ml-1"
      *ngIf="busy"
      color="primary"
      diameter="35"
    ></mat-spinner>
  </button>
</mat-dialog-actions>
