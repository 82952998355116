import { BaseModel } from './base.model';
import { ClassificationModel } from './classification.model';

export interface DerogatoryInformation extends BaseModel, ClassificationModel {
  id?: string;
  code?: string;
  offenseCode?: OffenseCode;
  relationalCode?: RelationalCode;
  numberOfCommunicationContacts?: CommunicationContact;
  timeFrameOfCommunicationContacts?: TimeFrame;
  verificationOfCommunicationContent?: Verification;
}

export enum Program {
  NATIONAL_SECURITY = 'National Security',
  CRIMINAL = 'Criminal',
  NATIONAL_SECURITY_CRIMINAL = 'National Security/Criminal',
}

export enum OffenseCode {
  MA = 'MA',
  AM = 'AM',
  KT = 'KT',
  KA = 'KA',
  TS = 'TS',
  TA = 'TA',
  CA = 'CA',
  CY = 'CY',
  EP = 'EP',
  OT = 'OT',
  EF = 'EF',
  NC = 'NC',
  NT = 'NT',
  SM = 'SM',
  TR = 'TR',
  VC = 'VC',
}

export const OffenseCodeDescriptions: {
  [key in OffenseCode]: string;
} = {
  [OffenseCode.MA]: 'Main Subject of an FBI Investigation',
  [OffenseCode.AM]: 'Associate of the Main Subject of an FBI Investigation',
  [OffenseCode.KT]:
    'Known Terrorist, to include leaders, members, spokesmen, or representatives of an IT group; individuals who have\n' +
    'received military training on behalf of an FTO or IT group; and individuals who have engaged in or are likely to engage in\n' +
    'terrorist-related activities or attacks',
  [OffenseCode.KA]:
    'Known Associate of an IT Group or Member, or individual who has engaged in or is likely to engage in terrorist-related\n' +
    'activities',
  [OffenseCode.TS]:
    'Involved in Providing Support for Terrorism (e.g., Safe House, Weapons, Identification, Terrorist Financing, etc.)',
  [OffenseCode.TA]: 'Tenuous Associate',
  [OffenseCode.CA]:
    'Involved in the Use, Transport, or Production of WMD materials or precursors',
  [OffenseCode.CY]:
    'Involved in Malicious Cyber Activities (also encompasses state- sponsored hacking activities)',
  [OffenseCode.EP]:
    'Foreign intelligence Officer, or engage in Clandestine Intelligence Activities; Espionage; Sabotage; or illegal Export of US Goods, Technology, or Sensitive/Proprietary Information',
  [OffenseCode.OT]: 'Involved in Domestic Terrorism',
  [OffenseCode.EF]:
    'Involved in the Use, Manufacture, or Transport of Illegal Explosives or Firearm',
  [OffenseCode.NC]:
    'Involved in non-violent criminal activity, to include non-CT financial criminal activity, mail fraud, wire fraud, passport fraud, \n' +
    'visa fraud, identity fraud, vehicle theft, etc.',
  [OffenseCode.NT]:
    'Narcotics Trafficker, Aider, Abettor, Conspirator, or Colluder',
  [OffenseCode.SM]: 'Involved in Alien Smuggling',
  [OffenseCode.TR]:
    'Involved in Human Trafficking, Sex Trafficking, or Forced Labor',
  [OffenseCode.VC]:
    'Involved in Violent Crime, to include violent crimes against children and child pornography',
};

export const OffenseCodePrograms: {
  [key in OffenseCode]: Program;
} = {
  [OffenseCode.MA]: Program.NATIONAL_SECURITY_CRIMINAL,
  [OffenseCode.AM]: Program.NATIONAL_SECURITY_CRIMINAL,
  [OffenseCode.KT]: Program.NATIONAL_SECURITY,
  [OffenseCode.KA]: Program.NATIONAL_SECURITY,
  [OffenseCode.TS]: Program.NATIONAL_SECURITY,
  [OffenseCode.TA]: Program.NATIONAL_SECURITY_CRIMINAL,
  [OffenseCode.CA]: Program.NATIONAL_SECURITY,
  [OffenseCode.CY]: Program.NATIONAL_SECURITY,
  [OffenseCode.EP]: Program.NATIONAL_SECURITY,
  [OffenseCode.OT]: Program.NATIONAL_SECURITY,
  [OffenseCode.EF]: Program.CRIMINAL,
  [OffenseCode.NC]: Program.CRIMINAL,
  [OffenseCode.NT]: Program.CRIMINAL,
  [OffenseCode.SM]: Program.CRIMINAL,
  [OffenseCode.TR]: Program.CRIMINAL,
  [OffenseCode.VC]: Program.CRIMINAL,
};

export const OffenseCodes = Object.values(OffenseCode).sort();

export enum RelationalCode {
  TRAVELER_APPLICANT = '1',
  FAMILY_MEMBER = '3',
  ASSOCIATE = '5',
}

export const RelationalCodeDescriptions: {
  [key in RelationalCode]: string;
} = {
  [RelationalCode.TRAVELER_APPLICANT]: 'Traveler/Applicant',
  [RelationalCode.FAMILY_MEMBER]: 'Family Member',
  [RelationalCode.ASSOCIATE]: 'Associate',
};

export const RelationalCodes = Object.values(RelationalCode);

export enum CommunicationContact {
  N_A = '0',
  CONTACTS_1_10 = '1',
  CONTACTS_11_PLUS = '2',
}

export const CommunicationContactDescriptions: {
  [key in CommunicationContact]: string;
} = {
  [CommunicationContact.N_A]: 'N/A (0 Contacts)',
  [CommunicationContact.CONTACTS_1_10]: '1-10 Contacts',
  [CommunicationContact.CONTACTS_11_PLUS]: '11 or more Contacts',
};

export const CommunicationContacts = Object.values(CommunicationContact);

export enum TimeFrame {
  N_A = '0',
  WITHIN_YEAR = '1',
  MORE_THAN_YEAR = '2',
}

export const TimeFrameDescriptions: {
  [key in TimeFrame]: string;
} = {
  [TimeFrame.N_A]: 'N/A',
  [TimeFrame.WITHIN_YEAR]: 'Within the past year',
  [TimeFrame.MORE_THAN_YEAR]: 'More than 1 year ago',
};

export const TimeFrames = Object.values(TimeFrame);

export enum Verification {
  N_A = '0',
  VERIFIED_DEROG = '1',
  UNKNOWN = '2',
}

export const VerificationDescriptions: {
  [key in Verification]: string;
} = {
  [Verification.N_A]: 'N/A (No Communication)',
  [Verification.VERIFIED_DEROG]: 'Verified Derogatory Content',
  [Verification.UNKNOWN]: 'Content Unknown',
};

export const Verifications = Object.values(Verification);

export const OffenseCodeMap = new Map<
  string,
  { description: string; program: Program }
>([
  [
    OffenseCode.MA,
    {
      description: OffenseCodeDescriptions.MA,
      program: OffenseCodePrograms.MA,
    },
  ],
  [
    OffenseCode.AM,
    {
      description: OffenseCodeDescriptions.AM,
      program: OffenseCodePrograms.AM,
    },
  ],
  [
    OffenseCode.KT,
    {
      description: OffenseCodeDescriptions.KT,
      program: OffenseCodePrograms.KT,
    },
  ],
  [
    OffenseCode.KA,
    {
      description: OffenseCodeDescriptions.KA,
      program: OffenseCodePrograms.KA,
    },
  ],
  [
    OffenseCode.TS,
    {
      description: OffenseCodeDescriptions.TS,
      program: OffenseCodePrograms.TS,
    },
  ],
  [
    OffenseCode.TA,
    {
      description: OffenseCodeDescriptions.TA,
      program: OffenseCodePrograms.TA,
    },
  ],
  [
    OffenseCode.CA,
    {
      description: OffenseCodeDescriptions.CA,
      program: OffenseCodePrograms.CA,
    },
  ],
  [
    OffenseCode.CY,
    {
      description: OffenseCodeDescriptions.CY,
      program: OffenseCodePrograms.CY,
    },
  ],
  [
    OffenseCode.EP,
    {
      description: OffenseCodeDescriptions.EP,
      program: OffenseCodePrograms.EP,
    },
  ],
  [
    OffenseCode.OT,
    {
      description: OffenseCodeDescriptions.OT,
      program: OffenseCodePrograms.OT,
    },
  ],
  [
    OffenseCode.EF,
    {
      description: OffenseCodeDescriptions.EF,
      program: OffenseCodePrograms.EF,
    },
  ],
  [
    OffenseCode.NC,
    {
      description: OffenseCodeDescriptions.NC,
      program: OffenseCodePrograms.NC,
    },
  ],
  [
    OffenseCode.NT,
    {
      description: OffenseCodeDescriptions.NT,
      program: OffenseCodePrograms.NT,
    },
  ],
  [
    OffenseCode.SM,
    {
      description: OffenseCodeDescriptions.SM,
      program: OffenseCodePrograms.SM,
    },
  ],
  [
    OffenseCode.TR,
    {
      description: OffenseCodeDescriptions.TR,
      program: OffenseCodePrograms.TR,
    },
  ],
  [
    OffenseCode.VC,
    {
      description: OffenseCodeDescriptions.VC,
      program: OffenseCodePrograms.VC,
    },
  ],
]);
