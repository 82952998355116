import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Education } from '../models';
import { Crud, CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
@Crud({
  apiUrl: `${environment.apiUrl}/education`,
  hasIdPathUpdate: true,
  entity: 'Education',
})
export class EducationService extends CrudService<Education> {
  constructor(http: HttpClient) {
    super(http);
  }
}
