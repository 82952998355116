import {
  Component,
  Input,
  IterableDiffer,
  IterableDiffers,
  DoCheck,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FvSocialMedia } from '@app/shared/models';
import { AlertService, SocialMediaService } from '@app/shared/services';
import { SocialMediaDialogComponent } from './social-media-dialog/social-media-dialog.component';
import { take } from 'rxjs/operators';
import { without } from 'lodash';

@Component({
  selector: 'app-fvview-social-media',
  templateUrl: './fvview-social-media.component.html',
  styleUrls: ['./fvview-social-media.component.scss'],
})
export class FvviewSocialMediaComponent implements DoCheck {
  @Input() id: string;

  @Input() deletable?: boolean = false;
  @Input() editable?: boolean = true;

  _data: FvSocialMedia[];
  @Input()
  set socialMedia(data: FvSocialMedia[]) {
    this._data = data;
  }
  get socialMedia() {
    return this._data;
  }

  private _iterableDiffer: IterableDiffer<FvSocialMedia>;

  get isEmpty(): boolean {
    if (this._data) return this._data.length === 0;
    return true;
  }

  constructor(
    public dialog: MatDialog,
    private socialMediaService: SocialMediaService,
    private iterableDiffers: IterableDiffers,
    private alert: AlertService
  ) {
    this._iterableDiffer = this.iterableDiffers.find([]).create();
  }

  ngDoCheck() {
    let changes = this._iterableDiffer.diff(this._data);
    if (changes) {
      this.socialMedia = this._data;
    }
  }

  openSocialMedia(item: FvSocialMedia, index: number) {
    let origItem = { ...item };
    let dialogRef = this.dialog.open(SocialMediaDialogComponent, {
      data: { id: this.id, editMode: true, item: origItem },
      width: '600px',
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          this._data[index] = data.complete;
        }
      });
  }
  newSocialMedia() {
    let dialogRef = this.dialog.open(SocialMediaDialogComponent, {
      data: { id: this.id, editMode: false },
      width: '600px',
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((data) => {
        if (data?.complete) {
          let newtable = [];
          newtable = this._data;
          newtable.push(data?.complete);
          this._data = newtable;
        }
      });
  }
  removeSocialMedia(socialMediaItem: FvSocialMedia) {
    this.alert
      .confirmDelete({
        message: 'Are you sure you would like to delete this record?',
        title: 'Delete Social Media',
        performAction: () => this.socialMediaService.delete(socialMediaItem.id),
      })
      .subscribe((_) => {
        this._data = without(this._data, socialMediaItem);
      });
  }
}
