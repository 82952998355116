import {
  Component,
  forwardRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  AbstractControl,
  NgForm,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {
  ForeignVisitor,
  ContactEmail,
  ContactPhoneNumber,
} from '@app/shared/models';
import { BaseControlComponent } from '@app/shared/controls/base-control.component';
import { MatFormFieldAppearance } from '@angular/material/form-field';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['contact.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ContactComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ContactComponent),
      multi: true,
    },
  ],
})
export class ContactComponent
  extends BaseControlComponent<ForeignVisitor>
  implements Validator
{
  @ViewChild('contactForm')
  contactForm: NgForm;
  appearance: MatFormFieldAppearance = 'outline';
  onValidationChange: any = () => {};
  @Input() fvEmail: ContactEmail = {};
  @Input() fvPhone: ContactPhoneNumber;

  constructor() {
    super();
  }

  writeValue(_value: ForeignVisitor) {
    super.writeValue(_value);
    this.value = _value || {};
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    return this.contactForm?.valid ? null : { contact: true };
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }
}
