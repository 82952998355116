import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
/**
 * This is a reusable component to be used when there should be a dialog
 * preventing users from navigating away from the current page due to unsaved errors.
 */
@Component({
  selector: 'app-deactivate-dialog',
  templateUrl: './deactivate-dialog.component.html',
  styleUrls: ['./deactivate-dialog.component.scss'],
})
export class DeactivateDialogComponent {
  header: string = 'Confirmation Needed';
  body: string = ``;

  constructor(private dialogRef: MatDialogRef<DeactivateDialogComponent>) {}

  cancel() {
    this.dialogRef.close(false);
  }

  proceed() {
    this.dialogRef.close(true);
  }
}
