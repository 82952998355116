import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CheckBoxProperties, UserPreferenceBlob } from '@app/shared/models';
import { FvSortFieldLabels } from '@app/shared/services';
import { AdditionalTableColumns } from '../user-preferences.component';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { UserPreferenceService } from '@app/shared/services/user-preference.service';
import {
  ControlContainer,
  FormArray,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';

@Component({
  selector: 'app-fn-tab-checkbox',
  templateUrl: './fn-tab-checkbox.component.html',
  styleUrls: ['./fn-tab-checkbox.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class FnTabCheckboxComponent implements OnInit, OnChanges {
  @Input() updated: boolean;
  @Input() checkedColumns: Array<string> = [];
  @Input() tabTitle: string;
  @Input() previewName: string;
  tabLabel: string = 'Foreign Nationals (FN)';
  tabIndex: number;
  _fnViewType: string = 'card';
  checkedColumnss: Array<string>;
  fnCheckedColumns: Array<CheckBoxProperties> = [];
  fnColumnNames: Array<string> = [];
  displayCheckedColumns: CheckBoxProperties[];
  _columnNames: Array<string> = [];
  fnForm!: FormGroup;

  constructor(
    private userPreferenceService: UserPreferenceService,
    private rootFormGroup: FormGroupDirective
  ) {}
  ngOnInit(): void {
    this.loadCheckboxes();
    this.fnForm = this.rootFormGroup.control.get('fv') as FormGroup;
  }

  loadCheckboxes(): void {
    this.tabIndex = 0;
    if (this.fnColumnNames.length === 0) {
      Object.entries(FvSortFieldLabels).forEach((key, value) => {
        if (
          key[1] !== 'Last Screening Completed' &&
          key[1] !== 'Last Screening Requested' &&
          key[1] !== 'Last Modified Date' &&
          key[1] !== 'Created Date' &&
          key[1] !== 'Residence Country' &&
          key[1] !== 'Linked Nationals Count' &&
          key[1] !== 'Select'
        ) {
          this.fnColumnNames.push(key[1]);
          this.fnCheckedColumns.push({ selected: true, label: key[1] });
        }
      });
      if (!this.fnColumnNames.includes(AdditionalTableColumns.at(0)!)) {
        this.fnColumnNames.push(AdditionalTableColumns[0]);
        this.fnCheckedColumns.push({
          selected: true,
          label: AdditionalTableColumns[0],
        });
      }

      if (!this.fnColumnNames.includes(AdditionalTableColumns.at(1)!)) {
        this.fnColumnNames.push(AdditionalTableColumns[1]);
        this.fnCheckedColumns.push({
          selected: true,
          label: AdditionalTableColumns[1],
        });
      }
    }

    this.displayCheckedColumns = this.fnCheckedColumns;
    this._columnNames = this.fnColumnNames;
    this.displayCheckedColumns = this.setDisplayCheckedColumns(
      this.fnCheckedColumns,
      this.setCheckedColumns(this.fnCheckedColumns)
    );
  }

  chooseView($event: MatButtonToggleChange) {
    this._fnViewType = $event.value;
    this.fnForm.get('fnViewType')?.setValue($event.value);
    $event.source.checked = true;
  }

  selectedColumns(selectedOption: MatCheckboxChange) {
    this.displayCheckedColumns.forEach((c) => {
      if (selectedOption.source.name === c.label) {
        selectedOption.checked ? (c.selected = true) : (c.selected = false);
      }
    });
    this.fnCheckboxes.controls.forEach((value, index) => {
      const newValue = value.value;
      if (newValue.label === selectedOption.source.name) {
        if (selectedOption.checked) {
          value.patchValue({ selected: true, label: newValue.label });
        } else {
          value.patchValue({ selected: false, label: newValue.label });
        }
      }
    });

    this.checkedColumnss = this.setCheckedColumns(this.displayCheckedColumns);
  }

  toggleAll(toggle: boolean) {
    this.fnCheckedColumns = [];
    this.checkedColumnss = [];
    if (toggle) {
      this.fnCheckboxes.controls.forEach((value, index) => {
        const newValue = value.value;
        this.fnCheckedColumns.push({ selected: true, label: newValue.label });
        value.patchValue({ selected: true, label: newValue.label });
        this.checkedColumnss.push(newValue.label);
      });
    } else {
      this.fnCheckboxes.controls.forEach((value, index) => {
        const newValue = value.value;
        this.fnCheckedColumns.push({ selected: false, label: newValue.label });
        value.patchValue({ selected: false, label: newValue.label });
      });
      this.checkedColumnss = [];
    }
    this.displayCheckedColumns = this.fnCheckedColumns;
  }

  get fnCheckboxes(): FormArray {
    return this.fnForm.get('fnCheckboxes') as FormArray;
  }

  setDisplayCheckedColumns(
    checkedArray: Array<CheckBoxProperties>,
    checkedArrayColumns: string[]
  ): Array<CheckBoxProperties> {
    let trueCheckedColumns: Array<CheckBoxProperties> = [];
    checkedArray.forEach((c) => {
      if (checkedArrayColumns.includes(c.label)) {
        trueCheckedColumns.push({
          selected: true,
          label: c.label,
        });
      } else {
        trueCheckedColumns.push({
          selected: false,
          label: c.label,
        });
      }
    });

    return trueCheckedColumns;
  }

  setCheckedColumns(checkedArray: Array<CheckBoxProperties>): string[] {
    let trueCheckedColumns: string[] = [];
    checkedArray.forEach((c) => {
      if (c.selected) {
        trueCheckedColumns.push(c.label);
      }
    });
    return trueCheckedColumns;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.userPreferenceService.updated.subscribe((result) => {
      if (result) {
        this.userPreferenceService.updateCheckboxes.subscribe((checkit) => {
          if (this.tabLabel === checkit.viewName) {
            this.checkedColumnss = this.setCheckedColumns(checkit.checkboxes!);
            this.fnCheckedColumns = checkit.checkboxes!;
            this.checkedColumnss = this.setCheckedColumns(
              this.fnCheckedColumns
            );

            this.displayCheckedColumns = this.setDisplayCheckedColumns(
              this.fnCheckedColumns,
              this.checkedColumnss
            );
            //this._fnViewType = checkit.viewType!;

            this.fnForm.get('fnViewType')?.patchValue(checkit.viewType!);
            this._fnViewType = this.fnForm.get('fnViewType')?.value;
            this.fnCheckboxes.controls.forEach((value) => {
              const newValue = value.value;
              checkit.checkboxes?.filter((x) => {
                if (x.label === newValue.label) {
                  value.patchValue({ selected: x.selected, label: x.label });
                }
              });
            });
          }
        });
      }
    });

    if (changes['tabTitle']) {
      if (changes['tabTitle'].currentValue !== null) {
        this.tabTitle = changes['tabTitle'].currentValue;
      }
    }
  }
}
