import { Component, Input } from '@angular/core';
import { ForeignVisitor } from '@app/shared/models';

@Component({
  selector: 'app-fn-metadata',
  templateUrl: './fn-metadata.component.html',
  styleUrls: ['./fn-metadata.component.scss'],
})
export class FnMetadataComponent {
  @Input() fn: ForeignVisitor;
  @Input() tableDisplay: boolean = false;
  @Input() hideMetadataFooter: boolean = false;
  @Input() farScreeningEligibility: boolean = false;

  get createdByFullName() {
    const givenName = this.fn.auditMetadata?.createdByGivenName;
    const surName = this.fn.auditMetadata?.createdBySurname;
    return `${givenName} ${surName}`;
  }

  get owningOrgShortName() {
    return this.fn.auditMetadata?.owningOrganizationShortName!;
  }

  get owningOrgName() {
    return this.fn.auditMetadata?.owningOrganizationName!;
  }

  get networkDoimain() {
    return this.fn.networkDomain!;
  }

  get comments() {
    return this.fn.comments?.length ?? 0;
  }

  get linkedVisitors() {
    return this.fn.linkedVisitorCount!;
  }

  get activeFarCount() {
    return this.fn.activeFarCount!;
  }

  get farCount() {
    return this.fn.farCount!;
  }

  get attachmentCount() {
    return this.fn.attachmentCount!;
  }

  get deletionDate() {
    return this.fn.deletionDate!;
  }
}
