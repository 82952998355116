import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogState,
} from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { finalize, scan, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'timeout-dialog',
  templateUrl: './timeoutdialog.component.html',
})
export class TimeoutDialogComponent {
  timeout$: Observable<number>;

  constructor(
    public dialogRef: MatDialogRef<TimeoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TimeoutDialogData
  ) {
    this.timeout$ = timer(0, 1000).pipe(
      scan((current) => --current, data.timeout + 1),
      takeWhile((counter) => counter > 0),
      finalize(() => this.onFinalize())
    );
  }

  logout(): void {
    this.dialogRef.close(true);
  }

  onFinalize() {
    // if the dialog is currently open then logout
    if (this.dialogRef.getState() === MatDialogState.OPEN) this.logout();
  }

  static openDialog(
    dialog: MatDialog,
    data: TimeoutDialogData
  ): MatDialogRef<TimeoutDialogComponent> {
    return dialog.open<TimeoutDialogComponent>(TimeoutDialogComponent, {
      maxWidth: '400px',
      data,
      disableClose: true,
    });
  }
}

//data model for dialog
export interface TimeoutDialogData {
  timeout: number;
}
