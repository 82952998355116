<div class="empty-state">
  <div
    class="
      p-3
      d-flex
      empty-state__inner
      flex-column
      align-items-center
      justify-content-center
    "
  >
    <div class="text-center mat-headline-5">{{ message }}</div>
    <div>
      <ng-content></ng-content>
    </div>
  </div>
</div>
