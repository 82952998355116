import { Component, OnInit } from '@angular/core';
import { User } from './shared/models';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from '@app/auth/auth.service';
import { UserAcceptedObservable } from './shared/services/user-accepted-observable.service';
import { AutoLogoutDirective } from './auto-logout.directive';
@Component({
  selector: 'app',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  hostDirectives: [AutoLogoutDirective],
})
export class AppComponent implements OnInit {
  systemUser$: Observable<User | null> = this.auth.user$;
  userAccepted: boolean;
  awsEnvClass?: string;
  authenticated: Observable<boolean> = this.auth.authenticated$;

  constructor(
    private auth: AuthService,
    private userAccept: UserAcceptedObservable
  ) {}

  ngOnInit(): void {
    this.awsEnvClass = environment.classification;
    let millisecondsInDay = 86400000;

    //Clear out Splash Screen Acceptance Once a Day
    if (localStorage.getItem('userAccepted')) {
      let acceptedDate: any =
        (JSON.parse(
          localStorage.getItem('userAcceptedDate') as string
        ) as number) || null;
      if (acceptedDate) {
        let difference = Date.now() - acceptedDate;
        if (difference > millisecondsInDay) {
          this.userAccepted = false;
          localStorage.removeItem('userAccepted');
          localStorage.removeItem('userAcceptedDate');
        }
      } else {
        this.userAccepted = false;
        localStorage.removeItem('userAccepted');
      }
    }
    this.userAccepted = localStorage.getItem('userAccepted')
      ? (JSON.parse(localStorage.getItem('userAccepted') as string) as boolean)
      : false;

    //only watch if user is Authenticated
    this.auth.authenticated$.subscribe();

    this.userAccept.UserAcceptedObservable$.subscribe((data) => {
      this.userAccepted = data;
    });
  }
}
