import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ScreeningFieldLabels } from '@app/shared/components/screening';
import {
  CheckBoxProperties,
  Role,
  UserPreferenceBlob,
} from '@app/shared/models';
import { AdditionalTableColumns } from '../user-preferences.component';
import { AuthService } from '@app/auth/auth.service';
import { UserPreferenceService } from '@app/shared/services/user-preference.service';
import {
  ControlContainer,
  FormArray,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';

@Component({
  selector: 'app-screening-tab-checkbox',
  templateUrl: './screening-tab-checkbox.component.html',
  styleUrls: ['./screening-tab-checkbox.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class ScreeningTabCheckboxComponent implements OnInit, OnChanges {
  @Input() updated: boolean;
  @Input() checkedColumns: Array<string> = [];
  @Input() userRoles: Role[];
  @Input() tabTitle: string;
  @Input() previewName: string;
  tabLabel: string = 'Screening Review';
  tabIndex: number;
  checkedColumnss: Array<string>;
  screenCheckedColumns: Array<CheckBoxProperties> = [];
  screenColumnNames: Array<string> = [];
  displayCheckedColumns: Array<CheckBoxProperties> = [];
  _columnNames: Array<string> = [];
  screenForm!: FormGroup;

  constructor(
    private authService: AuthService,
    private userPreferenceService: UserPreferenceService,
    private rootFormGroup: FormGroupDirective
  ) {}

  ngOnInit(): void {
    this.loadCheckboxes();
    this.screenForm = this.rootFormGroup.control.get('screen') as FormGroup;
  }

  loadCheckboxes(): void {
    this.tabIndex = 2;
    if (this.screenCheckedColumns.length === 0) {
      Object.values(ScreeningFieldLabels).forEach((value) => {
        if (value !== 'Expedited' && value !== 'Derogatory Info') {
          this.screenColumnNames.push(value);
          this.screenCheckedColumns.push({ selected: true, label: value });
        }
      });
      if (!this.screenColumnNames.includes('Total Screenings')) {
        this.screenColumnNames.push('Total Screenings');
        this.screenCheckedColumns.push({
          selected: true,
          label: 'Total Screenings',
        });
      }
      if (!this.screenColumnNames.includes(AdditionalTableColumns.at(1)!)) {
        this.screenColumnNames.push(AdditionalTableColumns.at(1)!);
        this.screenCheckedColumns.push({
          selected: true,
          label: AdditionalTableColumns.at(1)!,
        });
      }
      this._columnNames = this.screenColumnNames;
    }
    this.displayCheckedColumns = this.screenCheckedColumns;
    this._columnNames = this.screenColumnNames;
    this.displayCheckedColumns = this.setDisplayCheckedColumns(
      this.screenCheckedColumns,
      this.setCheckedColumns(this.screenCheckedColumns)
    );
  }

  selectedColumns(selectedOption: MatCheckboxChange) {
    this.displayCheckedColumns.forEach((c) => {
      if (selectedOption.source.name === c.label) {
        selectedOption.checked ? (c.selected = true) : (c.selected = false);
      }
    });

    this.screenCheckboxes.controls.forEach((value) => {
      const newValue = value.value;
      if (newValue.label === selectedOption.source.name) {
        if (selectedOption.checked) {
          value.patchValue({ selected: true, label: newValue.label });
        } else {
          value.patchValue({ selected: false, label: newValue.label });
        }
      }
    });

    this.checkedColumnss = this.setCheckedColumns(this.displayCheckedColumns);
  }

  toggleAll(toggle: boolean) {
    this.screenCheckedColumns = [];
    this.checkedColumnss = [];

    if (toggle) {
      this.screenCheckboxes.controls.forEach((value, index) => {
        const newValue = value.value;
        this.screenCheckedColumns.push({
          selected: true,
          label: newValue.label,
        });
        value.patchValue({ selected: true, label: newValue.label });
        this.checkedColumnss.push(newValue.label);
      });
    } else {
      this.screenCheckboxes.controls.forEach((value, index) => {
        const newValue = value.value;
        this.screenCheckedColumns.push({
          selected: false,
          label: newValue.label,
        });
        value.patchValue({ selected: false, label: newValue.label });
      });

      this.checkedColumnss = [];
    }
    this.displayCheckedColumns = this.screenCheckedColumns;
  }

  get screenCheckboxes(): FormArray {
    return this.screenForm.get('screenCheckboxes') as FormArray;
  }

  setDisplayCheckedColumns(
    checkedArray: Array<CheckBoxProperties>,
    checkedArrayColumns: string[]
  ): Array<CheckBoxProperties> {
    let trueCheckedColumns: Array<CheckBoxProperties> = [];
    checkedArray.forEach((c) => {
      checkedArrayColumns.includes(c.label)
        ? trueCheckedColumns.push({
            selected: true,
            label: c.label,
          })
        : trueCheckedColumns.push({
            selected: false,
            label: c.label,
          });
    });

    return trueCheckedColumns;
  }

  setCheckedColumns(checkedArray: Array<CheckBoxProperties>): string[] {
    let trueCheckedColumns: string[] = [];
    checkedArray.forEach((c) => {
      if (c.selected) {
        trueCheckedColumns.push(c.label);
      }
    });
    return trueCheckedColumns;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.userPreferenceService.updated.subscribe((result) => {
      if (result) {
        this.userPreferenceService.updateCheckboxes.subscribe((checkit) => {
          if (this.tabLabel === checkit.viewName) {
            this.checkedColumnss = this.setCheckedColumns(checkit.checkboxes!);
            this.screenCheckedColumns = checkit.checkboxes!;
            this.displayCheckedColumns = this.setDisplayCheckedColumns(
              this.screenCheckedColumns,
              this.checkedColumnss
            );
            this.screenCheckboxes.controls.forEach((value) => {
              const newValue = value.value;
              checkit.checkboxes?.filter((x) => {
                if (x.label === newValue.label) {
                  value.patchValue({ selected: x.selected, label: x.label });
                }
              });
            });
          }
        });
      }
    });

    if (changes['tabTitle']) {
      if (changes['tabTitle'].currentValue !== null) {
        this.tabTitle = changes['tabTitle'].currentValue;
      }
    }
  }
}
