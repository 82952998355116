import { AfterViewInit, Component, Injector, Input } from '@angular/core';
import { BaseMatSelectComponent } from '@shared/controls';
import { FAR, FilterParams, PageableCollection } from '@shared/models';
import { FloatLabelType } from '@angular/material/form-field';
import { FarService } from '@shared/services';
import { Observable } from 'rxjs';
import { isArray } from 'lodash';

@Component({
  selector: 'app-select-far',
  templateUrl: './select-far.component.html',
  styleUrls: ['./select-far.component.scss'],
})
export class SelectFarComponent
  extends BaseMatSelectComponent<FAR>
  implements AfterViewInit
{
  @Input() floatLabel: FloatLabelType = 'auto';
  @Input() filterLabel: string = 'Search';
  @Input() excludeFvId: string = '';
  @Input() multiple: boolean;
  @Input() label: string = 'Select a FAR';
  @Input() restrictCurrentOrganization: boolean = true;

  baseFilterParams: FilterParams = new FilterParams({
    currentNetworkDomain: true,
    currentOrganization: this.restrictCurrentOrganization,
    excludeFvId: this.excludeFvId,
    sort: {
      active: 'createdDate',
      direction: 'desc',
    },
  });

  constructor(injector: Injector, private farService: FarService) {
    super(injector);
  }

  ngAfterViewInit(): void {
    this.baseFilterParams['currentOrganization'] =
      this.restrictCurrentOrganization;
    this.baseFilterParams['excludeFvId'] = this.excludeFvId;
    super.ngOnInit();

    this.ngModel?.valueChanges?.subscribe((change) => {
      if (change) {
        this.selectedItems = change;
        this.matSelect.selectionChange.emit();
      }
    });
  }

  updateSelection(far: FAR) {
    this.searchCtrl.setValue(far.eventTitle);
  }

  searchItems(params: FilterParams): Observable<PageableCollection<FAR>> {
    return this.farService.find(params);
  }

  getSelectedText(value: FAR | FAR[]) {
    if (isArray(value)) {
      const values = <FAR[]>value;
      const singleItem = `${values[0]?.eventTitle}`;
      const multipleItems = `${values.length} Options Selected`;
      return values.length > 1 ? multipleItems : singleItem;
    }

    return `${value?.eventTitle}`;
  }
}
