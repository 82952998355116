<mat-card appearance="outlined" class="activity d-flex flex-column">
  <mat-toolbar
    class="
      row
      px-0
      justify-content-between
      paginator-recolor
      screening-card-header
    "
  >
    <div class="col pl-0 d-flex align-items-center">
      <mat-icon class="mr-1 ml-2" svgIcon="timeline-clock-outline"></mat-icon
      >Screening Events
    </div>
    <div class="col pr-0">
      <mat-paginator
        #paginator
        [length]="fv.allScreenings!.length"
        [hidePageSize]="true"
        [pageSize]="2"
        (page)="pageEvent = $event"
      >
      </mat-paginator>
    </div>
  </mat-toolbar>
  <mat-card-content class="fill-height overflow-auto h-100 p-0">
    <div class="d-flex pb-2 card-container">
      <mat-card
        *ngFor="
          let item of fv.allScreenings
            | slice: paginator.pageIndex! * 2:(paginator.pageIndex! + 1) * 2
        "
        class="mt-2 mx-1 screening-card"
        [style]="fv.allScreenings?.length === 1 ? 'width: 80%' : 'width: 250px'"
      >
        <mat-card-header
          class="d-flex p-2 justify-content-between screening-card-header"
          [class]="item | screeningClassList"
        >
          <h6 class="d-flex flex-row align-items-center m-0 spacer">
            <div class="d-flex gap-1 align-items-center">
              <mat-icon [svgIcon]="item | screeningIcon"></mat-icon>
              {{ item | screeningLabel: "result" }}
            </div>
          </h6>
          <button
            style="margin-right: -8px; margin-top: -8px"
            mat-icon-button
            [matMenuTriggerFor]="actionMenu"
            matTooltip="Actions Menu"
            matTooltipPosition="before"
          >
            <mat-icon svgIcon="dots-vertical"></mat-icon>
          </button>
          <mat-menu #actionMenu>
            <button mat-menu-item (click)="viewScreening(item)">
              <mat-icon svgIcon="eye"></mat-icon>
              <span class="button-group-text">View</span>
            </button>
          </mat-menu>
        </mat-card-header>

        <div class="d-flex justify-content-center align-items-center px-2 mt-2">
          <screening-transition
            class="pr-3"
            [screeningId]="item.id!"
          ></screening-transition>
        </div>

        <mat-card-content class="spacer">
          <form #form="ngForm" *ngIf="item?.result; else noResponse">
            <section>
              <div class="d-flex py-3">
                <dl>
                  <ng-container
                    *ngIf="ScreeningUtils.isLegacyResult(item.result)"
                  >
                    <dt>Completed On</dt>
                    <dd>
                      {{ item | screeningDate | date: ScreeningDateFormat }}
                    </dd>
                  </ng-container>
                  <dt>Notes</dt>
                  <dd>{{ item.notes | default }}</dd>
                </dl>
              </div>
            </section>
          </form>
          <ng-template #noResponse>
            <div
              style="text-align: center"
              *ngIf="
                fv.screeningEligible &&
                fv.latestScreening!.status === ScreeningStatus.New
              "
            >
              Awaiting Screening Submission
            </div>
            <div
              style="text-align: center"
              *ngIf="
                fv.screeningEligible &&
                fv.latestScreening!.status === ScreeningStatus.InProcess
              "
            >
              Awaiting a Response
            </div>
            <div style="text-align: center" *ngIf="!fv.screeningEligible">
              Missing Screening Criteria
            </div>
          </ng-template>
        </mat-card-content>
        <mat-divider class="w-100"></mat-divider>
        <mat-card-footer>
          <div class="d-flex gap-1 flex-fill align-items-center ml-1 row">
            <mat-icon
              class="network-icon"
              color="primary"
              [svgIcon]="
                item.networkDomain === 'U' ? 'alpha-u-box' : 'alpha-j-box'
              "
              [matTooltip]="'Network ' + item.networkDomain"
            ></mat-icon>
            <span
              *ngIf="ScreeningUtils.isLegacyResult(item.result)"
              class="font-size-12"
              >{{ getLegacyMessage() }}</span
            >
          </div>
        </mat-card-footer>
      </mat-card>
    </div>
  </mat-card-content>
</mat-card>
