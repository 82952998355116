import { Directive, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

export interface MimeTypeDef {
  match: string | RegExp;
  icon: string;
  color?: string;
}

export const mimeTypeIcons: MimeTypeDef[] = [
  { match: 'application/pdf', icon: 'file-pdf-box', color: '#EB1E63' },
  {
    match:
      /(msword|ms\-word|officedocument\.wordprocessingml|opendocument\.text)/gi,
    icon: 'microsoft-word',
    color: '#2979FF',
  },
  {
    match: /(excel|opendocument\.spreadsheet|officedocument\.spreadsheetml)/gi,
    icon: 'microsoft-excel',
    color: '#24BD73',
  },
  {
    match: /(powerpoint|officedocument\.presentationml)$/gi,
    icon: 'microsoft-powerpoint',
    color: '#FFC107',
  },
  {
    match: /^image\//gi,
    icon: 'image',
    color: '#24BD73',
  },
  {
    match: /^video\//gi,
    icon: 'filmstrip',
    color: '#FFC107',
  },
  {
    match: /.*/gi,
    icon: 'file',
  },
];

const mimeTypeCache: { [type: string]: MimeTypeDef } = {};

@Directive({
  selector: 'mat-icon[mimeType]',
})
export class MimeTypeIconDirective {
  @Input()
  set mimeType(val: string | undefined) {
    const def: MimeTypeDef = this.getDef(val);
    if (def) this._matIcon.svgIcon = def.icon;
    this._matIcon._elementRef.nativeElement.style.color = def.color || '';
  }

  getDef(val: string = ''): MimeTypeDef {
    val = val || '';
    if (!mimeTypeCache[val]) {
      const type = mimeTypeIcons.find((type) => val.match(type.match));
      mimeTypeCache[val] = type as MimeTypeDef;
    }
    return mimeTypeCache[val];
  }

  constructor(private readonly _matIcon: MatIcon) {}
}
