import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/shared/helpers';
import { Roles } from '@shared/models';
import { UserPreferencesComponent } from './user-preferences.component';

const routes: Routes = [
  {
    path: 'user-preferences',
    canActivate: [AuthGuard],
    canDeactivate: [
      (component: UserPreferencesComponent) =>
        component.canDeactivateWithUnsavedChanges(),
    ],
    component: UserPreferencesComponent,
    data: { roles: Roles },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserPreferencesRoutingModule {}
