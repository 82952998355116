<div
  class="far-card__event pr-2 component far-events"
  style="padding-left: 0.4rem"
>
  <dl class="small justify-content-start">
    <div class="row" *ngIf="showLocation">
      <mat-icon
        class="mr-2"
        [matTooltip]="'Location Name'"
        color="primary"
        svgIcon="office-building-marker"
        style="width: 20px; height: 20px"
      ></mat-icon>
      <dt
        *ngIf="primaryLocation?.locationName"
        [matTooltip]="primaryLocation?.locationName | titlecase | default"
      >
        {{
          primaryLocation?.locationName
            | titlecase
            | shorten: contentTrimLength:"..."
            | default
        }}
      </dt>
      <dt
        *ngIf="!primaryLocation || !primaryLocation.locationName"
        [matTooltip]="primaryLocation?.locationName | titlecase | default"
      >
        N/A
      </dt>
    </div>
    <div class="row" *ngIf="showLocation">
      <mat-icon
        class="mr-2"
        [matTooltip]="'City and State'"
        svgIcon="map-marker"
        color="primary"
        style="width: 20px; height: 20px"
      ></mat-icon>
      <dt
        *ngIf="primaryLocation"
        [matTooltip]="
          buildCityStateDisplay(primaryLocation?.city, primaryLocation?.state)
            | default
        "
      >
        {{
          buildCityStateDisplay(primaryLocation?.city, primaryLocation?.state)
            | shorten: contentTrimLength:"..."
            | default
        }}
      </dt>
      <dt *ngIf="!primaryLocation">N/A</dt>
    </div>
    <div class="row">
      <mat-icon
        class="mr-2"
        [matTooltip]="'Event Date'"
        color="primary"
        svgIcon="calendar"
        style="width: 20px; height: 20px"
      ></mat-icon>
      <dt class="align-items-baseline d-flex">
        <span
          *ngIf="primaryLocation as item"
          matTooltip="{{ item | farLocation: 'visitDate' | default }}"
        >
          <ng-container *ngIf="item | farLocation: 'visitDate' as visitDate">
            {{ visitDate | default }}
          </ng-container>
        </span>
        <span *ngIf="!primaryLocation">N/A</span>
      </dt>
    </div>
  </dl>
  <div class="d-flex 2-col"></div>
</div>
