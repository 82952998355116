<dd *ngIf="phones[0] as phone">
  <button
    style="font-size: 12px"
    mat-button
    *ngIf="phones && phones.length > 0"
    matTooltip="View Phone Numbers"
    [matMenuTriggerFor]="phoneMenu"
  >
    <mat-icon
      [svgIcon]="'star'"
      class="d-flex align-self-center"
      *ngIf="phone.primaryPhone"
    ></mat-icon>
    {{ phone | phoneNumber: "number" | shorten: 20:"..." | default }}
  </button>
</dd>
<mat-menu #phoneMenu class="pt-0">
  <div mat-subheader class="d-flex justify-content-between mb-1 mt-0">
    <b style="font-size: 14px">PHONE NUMBERS</b>
  </div>
  <mat-divider></mat-divider>
  <mat-nav-list class="py-0" style="min-width: 100%" *ngIf="editable">
    <mat-list-item
      *ngFor="let phone of phones; last as lastPhone; index as i"
      [matTooltip]="phone | phoneNumber | default"
      [appPhoneDialog]="phone"
      [lockPrimary]="lockPrimary(phone)"
      [fvId]="fvId"
      data-cy="edit-phone-item"
    >
      <div class="d-flex justify-content-between text-truncate">
        <mat-icon
          [svgIcon]="'star'"
          class="d-flex align-self-center md-12"
          *ngIf="phone.primaryPhone"
        ></mat-icon
        >{{ phone | phoneNumber | default }}
        <span class="text-truncate"> </span>
        <mat-icon class="ml-3 wh-20" svgIcon="square-edit-outline"></mat-icon>
      </div>
      <mat-divider *ngIf="!lastPhone"></mat-divider>
    </mat-list-item>
  </mat-nav-list>
  <!--Read Only-->
  <mat-list style="min-width: 100%" *ngIf="!editable">
    <mat-list-item
      *ngFor="let phone of phones; last as lastPhone; index as i"
      [matTooltip]="phone | phoneNumber | default"
    >
      <div
        class="d-flex justify-content-between text-truncate"
        style="cursor: default"
      >
        <mat-icon
          [svgIcon]="'star'"
          class="d-flex align-self-center md-12"
          *ngIf="phone.primaryPhone"
        ></mat-icon
        >{{ phone | phoneNumber | default }}
        <span class="text-truncate"> </span>
      </div>
      <mat-divider *ngIf="!lastPhone"></mat-divider>
    </mat-list-item>
  </mat-list>
</mat-menu>
<dd *ngIf="phones.length === 0">
  <span matTooltip="N/A">
    {{ phones | default }}
  </span>
</dd>
