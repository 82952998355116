import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/shared/helpers';
import { ScreeningComponent } from './screening.component';
import { ScreeningRoles } from '@shared/models/role-permissions';

const routes: Routes = [
  {
    path: 'screening',
    title: 'Screening Review',
    component: ScreeningComponent,
    canActivate: [AuthGuard],
    data: { roles: ScreeningRoles },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ScreeningRoutingModule {}
