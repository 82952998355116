<app-screening-graph [hidden]="!showScreeningGraph"></app-screening-graph>
<app-organization-graph
  *appIfRoles="[
    Role.sv_admin,
    Role.sv_help_desk,
    Role.sv_vetter,
    Role.sv_analyst,
    Role.sv_org_admin
  ]"
  [hidden]="!showOrgGraph"
></app-organization-graph>
<app-line-graph [hidden]="!showStatusGraph"></app-line-graph>
<app-derogratory-graph
  *highEnvFeatures="true"
  [hidden]="!showDerogGraph"
></app-derogratory-graph>
