<ng-container *ngIf="data">
  <h3 mat-dialog-title class="pb-0">Vehicle Information</h3>
  <mat-dialog-content>
    <form ngForm #vehicleForm="ngForm" data-cy="physical-info-form">
      <div class="vehicle-container mt-3">
        <mat-form-field [appearance]="appearance">
          <mat-label>Driver's License Number</mat-label>
          <input
            id="drivers-license-number-input"
            aria-label="driver license number"
            placeholder="Enter driver's license number"
            matInput
            name="driversLicenseId"
            [(ngModel)]="data.driversLicenseId"
            (ngModelChange)="data.driversLicenseId = $event.toUpperCase()"
            maxlength="20"
          />
        </mat-form-field>
        <mat-form-field [appearance]="appearance">
          <mat-label>License Plate</mat-label>
          <input
            matInput
            [(ngModel)]="data.vehicleLicensePlate"
            name="vehicleLicensePlate"
            maxlength="20"
            id="license-plate-input"
          />
        </mat-form-field>
        <mat-form-field [appearance]="appearance">
          <mat-label>Vehicle Make</mat-label>
          <input
            matInput
            [(ngModel)]="data.vehicleMake"
            name="vehicleMake"
            maxlength="20"
            id="vehicle-make-input"
          />
        </mat-form-field>
        <mat-form-field [appearance]="appearance">
          <mat-label>Vehicle Model</mat-label>
          <input
            matInput
            [(ngModel)]="data.vehicleModel"
            name="vehicleModel"
            maxlength="20"
            id="vehicle-model-input"
          />
        </mat-form-field>
      </div>
      <mat-divider></mat-divider>
      <h5 class="mt-3 pb-0" style="color: black">Identifying Details</h5>
      <div class="identification-container mt-3">
        <mat-form-field [appearance]="appearance">
          <mat-label>Hair Color</mat-label>
          <input
            id="hair-color-input"
            matInput
            name="hairColor"
            maxlength="10"
            placeholder="Hair Color"
            aria-label="hair color"
            [(ngModel)]="data.hairColor"
          />
          <mat-error
            >Hair Color must be limited to 10 alphabetic characters.</mat-error
          >
        </mat-form-field>
        <mat-form-field [appearance]="appearance">
          <mat-label>Eye Color</mat-label>
          <input
            id="eye-color-input"
            matInput
            name="eyeColor"
            placeholder="Enter Eye Color"
            aria-label="eye color"
            [(ngModel)]="data.eyeColor"
            maxlength="10"
          />
          <mat-error
            >Eye Color must be limited to 10 alphabetic characters.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="marks-container">
        <mat-form-field [appearance]="appearance">
          <mat-label>Identifying Marks</mat-label>
          <textarea
            rows="3"
            id="identifying-marks-control"
            matInput
            name="identifyingMarks"
            placeholder="Enter Identifying Marks"
            aria-label="foreign-visitor-identifying-marks"
            [(ngModel)]="data.identifyingMarks"
            maxlength="1000"
          >
          </textarea>
          <mat-error>Max Characters 1000</mat-error>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions
    data-cy="physical-info-actions"
    class="justify-content-end"
  >
    <button
      mat-button
      color="primary"
      data-cy="cancel-physical-info-button"
      mat-dialog-close
      id="cancel-physical-info-button"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      data-cy="submit-physical-info-button"
      (click)="saveChanges(data)"
      id="submit-physical-info-button"
    >
      Submit
    </button>
  </mat-dialog-actions>
</ng-container>
