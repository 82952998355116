import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FilterParams, Group } from '@app/shared/models';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import {
  AlertService,
  AppConfigService,
  GroupService,
  GroupSortField,
  GroupSortLabels,
  ScreeningService,
} from '@shared/services';
import { TooltipPosition } from '@angular/material/tooltip';
import { take, takeUntil } from 'rxjs/operators';
import { ColumnChooserEventObservable } from '@app/shared/components/column-chooser/column-chooser-observable.service';
import {
  ColumnChooserComponent,
  ConfirmDialogComponent,
} from '@app/shared/components';
import { Subject } from 'rxjs';
import { AuthService } from '@app/auth/auth.service';
import {
  FVRescreenRoles,
  FVViewRoles,
} from '@app/shared/models/role-permissions';
import { ActivatedRoute, Router } from '@angular/router';
import { AddFvsDialogComponent } from '@app/foreign-access-requests/farview/far-view-associated-fvs/add-fvs-dialog/add-fvs-dialog.component';
import { UserPreferenceService } from '@app/shared/services/user-preference.service';

export const GroupTableColumns: string[] = [
  GroupSortField.NAME,
  GroupSortField.DESCRIPTION,
  GroupSortField.CREATED_DATE,
  GroupSortField.FN_COUNT,
  GroupSortField.TYPE,
  GroupSortField.NETWORK,
  'actions',
];

export const GroupTableName = 'groupTable';

@Component({
  selector: 'app-group-collection-table',
  templateUrl: './group-collection-table.component.html',
  styleUrls: ['./group-collection-table.component.scss'],
})
export class GroupCollectionTableComponent implements OnInit, OnDestroy {
  @Input() groups: Group[] = [];
  @Input() filterParams: FilterParams;
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  position: TooltipPosition = 'right';
  canViewFN: boolean = false;
  user = inject(AuthService);

  private ngUnsubscribe = new Subject<void>();

  GroupSortField = GroupSortField;
  GroupSortLabels = GroupSortLabels;
  groupColumns = GroupTableColumns;
  FVRescreenRoles = FVRescreenRoles;

  groupColumnsDisplay: { [key: string]: boolean } = this.groupColumns.reduce(
    (val, col) => ({ [col]: true, ...val }),
    {} as { [key: string]: boolean }
  );

  constructor(
    public dialog: MatDialog,
    public groupService: GroupService,
    public alert: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private _ColumnChooserEventObservable: ColumnChooserEventObservable,
    private config: AppConfigService,
    private screening: ScreeningService,
    private auth: AuthService,
    private userPreferenceService: UserPreferenceService
  ) {}

  ngOnInit(): void {
    this.userPreferenceService.userPreferenceDisplaySubject$.subscribe((x) => {
      if (x.hasPreference && x.tableName === GroupTableName) {
        this.groupColumns = x.columnNames!;
        this.groupColumnsDisplay = x.selectedColumns!;
      }
    });
    this._ColumnChooserEventObservable.ColumnChooserObservable$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((event) => {
      if (event.tableName === GroupTableName) {
        this.groupColumnsDisplay = ColumnChooserComponent.updateTableDisplay(
          event,
          this.groupColumns,
          this.groupColumnsDisplay
        );
      }
    });

    const userRoles = this.user.getRoles();
    let roleFound = userRoles.find((r) => FVViewRoles.includes(r)) || false;
    if (roleFound) this.canViewFN = true;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onEdited(result: Group) {
    const group = this.groups.find((g) => g.id === result.id);

    if (group) {
      Object.assign(group, result);
    }
  }

  onSortChange(sort: Sort) {
    this.filterParams.sortBy = sort.active;
    this.filterParams.direction = sort.direction.toUpperCase();
    this.filterParamsChange.emit(this.filterParams);
  }

  addFnToGroup(group?: Group) {
    let dialogRef = this.dialog.open(AddFvsDialogComponent, {
      data: group,
      width: '600px',
    });

    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((data) => {
        let ok = dialogRef.componentInstance.ok;
        if (ok) this.filterParamsChange.emit(this.filterParams);
      });
  }

  removeFnFromGroup(group?: Group) {
    let temp: any = group;
    temp.remove = true;
    let dialogRef = this.dialog.open(AddFvsDialogComponent, {
      data: group,
      width: '600px',
    });

    dialogRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe((data) => {
        let ok = dialogRef.componentInstance.ok;
        if (ok) this.filterParamsChange.emit(this.filterParams);
      });
  }

  deleteGroup(id?: string) {
    this.alert
      .confirmDelete({
        title: `Confirm Delete Group`,
        message: `Are you sure you want to delete this group?`,
        performAction: () => this.groupService.delete(id),
      })
      .pipe(take(1))
      .subscribe(() => {
        this.filterParamsChange.emit(this.filterParams);
      });
  }

  navigateToFNs(
    count: any,
    destination: string,
    queryField?: string,
    queryParam?: string | boolean
  ) {
    if (count) {
      let filter: any = {};

      if (this.router.url === '/') destination = 'fvs';

      if (queryField) {
        filter[queryField] = [queryParam];
        this.router.navigate([destination], {
          relativeTo: this.route,
          queryParams: filter,
        });
      } else {
        this.router.navigate([destination], {
          relativeTo: this.route,
        });
      }
    }
  }

  openConfirmationDialog(
    groupId: string
  ): MatDialogRef<ConfirmDialogComponent> {
    let data = {
      icon: 'alert-octagon',
      color: 'warn',
      title: `ARE YOU SURE?`,
      message: `This will create a new screening request for all completed Foreign Nationals that are part of this group.`,
      performAction: () => this.rescreen(groupId),
      confirmText: 'Confirm',
    };

    return this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data,
    });
  }

  rescreen(groupId: string) {
    this.screening
      .rescreenFnByGroup(groupId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.alert.successAlert(
          res + ' Foreign National(s) have been submitted for rescreen'
        );
        this.filterParamsChange.emit(this.filterParams);
      });
  }
}
