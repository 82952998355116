import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ForeignVisitor } from '@app/shared/models';
import { cloneDeep } from 'lodash';
import { NatlIdDialogComponent } from '../fvidentification/natl-id-dialog/natl-id-dialog/natl-id-dialog.component';

@Component({
  selector: 'app-fvview-national-id',
  templateUrl: './fvview-national-id.component.html',
  styleUrls: ['./fvview-national-id.component.scss'],
})
export class FvviewNationalIdComponent {
  @Input() deletable?: boolean = false;
  @Input() editable?: boolean = true;
  @Input() hasDocument: boolean = false;
  @Input() fv: ForeignVisitor;
  @Input() additionalDocumentsScreeningEligibility: boolean = false;

  constructor(public dialog: MatDialog) {}

  openNatlIdDialog(id?: string) {
    const dialogConfig: MatDialogConfig = {
      data: { fv: cloneDeep(this.fv), id: id },
      width: '800px',
    };
    this.dialog.open(NatlIdDialogComponent, dialogConfig);
  }
}
