import {
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { User } from '@shared/models';
import { AlertService, AppConfigValue, UserService } from '@shared/services';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Directive({
  selector: '[appEditUserContactInfo]',
})
export class EditUserContactInfoDirective {
  @Output() appContactInfoChanged = new EventEmitter<User>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    const dialog = EditContactInfoComponent.openDialog(this.dialog);
    dialog.afterClosed().subscribe((result) => {
      if (result) return this.appContactInfoChanged.emit(result);
    });
  }
}

@Component({
  selector: 'app-edit-contact-info',
  templateUrl: './edit-contact-info.component.html',
  styleUrls: ['./edit-contact-info.component.scss'],
})
export class EditContactInfoComponent {
  profileInfo: User = {};
  busy: boolean = false;

  @AppConfigValue('limitedUserAdmin')
  limitedUserAdmin = false;

  constructor(
    public dialogRef: MatDialogRef<EditContactInfoComponent>,
    private auth: AuthService,
    private userService: UserService,
    private alert: AlertService
  ) {
    const user = this.auth.getUser();
    if (user) {
      this.profileInfo = {
        phone: user.phone,
        mobilePhone: user.mobilePhone,
      };
    }
  }

  async submit() {
    this.busy = true;
    this.userService.updateProfile(this.profileInfo).subscribe(
      (result) => {
        this.auth.userSubject$.next(result);
        this.alert.successAlert(`Contact Info Updated!`);
        this.dialogRef.close(result);
      },
      (err) => (this.busy = false)
    );
  }

  static openDialog(dialog: MatDialog): MatDialogRef<EditContactInfoComponent> {
    return dialog.open<EditContactInfoComponent>(EditContactInfoComponent, {
      width: '530px',
    });
  }
}
