<h3 mat-dialog-title>Edit Event Description</h3>
<mat-dialog-content>
  <mat-form-field appearance="outline" class="mt-3 w-100">
    <mat-label>Event Description</mat-label>
    <textarea
      id="far-purpose-input"
      #description="ngModel"
      placeholder="Please describe the purpose of the visit"
      matInput
      rows="5"
      [(ngModel)]="far.description"
      name="description"
      maxlength="2000"
    ></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-button
    color="primary"
    id="updateFarDetailsCancel"
    mat-dialog-close
  >
    Cancel
  </button>
  <button
    id="update-far-description"
    [disabled]="busy"
    mat-raised-button
    color="primary"
    (click)="onSubmit()"
  >
    <span *ngIf="!busy; else loading">Submit</span>
  </button>
</mat-dialog-actions>

<ng-template #loading>
  <mat-spinner diameter="35" color="primary"></mat-spinner>
</ng-template>
