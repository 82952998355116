import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/shared/helpers';
import { OrganizationsComponent } from './organizations/organizations.component';
import { AdminViewRoles } from '@app/shared/models/role-permissions';

const routes: Routes = [
  {
    path: 'organizations',
    title: 'Organizations',
    component: OrganizationsComponent,
    canActivate: [AuthGuard],
    data: { roles: AdminViewRoles },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrganizationsRoutingModule {}
