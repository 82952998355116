<div class="my-2">
  <mat-table
    class="header-primary-color"
    [dataSource]="data"
    matSort
    (matSortChange)="onSortChange($event)"
  >
    <ng-container [matColumnDef]="ApprovalSortField.NAME">
      <mat-header-cell
        *matHeaderCellDef
        class="justify-content-center"
        [hidden]="!approvalColumnsDisplay[ApprovalSortField.NAME]"
        mat-sort-header
        >{{ ApprovalSortFieldLabels[ApprovalSortField.NAME] }}</mat-header-cell
      >
      <mat-cell
        class="justify-content-center"
        [hidden]="!approvalColumnsDisplay[ApprovalSortField.NAME]"
        *matCellDef="let row"
      >
        {{ row.systemUser | fullname: "surnameFirst" }}</mat-cell
      >
    </ng-container>
    <ng-container [matColumnDef]="ApprovalSortField.EMAIL">
      <mat-header-cell
        *matHeaderCellDef
        class="justify-content-center"
        [hidden]="!approvalColumnsDisplay[ApprovalSortField.EMAIL]"
        mat-sort-header
        >{{ ApprovalSortFieldLabels[ApprovalSortField.EMAIL] }}</mat-header-cell
      >
      <mat-cell
        class="justify-content-center"
        [hidden]="!approvalColumnsDisplay[ApprovalSortField.EMAIL]"
        *matCellDef="let row"
      >
        {{ row.systemUser.email }}</mat-cell
      >
    </ng-container>
    <ng-container [matColumnDef]="ApprovalSortField.ORGANIZATION">
      <mat-header-cell
        *matHeaderCellDef
        class="justify-content-center"
        [hidden]="!approvalColumnsDisplay[ApprovalSortField.ORGANIZATION]"
        mat-sort-header
        >{{
          ApprovalSortFieldLabels[ApprovalSortField.ORGANIZATION]
        }}</mat-header-cell
      >
      <mat-cell
        class="justify-content-center"
        [hidden]="!approvalColumnsDisplay[ApprovalSortField.ORGANIZATION]"
        *matCellDef="let row"
      >
        {{ row.orgId.shortName }}</mat-cell
      >
    </ng-container>
    <ng-container [matColumnDef]="ApprovalSortField.APPROVER">
      <mat-header-cell
        *matHeaderCellDef
        class="justify-content-center"
        [hidden]="!approvalColumnsDisplay[ApprovalSortField.APPROVER]"
        mat-sort-header
        >{{
          ApprovalSortFieldLabels[ApprovalSortField.APPROVER]
        }}</mat-header-cell
      >
      <mat-cell
        class="justify-content-center"
        [hidden]="!approvalColumnsDisplay[ApprovalSortField.APPROVER]"
        *matCellDef="let row"
        >{{ row.contact | fullname: "surnameFirst" | default }}</mat-cell
      >
    </ng-container>
    <ng-container [matColumnDef]="ApprovalSortField.APPROVAL_DATE">
      <mat-header-cell
        *matHeaderCellDef
        class="justify-content-center"
        [hidden]="!approvalColumnsDisplay[ApprovalSortField.APPROVAL_DATE]"
        mat-sort-header
        >{{
          ApprovalSortFieldLabels[ApprovalSortField.APPROVAL_DATE]
        }}</mat-header-cell
      >
      <mat-cell
        class="justify-content-center"
        [hidden]="!approvalColumnsDisplay[ApprovalSortField.APPROVAL_DATE]"
        *matCellDef="let row"
      >
        {{ row.approvalDate }}</mat-cell
      >
    </ng-container>
    <ng-container [matColumnDef]="ApprovalSortField.EXPIRATION_DATE">
      <mat-header-cell
        *matHeaderCellDef
        class="justify-content-center"
        [hidden]="!approvalColumnsDisplay[ApprovalSortField.EXPIRATION_DATE]"
        mat-sort-header
        >{{
          ApprovalSortFieldLabels[ApprovalSortField.EXPIRATION_DATE]
        }}</mat-header-cell
      >
      <mat-cell
        class="justify-content-center"
        [hidden]="!approvalColumnsDisplay[ApprovalSortField.EXPIRATION_DATE]"
        *matCellDef="let row"
      >
        <span *ngIf="!row.endedDate">
          {{ row.expirationDate }}
          <span
            class="pl-1"
            [class.text-danger]="parseInt(row.expirationDate | daysUntil) <= 30"
            >({{ row.expirationDate | daysUntil }} days)</span
          >
        </span>
        <span
          class="text-danger d-flex align-items-center"
          *ngIf="row.endedDate"
          >Inactive
          <mat-icon
            class="pl-1"
            color="primary"
            svgIcon="information"
            matTooltip="{{ row.endedComment }} ({{ row.endedDate }})"
          ></mat-icon
        ></span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell
        class="justify-content-center"
        *matHeaderCellDef
        [hidden]="!approvalColumnsDisplay['actions']"
        >Actions</mat-header-cell
      >
      <mat-cell
        class="justify-content-center"
        *matCellDef="let row"
        [hidden]="!approvalColumnsDisplay['actions']"
      >
        <button
          mat-icon-button
          type="button"
          #actionTrigger="matMenuTrigger"
          [matMenuTriggerFor]="actionMenu"
        >
          <mat-icon matTooltip="Click For Actions">more_vert</mat-icon>
        </button>
        <mat-menu #actionMenu="matMenu" data-cy="account-menu">
          <button
            *ngIf="!row.endedDate"
            mat-menu-item
            appAddApproval
            [user]="row.systemUser"
            (approvalSubmit)="extendApproval($event)"
            data-cy="approval-extend-button"
            id="approval-extend-button"
            aria-label="Extend Approval"
          >
            <mat-icon svgIcon="account-check-outline"></mat-icon> Extend
            Approval
          </button>
          <button
            mat-menu-item
            appAddApproval
            [approval]="row"
            id="approval-view-button"
            data-cy="approval-view-button"
            aria-label="View Approval"
          >
            <mat-icon svgIcon="account-eye-outline"></mat-icon> View Approval
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <!-- Header and Row Declarations -->
    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns"></mat-row>
  </mat-table>
</div>
