import { BaseModel } from './base.model';

export interface ContactPhoneNumber extends BaseModel {
  type?: string;
  primaryPhone?: boolean;
  countryCode?: string;
  number?: string;
  extension?: string;
}

export enum PhoneType {
  Home = 'Home',
  Work = 'Work',
  Mobile = 'Mobile',
  HomeFax = 'Home Fax',
  WorkFax = 'Work Fax',
  Pager = 'Pager',
  Other = 'Other',
}

export const phoneIcons: { [key: string]: string } = {
  [PhoneType.Home]: 'home',
  [PhoneType.Work]: 'deskphone',
  [PhoneType.Mobile]: 'cellphone',
  [PhoneType.HomeFax]: 'fax',
  [PhoneType.WorkFax]: 'fax',
  [PhoneType.Pager]: 'message-alert',
  [PhoneType.Other]: 'phone-classic',
};
