import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ForeignVisitor } from '@app/shared/models/foreign-visitor.model';
import { FilterParams } from '@app/shared/models';
import { Params } from '@angular/router';
import { tap } from 'rxjs/operators';
import { CrudService, FvService } from '@app/shared/services';
import { Repository } from '@app/shared/services/repository.service';
import { FvAdvancedSearch } from '@app/foreign-visitors/dashboard/fv-advanced-search';
import { getArrayKeys } from '@shared/components/advanced-search/filter-config';

@Component({
  selector: 'app-fv-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [Repository, { provide: CrudService, useClass: FvService }],
})
export class DashboardComponent implements OnInit, AfterContentChecked {
  filterParams = this.repo.defaultFilterParams;
  fvs$ = this.repo.collection$.pipe(
    tap(() => {
      this.loading = false;
    })
  );

  loading = false;

  constructor(
    private repo: Repository<ForeignVisitor>,
    private changeDetect: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    Object.assign(this.filterParams, this.queryParamsSnapshot);
    this.onFilterParamsChange(this.filterParams);
  }

  ngAfterContentChecked(): void {
    this.changeDetect.detectChanges();
  }

  get queryParamsSnapshot(): Params {
    return this.repo.getQueryParamsSnapshot(...getArrayKeys(FvAdvancedSearch));
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.navigateWithParams(filterParams);
  }
}
