import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Crud, CrudService } from './crud.service';
import { AsyncJob } from '../models';

@Injectable({
  providedIn: 'root',
})
@Crud({
  apiUrl: `${environment.apiUrl}/async`,
  hasIdPathUpdate: true,
  entity: 'Async',
})
export class AsyncService extends CrudService<AsyncJob> {
  constructor(http: HttpClient) {
    super(http);
  }

  markAsRead(ids: string[]) {
    return this.http.put<void>(this.apiUrl, {
      ids: ids,
      read: true,
    });
  }
}
