import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { Subject } from 'rxjs';

export type CollectionView = 'card' | 'table' | string;
@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ViewComponent {
  public toggleSubject = new Subject<string>();
  @Input() view: CollectionView = 'table';
  @Output() viewChange = new EventEmitter<CollectionView>();

  constructor() {}

  onToggleChange(event: MatButtonToggleChange) {
    const value: CollectionView = event.value;
    this.viewChange.emit(value);
  }
}
