import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpContextToken,
} from '@angular/common/http';
import { from, Observable } from 'rxjs';

import { AuthService } from '@app/auth/auth.service';
import { switchMap } from 'rxjs/operators';
import { AppConfigService } from '@shared/services/app-config.services';
import { Role } from '@shared/models';
import { environment } from '@environments/environment';
import { UserService } from '@shared/services';

export const UNFILTERED_ROLES = new HttpContextToken<boolean>(() => false);

@Injectable({
  providedIn: 'root',
})
export class JwtInterceptor implements HttpInterceptor {
  devUserRole?: Role;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private config: AppConfigService
  ) {
    this.devUserRole = config.get('devUserRole');
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!request.url.includes(environment.apiUrl)) return next.handle(request);

    const unfilteredRoles = request.context.get(UNFILTERED_ROLES);

    const jwt$ = from(this.authService.getToken());
    return jwt$.pipe(
      switchMap((token) => {
        if (!token?.length) return next.handle(request);

        const headers: { [name: string]: string } = {
          Authorization: token,
        };

        if (this.userService.filteredRoles?.length && !unfilteredRoles) {
          headers.Role = this.userService.filteredRoles.join(',');
        } else if (this.devUserRole) {
          headers.Role = this.devUserRole;
        }

        request = request.clone({
          setHeaders: headers,
        });
        return next.handle(request);
      })
    );
  }
}
