<div
  class="row panel-banner p-2 mx-0 my-2 justify-content-between cursor-pointer"
  id="toggleScreeningGraphs"
  (click)="display = !display"
>
  <div class="align-items-center d-flex">
    <mat-icon class="mr-2" svgIcon="chart-bar"></mat-icon> Derogatory Metrics
  </div>
  <mat-icon svgIcon="chevron-up" *ngIf="display"></mat-icon>
  <mat-icon svgIcon="chevron-down" *ngIf="!display"></mat-icon>
</div>
<mat-card class="mb-2" *ngIf="display">
  <div class="d-flex derogatory-results-picker">
    <mat-form-field appearance="outline">
      <mat-label>Select Dates</mat-label>
      <mat-date-range-input [rangePicker]="derogatoryDatePicker" [max]="today">
        <input
          id="derogatoryDateStart"
          name="derogatoryDateStart"
          matStartDate
          [(ngModel)]="startDate"
          placeholder="MM-DD-YYYY"
        />
        <input
          id="derogatoryDateEnd"
          name="derogatoryDateEnd"
          matEndDate
          [(ngModel)]="endDate"
          placeholder="MM-DD-YYYY"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="derogatoryDatePicker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #derogatoryDatePicker></mat-date-range-picker>
    </mat-form-field>
    <button
      mat-mini-fab
      class="mx-1 mt-1"
      color="accent"
      style="border-radius: 5px"
      matTooltip="Search"
      (click)="getScreeningResultsMetrics()"
    >
      <mat-icon class="text-black">search</mat-icon>
    </button>
    <div>
      <mat-button-toggle-group
        name="datepickerType"
        aria-label="Datepicker Unit"
        [(ngModel)]="datepickerUnit"
        (ngModelChange)="dateRangeExceeded = false"
      >
        <mat-button-toggle value="DAY">Day</mat-button-toggle>
        <mat-button-toggle value="WEEK">Week</mat-button-toggle>
        <mat-button-toggle value="MONTH">Month</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="ml-2" style="margin-top: 0.6rem">
      <mat-icon
        color="primary"
        svgIcon="information"
        style="height: 30px; width: 30px"
        matTooltip="Click on Legend entry to toggle visibility."
      ></mat-icon>
    </div>
  </div>
  <div *ngIf="dateRangeExceeded" class="range-error">
    {{ datepickerUnit }} cannot exceed a range of
    {{ datepickerUnitRange[datepickerUnit] }} days between Start and End Dates
  </div>
</mat-card>
<div class="row mb-2" *ngIf="display">
  <div class="col-9" [class]="derogMulti.length > 0 ? 'pr-0' : ''">
    <mat-card>
      <mat-card-content>
        <div *ngIf="!loading; else dataLoading">
          <ngx-charts-bar-vertical-2d
            [view]="view"
            [scheme]="colorScheme"
            [results]="derogMulti"
            [gradient]="gradient"
            [yAxis]="showYAxis"
            [xAxis]="showXAxis"
            [legend]="showLegend"
            [showYAxisLabel]="showYAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [showXAxisLabel]="showXAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [legendTitle]="legendTitle"
            [animations]="false"
            (select)="onSelect($event)"
          >
          </ngx-charts-bar-vertical-2d>
        </div>
        <ng-template #dataLoading>
          <div class="my-4" style="margin-left: 45%">
            <mat-spinner color="primary"></mat-spinner></div
        ></ng-template>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-3" *ngIf="derogMulti.length > 0 && !loading">
    <mat-card class="h-100">
      <mat-card-title class="text-center" style="background-color: whitesmoke"
        >Details</mat-card-title
      >
      <mat-divider></mat-divider>
      <mat-card-content class="h-100">
        <div class="row h-100">
          <div class="col h-100">
            <dl class="mt-2">
              <dt>
                Main Subject
                <mat-icon matTooltip="Main Subject of an FBI Investigation"
                  >help</mat-icon
                >
              </dt>
              <dd>{{ derogatoryCounts.offenseCodeTypeMACount }}</dd>
              <dt>
                Main Subject Associate
                <mat-icon
                  matTooltip="Associate of the Main Subject of an FBI investigation"
                  >help</mat-icon
                >
              </dt>
              <dd>{{ derogatoryCounts.offenseCodeTypeAMCount }}</dd>
              <dt>
                Known Terrorist
                <mat-icon
                  matTooltip="Known Terrorist, to include leaders, members, spokesmen, or representatives of an IT group; individuals who have received military training on behalf of an FTO or IT group; and individuals who have engaged in or are likely to engage in terrorist-related activities or attacks"
                  >help</mat-icon
                >
              </dt>
              <dd>{{ derogatoryCounts.offenseCodeTypeKTCount }}</dd>
              <dt>
                Known Associate
                <mat-icon
                  matTooltip="Known Associate of an IT Group or Member, or individual who has engaged in or is likely to engage in terrorist-related activities"
                  >help</mat-icon
                >
              </dt>
              <dd>{{ derogatoryCounts.offenseCodeTypeKACount }}</dd>
              <dt>
                Terrorism Support
                <mat-icon
                  matTooltip="Involved in Providing Support for Terrorism (e.g., State House, Weapons, Identification, Terrorist Financing, etc.)"
                  >help</mat-icon
                >
              </dt>
              <dd>{{ derogatoryCounts.offenseCodeTypeTSCount }}</dd>
              <dt>
                Tenuous Assoicate
                <mat-icon matTooltip="Tenuous Associate">help</mat-icon>
              </dt>
              <dd>{{ derogatoryCounts.offenseCodeTypeTACount }}</dd>
              <dt>
                WMD materials
                <mat-icon
                  matTooltip="Involved in the Use, Transport, or Production of WMD materials or precursors"
                  >help</mat-icon
                >
              </dt>
              <dd>{{ derogatoryCounts.offenseCodeTypeCACount }}</dd>
              <dt>
                Malicious Cyber
                <mat-icon
                  matTooltip="Involved in Malicious Cyber Activities (also encompasses state-sponsored hacking activities)"
                  >help</mat-icon
                >
              </dt>
              <dd>{{ derogatoryCounts.offenseCodeTypeCYCount }}</dd>
            </dl>
          </div>
          <mat-divider vertical></mat-divider>
          <div class="col">
            <dl class="mt-2">
              <dt>
                Foreign Intel Officer
                <mat-icon
                  matTooltip="Foreign intelligence Officer, or engage in Clandestine Intelligence Activities; Espionage; Sabotage; or illegal Export of US Goods, Technology, or Sensitive/Proprietary Information"
                  >help</mat-icon
                >
              </dt>
              <dd>{{ derogatoryCounts.offenseCodeTypeEPCount }}</dd>
              <dt>
                Domestic Terrorism
                <mat-icon matTooltip="Involved in Domestic Terrorism"
                  >help</mat-icon
                >
              </dt>
              <dd>{{ derogatoryCounts.offenseCodeTypeOTCount }}</dd>
              <dt>
                Explosives or Firearms
                <mat-icon
                  matTooltip="Involved in the Use, Manufacture, or Transport of Illegal Explosives or Firearms"
                  >help</mat-icon
                >
              </dt>
              <dd>{{ derogatoryCounts.offenseCodeTypeEFCount }}</dd>
              <dt>
                Non-violent Criminal
                <mat-icon
                  matTooltip="Involved in non-violent criminal activity, to include non-CT financial criminal activity, mail fraud, wire fraud, passport fraud, visa fraud, identity fraud, vehicle theft, etc."
                  >help</mat-icon
                >
              </dt>
              <dd>{{ derogatoryCounts.offenseCodeTypeNCCount }}</dd>
              <dt>
                Narcotics Trafficker
                <mat-icon
                  matTooltip="Narcotics Trafficker, Aider, Abettor, Conspirator, or Colluder"
                  >help</mat-icon
                >
              </dt>
              <dd>{{ derogatoryCounts.offenseCodeTypeNTCount }}</dd>
              <dt>
                Smuggling
                <mat-icon matTooltip="Involved in Alien Smuggling"
                  >help</mat-icon
                >
              </dt>
              <dd>{{ derogatoryCounts.offenseCodeTypeSMCount }}</dd>
              <dt>
                Trafficking
                <mat-icon
                  matTooltip="Involved in Human Trafficking, Sex Trafficking, or Forced Labor"
                  >help</mat-icon
                >
              </dt>
              <dd>{{ derogatoryCounts.offenseCodeTypeTRCount }}</dd>
              <dt>
                Violent Crime
                <mat-icon
                  matTooltip="Involved in Violent Crime, to include violent crimes against children and child pornography"
                  >help</mat-icon
                >
              </dt>
              <dd>{{ derogatoryCounts.offenseCodeTypeVCCount }}</dd>
            </dl>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
