import { Component, Input, ViewChild } from '@angular/core';
import { Role, OrgLocation } from '@app/shared/models';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlComponent } from '@app/shared/controls/base-control.component';
import { AddressComponent } from '@app/shared/controls';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { isEmpty } from 'lodash';
@Component({
  selector: 'app-location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: LocationFormComponent,
      multi: true,
    },
  ],
})
export class LocationFormComponent extends BaseControlComponent<OrgLocation> {
  @Input() appearance: MatFormFieldAppearance = 'outline';
  Role = Role;
  invalid: boolean = true;
  @ViewChild('address') address: AddressComponent;

  constructor() {
    super();
  }

  writeValue(value: OrgLocation): void {
    this.value = value || {};
  }

  hasValidValues() {
    if (
      this.value.name &&
      this.value.organization &&
      !isEmpty(this.value.organization.name)
    ) {
      return true;
    }
    return false;
  }
}
