import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Crud, CrudService } from '@shared/services/crud.service';
import { ScreeningHistory } from '../models/screening-history.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
@Crud({
  apiUrl: `${environment.apiUrl}/screenings/export/history`,
  hasIdPathUpdate: true,
})
export class ScreeningHistoryService extends CrudService<ScreeningHistory> {
  constructor(http: HttpClient) {
    super(http);
  }

  headers = new HttpHeaders().set('Content-Type', 'application/vnd.ms-excel');

  public reexportScreeningById(id: string): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/screenings/export/history/export/` + id,
      {
        headers: this.headers,
        observe: 'response',
        responseType: 'blob' as 'json',
      }
    );
  }
}
