<div class="mfa__entry">
  <form (keydown.enter)="submit()">
    <h2>Complete Sign-In</h2>
    <p>
      Please enter the verification code that was sent to
      <strong>{{ email | async }}</strong> to complete sign-in
    </p>
    <div class="mfa-entry d-flex justify-content-between">
      <mat-form-field class="digit-input" [appearance]="'outline'">
        <input
          matInput
          [formControl]="digit1"
          name="digit1"
          #digit1el
          type="tel"
          pattern="\d*"
        />
      </mat-form-field>

      <mat-form-field class="digit-input" [appearance]="'outline'">
        <input
          matInput
          [formControl]="digit2"
          name="digit2"
          #digit2el
          type="tel"
          pattern="\d"
          maxlength="1"
        />
      </mat-form-field>

      <mat-form-field class="digit-input" [appearance]="'outline'">
        <input
          matInput
          [formControl]="digit3"
          name="digit3"
          #digit3el
          type="tel"
          pattern="\d"
          maxlength="1"
        />
      </mat-form-field>

      <mat-form-field class="digit-input" [appearance]="'outline'">
        <input
          matInput
          [formControl]="digit4"
          name="digit4"
          #digit4el
          type="tel"
          pattern="\d"
          maxlength="1"
        />
      </mat-form-field>

      <mat-form-field class="digit-input" [appearance]="'outline'">
        <input
          matInput
          [formControl]="digit5"
          name="digit5"
          #digit5el
          type="tel"
          pattern="\d"
          maxlength="1"
        />
      </mat-form-field>

      <mat-form-field class="digit-input" [appearance]="'outline'">
        <input
          matInput
          [formControl]="digit6"
          name="digit6"
          #digit6el
          type="tel"
          pattern="\d"
          maxlength="1"
        />
      </mat-form-field>
    </div>
  </form>
  <div class="d-flex flex-row">
    <button mat-button color="primary" (click)="cancelLogin()">Cancel</button>
    <span class="col"></span>
    <button class="mr-2" mat-button color="primary" (click)="resendMFA()">
      Resend Code
    </button>
    <ng-container *ngIf="(busy | async) === false; else loading">
      <button
        mat-raised-button
        color="primary"
        (keydown.enter)="submit()"
        (click)="submit()"
        [disabled]="!completeCodeEntered()"
      >
        Continue
      </button>
    </ng-container>
    <ng-template #loading>
      <button mat-raised-button color="light">
        <mat-spinner [diameter]="36"></mat-spinner>
      </button>
    </ng-template>
  </div>
</div>
